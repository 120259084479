import { Box, Link, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { client } from '../../../../Graphql/ApolloClient';
import { QL_ITEMS_BY_IDS } from '../../../../Graphql/queries';
import { ColorSwatch, Item, Maybe, Promotion } from '../../../../types';
import AddToCart from '../../../Buttons/AddToCart/AddToCart';
import { CompareDrawerButton } from '../../../CompareDrawer/CompareDrawerButton';
import { Image } from '../../../Image';
import { LeavesWarehouseIn } from '../../../Item/ItemInfo';
import { PriceDisplay } from '../../../Item/Pricing';
import { PromoFlag } from '../../../PromoCountdown/PromoCountdown';
import StarRatings from '../../../StarRatings/StarRatings';
import { LineEllipsis } from '../../../_base/BBQGuysComponents';
import { QuickView } from '../../../_base/BBQGuysQuickView';
import {
    DeleteProductCardButton,
    ItemColorSwatch,
    ProductCardColorSwatches,
    ProductCardOptions,
    ViewItemButton,
} from '../../ProductCards';
import './../../ProductCards.scss';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';
import { getEnvironmentVariable } from '../../../../config';

const ITEM_DATA = new Map();

const ProductCardDefault = (props: ProductCardOptions) => {
    const {
        item,
        withAddToCartButton = true,
        withViewItemButton = false,
        withLeadTime = true,
        lazyLoad = true,
        withItemId = false,
        withQuickView = true,
        withPricingAndInfo = true,
        withMoreOptionsAvailable = false,
        trackingType,
        trackingGroup,
        trackingItemOrder,
        onDelete,
    } = props;

    const { getPricingTierId } = React.useContext(UserAuth);
    const [quickViewVisible, setQuickViewVisible] = useState(false);
    const [quickViewFocused, setQuickViewFocused] = useState(false);

    const [currentItem, setCurrentItem] = useState(item);
    const [loading, setLoading] = useState(false);

    const imgContainerRef = useRef<HTMLAnchorElement>(null);
    const quickViewRef = useRef<HTMLDivElement>(null);

    const handleProductCardSwap = (
        itemId: string,
        colorSwatches: ItemColorSwatch,
    ): Promise<Item> => {
        return new Promise((resolve, reject) => {
            (async function () {
                if (!ITEM_DATA.has(itemId)) {
                    const response = await client.query({
                        query: QL_ITEMS_BY_IDS,
                        variables: {
                            itemIds: Object.values(colorSwatches)
                                .map((c: Maybe<ColorSwatch>, i) => {
                                    if (c) {
                                        return c.itemId;
                                    }
                                })
                                .join(','),
                            pricingTierId: getPricingTierId(),
                        },
                    });

                    if (response.error) {
                        reject();
                    }
                    if (
                        response.data &&
                        response.data.itemsByIds?.totalResults > 1
                    ) {
                        response.data.itemsByIds.results.forEach(
                            (itm: Item) => {
                                ITEM_DATA.set(itm.id, itm);
                            },
                        );
                        resolve(ITEM_DATA.get(itemId));
                    }
                } else {
                    resolve(ITEM_DATA.get(itemId));
                }
            })();
        });
    };

    const productId = currentItem.id;
    const productHasMoreOptions = currentItem?.moreOptionsAvailable ?? 0 > 1;

    return (
        <Box
            className="product-card"
            onMouseEnter={() => setQuickViewVisible(true)}
            onMouseLeave={() => setQuickViewVisible(false)}
        >
            <Box
                position="relative"
                sx={{
                    pt: {
                        xs: 1.5,
                        sm: 0,
                    },
                }}
            >
                <Link
                    className={'ga-carousel-item-link'}
                    href={currentItem.url || '#'}
                    ref={imgContainerRef}
                    data-parent-product-group={trackingGroup}
                    data-parent-product-group-type={trackingType}
                    data-item-order={trackingItemOrder}
                    data-product-id={productId}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    <Image
                        className="img"
                        src={
                            loading
                                ? getEnvironmentVariable(
                                      'REACT_APP_CLEAR_PIXEL',
                                  )
                                : (currentItem.imageUrl as string)
                        }
                        alt={''}
                        width={240}
                        height={240}
                        sx={{
                            maxWidth: '100%',
                            height: 'auto',
                            mb: 0.45,
                        }}
                        lazyLoadImage={lazyLoad}
                    />
                    {withMoreOptionsAvailable && (
                        <Typography
                            component="span"
                            variant="small"
                            sx={{
                                fontSize: theme =>
                                    theme.typography.caption.fontSize,
                                visibility: productHasMoreOptions
                                    ? 'visible'
                                    : 'hidden',
                            }}
                        >
                            {productHasMoreOptions ? (
                                <>+ More Options</>
                            ) : (
                                <>&nbsp;</>
                            )}
                        </Typography>
                    )}
                    <ProductCardColorSwatches
                        item={item}
                        onHover={(
                            swatch: ColorSwatch,
                            swatches: ItemColorSwatch,
                        ) => {
                            setLoading(true);
                            if (imgContainerRef.current) {
                                imgContainerRef.current.style.width =
                                    imgContainerRef.current?.offsetWidth + 'px';
                                imgContainerRef.current.style.height =
                                    imgContainerRef.current?.offsetHeight +
                                    'px';
                                imgContainerRef.current.style.overflow =
                                    'hidden';
                            }

                            handleProductCardSwap(swatch.itemId, swatches)
                                .then((itemData: Item) => {
                                    setCurrentItem(itemData);
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }}
                    />
                    <span className="name">
                        <LineEllipsis
                            maxLines={3}
                            text={currentItem.name}
                            component="span"
                        />
                    </span>
                </Link>
                {withQuickView && (
                    <Box
                        ref={quickViewRef}
                        tabIndex={0}
                        onFocus={() => setQuickViewFocused(true)}
                        onBlur={() => setQuickViewFocused(false)}
                        aria-label="quick view"
                        onKeyDown={() => {
                            setQuickViewVisible(true);
                        }}
                    >
                        <QuickView
                            item={currentItem}
                            visible={quickViewVisible || quickViewFocused}
                            trackingType={trackingType}
                            trackingGroup={trackingGroup}
                            trackingItemOrder={trackingItemOrder}
                        />
                    </Box>
                )}
            </Box>
            {withPricingAndInfo && (
                <>
                    <Typography
                        variant="body2"
                        component="div"
                        className="user-rating"
                        sx={{ height: '1.5rem', pt: 1, pb: 3 }}
                    >
                        {currentItem.userReviewsRating > 0 &&
                        currentItem.userReviewsCount > 0 ? (
                            <StarRatings
                                size="small"
                                rating={currentItem.userReviewsRating}
                                item={currentItem}
                                count={currentItem.userReviewsCount || 0}
                                showCountText={false}
                            />
                        ) : null}
                    </Typography>
                    {currentItem.pricing && (
                        <PriceDisplay item={currentItem} variant="small" />
                    )}
                    {withLeadTime && (
                        <LeavesWarehouseIn
                            shipsIn={currentItem.shipsIn}
                            size="small"
                        />
                    )}
                    {currentItem.promotions && currentItem.promotions[0] ? (
                        <PromoFlag
                            promotion={currentItem.promotions[0] as Promotion}
                        />
                    ) : null}
                    {withAddToCartButton ? (
                        <>
                            <AddToCart
                                item={currentItem}
                                withIcon={false}
                                fullWidth
                                trackingType={trackingType}
                                trackingGroup={trackingGroup}
                                trackingItemOrder={trackingItemOrder}
                            />
                        </>
                    ) : null}
                    {withViewItemButton && (
                        <ViewItemButton item={currentItem} />
                    )}
                    {withItemId ? (
                        <>
                            <Typography
                                variant="body2"
                                className="item-id"
                                aria-label={'Item number ' + currentItem.id}
                            >
                                #{currentItem.id}
                            </Typography>
                        </>
                    ) : null}
                </>
            )}
            <CompareDrawerButton item={item} />
            <DeleteProductCardButton item={item} onDelete={onDelete} />
        </Box>
    );
};
export default ProductCardDefault;
