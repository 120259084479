import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { ReactElement } from 'react';
import {
    IconFacebook,
    IconInstagram,
    IconPinterest,
    IconSpotify,
    IconTikTok,
    IconTwitter,
    IconYoutube,
} from '../../_base/icons/icons';

type SocialIconProps = {
    icon: ReactElement<any, any>;
    href: string;
    name: string;
};

const SocialIcon = (props: SocialIconProps) => {
    return (
        <IconButton
            className="btn-social"
            href={props.href}
            target="_blank"
            title={'Follow us on ' + props.name}
            sx={{
                border: 0,
                borderRadius: '100%',
                backgroundColor: theme => theme.palette.secondary.main,
                color: theme => theme.palette.secondary.contrastText,
                padding: theme => {
                    return { xs: theme.spacing(0.75), md: theme.spacing(1.25) };
                },
                '> svg': {
                    transitionProperty: 'transform',
                    transitionDuration: theme =>
                        theme.transitions.duration.shorter,
                    transitionTimingFunction:
                        'cubic-bezier(0.3, 0.7, 0.4, 1.5)',
                },
                '&:hover': {
                    backgroundColor: theme => theme.palette.primary.main,
                    color: theme => theme.palette.primary.contrastText,
                    '> svg': {
                        transform: 'scale(1.2)',
                    },
                },
            }}
        >
            {props.icon}
        </IconButton>
    );
};

export default function SocialMediaBar() {
    return (
        <Stack
            direction="row"
            columnGap={{ xs: 1.5, md: 4 }}
            justifyContent="center"
            pb={2}
        >
            <SocialIcon
                href="https://www.facebook.com/BBQGuys"
                icon={<IconFacebook />}
                name="Facebook"
            />
            <SocialIcon
                href="https://x.com/BBQ_Guys"
                icon={<IconTwitter />}
                name="X"
            />
            <SocialIcon
                href="https://www.youtube.com/c/BBQGuys1"
                icon={<IconYoutube />}
                name="YouTube"
            />
            <SocialIcon
                href="https://instagram.com/bbqguys"
                icon={<IconInstagram />}
                name="Instagram"
            />
            <SocialIcon
                href="https://www.pinterest.com/BBQGuys/"
                icon={<IconPinterest />}
                name="Pinterest"
            />
            <SocialIcon
                href="https://www.tiktok.com/@bbqguys"
                icon={<IconTikTok />}
                name="TikTok"
            />
            <SocialIcon
                href="https://open.spotify.com/user/wj7z356gfofpsvfh1ovfqrde9"
                icon={<IconSpotify />}
                name="Spotify"
            />
        </Stack>
    );
}
