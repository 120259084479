import { Grid } from '@mui/material';
import React from 'react';
import theme from '../../Theme';
import BBQGuysInfo from './BBQGuysInfo';
import { RenderContentWithId } from '../../Pages/PageContent/PageContent';

import window from 'global/window';

const CustomerService: React.FC = () => {
    return (
        <Grid
            container
            direction="column"
            height="100%"
            sx={{
                marginX: { md: '1%', xs: '0%' },
                width: { md: '24%', xs: '100%' },

                textAlign: { md: 'left', xs: 'left' },
                mt:
                    window.innerWidth > theme.breakpoints.values.md
                        ? theme.spacing(8)
                        : 0,

                p: theme.spacing(2),
            }}
        >
            <Grid
                container
                direction="column"
                sx={{ mb: theme.spacing(2), width: '100%' }}
            >
                <RenderContentWithId contentId="42432" />
            </Grid>
            <BBQGuysInfo />
        </Grid>
    );
};

export default CustomerService;
