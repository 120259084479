import { CloseOutlined } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React from 'react';
import { LoadingSpinner } from '../../../../../../Components/_base/BBQGuysComponents';
import { CustomerReviewsContext } from '../../../../../../Contexts/CustomerReviewsProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../../../Contexts/DeviceTypeProvider';
import theme from '../../../../../../Theme';
import { CustomerReview } from '../../../../../../types';

const CustomerReviewsUnhelpfulButton = (props: { review: CustomerReview }) => {
    const { markUnhelpful, hasInteractedWithHelpfulUnhelpful } =
        React.useContext(CustomerReviewsContext);
    const [loading, setLoading] = React.useState(false);
    const hasInteractedHelpful = hasInteractedWithHelpfulUnhelpful(
        props.review.id,
        'helpful',
    );
    const hasInteractedUnhelpful = hasInteractedWithHelpfulUnhelpful(
        props.review.id,
        'unhelpful',
    );
    const interactedWith = hasInteractedHelpful || hasInteractedUnhelpful;

    const handleClick = () => {
        setLoading(true);
        markUnhelpful(props.review).finally(() => {
            setLoading(false);
        });
    };
    return (
        <>
            <DesktopAndTabletDevice>
                <IconButton
                    disabled={interactedWith || loading}
                    onClick={() => handleClick()}
                    aria-label="Mark Unhelpful"
                    sx={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        border: `5px solid ${interactedWith ? theme.palette.divider : theme.palette.error.main}`,
                    }}
                >
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <CloseOutlined
                            fontSize="large"
                            color={interactedWith ? 'disabled' : 'error'}
                            sx={{
                                fontSize: 40,
                                '&:hover': {
                                    backgroundColor: 'rgba(162, 46, 50, .1)',
                                },
                            }}
                        />
                    )}
                </IconButton>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Button
                    disabled={interactedWith || loading}
                    onClick={() => handleClick()}
                    aria-label="Mark Unhelpful"
                    variant="link"
                >
                    No
                </Button>
            </MobileDevice>
        </>
    );
};

export default CustomerReviewsUnhelpfulButton;
