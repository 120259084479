import { useLocation } from 'react-router-dom';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { QuestionAndAnswersProvider } from '../../../../Contexts/QuestionAndAnswersProvider';
import { Item } from '../../../../types';
import QuestionsAndAnswersGrid from './Layouts/QuestionsAndAnswersGrid';

export default function QuestionsAndAnswers(props: { item: Item }) {
    const { item } = props;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchPageParam = parseInt(searchParams.get('page') as string) || 0;
    const searchSortParam = searchParams.get('sort') || 'recommended';
    const page2 = searchPageParam > 0 ? searchPageParam : 1;

    return (
        <QuestionAndAnswersProvider
            page={page2}
            sort={searchSortParam}
            item={item}
        >
            <Section title="Questions &amp; Answers" id="q-and-a">
                <QuestionsAndAnswersGrid />
            </Section>
        </QuestionAndAnswersProvider>
    );
}
