import { BoxProps } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Banner, BannerLayouts } from '../../../types';
import BannerImage from './BannerImage';
import { BannerBaseContainer } from './BannerStyledContainer';

interface BannersArrayProps extends BoxProps {
    banners: Maybe<Banner>[];
    layout: BannerLayouts;
}

const BannersByArray = ({ banners, layout, className }: BannersArrayProps) => {
    if (banners.length === 1) {
        return (
            <BannerBaseContainer className={className}>
                <BannerImage {...banners[0]} />
            </BannerBaseContainer>
        );
    }

    if (layout === BannerLayouts.HomeHero) {
        return (
            <BannerBaseContainer className={className}>
                {banners.map((banner: Maybe<Banner>) => {
                    return (
                        banner && (
                            <BannerImage
                                key={'banner-' + layout + '-' + banner.id}
                                {...banner}
                            />
                        )
                    );
                })}
            </BannerBaseContainer>
        );
    }

    const b = [...banners];
    const firstBanner = b.shift();
    return (
        <BannerBaseContainer className={className}>
            <BannerImage {...firstBanner} />
            <span className="subgrid">
                {b.map((banner: Maybe<Banner>) => {
                    return (
                        banner && (
                            <BannerImage
                                key={'banner-' + layout + '-' + banner.id}
                                {...banner}
                            />
                        )
                    );
                })}
            </span>
        </BannerBaseContainer>
    );
};

export default BannersByArray;
