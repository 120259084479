import React from 'react';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import CustomerReviewsContentDesktopLoading from './Layouts/CustomerReviewsContentDesktopLoading';
import CustomerReviewsContentMobileLoading from './Layouts/CustomerReviewsContentMobileLoading';
import CustomerReviewsHeaderLoading from './CustomerReviewsHeaderLoading';
import { Section } from '../../../../Components/_base/BBQGuysComponents';

export default function CustomerReviewsLoading() {
    return (
        <>
            <Section title="Customer Reviews" anchor="reviews" expandable>
                <CustomerReviewsHeaderLoading />
                {[...Array(10)].map((v: number, i: number) => {
                    return (
                        <React.Fragment key={'skeleton-customer-reviews-' + i}>
                            <DesktopAndTabletDevice>
                                <CustomerReviewsContentDesktopLoading />
                            </DesktopAndTabletDevice>
                            <MobileDevice>
                                <CustomerReviewsContentMobileLoading />
                            </MobileDevice>
                        </React.Fragment>
                    );
                })}
            </Section>
        </>
    );
}
