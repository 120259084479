import { gql, useQuery } from '@apollo/client';
import { Box, MenuItem, Skeleton } from '@mui/material';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import PageBreadcrumbs, {
    PageBreadcrumbsLoading,
} from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { Select } from '../../Components/_base/BBQGuysComponents';
import { BBQGuysGrid } from '../../Components/_base/BBQGuysGrid';
import {
    ExpertReviewSortOption,
    ExpertReviewsContext,
    ExpertReviewsProvider,
} from '../../Contexts/ExpertReviewsProvider';
import {
    EXPERT_REVIEW_CLASS_FIELDS,
    PAGINATED_EXPERT_REVIEW_ITEMS,
} from '../../Graphql/fragments';
import { Breadcrumb, ExpertReviewClass } from '../../types';
import { ContentDisplay } from '../PageContent/PageContent';
import { ExpertReviewItems } from './ExpertReviewItem';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { AddSchemaString } from '../../Components/Meta/CreateSchemaOrg';

export const getExpertReviewBreadcrumbsArray = (
    expertReview: ExpertReviewClass,
): Breadcrumb[] => {
    const breadCrumbs = [
        {
            title: 'Expert Reviews',
            url: '/expert-reviews',
        } as Breadcrumb,
    ];

    const parent = expertReview?.parentClass;
    if (parent && parent.id) {
        breadCrumbs.push({
            title: parent.name,
            url: parent.url,
        } as Breadcrumb);
    }

    breadCrumbs.push({
        title: expertReview.name,
        url: expertReview.url,
    });

    return breadCrumbs;
};

const QL_EXPERT_REVIEW_CLASS = gql`
    ${EXPERT_REVIEW_CLASS_FIELDS}
    ${PAGINATED_EXPERT_REVIEW_ITEMS}
    query getExpertReviewClass($id: ID!, $pricingTierId: Int) {
        expertReviewClass(id: $id) {
            ...ExpertReviewClassFields
        }
        expertReviewClassItems(id: $id, pricingTierId: $pricingTierId) {
            ...PaginatedExpertReviewItems
        }
    }
`;

const ExpertReviewGridLoading = () => {
    return (
        <ScContainer>
            <PageBreadcrumbsLoading count={1} />
            <Skeleton variant="text" height="50px" width="40%" />
            <Skeleton variant="rectangular" height="260px" />
            <br />
            <BBQGuysGrid>
                {Array.from(Array(18).keys()).map((num: number, i: number) => {
                    return (
                        <Skeleton
                            key={'exp-grid-' + i}
                            variant="rectangular"
                            height="310px"
                        />
                    );
                })}
            </BBQGuysGrid>
        </ScContainer>
    );
};

const ExpertReviewContentWithoutRatingsTable = (props: {
    expertReview: ExpertReviewClass;
}) => {
    const { expertReview } = props;

    return expertReview.content ? (
        <ContentDisplay content={expertReview.content} withIntro={true} />
    ) : (
        <></>
    );
};

const ExpertReviewSort = () => {
    const { defaultSortValue, sort, setSort, getSortOptions } =
        useContext(ExpertReviewsContext);
    const sortOptions = getSortOptions();
    return (
        <Box>
            <Select
                sx={{ width: { xs: '100%', md: 350 } }}
                labelId={'exp-review-sort'}
                label={'Sort By'}
                id={'exp-review-sort'}
                defaultValue={defaultSortValue}
                value={sort}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setSort(e.target.value)
                }
                renderValue={(val: string, t: string) => {
                    if (sortOptions.length > 0) {
                        const opts = sortOptions.filter(
                            (opt: ExpertReviewSortOption, i: number) =>
                                opt.name === val,
                        );
                        return opts[0]?.name ?? 'Unknown';
                    }
                    return 'Unknown';
                }}
                aria-description="Sort Expert Reviews"
            >
                {sortOptions.map((opt: ExpertReviewSortOption) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

const ExpertReviewFilter = () => {
    const { defaultFilterValue, filter, setFilter, getFilterOptions } =
        useContext(ExpertReviewsContext);
    const filterOptions = getFilterOptions();
    return (
        <Box>
            <Select
                sx={{ width: { xs: '100%', md: 350 } }}
                labelId={'exp-review-filter'}
                label={'Filter'}
                id={'exp-review-filter'}
                defaultValue={defaultFilterValue}
                value={filter}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setFilter(e.target.value)
                }
                renderValue={(val: string) => {
                    if (filterOptions.length > 0) {
                        const opts = filterOptions.filter(
                            (opt: ExpertReviewSortOption, i: number) =>
                                opt.name === val,
                        );
                        return opts[0]?.name ?? 'Unknown';
                    }
                    return 'Unknown';
                }}
                aria-description="Filter Expert Reviews"
            >
                {filterOptions.map((opt: ExpertReviewSortOption) => (
                    <MenuItem key={opt.value} value={opt.value}>
                        {opt.name}
                    </MenuItem>
                ))}
            </Select>
        </Box>
    );
};

const ExpertReviewSortAndFilterBar = () => {
    return (
        <ScContainer variant="alt" sx={{ p: 1, mb: 2, mx: { xs: -2, md: 0 } }}>
            <Box
                display={{ xs: 'grid', md: 'flex' }}
                gap={2}
                gridTemplateColumns={'1fr'}
            >
                <ExpertReviewSort />
                <ExpertReviewFilter />
            </Box>
        </ScContainer>
    );
};

const ExpertReviewItemsDisplay = () => {
    const { sort, getItems } = useContext(ExpertReviewsContext);
    const items = getItems();
    return (
        <>
            {items.length > 0 && (
                <>
                    <ExpertReviewSortAndFilterBar />
                    <ExpertReviewItems
                        expertReviews={items}
                        withSummary={true}
                        showRatingForField={sort}
                    />
                </>
            )}
        </>
    );
};

const ExpertReviewGrid = (props: { expertReview: ExpertReviewClass }) => {
    const { expertReview } = props;
    return (
        <>
            <PageBreadcrumbs
                breadcrumbs={getExpertReviewBreadcrumbsArray(expertReview)}
            />
            <ExpertReviewContentWithoutRatingsTable
                expertReview={expertReview}
            />
            <ExpertReviewItemsDisplay />
        </>
    );
};

const PageExpertReviewClass = (props: { expertReviewId?: string }) => {
    let { expertReviewId } = useParams();
    const { getPricingTierId } = React.useContext(UserAuth);
    if (props.expertReviewId !== undefined) {
        expertReviewId = props.expertReviewId;
    }
    const qry = QL_EXPERT_REVIEW_CLASS;
    const { data, loading } = useQuery(qry, {
        variables: {
            id: expertReviewId,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ExpertReviewGridLoading />;
    }
    const er = data.expertReviewClass;
    const items = data.expertReviewClassItems.results;

    return (
        <>
            {items[0] && items[0].contentSchema && (
                <AddSchemaString schema={items[0].contentSchema} />
            )}
            <ScContainer>
                <ExpertReviewsProvider parentClass={er} items={items}>
                    <ExpertReviewGrid expertReview={er} />
                </ExpertReviewsProvider>
            </ScContainer>
        </>
    );
};

export default PageExpertReviewClass;
