import {
    KeyboardArrowDown,
    KeyboardArrowUp,
    PlayArrow,
    PlayCircleOutlined,
} from '@mui/icons-material';
import { Skeleton, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { createRef, useMemo, useState } from 'react';
import {
    DesktopAndTabletDevice,
    MobileDevice,
    isMobile,
} from '../../Contexts/DeviceTypeProvider';
import { getEnvironmentVariable } from '../../config';
import { ImageType, Item, Maybe } from '../../types';
import { Image } from '../Image';
import Image360 from '../Image360/Image360';
import { ImageGalleryButton } from '../_base/BBQGuysStyledComponents';
import './ImageCarousel.scss';
import {
    ImageCarouselContext,
    ImageCarouselProvider,
} from '../../Contexts/ImageCarouselContext';
import { BBQGuysGrid } from '../_base/BBQGuysGrid';

export type PhotoSwipeImageData = {
    src: string;
    width: number;
    height: number;
    alt: string;
};
export const IMAGE_CAROUSEL_THUMBNAIL_SIZE = 50;

export const ImageCarouselLoading = () => {
    const { numberOfVisibleThumbnails } =
        React.useContext(ImageCarouselContext);
    return (
        <>
            <DesktopAndTabletDevice>
                <Stack spacing={2}>
                    {Array(numberOfVisibleThumbnails)
                        .fill(0)
                        .map((v, i) => {
                            return (
                                <Skeleton
                                    key={'img-thumb-skeleton-' + i}
                                    variant="rectangular"
                                    height={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                    width={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                />
                            );
                        })}
                </Stack>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Skeleton variant="rectangular" height={400} width={'100%'} />
            </MobileDevice>
        </>
    );
};

const ImageCarousel: React.FC<{
    item: Item;
    itemImages?: ImageType[];
    numberOfVisibleThumbnails?: number;
    showVideoAnd360Thumbnails?: boolean;
    zoomEnabled?: boolean;
}> = ({
    item,
    itemImages = [],
    numberOfVisibleThumbnails = 6,
    showVideoAnd360Thumbnails = true,
    zoomEnabled = true,
}) => {
    return (
        <ImageCarouselProvider
            item={item}
            itemImages={itemImages}
            numberOfVisibleThumbnails={numberOfVisibleThumbnails}
            showVideoAnd360Thumbnails={showVideoAnd360Thumbnails}
            zoomEnabled={zoomEnabled}
        >
            <MobileDevice>
                <ImageCarouselMobile />
            </MobileDevice>
            <DesktopAndTabletDevice>
                <ImageCarouselDesktop />
            </DesktopAndTabletDevice>
        </ImageCarouselProvider>
    );
};

export const openPhotoSwipe = (
    idx: number,
    images: Maybe<ImageType>[],
    callback?: (index: number) => void,
    endOfImagesCallback?: (index: number, ps: any) => void,
) => {
    if (images === undefined || images.length < 1) {
        return;
    }
    const psImages = getPhotoSwapImageData(images);
    import('photoswipe/lightbox').then(({ default: PhotoSwipeLightbox }) => {
        const ps = new PhotoSwipeLightbox({
            gallery: '#selected-image',
            children: '.pswp-gallery__item',
            showHideAnimationType: 'none',
            dataSource: psImages,
            index: idx,
            secondaryZoomLevel: 2,
            maxZoomLevel: 3,
            pswpModule: () => import('photoswipe'),
        });
        ps.on('uiRegister', function () {
            ps.pswp.ui.registerElement({
                name: 'custom-caption',
                order: 9,
                isButton: false,
                appendTo: 'root',
                html: '',
            });
        });

        ps.on('change', () => {
            if (ps.pswp.currSlide.container) {
                const currSlideElement = ps.pswp.currSlide.container.parentNode;
                let captionHTML = '';
                if (ps.pswp.currSlide.content.element) {
                    captionHTML =
                        ps.pswp.currSlide.content.element.getAttribute('alt');
                }
                if (captionHTML) {
                    let captionElement =
                        currSlideElement.querySelector('caption');
                    if (captionElement !== null) {
                        captionElement.remove();
                    }
                    captionElement = document.createElement('caption');
                    captionElement.style.background = '#333';
                    captionElement.style.position = 'absolute';
                    captionElement.style.display = 'flex';
                    captionElement.style.justifyContent = 'center';
                    captionElement.style.color = 'white';
                    captionElement.style.bottom = 0;
                    captionElement.style.padding = '1rem';

                    if (!isMobile) {
                        captionElement.style.bottom = 100;
                        captionElement.style.right = '1.5%';
                        captionElement.style.padding = '1rem';
                        captionElement.style.width = '22%';
                        captionElement.style.border = '1px solid #ccc';
                    }

                    captionElement.innerHTML = captionHTML || '';
                    currSlideElement.appendChild(captionElement);
                }
            }
        });
        ps.on('close', () => {
            if (ps.pswp?.currIndex !== idx && typeof callback === 'function') {
                callback(ps.pswp?.currIndex ?? idx);
            }
        });

        ps.on('change', () => {
            // triggers when slide is switched, and at initialization
            if (ps.pswp?.currIndex == ps.pswp.options.dataSource.length - 1) {
                if (typeof endOfImagesCallback === 'function') {
                    endOfImagesCallback(ps.pswp?.currIndex, ps);
                }
            }
        });
        ps.loadAndOpen(idx, psImages);
    });
};

export const getPhotoSwapImageData = (
    images: Maybe<ImageType>[],
): PhotoSwipeImageData[] => {
    if (images === undefined) return [];

    return images.map((img: Maybe<ImageType>, i: number) => {
        if (img) {
            return {
                src: img.url,
                width: 1200,
                height: 1200,
                alt: img.description,
            } as PhotoSwipeImageData;
        } else {
            return {} as PhotoSwipeImageData;
        }
    });
};
const ImageCarouselDesktop = () => {
    const {
        selectedImage,
        carouselSelectedImageRef,
        openPhotoViewer,
        zoomEnabled,
    } = React.useContext(ImageCarouselContext);
    return (
        <BBQGuysGrid
            className={`image-carousel-container${zoomEnabled ? '' : ' no-zoom'}`}
            id="image-gallery"
            sx={{
                gridTemplateColumns: IMAGE_CAROUSEL_THUMBNAIL_SIZE + 'px 1fr',
            }}
        >
            <ImageCarouselDesktopThumbnails />
            <div
                id="selected-image"
                className="selected-image"
                ref={el =>
                    carouselSelectedImageRef !== null
                        ? (carouselSelectedImageRef.current[0] = el)
                        : () => {}
                }
                onClick={() => openPhotoViewer()}
            >
                {selectedImage && (
                    <Image
                        src={selectedImage?.url}
                        alt={selectedImage.description ?? ''}
                        width={800}
                        height={800}
                        lazyLoadImage={false}
                    />
                )}
            </div>
        </BBQGuysGrid>
    );
};

const ImageCarouselDesktopThumbnails = () => {
    const {
        item,
        images,
        selectedImageIndex,
        carouselItemsRef,
        carouselSelectedImageRef,
        loading,
        numberOfVisibleThumbnails,
        showVideoAnd360Thumbnails,
        handleSelectedImageChange,
        handleLeftClick,
        handleRightClick,
    } = React.useContext(ImageCarouselContext);
    const showArrows = images.length > numberOfVisibleThumbnails - 1;
    const maxHeightStyleValue = `calc(${IMAGE_CAROUSEL_THUMBNAIL_SIZE}px * ${numberOfVisibleThumbnails}`;
    return (
        <>
            {loading ? (
                <ImageCarouselLoading />
            ) : (
                <div
                    className={'carousel' + (showArrows ? '' : ' no_arrows')}
                    style={{ maxHeight: maxHeightStyleValue }}
                >
                    <div
                        className={'carousel__images hide-scrollbar'}
                        style={{ maxHeight: maxHeightStyleValue }}
                    >
                        {images &&
                            images.map((image, idx) => (
                                <a
                                    href={image.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={e => {
                                        e.preventDefault();
                                        handleSelectedImageChange(idx);
                                    }}
                                    onMouseOver={() =>
                                        handleSelectedImageChange(idx)
                                    }
                                    key={'image-gallery-' + idx}
                                    className={`carousel__image ${selectedImageIndex === idx && 'carousel__image-selected'}`}
                                    ref={el =>
                                        (carouselItemsRef.current[idx] = el)
                                    }
                                >
                                    <Image
                                        src={image.url}
                                        alt={
                                            image.description + ' thumbnail' ??
                                            ''
                                        }
                                        width={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                        height={IMAGE_CAROUSEL_THUMBNAIL_SIZE}
                                        lazyLoadImage={false}
                                        data-pswp-width={1200}
                                        data-pswp-height={1200}
                                    />
                                </a>
                            ))}
                    </div>
                    {showArrows ? (
                        <>
                            <button
                                className="carousel__button carousel__button-left"
                                onClick={handleLeftClick}
                                aria-label="image carousel previous"
                            >
                                <KeyboardArrowUp />
                            </button>
                            <button
                                className="carousel__button carousel__button-right"
                                onClick={handleRightClick}
                                aria-label="image carousel next"
                            >
                                <KeyboardArrowDown />
                            </button>
                        </>
                    ) : null}
                    {showVideoAnd360Thumbnails && (
                        <Stack sx={{ mt: 5 }} spacing={1}>
                            {item?.hasVideo ? (
                                <ImageGalleryButton
                                    href={item?.videoUrl as string}
                                >
                                    <PlayArrow
                                        htmlColor="white"
                                        fontSize="large"
                                    />
                                    <Box
                                        component="span"
                                        sx={{
                                            color: 'white',
                                            display: 'block',
                                            fontSize: '0.625rem',
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        Video
                                    </Box>
                                </ImageGalleryButton>
                            ) : (
                                <></>
                            )}
                            {item?.has360Image ? (
                                <Image360
                                    itemId={item.id}
                                    elementRef={carouselSelectedImageRef}
                                />
                            ) : (
                                <></>
                            )}
                        </Stack>
                    )}
                </div>
            )}
        </>
    );
};
const ImageCarouselMobile = () => {
    const {
        item,
        images,
        openPhotoViewer,
        handleSelectedImageChange,
        loading,
        carouselItemsRef,
        carouselSelectedImageRef,
    } = React.useContext(ImageCarouselContext);
    const carouselContainerRef = createRef();
    const [scrolledIntoViewIndex, setScrolledIntoViewIndex] = useState(0);
    const [scrollStatus, setScrollStatus] = useState('scroll stopped');

    let scrollTimeout = null as any;

    const focusOnImage = (imageIndex: number) => {
        const idx0 = imageIndex - 1;
        // if (scrolledIntoViewIndex !== idx0) {
        handleSelectedImageChange(idx0);
        // }
    };
    useMemo(() => {
        if (!loading && scrollStatus === 'scroll stopped') {
            setTimeout(() => {
                focusOnImage(scrolledIntoViewIndex);
            }, 10);
        }
    }, [scrollStatus]);

    const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
        if (scrollTimeout !== null) {
            //if there is already a timeout in process cancel it
            clearTimeout(scrollTimeout);
        }

        scrollTimeout = setTimeout(() => {
            scrollTimeout = null;
            setScrollStatus('scroll stopped');
        }, 700);

        if (scrollStatus !== 'scrolling') {
            setScrollStatus('scrolling');
        }

        const containerWidth = event.currentTarget.clientWidth;
        const scrollWidth = event.currentTarget.scrollWidth;

        const scrollLeft = event.currentTarget.scrollLeft;
        const percentScrolled =
            ((scrollLeft + containerWidth) / scrollWidth) * 100;

        const imagesCnt = images?.length ?? 1;
        const idx0 = Math.floor(imagesCnt * (percentScrolled / 100) + 0.5);
        if (idx0 !== scrolledIntoViewIndex) {
            setScrolledIntoViewIndex(idx0);
        }
    };

    return (
        <>
            <Box
                sx={{
                    whiteSpace: 'nowrap',
                    overflowX: 'scroll',
                    width: '110%',
                    marginLeft: '-5%',
                    marginRight: '-5%',
                    scrollBehavior: 'smooth',
                    minHeight: '100vw',
                    '&::-webkit-scrollbar': {
                        height: '1px',
                        overflow: 'visible',
                    },
                    '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
                        borderRadius: 10,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#333',
                        height: '4px',
                        overflow: 'visible',
                        borderRadius: 10,
                    },
                }}
                onScroll={scrollHandler}
                ref={carouselContainerRef}
            >
                {images &&
                    images.map((image, idx) => (
                        <a
                            href={image.url}
                            target="_blank"
                            rel="noreferrer"
                            onClick={e => {
                                e.preventDefault();
                                handleSelectedImageChange(idx);
                            }}
                            key={idx}
                            className={`carousel__image ${
                                scrolledIntoViewIndex === idx &&
                                'carousel__image-selected'
                            }`}
                            ref={el => (carouselItemsRef.current[idx] = el)}
                            aria-hidden="true"
                        >
                            <Image
                                src={image.url}
                                alt={image.description ?? ''}
                                width={800}
                                height={800}
                                lazyLoadImage={false}
                                fullWidth
                                sx={{ width: '100%', height: 'auto' }}
                                onClick={() => openPhotoViewer()}
                                onError={({
                                    currentTarget,
                                }: {
                                    currentTarget: any;
                                }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src =
                                        getEnvironmentVariable('NO_IMAGE_URL');
                                }}
                            />
                        </a>
                    ))}
            </Box>
            {loading ? (
                <Box textAlign="right">
                    <Skeleton
                        variant="text"
                        width="4rem"
                        height="1.5rem"
                        sx={{ display: 'inline-block' }}
                    />
                </Box>
            ) : (
                <>
                    {images !== undefined && images.length > 1 ? (
                        <Box textAlign="right">
                            <Typography variant="bold">
                                {scrolledIntoViewIndex < 1
                                    ? 1
                                    : scrolledIntoViewIndex}{' '}
                                of {images.length}
                            </Typography>
                        </Box>
                    ) : null}
                </>
            )}
            {(item?.has360Image || item?.hasVideo) && (
                <Stack
                    direction="row"
                    gap={2}
                    alignItems="center"
                    justifyContent="space-evenly"
                    marginBottom={1}
                >
                    {item?.hasVideo ? (
                        <>
                            <ImageGalleryButton href={item?.videoUrl as string}>
                                <PlayCircleOutlined />
                                Watch Video
                            </ImageGalleryButton>
                        </>
                    ) : (
                        <></>
                    )}
                    {item?.has360Image ? (
                        <Image360
                            itemId={item.id}
                            elementRef={carouselSelectedImageRef}
                        />
                    ) : (
                        <></>
                    )}
                </Stack>
            )}
        </>
    );
};

export default ImageCarousel;
