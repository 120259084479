import { Helmet } from 'react-helmet-async';
import ScContainer from '../../../Components/Layout/ScContainer/ScContainer';
import {
    FullPageIconBanner,
    LoadingSpinner,
} from '../../../Components/_base/BBQGuysComponents';
import { gql } from '@apollo/client';
import { BASIC_ORDER_FIELDS } from '../../../Graphql/fragments';
import { CartContext, CartProvider } from '../../../Contexts/CartProvider';
import { useContext, useState } from 'react';
import { ReceiptLong } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import window from 'global/window';
import { SSRContext } from '../../../Contexts/SSRProvider';
import { client } from '../../../Graphql/ApolloClient';
import { Box } from '@mui/material';

const QL_CART_EXTERNAL_LINK_QUOTE = gql`
    ${BASIC_ORDER_FIELDS}
    mutation getCartExternalLinkQuote($secret: String!) {
        cartExternalLinkQuote(secret: $secret) {
            order {
                ...BasicOrderFields
            }
        }
    }
`;

let loadingExternalLink = false;

const FetchRedirect = ({ secret }: { secret: string }) => {
    const { setOrderIntoLocalStorage, getOrderFromServer } =
        useContext(CartContext);
    const { isClientSide } = useContext(SSRContext);

    const [showOrderNotFound, setShowOrderNotFound] = useState(false);

    if (!isClientSide()) {
        return <></>;
    }

    if (!loadingExternalLink) {
        loadingExternalLink = true;
        (async () => {
            const response = await client.mutate({
                mutation: QL_CART_EXTERNAL_LINK_QUOTE,
                variables: {
                    secret: secret,
                },
                fetchPolicy: 'no-cache',
            });

            if (
                response.errors ||
                response.data?.cartExternalLinkQuote.order === null
            ) {
                setShowOrderNotFound(true);
            }
            if (response.data && response.data.cartExternalLinkQuote.order) {
                getOrderFromServer(
                    response.data.cartExternalLinkQuote.order.id,
                    false,
                )
                    .then(o => {
                        setOrderIntoLocalStorage(o);
                        if (isClientSide() && window && window.location) {
                            setTimeout(() => {
                                window.location = '/cart';
                            }, 500);
                        }
                    })
                    .catch(o => {
                        setOrderIntoLocalStorage(o);
                        if (isClientSide() && window && window.location) {
                            setTimeout(() => {
                                window.location = '/cart';
                            }, 500);
                        }
                    });
            }
        })();
    }

    return showOrderNotFound ? (
        <FullPageIconBanner
            icon={<ReceiptLong />}
            title="Order not found"
            subtitle="The order could not be found in our system. The order may have expired or been canceled."
        />
    ) : (
        <LoadingSpinner center />
    );
};

export default function PageCartExternalLink() {
    const location = useLocation();
    const secret = new URLSearchParams(location.search).get('s') ?? '';
    return (
        <CartProvider>
            <ScContainer>
                <Helmet>
                    <title>Cart External Link</title>
                </Helmet>
                <ScContainer size="small">
                    <Box sx={{ mt: 3 }}>
                        <FetchRedirect secret={secret} />
                    </Box>
                </ScContainer>
            </ScContainer>
        </CartProvider>
    );
}
