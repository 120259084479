import { Typography } from '@mui/material';
import PageBanners from '../../../Components/Banners/Banners';
import { Image } from '../../../Components/Image';
import { BulletedList } from '../../../Components/_base/BBQGuysComponents';
import VideoPlayer from '../../../Components/_base/VideoPlayer';
import { Brand } from '../../../types';
import {
    DesktopAndTabletDevice,
    DesktopDevice,
    MobileDevice,
    TabletDevice,
} from '../../../Contexts/DeviceTypeProvider';
import BrandTitle from './BrandTitle';
import { getEnvironmentVariable } from '../../../config';
import { BBQGuysGrid } from '../../../Components/_base/BBQGuysGrid';
import { empty } from '../../../functions';

const BrandAbout = ({ brand }: { brand: Brand }) => {
    return !empty(brand.description) || !empty(brand.about) ? (
        <div>
            <Typography component="h2" variant="subtitle" sx={{ marginTop: 2 }}>
                Why We Love {brand.name}
            </Typography>
            <Typography variant="body1">{brand.description}</Typography>
            <Typography variant="body1">{brand.about}</Typography>
        </div>
    ) : (
        <></>
    );
};
const BrandFeatures = ({ brand }: { brand: Brand }) => {
    return !empty(brand.features) ? (
        <div>
            <Typography
                component="h2"
                variant="subtitle"
                sx={{ marginTop: { xs: 1, md: 2 } }}
            >
                {brand.name} Features
            </Typography>
            {brand.features && <BulletedList bullets={brand.features} />}
        </div>
    ) : (
        <></>
    );
};
const BrandVideo = ({ brand }: { brand: Brand }) => {
    return (
        <div>
            {brand?.videoUrl && brand?.videoImageUrl ? (
                <VideoPlayer
                    src={brand.videoUrl}
                    image={brand.videoImageUrl}
                    title={brand.name}
                    height={200}
                />
            ) : brand?.imageUrl &&
              brand?.imageUrl !==
                  getEnvironmentVariable('REACT_APP_NO_IMAGE_URL') ? (
                <Image
                    src={brand.imageUrl}
                    alt={brand.name + ' image'}
                    height={100}
                    fullWidth
                />
            ) : null}
        </div>
    );
};

const BrandHeader = ({ brand }: { brand: Brand }) => {
    return (
        <>
            <DesktopAndTabletDevice>
                <BrandTitle brand={brand} />
                <PageBanners />
            </DesktopAndTabletDevice>
            <DesktopDevice>
                <BBQGuysGrid gap={3} sx={{ mb: 2 }} columns="1fr 320px 350px">
                    <BrandAbout brand={brand} />
                    <BrandFeatures brand={brand} />
                    <BrandVideo brand={brand} />
                </BBQGuysGrid>
            </DesktopDevice>
            <TabletDevice>
                <BBQGuysGrid gap={3} sx={{ mb: 2 }} columns="2fr 1fr">
                    <BrandAbout brand={brand} />
                    <BrandVideo brand={brand} />
                </BBQGuysGrid>
                <BrandFeatures brand={brand} />
            </TabletDevice>
            <MobileDevice>
                <Image
                    src={brand.logoUrl ?? ''}
                    alt={brand.name + ' logo'}
                    height={100}
                    width="auto"
                    sx={{ maxWidth: '100%', objectFit: 'contain' }}
                />
            </MobileDevice>
        </>
    );
};

export default BrandHeader;
