import { gql, useQuery } from '@apollo/client';
import { Grid, Skeleton, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageBreadcrumbs, {
    PageBreadcrumbsLoading,
} from '../../Components/Breadcrumbs/PageBreadcrumbs';
import { CustomersAlsoBoughtCarousel } from '../../Components/CustomersAlsoBoughtCarousel/CustomersAlsoBoughtCarousel';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import ProductCarousel from '../../Components/ProductCarousels/ProductCarousel/ProductCarousel';
import {
    EXPERT_REVIEW_CLASS_FIELDS,
    PAGINATED_ITEMS,
} from '../../Graphql/fragments';
import { Item } from '../../types';
import ExpertReviewContentWithRatingsTable from './ExpertReviewContentWithRatingsTable';
import { getExpertReviewBreadcrumbsArray } from './PageExpertReviewClass';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { useContext } from 'react';

const QL_EXPERT_REVIEW = gql`
    ${EXPERT_REVIEW_CLASS_FIELDS}
    ${PAGINATED_ITEMS}
    query getExpertReview($id: ID!, $pricingTierId: Int) {
        expertReviewClass(id: $id) {
            ...ExpertReviewClassFields
        }
        expertReviewRelatedItems(id: $id, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

const ExpertReviewRelatedItemsCarousel = (props: { items: Item[] }) => {
    const { items } = props;
    return <ProductCarousel items={items} title="Shop the Collection" />;
};

export const ExpertReviewContentLoading = () => {
    return (
        <ScContainer>
            <PageBreadcrumbsLoading count={4} />
            <Skeleton variant="text" height="50px" width="40%" sx={{ mb: 2 }} />
            <Grid container spacing={3}>
                <Grid item md={8}>
                    {Array(6)
                        .fill(0)
                        .map((n: number, i: number) => {
                            return (
                                <Skeleton
                                    key={'txt-' + i}
                                    variant="text"
                                    width="40%"
                                />
                            );
                        })}
                    <Skeleton variant="text" width="20%" sx={{ mb: 2 }} />
                    {Array(30)
                        .fill(0)
                        .map((n: number, i: number) => {
                            return (
                                <Skeleton
                                    key={'txt-' + i}
                                    variant="text"
                                    width="100%"
                                />
                            );
                        })}
                    <Skeleton variant="text" width="60%" />
                </Grid>
                <Grid item md={4}>
                    <Skeleton variant="rectangular" width="100%" height={200} />
                    <br />
                    <Skeleton variant="rectangular" width="100%" height={800} />
                </Grid>
            </Grid>
        </ScContainer>
    );
};

const PageExpertReview = (props: { expertReviewId?: string }) => {
    let { expertReviewId } = useParams();
    const { getPricingTierId } = useContext(UserAuth);
    if (props.expertReviewId !== undefined) {
        expertReviewId = props.expertReviewId;
    }
    const qry = QL_EXPERT_REVIEW;
    const { data, loading } = useQuery(qry, {
        variables: {
            id: expertReviewId,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ExpertReviewContentLoading />;
    }
    const relatedItems = data.expertReviewRelatedItems;
    const expertReview = data.expertReviewClass;
    return (
        <>
            <ScContainer>
                <PageBreadcrumbs
                    breadcrumbs={getExpertReviewBreadcrumbsArray(expertReview)}
                />
                <Typography variant="title" component="h1" sx={{ mb: 3 }}>
                    {expertReview.name}
                </Typography>
                <ExpertReviewContentWithRatingsTable
                    expertReview={expertReview}
                />

                {relatedItems && relatedItems.results && (
                    <>
                        <ExpertReviewRelatedItemsCarousel
                            items={relatedItems.results}
                        />
                        <CustomersAlsoBoughtCarousel
                            itemIds={relatedItems.results.map(
                                (item: Item, i: number) => item.id,
                            )}
                        />
                    </>
                )}
            </ScContainer>
        </>
    );
};

export default PageExpertReview;
