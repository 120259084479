import { Link, Typography, Stack, SxProps } from '@mui/material';
import StarRatings from '../../../../Components/StarRatings/StarRatings';
import { Item } from '../../../../types';

interface ItemMetaProps {
    item: Item;
    withBrand?: boolean;
    sx?: SxProps;
}

const ItemMetaComponent = ({ item, withBrand = true }: ItemMetaProps) => {
    return (
        <>
            <Stack direction="column" sx={{ mb: 0 }}>
                <Stack direction="row" sx={{ gap: 1, whiteSpace: 'nowrap' }}>
                    <Typography
                        component="span"
                        variant="body2"
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        ID # {item.id}
                    </Typography>
                    {item.modelNumber.length > 0 &&
                        item.modelNumber.split('+').length < 2 && (
                            <Typography
                                component="span"
                                variant="body2"
                                sx={{ whiteSpace: 'nowrap' }}
                            >
                                Model # {item.modelNumber}
                            </Typography>
                        )}
                </Stack>
            </Stack>
            <Stack
                direction="column"
                sx={{ mb: 0, gap: 1, whiteSpace: 'nowrap' }}
            >
                <Typography component="h1" variant="pageTitle">
                    {item.name}
                </Typography>
                {withBrand && (
                    <div>
                        <Link href={item.manufacturerUrl} underline="always">
                            {item.manufacturerName}
                        </Link>
                    </div>
                )}
                <StarRatings
                    rating={item.userReviewsRating}
                    item={item}
                    count={item.userReviewsCount}
                    showCountText={false}
                />
            </Stack>
        </>
    );
};

export const ItemMeta = ({
    item,
    withBrand = true,
    sx = {},
}: ItemMetaProps) => (
    <Typography
        variant="itemPageMeta"
        className="item-meta"
        component="div"
        sx={sx}
    >
        <ItemMetaComponent item={item} withBrand={withBrand} />
    </Typography>
);
