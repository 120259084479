import document from 'global/document';
import window from 'global/window';
import { getEnvironmentVariable, isProduction } from './config';
import { Maybe, Order } from './types';
import Cookies, { CookieSetOptions } from 'universal-cookie';
export const DEVICE_MOBILE = 'Mobile';
export const DEVICE_TABLET = 'Tablet';
export const DEVICE_DESKTOP = 'Desktop';
export let CookieContext = new Cookies(null, { path: '/' });

export const setCookieContext = (cookies: string) => {
    CookieContext = new Cookies(cookies, { path: '/' });
    return CookieContext;
};

// Create our number formatter.
export const numberFormatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 3,
});
export const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
export const datetime = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'short',
    timeStyle: 'short',
    timeZone: 'America/Chicago',
});
export const date = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
    timeZone: 'America/Chicago',
});
export const dateShort = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'short',
    timeZone: 'America/Chicago',
});

export const slugify = (text: string | Maybe<string> | undefined) => {
    return typeof text !== 'string'
        ? ''
        : text
              .toString() // Cast to string (optional)
              .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
              .toLowerCase() // Convert the string to lowercase letters
              .trim() // Remove whitespace from both sides of a string (optional)
              .replace(/\s+/g, '-') // Replace spaces with -
              .replace(/[^\w-]+/g, '') // Remove all non-word chars
              .replace(/--+/g, '-'); // Replace multiple - with single -
};

export const hasParent = (e: any, id: string) => {
    if (!e) {
        return false;
    }
    let el = e.target || e.srcElement || e || false;
    while (el && el.id != id) {
        el = el.parentNode || false;
    }
    return el !== false;
};

export const randomNumber = (min: number, max: number) => {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
};
export const getCdnLink = (
    src: string,
    width: string | number = 500,
    height: string | number = 500,
): string => {
    // min and max included
    if (
        src === undefined ||
        src === null ||
        typeof src !== 'string' ||
        src.length < 1
    ) {
        return getEnvironmentVariable('NO_IMAGE_URL');
    }
    const sizes = [75, 250, 500, 800, 1200];
    let maxSize = 1200;
    if (typeof width == 'number') {
        if (typeof height == 'number' && height > width) {
            maxSize = height;
        } else {
            maxSize = width;
        }
    } else if (typeof height == 'number') {
        maxSize = height;
    }
    let selectedSize = maxSize;
    for (let i = sizes.length - 1; i >= 0; i--) {
        if (maxSize <= sizes[i]) {
            if (sizes[i + 1]) {
                selectedSize = sizes[i + 1];
            } else {
                selectedSize = sizes[i];
            }
        } else {
            break;
        }
    }

    // if our url already has image resize refinements, lets remove them and apply new ones that meet our consistency standards
    if (
        src.indexOf('?i10c=') > -1 &&
        src.match(/\.(jpeg|jpg|gif|png)$/) != null
    ) {
        const url = new URL(src);
        url.searchParams.delete('i10c');
        src = url.toString();
    }

    if (src.indexOf('sc-site.s3.amazonaws.com') > -1) {
        src = src.replace('sc-site.s3.amazonaws.com', 'cdn.shocho.co/sc-site');
    }

    return (
        src +
        (src.indexOf('cdn.shocho.co') === -1 || src.indexOf('?') > -1
            ? ''
            : '?i10c=img.resize(width:' +
              selectedSize +
              ',height:' +
              selectedSize +
              ')')
    );
};

export const alphanumericTextOnly = (txt: string) => {
    return empty(txt) ? '' : txt.replace(/[^\w\s\\%\\-]/gi, '');
};

export const empty = (mixedVar: any) => {
    //  discuss at: https://locutus.io/php/empty/
    // original by: Philippe Baumann
    //    input by: Onno Marsman (https://twitter.com/onnomarsman)
    //    input by: LH
    //    input by: Stoyan Kyosev (https://www.svest.org/)
    // bugfixed by: Kevin van Zonneveld (https://kvz.io)
    // improved by: Onno Marsman (https://twitter.com/onnomarsman)
    // improved by: Francesco
    // improved by: Marc Jansen
    // improved by: Rafał Kukawski (https://blog.kukawski.pl)
    //   example 1: empty(null)
    //   returns 1: true
    //   example 2: empty(undefined)
    //   returns 2: true
    //   example 3: empty([])
    //   returns 3: true
    //   example 4: empty({})
    //   returns 4: true
    //   example 5: empty({'aFunc' : function () { alert('humpty'); } })
    //   returns 5: false
    let undef;
    let key;
    let i;
    let len;
    const emptyValues = [undef, null, false, 0, '', '0'];
    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true;
        }
    }
    if (typeof mixedVar === 'object') {
        for (key in mixedVar) {
            if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
                return false;
            }
        }
        return true;
    }
    return false;
};

export const findAncestor = (el: any, sel: any) => {
    while (
        (el = el.parentElement) &&
        !el.classList.contains(sel) &&
        !(el.matches || el.matchesSelector).call(el, sel)
    );
    return el;
};

export const doesScriptExist = (url: string) => {
    return (
        document &&
        typeof document.querySelectorAll === 'function' &&
        document.querySelectorAll(`script[src="${url}"]`).length > 0
    );
};

export const highlightString = (text: string, highlightPhrase: string) => {
    if (
        highlightPhrase == '' ||
        (highlightPhrase && highlightPhrase.length < 1)
    ) {
        return text;
    }
    const esc = highlightPhrase.replace(/([-/\\^$*+?.()|[\]{}])/g, '\\$&');
    const reg = new RegExp(esc, 'ig');
    return text.replace(
        reg,
        (a, b) => "<span class='highlight'>" + a + '</span>',
    );
};

export const loadScript = (
    url: string,
    callback?: () => void,
    reload?: boolean,
): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!doesScriptExist(url) || reload) {
            const script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.onload = () => {
                if (callback) callback();
                resolve();
            };
            script.onerror = (error: any) => {
                reject(error);
            };
            document.body.appendChild(script);
        } else {
            reject(new Error('Script already exists'));
        }
    });
};

export const loadAffirmScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
        if (!doesScriptExist(getEnvironmentVariable('AFFIRM_ENDPOINT'))) {
            const script = document.createElement('script');
            script.innerHTML = `_affirm_config = {
                public_api_key:  '${getEnvironmentVariable('AFFIRM_PUBLIC_KEY')}',
                script:          '${getEnvironmentVariable('AFFIRM_ENDPOINT')}',
                locale: "en_US",
                country_code: "USA",
              };
            (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=
            !0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");
          `;
            document.body.appendChild(script);
            affirm.ui.ready(() => {
                resolve();
            });
        } else {
            resolve();
        }
    });
};
export const setDualCookie = (
    name: string,
    value: string,
    expires?: Date,
    path?: string,
) => {
    setCookie(name, value, expires, path);
    // setCookie(name, value, expires, path, getCookieDomain());
};

// name - name of the cookie
// value - value of the cookie
// [expires] - expiration date of the cookie (defaults to end of current session)
// [path] - path for which the cookie is valid (defaults to path of calling document)
// [domain] - domain for which the cookie is valid (defaults to domain of calling document)
// [secure] - Boolean value indicating if the cookie transmission requires a secure transmission
// * an argument defaults when it is assigned null as a placeholder
// * a null placeholder is not required for trailing omitted arguments
export const setCookie = (
    name: string,
    value: string,
    expires?: Date,
    path?: string,
    domain?: string,
    secure?: boolean,
) => {
    const isSecure = window.location.protocol == 'https:' ? true : false;
    CookieContext.set(name, value, {
        expires: expires,
        path: path,
        domain: domain,
        secure: secure || isSecure,
    });
};

// name - name of the desired cookie
// * return string containing value of specified cookie or null if cookie does not exist
export const getCookie = (name: string) => {
    return CookieContext.get(name);
};

// name - name of the cookie
// [path] - path of the cookie (must be same as path used to create cookie)
// [domain] - domain of the cookie (must be same as domain used to create cookie)
// * path and domain default if assigned null or omitted if no explicit argument proceeds
export const deleteCookie = (name: string, path?: string, domain?: string) => {
    const options: CookieSetOptions = {};
    if (path) {
        options.path = path;
    }
    if (domain) {
        options.domain = domain;
    }
    CookieContext.remove(name, options);
};

export const decodeHTMLEntities = (str: string): string => {
    const entities: { [key: string]: string } = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&#39;': "'",
        '&nbsp;': ' ',
        '&copy;': '©',
        '&reg;': '®',
        '&euro;': '€',
        '&trade;': '™',
        '&ndash;': '–',
        '&mdash;': '—',
        '&iexcl;': '¡',
        '&cent;': '¢',
        '&pound;': '£',
        '&yen;': '¥',
        '&sect;': '§',
        '&para;': '¶',
        '&laquo;': '«',
        '&raquo;': '»',
        '&lsquo;': '‘',
        '&rsquo;': '’',
        '&ldquo;': '“',
        '&rdquo;': '”',
    };
    return str.replace(
        new RegExp(Object.keys(entities).join('|'), 'g'),
        (match: string) => entities[match],
    );
};

export const scrollToHash = (hash: string | undefined = undefined) => {
    if (window === undefined || document === undefined) {
        return;
    }
    const url = new URL(window.location.href);
    if (hash === undefined) {
        hash = url.hash.substring(1);
    }
    if (hash) {
        const selector = document.querySelector('[id="' + hash + '"]');
        if (selector) {
            selector.scrollIntoView();
        }
        if (hash) {
            const selector = document.querySelector('[id="' + hash + '"]');
            if (selector) {
                selector.scrollIntoView();
            }
        }
    }
};

export const sanitize = (str: string): string => {
    const map: { [key: string]: string } = {
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;',
    };
    const reg = /[<>"'/]/gi;
    return str.replace(reg, (match: string) => map[match] as string);
};

export const dollarsToCents = (amount: number): number => {
    if (amount === 0) {
        return 0;
    }
    return Number(amount.toFixed(2).replace('.', ''));
};

export const formatPhoneNumber = (str: string): string => {
    //Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '');

    //Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    return str;
};

export const getGraphQLErrorStatus = (error: any): number | null => {
    if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
        return error.graphQLErrors[0]?.extensions?.response?.status ?? null;
    }
    return null;
};

export const getOldSiteDomain = (domainOnly = false) => {
    if (window.location.hostname.indexOf('local') > -1) {
        return (!domainOnly ? 'https://' : '') + 'local.bbqguys.com';
    } else if (window.location.hostname.indexOf('test') > -1) {
        return (!domainOnly ? 'https://' : '') + 'testing-lb.bbqguys.com';
    } else if (window.location.hostname.indexOf('staging') > -1) {
        return (!domainOnly ? 'https://' : '') + 'staging.bbqguys.com';
    } else {
        return (!domainOnly ? 'https://' : '') + 'www.bbqguys.com';
    }
};

export const getCookieDomain = () => {
    const hostname = window.location.hostname;
    if (isProduction() && hostname.includes('www')) {
        return `www.bbqguys.com`;
    }
    return hostname;
};

export const areWeTestingWithJest = () => {
    return process.env.JEST_WORKER_ID !== undefined;
};

export const findDigitalDataEntry = (
    path: string[],
    callback?: (current: object) => void,
) => {
    if (typeof window.digitalData == 'undefined') {
        return '';
    }
    let current = window.digitalData;
    for (let i = 0; i < path.length; ++i) {
        if (Object.prototype.hasOwnProperty.call(current, path[i])) {
            current = current[path[i]];
        } else {
            return '';
        }
    }
    try {
        if (typeof callback === 'function') {
            return callback(current);
        } else {
            return current;
        }
    } catch (e) {
        return '';
    }
};

export const getFakeOrderNumber = (order: Order) => {
    return `N2${order.id}`;
};
