import { gql, useQuery } from '@apollo/client';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import {
    Box,
    Button,
    DialogContent,
    Grid,
    Link,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { Image } from '../../../../Components/Image';
import ScContainer from '../../../../Components/Layout/ScContainer/ScContainer';
import {
    ProductCard,
    ProductCardLoading,
} from '../../../../Components/ProductCards/ProductCards';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import Modal from '../../../../Components/_base/BBQGuysModal';
import {
    DesktopAndTabletDevice,
    isDesktop,
    isMobile,
    isTablet,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { PAGINATED_ITEMS } from '../../../../Graphql/fragments';
import { getEnvironmentVariable } from '../../../../config';
import { Item, RelatedDepartment } from '../../../../types';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';

export const groupArray = (arr: any[], n: number) => {
    const chunkLength = n;
    const chunks = [];
    for (let i = 0; i < n; i++) {
        if (chunkLength * (i + 1) <= arr.length)
            chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
};

const QL_ITEM_RELATED_DEPTS = gql`
    query getItemRelatedDepts($itemId: ID) {
        itemRelatedDepartments(itemId: $itemId) {
            results {
                id
                title
                imageUrl
                url
            }
        }
    }
`;
const QL_ITEM_RELATED_DEPT_ITEMS = gql`
    ${PAGINATED_ITEMS}
    query getItemRelatedDeptItems(
        $itemId: ID
        $deptId: ID
        $pricingTierId: Int
    ) {
        itemRelatedDepartmentItems(
            itemId: $itemId
            deptId: $deptId
            pricingTierId: $pricingTierId
        ) {
            ...PaginatedItems
        }
    }
`;

export const RelatedDepartmentItemsGrid = (props: {
    item: Item;
    dept: RelatedDepartment;
}) => {
    const { item, dept } = props;
    const { getPricingTierId } = React.useContext(UserAuth);
    const { data, loading } = useQuery(QL_ITEM_RELATED_DEPT_ITEMS, {
        variables: {
            itemId: item.id,
            deptId: dept.id,
            pricingTierId: getPricingTierId(),
        },
        ssr: false,
    });

    if (loading) {
        return (
            <Grid container spacing={1}>
                {[...Array(10)].map((num: number, i: number) => {
                    return (
                        <Grid key={i} item xs={6} sm={4} md={4} lg={2.4}>
                            <ProductCardLoading />
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
    return (
        <>
            <Grid container spacing={1}>
                {data.itemRelatedDepartmentItems.results.map(
                    (item: Maybe<Item>, i: number) => {
                        return item ? (
                            <Grid key={i} item xs={6} sm={4} md={4} lg={2.4}>
                                <ProductCard
                                    item={item}
                                    withAddToCartButton={true}
                                />
                            </Grid>
                        ) : null;
                    },
                )}
            </Grid>
            <Box textAlign="center" mt={1}>
                View All <Link href={dept.url}>{dept.title}</Link>
            </Box>
        </>
    );
};

export default function RelatedDepartments(props: { item: Item }) {
    const { item } = props;
    const DEFAULT_OPEN_STATE = { group: -1, dept: {} as RelatedDepartment };
    const [openGroup, setOpenGroup] = React.useState(DEFAULT_OPEN_STATE);
    const { data, loading } = useQuery(QL_ITEM_RELATED_DEPTS, {
        variables: {
            itemId: item.id,
        },
    });

    const elmsPerRow = isDesktop ? 5 : isTablet ? 3 : 2;
    if (loading) {
        return (
            <Section
                title="Related Departments"
                expandable={true}
                open={false}
                numberOfElements={elmsPerRow}
                elementsPerRow={elmsPerRow}
            >
                <Grid container spacing={4}>
                    {[...Array(elmsPerRow * 2)].map((v, i) => {
                        return (
                            <Grid
                                item
                                textAlign="center"
                                key={i}
                                xs={6}
                                md={4}
                                lg={2.4}
                            >
                                <Skeleton
                                    variant="rectangular"
                                    width={230}
                                    height={230}
                                    sx={{ display: 'inline-block' }}
                                />
                                <Skeleton
                                    variant="text"
                                    width="50%"
                                    sx={{ display: 'inline-block' }}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </Section>
        );
    }
    if (
        data.itemRelatedDepartments &&
        data.itemRelatedDepartments.results.length > 0
    ) {
        let deptChunks = [data.itemRelatedDepartments.results];
        if (!isMobile) {
            deptChunks = groupArray(data.itemRelatedDepartments.results, 5);
        }
        if (deptChunks.length === 0) {
            return <></>;
        }
        return (
            <Section
                title="Related Departments"
                expandable={true}
                open={openGroup.group > -1}
                numberOfElements={data.itemRelatedDepartments.results.length}
                elementsPerRow={elmsPerRow}
            >
                {deptChunks.map((depts: RelatedDepartment[], i: number) => {
                    return (
                        <React.Fragment key={i}>
                            <Grid container spacing={4}>
                                {depts
                                    .filter((dept: RelatedDepartment) => {
                                        return !(
                                            dept.imageUrl === null ||
                                            dept.imageUrl ===
                                                getEnvironmentVariable(
                                                    'NO_IMAGE_URL',
                                                )
                                        );
                                    })
                                    .map(
                                        (
                                            dept: RelatedDepartment,
                                            n: number,
                                        ) => {
                                            const isOpen =
                                                openGroup.group === i &&
                                                openGroup.dept &&
                                                openGroup.dept.id == dept.id;
                                            const color = isOpen
                                                ? 'primary'
                                                : 'secondary';
                                            return (
                                                <Grid
                                                    item
                                                    key={i + '-' + n}
                                                    xs={6}
                                                    md={4}
                                                    lg={2.4}
                                                >
                                                    <Button
                                                        variant="text"
                                                        color={color}
                                                        onClick={() =>
                                                            setOpenGroup(
                                                                isOpen
                                                                    ? DEFAULT_OPEN_STATE
                                                                    : {
                                                                          group: i,
                                                                          dept: dept,
                                                                      },
                                                            )
                                                        }
                                                        sx={{ p: 2 }}
                                                    >
                                                        <Stack alignItems="center">
                                                            <Image
                                                                src={
                                                                    dept.imageUrl ||
                                                                    getEnvironmentVariable(
                                                                        'NO_IMAGE_URL',
                                                                    )
                                                                }
                                                                width={230}
                                                                height={230}
                                                                sx={{
                                                                    mb: 2,
                                                                    width: '100%',
                                                                    height: '100%',
                                                                }}
                                                                aria-labelledby={
                                                                    'related-dept-' +
                                                                    dept.id
                                                                }
                                                            />
                                                            <Typography
                                                                variant="bold"
                                                                color={color}
                                                                id={
                                                                    'related-dept-' +
                                                                    dept.id
                                                                }
                                                            >
                                                                {dept.title}
                                                            </Typography>

                                                            <DesktopAndTabletDevice>
                                                                {isOpen ? (
                                                                    <KeyboardArrowUp
                                                                        color={
                                                                            color
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <KeyboardArrowDown
                                                                        color={
                                                                            color
                                                                        }
                                                                    />
                                                                )}
                                                            </DesktopAndTabletDevice>
                                                        </Stack>
                                                    </Button>
                                                </Grid>
                                            );
                                        },
                                    )}
                            </Grid>
                            <DesktopAndTabletDevice>
                                {openGroup.group === i &&
                                openGroup.dept.id !== undefined ? (
                                    <ScContainer variant="alt" sx={{ p: 2 }}>
                                        <RelatedDepartmentItemsGrid
                                            item={item}
                                            dept={openGroup.dept}
                                        />
                                    </ScContainer>
                                ) : null}
                            </DesktopAndTabletDevice>
                            <MobileDevice>
                                <Modal
                                    open={openGroup.dept.id !== undefined}
                                    title={'Related Items'}
                                    fullScreen
                                    handleClose={() =>
                                        setOpenGroup(DEFAULT_OPEN_STATE)
                                    }
                                >
                                    <DialogContent>
                                        <RelatedDepartmentItemsGrid
                                            item={item}
                                            dept={openGroup.dept}
                                        />
                                    </DialogContent>
                                </Modal>
                            </MobileDevice>
                        </React.Fragment>
                    );
                })}
            </Section>
        );
    }
    return <></>;
}
