import { ApolloLink } from '@apollo/client';
import { Request } from 'express';
import { window } from 'global/window';
import { COOKIE_JWT, getJsonWebToken } from '../Contexts/UserAuthProvider';
import { CookieContext } from '../functions';

const getJsonWebTokenSSR = (req: Request | undefined) => {
    if (req) {
        return CookieContext.get(COOKIE_JWT);
    }
    return getJsonWebToken();
};

// handle authentication to pass to graphql so we can make authenticated requests to the API server
export default function getAuthMiddleware(
    isSSR = false,
    req: Request | undefined,
) {
    return new ApolloLink((operation, forward) => {
        const headers = {} as any;
        const jsonWebToken = getJsonWebTokenSSR(req);
        // add the authorization to the headers
        if (jsonWebToken) {
            headers.authorization = `Bearer ${jsonWebToken}`;
        }
        // handle breakcache
        const url = new URL(
            isSSR && req
                ? `${req.protocol}://${req.get('host')}${req.originalUrl}`
                : window.location,
        );
        if (url.searchParams.has('breakcache')) {
            headers['X-BREAKCACHE'] = true;
        }
        headers['x-client-url'] = url.href;
        const cookies = isSSR && req ? CookieContext.getAll() : document.cookie;
        headers['request-cookie'] = cookies;
        operation.setContext({
            headers: headers,
        });

        return forward(operation);
    });
}
