import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Attribute, Facet, SelectedAttribute } from '../types';
import { UserAuth } from './UserAuthProvider';
import window from 'global/window';
import { useQuery } from '@apollo/client';
import { QL_FACETS_DEPT } from '../Graphql/queries';

export const getPageQuery = () => {
    const location = window?.location ?? useLocation();
    const searchParams = new URLSearchParams(location.search);
    return parseInt(searchParams.get('page') as string) || 0;
};
export const getSortQuery = () => {
    const location = window?.location ?? useLocation();
    const searchParams = new URLSearchParams(location.search);
    return (searchParams.get('sort') as string) || '';
};

const INITIAL_REFINEMENT = window?.location?.search; // important, store as constant outside method so we do not trigger a re-render on facet change since that is handled in a lower level component
export const getRefinementQuery = () => {
    const { getProTierId } = React.useContext(UserAuth);
    const searchParams = new URLSearchParams(INITIAL_REFINEMENT);
    searchParams.delete('page');
    searchParams.delete('sort');
    return searchParams.toString() + '&proTierId=' + getProTierId(); // keep pro tier for on page sign in/out refresh
};
type GridViewValues = 'grid' | 'list';
export type GridPageTypeValues = 'department' | 'search' | 'deals';

export const ProductGridRefinement = React.createContext({
    pageType: 'department' as GridPageTypeValues,
    url: '',
    page: 1,
    displayTitle: '',
    displayExploreMore: false,
    sortValue: 'recommended',
    gridViewValue: 'grid' as GridViewValues,
    totalItemsCount: 0,
    totalPagesCount: 0,
    facets: [] as Facet[],
    selectedAttributes: [] as SelectedAttribute[],
    ignoreLazyLoadOnFirstXItems: 0,
    facetsLoading: true,
    showGridViewOptions: true,
    setShowGridViewOptions: (visible: boolean) => {},
    setUrl: (url: string) => {
        return;
    },
    setDisplayTitle: (title: string) => {
        return;
    },
    setDisplayExploreMore: (val: boolean) => {
        return;
    },
    setPageNumber: (page: number) => {
        return;
    },
    setSelectedAttributes: (selectedAttributes: SelectedAttribute[]) => {
        return;
    },
    setFacetData: (facets: Facet[]) => {
        return;
    },
    setFacetsLoading: (isLoading: boolean) => {
        return;
    },
    setTotalItems: (count: number) => {
        return;
    },
    setTotalPages: (count: number) => {
        return;
    },
    setIgnoreLazyLoadOnFirstXItems: (itemsToIgnore: number) => {
        return;
    },
    getDeptFacets: (deptId: string) => {
        return { data: { departmentFacets: [] as Facet[] }, loading: true };
    },
    addAttribute: (attribute: Attribute) => {
        return;
    },
    removeAttribute: (attribute: Attribute) => {
        return;
    },
    removeAllAttributes: () => {
        return;
    },
    setSortValue: (value: string) => {
        return;
    },
    setGridViewValue: (value: GridViewValues) => {
        return;
    },
    getGridViewValue: (): GridViewValues => {
        return localStorage.getItem('gridview') === 'list' ? 'list' : 'grid';
    },
    setPageType: (value: GridPageTypeValues) => {
        return;
    },
    showFacets: false,
    setShowFacets: (show: boolean) => {
        return;
    },
    lastClickedAttribute: undefined as Attribute | undefined,
    setSort: (value: string) => {
        return;
    },
});

export function ProductGridRefinementProvider(props: {
    children: any;
    defaultSortType?: string;
    defaultShowGridViewOptions?: boolean;
}) {
    const location = useLocation();
    const [url, setUrl] = React.useState('');
    const [showGridViewOptions, setShowGridViewOptions] = React.useState(
        props.defaultShowGridViewOptions ?? true,
    );
    const [sortValue, setSort] = React.useState(
        props.defaultSortType || 'recommended',
    );
    const [pageType, setPageTypeValue] = React.useState(
        'department' as GridPageTypeValues,
    );
    const [gridViewValue, setGridView] = React.useState(
        'grid' as GridViewValues,
    );
    const [page, setPage] = React.useState(1);
    const [ignoreLazyLoadOnFirstXItems, setIgnoreLazyLoadOnItems] =
        React.useState(0);
    const [totalItemsCount, setTotalItemsCount] = React.useState(0);
    const [totalPagesCount, setTotalPagesCount] = React.useState(0);
    const [selectedAttributes, setSelectedAttributesData] = React.useState(
        [] as SelectedAttribute[],
    );
    const [facets, setFacets] = React.useState([] as Facet[]);
    const [facetsLoading, setFacetsLoadingValue] = React.useState(true);
    const [showFacets, setShowFacetsValue] = React.useState(false);
    const [lastClickedAttribute, setLastClickedAttribute] = React.useState(
        undefined as Attribute | undefined,
    );
    const [displayTitle, setDisplayTitleValue] = React.useState('');
    const [displayExploreMore, setDisplayExploreMoreValue] =
        React.useState(false);
    const navigate = useNavigate();

    const setPageNumber = (page: number) => {
        const searchParams = new URLSearchParams(location.search);
        if (page > 1) {
            searchParams.set('page', page.toString());
            navigate(location.pathname + '?' + searchParams.toString());
        } else {
            searchParams.delete('page');
            if (searchParams.toString() === '') {
                navigate(location.pathname);
            } else {
                navigate(location.pathname + '?' + searchParams.toString());
            }
        }
        setPage(page);
    };

    const setDisplayTitle = (title: string) => {
        setDisplayTitleValue(title);
    };

    const setDisplayExploreMore = (val: boolean) => {
        setDisplayExploreMoreValue(val);
    };

    const setSortValue = (value: string) => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.has('page')) {
            searchParams.delete('page');
            setPage(1);
        }
        if (value !== '') {
            searchParams.set('sort', value.toString());
            navigate(location.pathname + '?' + searchParams.toString());
        } else {
            searchParams.delete('sort');
            if (searchParams.toString() === '') {
                navigate(location.pathname);
            } else {
                navigate(location.pathname + '?' + searchParams.toString());
            }
        }
        setSort(value.toString());
    };

    const setPageType = (value: GridPageTypeValues) => {
        setPageTypeValue(value);
    };

    const setGridViewValue = (value: GridViewValues) => {
        if (value !== 'grid') {
            localStorage.setItem('gridview', value);
        } else {
            localStorage.removeItem('gridview');
        }
        setGridView(value);
    };

    const getGridViewValue = (): GridViewValues => {
        return localStorage.getItem('gridview') === 'list' ? 'list' : 'grid';
    };

    const setSelectedAttributes = (selectedAttributes: SelectedAttribute[]) => {
        setSelectedAttributesData(selectedAttributes);
    };

    const setTotalItems = (count: number) => {
        setTotalItemsCount(count);
    };

    const setIgnoreLazyLoadOnFirstXItems = (itemsToIgnore: number) => {
        setIgnoreLazyLoadOnItems(itemsToIgnore);
    };

    const setTotalPages = (count: number) => {
        setTotalPagesCount(count);
    };

    const setFacetData = (facetData: Facet[]) => {
        setFacets(facetData);
    };

    const setFacetsLoading = (isLoading: boolean) => {
        setFacetsLoadingValue(isLoading);
    };

    const getDeptFacets = (deptId: string) => {
        const query = new URLSearchParams(location.search);
        const page = (query.get('page') as string) || '1';
        const sortValue = (query.get('sort') as string) || 'recommended';
        query.delete('page');
        query.delete('sort');
        const refinements = query.toString();

        // Fetching department facets
        return useQuery(QL_FACETS_DEPT, {
            variables: {
                departmentId: deptId,
                page: parseInt(page),
                sort: sortValue,
                refinements: refinements,
            },
        });
    };

    const removeAttribute = (attribute: Attribute) => {
        const searchParams = new URLSearchParams(location.search);
        let urlString = location.pathname;
        const sortValue = searchParams.get('sort');

        if (pageType === 'search') {
            urlString = attribute.url;

            if (sortValue && urlString.indexOf('sort=') == -1) {
                urlString +=
                    (urlString.includes('?') ? '&' : '?') + 'sort=' + sortValue;
            }
            setPage(1);
            setUrl(urlString);
            setLastClickedAttribute(attribute);
            navigate(urlString);
        } else if (pageType === 'deals') {
            const tags = attribute.parameter;

            if (sortValue) {
                urlString +=
                    (urlString.includes('?') ? '&' : '?') + 'sort=' + sortValue;
            }

            if (tags) {
                urlString += (urlString.includes('?') ? '&' : '?') + tags;
            }
            setPage(1);
            setUrl(urlString);
            setLastClickedAttribute(attribute);
            navigate(urlString);
        } else {
            let urlString = decodeURIComponent(
                window.location.pathname + window.location.search,
            )
                .replace('?&', '?')
                .replace('?' + attribute.parameter, '?')
                .replace('&' + attribute.parameter, '')
                .replace(attribute.parameter, '');

            if (attribute.url) {
                urlString = attribute.url
                    .replace('view=', '')
                    .replace(/\?+$/, '')
                    .replace(/&+$/, '');
            }

            if (sortValue && urlString.indexOf('sort=') == -1) {
                urlString +=
                    (urlString.includes('?') ? '&' : '?') + 'sort=' + sortValue;
            }
            setPage(1);
            setUrl(urlString);
            setLastClickedAttribute(attribute);
            navigate(urlString);
        }
    };

    const removeAllAttributes = () => {
        const searchParams = new URLSearchParams(location.search);
        const paramsToKeep = ['sort', 'view', 'sstring'];
        for (const paramName of [...searchParams.keys()]) {
            if (paramsToKeep.indexOf(paramName) == -1) {
                searchParams.delete(paramName);
            }
        }

        let url = location.pathname;
        const paramString = searchParams.toString();
        if (paramString != '') {
            url += '?' + paramString;
        }
        setPage(1);
        setUrl(url);
        navigate(url);
    };

    const addAttribute = (attribute: Attribute) => {
        const searchParams = new URLSearchParams(window.location.search);
        let urlString = window.location.pathname;
        const tags = attribute.parameter;
        const sortValue = searchParams.get('sort');

        if (pageType === 'search') {
            if (attribute.url.length > 0) {
                urlString = attribute.url;
            }

            if (sortValue && urlString.indexOf('sort=') == -1) {
                urlString +=
                    (urlString.includes('?') ? '&' : '?') + 'sort=' + sortValue;
            }

            setPage(1);
            setUrl(urlString);
            setLastClickedAttribute(attribute);
            navigate(urlString);
        } else if (pageType === 'deals') {
            if (sortValue && urlString.indexOf('sort=') == -1) {
                urlString +=
                    (urlString.includes('?') ? '&' : '?') + 'sort=' + sortValue;
            }

            if (tags) {
                urlString += (urlString.includes('?') ? '&' : '?') + tags;
            }

            setPage(1);
            setUrl(urlString);
            setLastClickedAttribute(attribute);
            navigate(urlString);
        } else {
            let urlString = decodeURIComponent(
                window.location.pathname + window.location.search,
            )
                .replace('?&', '?')
                .replace('?' + attribute.parameter, '?')
                .replace('&' + attribute.parameter, '')
                .replace(attribute.parameter, '');

            if (urlString.indexOf('?') !== -1) {
                urlString += '&' + attribute.parameter;
            } else {
                urlString += '?' + attribute.parameter;
            }

            if (attribute.url) {
                urlString = attribute.url
                    .replace('view=', '')
                    .replace(/\?+$/, '')
                    .replace(/&+$/, '');
            }

            if (sortValue && urlString.indexOf('sort=') == -1) {
                urlString +=
                    (urlString.includes('?') ? '&' : '?') + 'sort=' + sortValue;
            }

            setPage(1);
            setUrl(urlString);
            setLastClickedAttribute(attribute);
            navigate(urlString);
        }
    };

    const setShowFacets = (show: boolean) => {
        setShowFacetsValue(show);
    };

    return (
        <ProductGridRefinement.Provider
            value={{
                pageType,
                setPageType,
                url,
                page,
                totalItemsCount,
                totalPagesCount,
                facets,
                facetsLoading,
                ignoreLazyLoadOnFirstXItems,
                displayTitle,
                setDisplayTitle,
                displayExploreMore,
                setDisplayExploreMore,
                showGridViewOptions,
                selectedAttributes,
                setSelectedAttributes,
                setShowGridViewOptions,
                setSort,
                sortValue,
                setSortValue,
                showFacets,
                setShowFacets,
                gridViewValue,
                setGridViewValue,
                getGridViewValue,
                setUrl,
                setPageNumber,
                setIgnoreLazyLoadOnFirstXItems,
                getDeptFacets,
                setFacetData,
                setFacetsLoading,
                setTotalItems,
                setTotalPages,
                addAttribute,
                removeAttribute,
                removeAllAttributes,
                lastClickedAttribute,
            }}
        >
            {props.children}
        </ProductGridRefinement.Provider>
    );
}
