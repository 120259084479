import { Paper, Typography } from '@mui/material';
import {
    DefaultComponentProps,
    OverridableTypeMap,
} from '@mui/material/OverridableComponent';
import { Box, SxProps } from '@mui/system';
import React from 'react';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../Contexts/DeviceTypeProvider';
import './ScContainer.scss';

export default function ScContainer(props: {
    children: React.ReactNode;
    title?: string;
    className?: string;
    fullWidth?: boolean;
    variant?: 'alt' | 'alt-light' | 'container-outside' | 'receipt';
    size?: 'large' | 'small';
    sx?: SxProps;
    containerProps?: DefaultComponentProps<OverridableTypeMap>;
}) {
    let className = ['container'];
    if (props.className) {
        className.push(props.className);
    }
    if (props.fullWidth) {
        className.push('full');
    }
    if (props.size) {
        if (props.size === 'small') {
            className.push('quarter');
        }
    }
    if (props.variant === 'container-outside') {
        className = ['container-outside'];
    }
    if (props.variant == 'alt' || props.variant == 'alt-light') {
        return (
            <>
                {props.title && props.title.length > 0 ? (
                    <Box className={'container-title container'} sx={{ py: 2 }}>
                        <Typography
                            variant="title"
                            component="h1"
                            dangerouslySetInnerHTML={{ __html: props.title }}
                        />
                    </Box>
                ) : null}
                <Box
                    className={'container-' + props.variant}
                    sx={{ ...props.sx }}
                    {...props.containerProps}
                >
                    <Box className={className.join(' ')}>{props.children}</Box>
                </Box>
            </>
        );
    }

    const renderChildren = (children: React.ReactNode) => {
        if (props.variant === 'receipt') {
            return (
                <Box sx={{ minHeight: '100vh' }}>
                    <DesktopAndTabletDevice>
                        <Paper
                            sx={{
                                width: 800,
                                mx: 'auto',
                                p: 10,
                                marginTop: '-30px',
                                mb: 3,
                                minHeight: '80vh',
                            }}
                        >
                            {children}
                        </Paper>
                    </DesktopAndTabletDevice>
                    <MobileDevice>{children}</MobileDevice>
                </Box>
            );
        }
        return children;
    };

    return (
        <Box
            className={className.join(' ')}
            sx={{ ...props.sx }}
            {...props.containerProps}
        >
            {props.title ? (
                <Typography
                    variant="title"
                    component="h2"
                    dangerouslySetInnerHTML={{ __html: props.title }}
                ></Typography>
            ) : null}
            {renderChildren(props.children)}
        </Box>
    );
}
