import { LoadingButton } from '@mui/lab';
import {
    Alert,
    AlertTitle,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import { Box, SxProps } from '@mui/system';
import { FormikProvider, FormikValues, useFormik } from 'formik';
import window from 'global/window';
import * as React from 'react';
import { LeavesWarehouseIn } from '../../../../Components/Item/ItemInfo';
import { addEmailAddressToNewsletterList } from '../../../../Components/Layout/Footer/FooterNewsletterSignup';
import { CheckboxWithLabel } from '../../../../Components/_base/BBQGuysComponents';
import { NotifyMeWhenInStockSchema } from '../../../../Schemas/Schemas';
import { Item } from '../../../../types';
import { EmailWithLabel } from '../../../Account/PageEditMyAccount/Components/EmailWithLabel';

export const ItemStockMessaging = (props: { item: Item; sx?: SxProps }) => {
    const { item, sx = {} } = props;
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState('');
    const [success, setSuccess] = React.useState(false);
    const howManyIsLimited = 10;
    const showQtyAvailable =
        !item.hideStockQty &&
        !item.isDirectShip &&
        item.qtyAvailable < howManyIsLimited;

    const handleClose = () => {
        setOpen(false);
        setError('');
        setSuccess(false);
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            newsletterSignup: false,
        },
        validationSchema: NotifyMeWhenInStockSchema,
        onSubmit: (values: FormikValues, actions: any) => {
            actions.setSubmitting(true);
            setError('');
            setSuccess(false);
            // the "SKU" is actually the product ID
            const productSKU = item.id;
            const emailAddress = values.email;
            const alertCode = 'PA';
            if (window._ltk == 'object') {
                window._ltk.Alerts.AddAlert(
                    emailAddress,
                    productSKU,
                    alertCode,
                );
                window._ltk.Alerts.Submit();
            } else {
                setError(
                    'An error occurred while adding to newsletter. _ltk not defined',
                );
                actions.setSubmitting(false);
                return;
            }
            if (values.newsletterSignup) {
                addEmailAddressToNewsletterList(emailAddress)
                    .then(() => {
                        try {
                            if (window._ltk == 'object') {
                                window._ltk.Subscriber.List =
                                    'Popup-AlertsSuite';
                                window._ltk.Subscriber.Profile.Add(
                                    'Checkbox.Source.Popup-AlertsSuite',
                                    'on',
                                );
                                window._ltk.Subscriber.Email = emailAddress;
                                window._ltk.Subscriber.Submit();
                            }
                            // Track that the email was successfully added in Adobe Analytics as event63
                            document.body.dispatchEvent(
                                new CustomEvent(
                                    'emailMeWhenBackInStockEmailAdded',
                                ),
                            );
                            setSuccess(true);
                        } catch (e) {
                            console.error(e);
                        }
                    })
                    .catch(err => {
                        setError(
                            'An error occurred while adding to newsletter. ' +
                                err,
                        );
                    })
                    .finally(() => {
                        actions.setSubmitting(false);
                    });
            } else {
                setSuccess(true);
                actions.setSubmitting(false);
            }
        },
    });

    const renderEmailLink = () => {
        return (
            <>
                <Button variant="link" onClick={() => setOpen(true)}>
                    email you
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll={'paper'}
                    fullWidth={true}
                    maxWidth={'sm'}
                >
                    <DialogTitle id="oos-dialog-title">
                        Temporarily Out of Stock
                    </DialogTitle>
                    <DialogContent>
                        {error ? (
                            <Alert severity="error">
                                <AlertTitle>Oops</AlertTitle>
                                {error}
                            </Alert>
                        ) : null}
                        {success ? (
                            <Alert severity="success">
                                <AlertTitle>Email Submitted</AlertTitle>we will
                                notify you when the item is back in stock.{' '}
                                {formik.values.newsletterSignup
                                    ? 'You will also be notified of any deals, recipies, or other exciting news from BBQGuys.'
                                    : null}
                            </Alert>
                        ) : (
                            <FormikProvider value={formik}>
                                <Typography variant="body1">
                                    Enter your email and we&apos;ll notify you
                                    when the product is back in stock.
                                </Typography>
                                <Stack
                                    spacing={2}
                                    direction="row"
                                    marginBottom={2}
                                >
                                    <EmailWithLabel
                                        formik={formik}
                                        name="email"
                                        autoFocus
                                        id="email"
                                        label="Email Address"
                                        fullWidth
                                        placeholder="Enter your email address"
                                    />
                                </Stack>
                                <CheckboxWithLabel
                                    label="Plus, email me the latest deals, recipes, and more."
                                    checked={formik.values.newsletterSignup}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>,
                                    ) =>
                                        formik.setFieldValue(
                                            'newsletterSignup',
                                            event.target.checked,
                                        )
                                    }
                                />
                            </FormikProvider>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <LoadingButton
                            loading={formik.isSubmitting}
                            onClick={() => formik.submitForm()}
                            variant="contained"
                        >
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    return (
        <Box sx={sx}>
            {item.isAvailable ? (
                <>
                    {!item.isDirectShip && item.qtyAvailable > 0 && (
                        <div>
                            <Typography variant="inStock">
                                {item.stockStatus
                                    .toLowerCase()
                                    .split('_')
                                    .map(
                                        (v: string) =>
                                            v.charAt(0).toUpperCase() +
                                            v.substring(1),
                                    )
                                    .join(' ')}
                                {showQtyAvailable && (
                                    <span>{` | Only ${item.qtyAvailable} left`}</span>
                                )}
                            </Typography>
                        </div>
                    )}
                    {item.shipsIn != '' && (
                        <Box>
                            <LeavesWarehouseIn
                                shipsIn={item.shipsIn}
                                size="large"
                            />
                        </Box>
                    )}
                </>
            ) : (
                <>
                    <Typography variant="outOfStock">
                        {'Temporarily Out of Stock'}
                    </Typography>
                    <Box sx={{ marginBottom: 3 }}>
                        <Typography variant="body1">
                            Order now and we&apos;ll ship this item out within{' '}
                            <strong>{item.shipsIn}</strong> of it being in stock
                            <br />
                            Or let us {renderEmailLink()} when its back in stock
                            <br />
                            <br />
                            Have questions? Call or chat with our experts for
                            availability.
                        </Typography>
                    </Box>
                </>
            )}
        </Box>
    );
};
