import { useQuery } from '@apollo/client';
import {
    ChildDepartmentLinks,
    Panel,
} from '../../Components/_base/BBQGuysComponents';
import { QL_DEPT_CHILDREN } from '../../Graphql/queries';
import { Department } from '../../types';

const DepartmentChildPanel = (props: { department: Department }) => {
    const { data } = useQuery(QL_DEPT_CHILDREN, {
        variables: {
            departmentId: props.department.id,
            depth: 2,
        },
    });

    return (
        <>
            {data &&
            data.departmentChildren &&
            data.departmentChildren.countResults > 0 ? (
                <Panel title="Shop By Department" sxContents={{ py: 1, px: 2 }}>
                    <ChildDepartmentLinks
                        departments={
                            data.departmentChildren.results as Department[]
                        }
                    />
                </Panel>
            ) : null}
        </>
    );
};

export default DepartmentChildPanel;
