import * as React from 'react';
import { gql } from '@apollo/client';
import { client } from '../Graphql/ApolloClient';
import { Item, Question } from '../types';

type QASortOptions = 'age_desc' | 'age_asc' | string;
type QASortOption = {
    name: string;
    value: string;
};

const sortOptions: QASortOption[] = [
    {
        name: 'Newest first',
        value: 'age_desc',
    },
    {
        name: 'Oldest first',
        value: 'age_asc',
    },
];

export const QL_ANSWER_MARK_HELPFUL = gql`
    mutation QuestionAnswerMarkAnswerHelpful(
        $answerId: ID!
        $helpful: Boolean!
    ) {
        markAnswerHelpful(answerId: $answerId, helpful: $helpful) {
            success
        }
    }
`;

export const QuestionAndAnswersContext = React.createContext({
    page: 1,
    totalPages: 1,
    currentPage: 1,
    setPage: (val: number) => {
        return;
    },
    setTotalPages: (val: number) => {
        return;
    },
    setCurrentPage: (val: number) => {
        return;
    },
    sort: 'age_desc',
    setSort: (val: QASortOptions) => {
        return;
    },
    questions: [] as Question[] | [],
    setQuestions: (val: Question[]) => {
        return;
    },
    item: {} as Item,
    setItem: (val: Item) => {
        return;
    },
    searchTerm: '',
    setSearchTerm: (term: string) => {
        return;
    },
    sortOptions: sortOptions as QASortOption[],
    markHelpful: (answerId: number, helpful: boolean): Promise<any> => {
        return new Promise(() => {
            return;
        });
    },
    hasInteractedWithHelpfulUnhelpful: (
        answerId: number,
        type: 'helpful' | 'unhelpful',
    ): boolean => {
        return false;
    },
});

export function QuestionAndAnswersProvider(props: {
    page: number;
    sort: QASortOptions;
    item: Item;
    children: any;
}) {
    const [page, setPageNumber] = React.useState(props.page);
    const [item, setItem] = React.useState(props.item);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sort, setPageSort] = React.useState(props.sort);
    const [searchTerm, setTerm] = React.useState('');
    const [questions, setPageQuestions] = React.useState<Question[]>([]);

    const setPage = (val: number) => {
        setPageNumber(val);
    };
    const setSort = (val: QASortOptions) => {
        setPageSort(val);
    };
    const setQuestions = (questions: Question[]) => {
        setPageQuestions(questions);
    };
    const setSearchTerm = (term: string) => {
        setTerm(term);
    };
    const markHelpful = (answerId: number, helpful: boolean): Promise<any> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const response = await client.mutate({
                    mutation: QL_ANSWER_MARK_HELPFUL,
                    variables: {
                        answerId: answerId,
                        helpful: helpful,
                    },
                    fetchPolicy: 'no-cache',
                });
                if (response.data?.markAnswerHelpful?.success) {
                    setHelpfulUnhelpfulIntoStorage(
                        answerId,
                        helpful ? 'helpful' : 'unhelpful',
                    );
                    resolve(questions);
                } else {
                    reject(questions);
                }
            })();
        });
    };
    const setHelpfulUnhelpfulIntoStorage = (
        reviewId: number,
        type: 'helpful' | 'unhelpful',
    ) => {
        if (hasInteractedWithHelpfulUnhelpful(reviewId, type)) {
            return;
        }
        const reviewedItemsStored = localStorage.getItem('answers-' + type);
        let reviewedItems = [];
        if (reviewedItemsStored) {
            reviewedItems = JSON.parse(reviewedItemsStored);
        }

        reviewedItems.push(reviewId);
        localStorage.setItem('answers-' + type, JSON.stringify(reviewedItems));
    };
    const hasInteractedWithHelpfulUnhelpful = (
        answerId: number,
        type: 'helpful' | 'unhelpful',
    ): boolean => {
        const reviewedItemsStored = localStorage.getItem('answers-' + type);
        let reviewedItems = [];
        if (reviewedItemsStored) {
            reviewedItems = JSON.parse(reviewedItemsStored);
        }
        return reviewedItems.indexOf(answerId) > -1;
    };
    return (
        <QuestionAndAnswersContext.Provider
            value={{
                page,
                setPage,
                sort,
                setSort,
                questions,
                setQuestions,
                totalPages,
                setTotalPages,
                currentPage,
                setCurrentPage,
                item,
                setItem,
                searchTerm,
                setSearchTerm,
                sortOptions,
                markHelpful,
                hasInteractedWithHelpfulUnhelpful,
            }}
        >
            {props.children}
        </QuestionAndAnswersContext.Provider>
    );
}
