import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Link, List, ListItem } from '@mui/material';
import { LearningCenterItem, Maybe } from '../../../../types';

const LearningCenterDocuments = ({
    documents,
}: {
    documents: Maybe<LearningCenterItem>[];
}) => {
    return (
        <List>
            {documents.map((doc: Maybe<LearningCenterItem>, i: number) => {
                const docName =
                    doc && doc.title && doc.title.length > 0
                        ? doc.title
                        : 'Specifications Document';
                return doc ? (
                    <Link
                        key={'man-download-' + doc.url}
                        href={doc.url}
                        target="_blank"
                        sx={{ display: 'block', mb: 1 }}
                        aria-label={docName + ' File Download Link'}
                    >
                        <ListItem disablePadding>
                            <FileDownloadIcon
                                color="secondary"
                                sx={{ mr: 1 }}
                            />
                            {docName}
                        </ListItem>
                    </Link>
                ) : (
                    <></>
                );
            })}
        </List>
    );
};

export default LearningCenterDocuments;
