import {
    FreeShippingText,
    LeavesWarehouseIn,
} from '../../../../../../../Components/Item/ItemInfo';
import { OpenBoxItem } from '../../../../../../../types';

const OpenBoxShippingDetails = (props: { item: OpenBoxItem }) => {
    const { item } = props;
    return (
        <>
            <FreeShippingText
                isFreeShipping={true}
                withPlusSign={false}
                size="small"
            />
            <LeavesWarehouseIn shipsIn={item.shipsIn} size="small" inline />
        </>
    );
};

export default OpenBoxShippingDetails;
