import { Skeleton } from '@mui/material';
import React from 'react';
import { PriceDisplay } from '../../../../Components/Item/Pricing';
import { Promo } from '../../../../Components/PromoCountdown/PromoCountdown';
import { ProductQtyContext } from '../../../../Contexts/ProductQtyProvider';
import { Item } from '../../../../types';
import { ItemStockMessaging } from './ItemStockMessaging';

export const ItemPriceAndQtyLoading = () => {
    return (
        <>
            <Skeleton variant="text" height={'1.5rem'} width={'10%'} />
            <Skeleton variant="text" height={'3rem'} width={'25%'} />
            <Skeleton variant="text" height={'1.5rem'} width={'70%'} />
        </>
    );
};

const ItemPriceAndQty = (props: { item: Item }) => {
    const { item } = props;
    const { loading } = React.useContext(ProductQtyContext);
    if (loading) {
        return <ItemPriceAndQtyLoading />;
    }
    return (
        <>
            <Promo item={item} />
            <PriceDisplay
                item={item}
                variant="large"
                openBoxOnPageLink="open-box"
            />
            <ItemStockMessaging item={item} sx={{ marginBottom: '20px' }} />
        </>
    );
};

export default ItemPriceAndQty;
