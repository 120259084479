import { useContext } from 'react';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { QL_DEPT_BEST_SELLERS } from '../../Graphql/queries';
import { useQuery } from '@apollo/client';
import ProductCarousel, {
    ProductCarouselLoading,
} from '../../Components/ProductCarousels/ProductCarousel/ProductCarousel';

export const DepartmentBestSellers = (props: {
    deptId: string;
    title: string;
    count?: number;
}) => {
    const { deptId, title, count = 6 } = props;
    const { getPricingTierId } = useContext(UserAuth);

    const { data, loading } = useQuery(QL_DEPT_BEST_SELLERS, {
        variables: {
            departmentId: deptId,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ProductCarouselLoading count={count} />;
    }

    return data && data.departmentBestSellingItems ? (
        data?.departmentBestSellingItems?.results ? (
            <ProductCarousel
                items={data.departmentBestSellingItems.results}
                title={title}
                count={count}
            />
        ) : null
    ) : null;
};
