import { Box, Typography } from '@mui/material';
import React from 'react';
import { BBQPagination } from '../../../../../Components/Pagination/BBQPagination';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import { SSRContext } from '../../../../../Contexts/SSRProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../../Contexts/DeviceTypeProvider';
import theme from '../../../../../Theme';
import { CustomerReview, Item } from '../../../../../types';
import CustomerReviewsSchemaOrg from '../Components/CustomerReviewsSchemaOrg';
import CustomerReviewsContentDesktop from './CustomerReviewsContentDesktop';
import CustomerReviewsContentMobile from './CustomerReviewsContentMobile';

const CustomerReviewsDisplay = (props: {
    item: Item;
    reviews: CustomerReview[];
    totalPages: number;
    currentPage: number;
}) => {
    const { item, reviews, totalPages, currentPage } = props;
    const { isClientSide } = React.useContext(SSRContext);
    const customerReviewsRef = React.useRef(null);
    const { setPage } = React.useContext(CustomerReviewsContext);
    const executeScroll = () => {
        if (isClientSide() && customerReviewsRef.current) {
            const cur = customerReviewsRef.current as HTMLDivElement;
            window.scrollTo(0, cur.offsetTop - 110);
        }
    };

    const handlePaginationChange = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        executeScroll();
        setPage(value);
    };

    return (
        <>
            {reviews.length === 0 ? (
                <>
                    <Typography variant="bold">No review found</Typography>
                </>
            ) : (
                <div id="reviews" ref={customerReviewsRef}>
                    <CustomerReviewsSchemaOrg item={item} reviews={reviews} />
                    <Box
                        sx={{
                            '&> div:not(:last-child)': {
                                borderBottom: `1px solid ${theme.palette.divider}`,
                            },
                        }}
                    >
                        {reviews.map((review: CustomerReview, i: number) => {
                            return (
                                <React.Fragment
                                    key={'cust-review-' + review.id}
                                >
                                    <DesktopAndTabletDevice>
                                        <CustomerReviewsContentDesktop
                                            review={review}
                                            item={item}
                                        />
                                    </DesktopAndTabletDevice>
                                    <MobileDevice>
                                        <CustomerReviewsContentMobile
                                            review={review}
                                            item={item}
                                        />
                                    </MobileDevice>
                                </React.Fragment>
                            );
                        })}
                    </Box>
                    {totalPages > 1 ? (
                        <Box sx={{ marginTop: '1rem' }} textAlign="center">
                            <BBQPagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePaginationChange}
                            />
                        </Box>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default CustomerReviewsDisplay;
