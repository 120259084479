import Slider from 'react-slick';
import { BannerCampaign } from '../../../types';
import { BannerCarouselContainer } from './BannerStyledContainer';
import './Banners.scss';
import BannersByLayout from './BannersByLayout';
import { SxProps } from '@mui/system';

const BannerCampaigns = ({
    campaigns,
    sx,
}: {
    campaigns: BannerCampaign[];
    sx?: SxProps;
}) => {
    const renderCampaigns = () => {
        return campaigns
            .map((camp: BannerCampaign) => {
                return (
                    camp.banners && (
                        <BannersByLayout
                            key={'banner-layout-' + camp.layout + '-' + camp.id}
                            layout={camp.layout}
                            banners={camp.banners}
                        />
                    )
                );
            })
            .filter((node: React.ReactNode) => node !== null);
    };
    if (campaigns.length < 1) {
        return <></>;
    }
    if (campaigns.length === 1) {
        return (
            <BannerCarouselContainer data-layout={campaigns[0].layout} sx={sx}>
                {renderCampaigns()}
            </BannerCarouselContainer>
        );
    }
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    return (
        <BannerCarouselContainer data-layout={campaigns[0].layout} sx={sx}>
            <Slider {...settings}>{renderCampaigns()}</Slider>
        </BannerCarouselContainer>
    );
};

export default BannerCampaigns;
