import StarIcon from '@mui/icons-material/Star';
import { Link, SxProps, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { Item, Maybe } from '../../types';

// const labels: { [index: string]: string } = {
//     0.5: 'Useless',
//     1: 'Useless+',
//     1.5: 'Poor',
//     2: 'Poor+',
//     2.5: 'Ok',
//     3: 'Ok+',
//     3.5: 'Good',
//     4: 'Good+',
//     4.5: 'Excellent',
//     5: 'Excellent+',
//   };

export default function StarRatings(props: {
    rating: Maybe<number> | undefined;
    count?: Maybe<number> | undefined;
    showCount?: boolean;
    item?: Item;
    showCountText?: boolean;
    itemId?: string;
    readOnly?: boolean;
    max?: number;
    size?: 'x-large' | 'large' | 'medium' | 'small';
    block?: boolean;
    loading?: boolean;
    sx?: SxProps;
    fontSize?: number | string;
    fontSizeCount?: number | string;
}) {
    const {
        rating = 0,
        count = 0,
        readOnly = true,
        max = 5,
        showCount = true,
        size = 'medium',
        showCountText = true,
        fontSize = 20,
        fontSizeCount = 14,
        block = false,
        loading = false,
        sx = {},
        item,
    } = props;
    let pixelSize = fontSize;
    let height = fontSize;
    let countFontSize = fontSizeCount;
    switch (size) {
        case 'medium':
            pixelSize = 28;
            height = 29;
            countFontSize = 18;
            break;
        case 'large':
            pixelSize = 36;
            height = 37;
            countFontSize = 28;
            break;
        case 'x-large':
            pixelSize = 48;
            height = 49;
            countFontSize = 32;
            break;
        case 'small':
        default:
            pixelSize = fontSize;
            height = fontSize;
            countFontSize = fontSizeCount;
            break;
    }
    if (showCount && count !== null && count === 0) {
        return null;
    }
    return (
        <Box
            component="span"
            sx={{
                display: block ? 'block' : 'inline-flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                marginLeft: '-3px',
                ...sx,
            }}
        >
            {loading ? (
                <>
                    <StarIcon
                        style={{
                            opacity: 0.15,
                            fontSize: pixelSize + 'px',
                            height: height + 'px',
                        }}
                    />
                    <StarIcon
                        style={{
                            opacity: 0.15,
                            fontSize: pixelSize + 'px',
                            height: height + 'px',
                        }}
                    />
                    <StarIcon
                        style={{
                            opacity: 0.15,
                            fontSize: pixelSize + 'px',
                            height: height + 'px',
                        }}
                    />
                    <StarIcon
                        style={{
                            opacity: 0.15,
                            fontSize: pixelSize + 'px',
                            height: height + 'px',
                        }}
                    />
                    <StarIcon
                        style={{
                            opacity: 0.15,
                            fontSize: pixelSize + 'px',
                            height: height + 'px',
                        }}
                    />
                </>
            ) : (
                <Rating
                    name="star-rating"
                    sx={{ fontSize: pixelSize, ...sx }}
                    emptyIcon={
                        <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize="inherit"
                        />
                    }
                    precision={loading ? 0.1 : 0.5}
                    max={max}
                    value={rating ?? 0}
                    readOnly={readOnly}
                    color={loading ? '#ccc' : 'blue'}
                />
            )}
            {showCount === true ? (
                <Typography
                    variant="body1"
                    component="span"
                    sx={{ ml: 1, fontSize: countFontSize }}
                    aria-label={
                        'based on ' +
                        count +
                        ' review' +
                        (count === 1 ? '' : 's')
                    }
                >
                    {item?.url ? (
                        <Link
                            href={`${item?.url}#reviews`}
                            variant="inherit"
                            style={{ textDecoration: 'none' }}
                        >
                            (
                            <span
                                style={{
                                    textDecoration: 'underline',
                                    margin: '0 2px',
                                }}
                            >
                                {count}
                            </span>
                            {showCountText
                                ? ' review' + (count === 1 ? '' : 's')
                                : ''}
                            )
                        </Link>
                    ) : (
                        `(${count}` +
                        (showCountText
                            ? ' review' + (count === 1 ? '' : 's')
                            : '') +
                        ')'
                    )}
                </Typography>
            ) : null}
        </Box>
    );
}
