import * as React from 'react';
import theme from '../Theme';
import {
    DEVICE_DESKTOP,
    DEVICE_MOBILE,
    DEVICE_TABLET,
    setDualCookie,
} from '../functions';
import { useMediaQuery, useTheme } from '@mui/material';
import { SSRContext } from './SSRProvider';

let isMobile = false;
let isTablet = false;
let isDesktop = true;

const getIsMobile = () =>
    typeof window !== 'undefined' &&
    window.matchMedia !== undefined &&
    window.matchMedia(theme.breakpoints.down('md').substring(7)).matches;
const getIsDesktop = () =>
    typeof window !== 'undefined' &&
    window.matchMedia !== undefined &&
    window.matchMedia(theme.breakpoints.up('md').substring(7)).matches;
const getIsTablet = () =>
    typeof window !== 'undefined' &&
    window.matchMedia !== undefined &&
    window.matchMedia(theme.breakpoints.only('md').substring(7)).matches;

const DeviceTypeContext = React.createContext({
    deviceType: 'desktop',
    isMobile: false,
    isTablet: false,
    isDesktop: true,
});

const DeviceTypeProvider: React.FC = ({ children }) => {
    const [deviceType, setDeviceType] = React.useState(DEVICE_DESKTOP);
    const [mobile, setMobile] = React.useState(false);
    const [tablet, setTablet] = React.useState(false);
    const [desktop, setDesktop] = React.useState(true);

    const checkDeviceType = () => {
        if (typeof window !== 'undefined' && window.matchMedia) {
            if (
                window.matchMedia(theme.breakpoints.only('md').substring(7))
                    .matches
            ) {
                setDeviceType(DEVICE_TABLET);
                setMobile(false);
                setTablet(true);
                setDesktop(false);
            } else if (
                window.matchMedia(theme.breakpoints.down('md').substring(7))
                    .matches
            ) {
                setDeviceType(DEVICE_MOBILE);
                setMobile(true);
                setTablet(false);
                setDesktop(false);
            } else if (
                window.matchMedia(theme.breakpoints.up('md').substring(7))
                    .matches
            ) {
                setDeviceType(DEVICE_DESKTOP);
                setMobile(false);
                setTablet(false);
                setDesktop(true);
            } else {
                setMobile(false);
                setTablet(false);
                setDesktop(true);
            }
        }

        // update module-level variables
        isMobile = mobile;
        isTablet = tablet;
        isDesktop = desktop;
    };

    React.useEffect(() => {
        checkDeviceType();
        setDualCookie('DeviceType', deviceType);

        window.addEventListener('resize', checkDeviceType);
        return () => {
            window.removeEventListener('resize', checkDeviceType);
        };
    }, [mobile, tablet, desktop]);

    return (
        <DeviceTypeContext.Provider
            value={{
                deviceType,
                isMobile: mobile,
                isTablet: tablet,
                isDesktop: desktop,
            }}
        >
            {children}
        </DeviceTypeContext.Provider>
    );
};

const useDeviceType = () => {
    const { isMobile, isTablet, isDesktop, deviceType } =
        React.useContext(DeviceTypeContext);
    return { isMobile, isTablet, isDesktop, deviceType };
};

const MobileDevice = (props: any) => {
    const theme = useTheme();
    const visible = useMediaQuery(theme.breakpoints.down('md'));
    const { isMobileDevice, isClientSide, isServerSide } =
        React.useContext(SSRContext);
    return (isClientSide() && visible) ||
        (isServerSide() && isMobileDevice()) ? (
        props.children
    ) : (
        <></>
    );
};
const TabletDevice = (props: any) => {
    const { isTabletDevice, isClientSide, isServerSide } =
        React.useContext(SSRContext);
    const theme = useTheme();
    const visible = useMediaQuery(theme.breakpoints.only('md'));
    return (isClientSide() && visible) ||
        (isServerSide() && isTabletDevice()) ? (
        props.children
    ) : (
        <></>
    );
};
const DesktopDevice = (props: any) => {
    const { isDesktopDevice, isClientSide, isServerSide } =
        React.useContext(SSRContext);
    const theme = useTheme();
    const visible = useMediaQuery(theme.breakpoints.up('lg'));
    return (isClientSide() && visible) ||
        (isServerSide() && isDesktopDevice()) ? (
        props.children
    ) : (
        <></>
    );
};
const DesktopAndTabletDevice = (props: any) => {
    const { isTabletDevice, isDesktopDevice, isClientSide, isServerSide } =
        React.useContext(SSRContext);
    const theme = useTheme();
    const visible = useMediaQuery(theme.breakpoints.up('md'));
    return (isClientSide() && visible) ||
        (isServerSide() && (isTabletDevice() || isDesktopDevice()))
        ? props.children
        : null;
};

const MobileAndTabletDevice = (props: any) => {
    const { isTabletDevice, isMobileDevice, isClientSide, isServerSide } =
        React.useContext(SSRContext);
    const theme = useTheme();
    const visible =
        useMediaQuery(theme.breakpoints.down('md')) ||
        useMediaQuery(theme.breakpoints.only('md'));
    return (isClientSide() && visible) ||
        (isServerSide() && (isTabletDevice() || isMobileDevice()))
        ? props.children
        : null;
};
export {
    DeviceTypeProvider,
    useDeviceType,
    MobileDevice,
    TabletDevice,
    DesktopDevice,
    DesktopAndTabletDevice,
    MobileAndTabletDevice,
    isMobile,
    isTablet,
    isDesktop,
    getIsMobile,
    getIsTablet,
    getIsDesktop,
};
