import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { Image } from '../../../../../Components/Image';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import { ImageType } from '../../../../../types';
import CustomerReviewsMediaCarouselLoading from './CustomerReviewsMediaCarouselLoading';
import {
    PhotoSwipeImageData,
    getPhotoSwapImageData,
    openPhotoSwipe,
} from '../../../../../Components/ImageCarousel/ImageCarousel';

const CustomerReviewsMediaCarousel = () => {
    const { fetchCustomerImages } = React.useContext(CustomerReviewsContext);
    const [loading, setLoading] = React.useState(true);
    const [page, setPage] = React.useState(1);
    const [images, setImages] = React.useState([] as ImageType[]);

    useEffect(() => {
        fetchCustomerImages(page)
            .then(images => {
                setImages(images);
            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    if (loading) {
        return <CustomerReviewsMediaCarouselLoading />;
    }

    if (images.length > 0) {
        return (
            <Box
                display="flex"
                gap={2}
                sx={{ overflowX: 'scroll', scrollbarWidth: 'none' }}
            >
                {images.map((media: ImageType, i: number) => {
                    if (media) {
                        return (
                            <Image
                                rounded
                                key={
                                    'cust-review-media-img-' +
                                    (media.id + '-' + i)
                                }
                                src={media.url}
                                alt={
                                    media.description ||
                                    'customer review image for review id ' +
                                        media.id
                                }
                                height={120}
                                onClick={() => {
                                    openPhotoSwipe(
                                        i,
                                        images,
                                        () => {},
                                        (index, ps) => {
                                            fetchCustomerImages(page + 1)
                                                .then(imgs => {
                                                    setPage(page + 1);
                                                    const psImages =
                                                        getPhotoSwapImageData(
                                                            imgs,
                                                        );
                                                    psImages.forEach(
                                                        (
                                                            psImage: PhotoSwipeImageData,
                                                        ) => {
                                                            ps.pswp.options.dataSource.push(
                                                                psImage,
                                                            );
                                                        },
                                                    );
                                                    ps.pswp.refreshSlideContent(
                                                        ps.pswp.getNumItems(),
                                                    );
                                                })
                                                .catch(err => {
                                                    console.log(err);
                                                });
                                        },
                                    );
                                }}
                            />
                        );
                    } else {
                        return <></>;
                    }
                })}
            </Box>
        );
    } else {
        return <></>;
    }
};

export default CustomerReviewsMediaCarousel;
