import { Grid, Typography } from '@mui/material';
import React from 'react';
import { SiteContext } from '../../Contexts/SiteProvider';
import theme from '../../Theme';

const BBQGuysInfo: React.FC = () => {
    const { locations } = React.useContext(SiteContext);

    return (
        <div>
            <Typography
                variant="h4"
                component="h2"
                sx={{ mb: theme.spacing(2) }}
            >
                Sales & Support Hours
            </Typography>
            <Grid container sx={{ flexDirection: 'column', width: '100%' }}>
                <Typography
                    variant="small"
                    sx={{ mb: theme.spacing(2) }}
                    dangerouslySetInnerHTML={{
                        __html: locations.website.hours.primary,
                    }}
                />
            </Grid>
        </div>
    );
};

export default BBQGuysInfo;
