import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';

const CustomerReviewsRatingBar = (props: {
    ratingValue: number;
    percent: string | number;
    count: number;
    id?: string;
}) => {
    const { setRating, rating } = React.useContext(CustomerReviewsContext);
    const { ratingValue, count, id = '' } = props;
    let { percent } = props;

    if (typeof percent == 'string') {
        percent = parseInt(percent.replace('%', ''));
    }

    const handleRatingSelect = () => {
        if (ratingValue === rating) {
            // if our rating is already set, unset it
            setRating(null);
        } else {
            setRating(ratingValue);
        }
    };

    return (
        <Button
            variant="link"
            onClick={() => handleRatingSelect()}
            id={id}
            className={rating === ratingValue ? 'highlight' : ''}
        >
            <Stack
                spacing={2}
                sx={{ py: 0, px: 1, alignItems: 'center' }}
                direction="row"
                className="review-link-5-1-stars"
            >
                <Typography sx={{ width: 60 }}>
                    {ratingValue}&nbsp;stars
                </Typography>
                <Box
                    // component="meter"
                    // aria-valuemax={100}
                    // aria-valuemin={0}
                    // aria-valuenow={percent}
                    // role="meter"
                    // min={0}
                    // max={100}
                    // value={percent}
                    title={
                        count +
                        ' reviews (' +
                        percent +
                        '%) have given this product a ' +
                        ratingValue +
                        ' star rating'
                    }
                    sx={{
                        border: 0,
                        appearance: 'none',
                        display: 'block',
                        width: '100%',
                        height: '1.0625em',
                        // '&::-webkit-meter-optimum-value':{
                        backgroundColor: '#e7ebed',
                        // }
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#e7ebed',
                            boxShadow:
                                'inset 0 0.0625em 0.1875em rgb(0 0 0 / 20%)',
                            height: '1.0625em',
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: percent + '%',
                                backgroundColor: 'blue',
                                backgroundImage:
                                    'linear-gradient(135deg, #da8401 0, #f8b42d 100%)',
                                backgroundSize: '100% 100%',
                                height: 'inherit',
                                textIndent: -9999,
                            }}
                        ></Box>
                    </Box>
                </Box>
                <Typography sx={{ width: 60, textAlign: 'right' }}>
                    {percent}%
                </Typography>
            </Stack>
        </Button>
    );
};

export default CustomerReviewsRatingBar;
