import BBQGuysCarousel, {
    CarouselAlignTitle,
} from '../../_base/BBQGuysCarousel';
import { BBQGuysImageCard } from '../../_base/BBQGuysImageCard';

export type ImageCardData = {
    url: string;
    src: string;
    title: string;
    alt: string;
};

export default function ImageCardCarousel({
    imageCardData,
    withCardBorder = false,
    withCardTitle = true,
    countToShow = 4,
    title = undefined,
    alignTitle = 'left',
}: {
    imageCardData: ImageCardData[];
    withCardBorder?: boolean;
    withCardTitle?: boolean;
    countToShow?: number;
    title?: string;
    alignTitle?: CarouselAlignTitle;
}) {
    return (
        <BBQGuysCarousel
            count={countToShow}
            title={title}
            alignTitle={alignTitle}
        >
            {imageCardData.map((d: ImageCardData, i: number) => {
                return (
                    <BBQGuysImageCard
                        withBorder={withCardBorder}
                        withTitle={withCardTitle}
                        key={'img-card-' + d.url}
                        url={d.url}
                        image={d.src}
                        cardProps={{
                            padding: 0,
                        }}
                        title={d.title}
                        imageProps={{
                            alt: `${d.alt}`,
                        }}
                    />
                );
            })}
        </BBQGuysCarousel>
    );
}
