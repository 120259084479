import { KeyboardArrowLeft } from '@mui/icons-material';
import Chat from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Mail from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Phone from '@mui/icons-material/Phone';
import { ListItemButton, Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Box } from '@mui/system';
import document from 'global/document';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CartContext } from '../../../../../../Contexts/CartProvider';
import {
    MenuContext,
    MenuProvider,
} from '../../../../../../Contexts/MenuProvider';
import { SiteContext } from '../../../../../../Contexts/SiteProvider';
import {
    LogoutResponse,
    UserAuth,
} from '../../../../../../Contexts/UserAuthProvider';
import { Maybe, MenuItem } from '../../../../../../types';
import { ProPriceLabel } from '../../../../../Item/Pricing';
import {
    EmailLink,
    LiveChatLink,
    PhoneLink,
} from '../../../../../_base/BBQGuysComponents';
import { CustomerServiceMessagingContent } from '../../../../Header/Header';

const MobileMenu = (props: { buttonWidth: number | string | undefined }) => {
    const { isPro, user, isLoggedIn, logout, setLoginRedirectUrl } =
        React.useContext(UserAuth);
    const { topMenu, mainMenu } = React.useContext(SiteContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const HEADER_HEIGHT =
        document.getElementById('header-container')?.offsetHeight ?? 1;
    const toggleDrawer = (open: boolean) => (event: any) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setMenuOpen(open);
    };

    const ProMessagingMobile = () => {
        return isPro() ? (
            <Divider />
        ) : (
            <>
                <Divider />
                <ListItemButton
                    component={Link}
                    sx={{ width: '100%' }}
                    href="/pro"
                    onClick={() => {
                        setMenuOpen(false);
                    }}
                >
                    <ListItemText>
                        Get <ProPriceLabel label="Pricing" forceDisplay />
                    </ListItemText>
                </ListItemButton>
                <Divider />
            </>
        );
    };

    const SignOutLink = () => {
        const { setOrder } = React.useContext(CartContext);
        return isLoggedIn() ? (
            <>
                <Divider />
                <ListItemButton
                    className="btn-signout"
                    sx={{ width: '100%', display: 'block', flex: 0 }}
                    onClick={() => {
                        logout().then((resp: LogoutResponse) => {
                            setOrder(resp.order);
                            setLoginRedirectUrl();
                        });
                        setMenuOpen(false);
                    }}
                >
                    <ListItemText>Sign Out</ListItemText>
                </ListItemButton>
                <Divider />
            </>
        ) : (
            <></>
        );
    };

    const MobileContactUs = () => {
        const { isPro } = React.useContext(UserAuth);
        const linkProps = {
            sx: {
                display: 'inline-flex',
                alignItems: 'center',
                gap: 1,
            },
        };
        return (
            <>
                {isPro() ? (
                    <Box sx={{ p: 2, backgroundColor: '#F1F9FF' }}>
                        <Typography variant="body1">
                            Your Pro Account Manager:
                        </Typography>
                        <Typography
                            variant="bold"
                            sx={{ mb: 1, display: 'block' }}
                        >
                            {user.proAccountRep?.name}
                        </Typography>
                        <Stack direction="row" spacing={3}>
                            <PhoneLink
                                icon={
                                    <Phone
                                        sx={{
                                            fontSize: 16,
                                            mr: 0.5,
                                            color: '#1167B1',
                                        }}
                                    />
                                }
                                number={user.proAccountRep?.phone}
                                linkProps={linkProps}
                            />
                            <EmailLink
                                icon={
                                    <Mail
                                        sx={{
                                            fontSize: 16,
                                            mr: 0.5,
                                            color: '#1167B1',
                                        }}
                                    />
                                }
                                emailText="Email"
                                emailAddress={user.proAccountRep?.email}
                                linkProps={linkProps}
                            />
                        </Stack>
                    </Box>
                ) : (
                    <Box sx={{ paddingX: 1, paddingY: 2 }}>
                        <Stack
                            direction="row"
                            spacing={1}
                            sx={{
                                paddingX: 1,
                                justifyContent: 'space-between',
                            }}
                        >
                            <PhoneLink
                                icon={
                                    <Phone
                                        sx={{
                                            fontSize: 18,
                                            mr: 0.25,
                                            opacity: '0.6',
                                        }}
                                    />
                                }
                                linkProps={linkProps}
                            />
                            <LiveChatLink
                                icon={
                                    <Chat
                                        sx={{
                                            fontSize: 18,
                                            mr: 0.25,
                                            opacity: '0.6',
                                        }}
                                    />
                                }
                                linkProps={linkProps}
                            />
                        </Stack>
                    </Box>
                )}
                <Divider />
            </>
        );
    };

    const MobileMenuHeader = (props: {
        menuItem: Maybe<MenuItem>;
        headerComponent?: React.ReactNode;
    }) => {
        const { menuItem, headerComponent = <></> } = props;
        const { menu, setActiveMenuData, activeMenu, getParentMenu } =
            React.useContext(MenuContext);

        const handleRowClick = () => {
            const parent = getParentMenu(activeMenu);
            if (parent) {
                setActiveMenuData(parent);
            } else {
                setActiveMenuData(menu);
            }
        };
        return menuItem && menuItem.text ? (
            <>
                <Typography
                    variant="muted"
                    display="block"
                    onClick={() => handleRowClick()}
                    sx={{
                        pt: 1,
                        pl: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <KeyboardArrowLeft />
                    BACK
                </Typography>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr auto',
                        gap: 2,
                        alignItems: 'baseline',
                        paddingX: 2,
                        paddingY: 1,
                    }}
                >
                    <Typography variant="bold" color="primary">
                        {menuItem.text}
                    </Typography>
                    {menuItem.href && (
                        <Link
                            href={menuItem.href || '#'}
                            variant="muted"
                            ml="auto"
                            whiteSpace={'nowrap'}
                            sx={{ textDecoration: 'none' }}
                            onClick={() => {
                                setMenuOpen(false);
                            }}
                        >
                            SHOP ALL
                            <KeyboardArrowRight
                                color="primary"
                                sx={{
                                    verticalAlign: 'text-bottom',
                                    transform: 'scale(1.3)',
                                    width: 16,
                                }}
                            />
                        </Link>
                    )}
                </Box>
                <Divider />
            </>
        ) : (
            <>{headerComponent}</>
        );
    };

    const MobileMenuFooter = (props: {
        menuItem: Maybe<MenuItem>;
        footerComponent?: React.ReactNode;
    }) => {
        const { menuItem, footerComponent = <></> } = props;
        return <>{menuItem && menuItem.text ? <></> : footerComponent}</>;
    };

    const MenuLevelPageProvider = (props: {
        menuItem: MenuItem;
        headerComponent?: React.ReactNode;
        footerComponent?: React.ReactNode;
    }) => {
        const {
            menuItem,
            headerComponent = <></>,
            footerComponent = <></>,
        } = props;
        return (
            <MenuProvider menu={menuItem}>
                <MenuLevelPage
                    headerComponent={headerComponent}
                    footerComponent={footerComponent}
                />
            </MenuProvider>
        );
    };

    const MenuLevelPage = (props: {
        isShopAll?: boolean;
        headerComponent?: React.ReactNode;
        footerComponent?: React.ReactNode;
    }) => {
        const { activeMenu, setActiveMenuData } = React.useContext(MenuContext);
        const navigate = useNavigate();
        const {
            isShopAll = false,
            headerComponent = <></>,
            footerComponent = <></>,
        } = props;

        const handleRowClick = (i2: MenuItem) => {
            const hasChildren =
                !isShopAll && i2 && i2?.children && i2?.children.length > 0
                    ? true
                    : false;
            if (hasChildren) {
                setActiveMenuData(i2);
            } else if (i2.href) {
                setMenuOpen(false);
                navigate(i2.href);
            }
        };

        const RowData = (props: {
            menuItem: MenuItem;
            hasChildren: boolean;
        }) => {
            const { menuItem, hasChildren } = props;
            return (
                <>
                    <ListItemText
                        primary={isShopAll ? 'Shop All' : menuItem.text}
                    />
                    {hasChildren ? (
                        <ListItemIcon className={'menu-item-icon'}>
                            <KeyboardArrowRight color="primary" />
                        </ListItemIcon>
                    ) : null}
                </>
            );
        };
        return (
            <>
                <MobileMenuHeader
                    menuItem={activeMenu}
                    headerComponent={headerComponent}
                />
                {activeMenu?.children.map(
                    (menuItem: Maybe<MenuItem>, i: number) => {
                        const hasChildren =
                            !isShopAll &&
                            menuItem &&
                            menuItem.children &&
                            menuItem?.children.length > 0
                                ? true
                                : false;
                        return (
                            menuItem &&
                            (hasChildren ? (
                                <ListItemButton
                                    component={'button'}
                                    key={'menu-item-' + menuItem.id}
                                    onClick={() => handleRowClick(menuItem)}
                                    sx={{ width: '100%' }}
                                >
                                    <RowData
                                        hasChildren={hasChildren}
                                        menuItem={menuItem}
                                    />
                                </ListItemButton>
                            ) : (
                                <ListItemButton
                                    key={'menu-item-' + menuItem.id}
                                    component={Link}
                                    onClick={() => handleRowClick(menuItem)}
                                    href={
                                        !hasChildren && menuItem.href
                                            ? menuItem.href || undefined
                                            : undefined
                                    }
                                    sx={{
                                        width: '100%',
                                        color: theme =>
                                            theme.palette.text.primary,
                                    }}
                                >
                                    <RowData
                                        hasChildren={hasChildren}
                                        menuItem={menuItem}
                                    />
                                </ListItemButton>
                            ))
                        );
                    },
                )}
                <MobileMenuFooter
                    menuItem={activeMenu}
                    footerComponent={footerComponent}
                />
            </>
        );
    };

    const list = () => {
        let MyAccountLinkItem = {} as MenuItem;
        if (typeof topMenu !== 'undefined') {
            MyAccountLinkItem = { ...topMenu[0] };
        }

        if (isLoggedIn()) {
            MyAccountLinkItem = {
                text: '',
                children: [
                    {
                        ...MyAccountLinkItem,
                        children: [],
                        text: 'My Account',
                        href: '/account',
                    },
                ],
                href: null,
            } as unknown as MenuItem;
        } else {
            MyAccountLinkItem = {
                text: '',
                children: [
                    {
                        ...MyAccountLinkItem,
                        children: [],
                        text: 'My Account',
                        href: '/login',
                    },
                ],
                href: null,
            } as unknown as MenuItem;
        }

        return (
            <div role="presentation">
                <MenuLevelPageProvider
                    menuItem={
                        {
                            text: null,
                            children: mainMenu,
                        } as unknown as MenuItem
                    }
                    headerComponent={<MobileContactUs />}
                    footerComponent={
                        <>
                            <ProMessagingMobile />
                            <MenuLevelPageProvider
                                menuItem={MyAccountLinkItem}
                            />
                            <Divider />
                            <MenuLevelPageProvider
                                menuItem={
                                    {
                                        text: null,
                                        children: topMenu,
                                    } as unknown as MenuItem
                                }
                            />

                            <SignOutLink />
                            <Divider />
                            <Box sx={{ p: 2 }}>
                                <Typography variant="bold" component="div">
                                    {isPro()
                                        ? 'Pro Account Manager Hours:'
                                        : 'Customer Service Hours:'}
                                </Typography>
                                <br />
                                <CustomerServiceMessagingContent />
                            </Box>
                        </>
                    }
                />
            </div>
        );
    };
    return (
        <>
            <Button
                onClick={toggleDrawer(!menuOpen)}
                sx={{
                    width: props.buttonWidth ? props.buttonWidth : 'auto',
                    zIndex: 10,
                }}
                aria-label="Open Menu"
            >
                {menuOpen ? (
                    <CloseIcon
                        sx={{ width: '1.5rem', height: '1.5rem' }}
                        color={'primary'}
                    />
                ) : (
                    <MenuIcon sx={{ width: '1.5rem', height: '1.5rem' }} />
                )}
            </Button>

            <Drawer
                anchor="left"
                open={menuOpen}
                onClose={toggleDrawer(false)}
                sx={{
                    zIndex: 2,
                }}
                BackdropProps={{
                    sx: {
                        marginTop: HEADER_HEIGHT + 'px',
                        borderTop: '1px solid #ccc',
                        // background: 'transparent',
                        zIndex: 8,
                    },
                }}
                PaperProps={{
                    sx: {
                        borderTop: 0,
                        borderLeft: 0,
                        width: '80%',
                        maxWidth: 300,
                        marginTop: HEADER_HEIGHT + 'px',
                        zIndex: 9,
                        boxShadow: 'none',
                        overflowY: 'scroll',
                        height: 'calc(100vh - ' + HEADER_HEIGHT + 'px)',
                        bottom: 0,
                        paddingBottom: '100px',
                    },
                }}
            >
                {list()}
            </Drawer>
        </>
    );
};
export default function MobileDefaultMenu(props: any) {
    return <MobileMenu buttonWidth={props.buttonWidth} />;
}
