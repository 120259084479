import { Item } from '../../../../types';
import OpenBoxItems from './OpenBoxItems';

const OpenBoxItemsSection = ({ item }: { item: Item }) => {
    return (
        <>
            {item.openBoxCount > 0 && (
                <OpenBoxItems itemId={item.id} showTitle={true} />
            )}
        </>
    );
};

export default OpenBoxItemsSection;
