import { gql, useQuery } from '@apollo/client';
import ProductCarousel, {
    ProductCarouselLoading,
} from '../../../../Components/ProductCarousels/ProductCarousel/ProductCarousel';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { PAGINATED_ITEMS } from '../../../../Graphql/fragments';
import { Item } from '../../../../types';
import React from 'react';
import { DigitalDataContext } from '../../../../Contexts/DigitalDataProvider';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';

const QL_ITEM_COLLECTION = gql`
    ${PAGINATED_ITEMS}
    query getItemCollection($itemId: ID, $pricingTierId: Int) {
        itemCollection(itemId: $itemId, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

export default function ShopTheCollectionItems(props: { item: Item }) {
    const { item } = props;
    const { getPricingTierId } = React.useContext(UserAuth);
    const { pushCarouselDependency } = React.useContext(DigitalDataContext);
    const { data, loading } = useQuery(QL_ITEM_COLLECTION, {
        variables: {
            itemId: item.id,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ProductCarouselLoading title="Shop This Collection" />;
    }

    const dependencyName = 'shop-the-collection-carousel';

    if (data && data.itemCollection) {
        if (data.itemCollection.results.length > 0) {
            return (
                <Section title="Shop This Collection">
                    <ProductCarousel
                        items={data.itemCollection.results}
                        trackingType={'carousel'}
                        trackingGroup={dependencyName}
                        dependencyName={dependencyName}
                    />
                </Section>
            );
        } else {
            pushCarouselDependency({
                carouselName: dependencyName,
                results: data.itemCollection.results,
                itemWidth: 6,
            });
            return null;
        }
    }
    return null;
}
