import * as React from 'react';
import { client } from '../Graphql/ApolloClient';
import { QL_ITEM_QTY_UPDATE } from '../Graphql/queries';
import { Item, Maybe } from '../types';
import { AddToCartItem } from './CartProvider';
import { UserAuth } from './UserAuthProvider';

export const ProductQtyContext = React.createContext({
    item: {} as Item,
    qty: 1,
    maxQty: 1000,
    loading: false,
    customPricing: 0,
    setMaxQty: (val: number) => {
        return;
    },
    setQty: (val: number | string) => {
        return;
    },
    setItem: (val: Item) => {
        return;
    },
    updateItemQty: (prod: Item, qty: number) => {
        return;
    },
    setCustomPricing: (val: number) => {
        return;
    },
    disableAddToCartButton: false,
    setDisableAddToCartButton: (val: boolean) => {
        return;
    },
    addItemToBundle: (item: Item, parentItemId?: string) => {
        return;
    },
    removeItemFromBundle: (item: Item) => {
        return;
    },
    removeItemsFromBundle: (item: Maybe<Item>[]) => {
        return;
    },
    resetBundle: () => {
        return;
    },
    getAddToCartItems: (): AddToCartItem[] => {
        return [];
    },
});

export function ProductQtyProvider(props: {
    item: Item;
    qty?: number;
    maxQty?: number;
    children: any;
}) {
    const { getPricingTierId } = React.useContext(UserAuth);
    const [item, setProduct] = React.useState(props.item);
    const [qty, setProductQty] = React.useState(props.qty || 1);
    const [maxQty, setMaxQty] = React.useState(props.maxQty || 1000);
    const [customPricing, setCustomPricing] = React.useState(0);
    const [loading, setProductLoading] = React.useState(false);
    const [disableAddToCartButton, setDisableAddToCartButton] =
        React.useState(false);
    let items: AddToCartItem[] = [
        { item: props.item, qty: qty } as AddToCartItem,
    ];

    const updateItemQty = async (prod: Item, qty: number) => {
        const variables = {
            itemId: prod.id,
            qty: qty,
            pricingTierId: getPricingTierId(),
        };
        const { data, loading } = await client.query({
            query: QL_ITEM_QTY_UPDATE,
            variables: variables,
        });

        if (!loading) {
            setItem(data.item);
        }
        setProductQty(qty);
    };

    const setItem = (item: Item) => {
        setProduct(item);
        setProductLoading(false);
        items = [{ item: item, qty: qty }];
        if (item.qtyMax) {
            setMaxQty(item.qtyMax);
        }
    };

    const setQty = (val: number | string) => {
        try {
            if (typeof val == 'string') {
                val = parseInt(val);
            }
            if (val > maxQty) {
                val = maxQty;
            }
            if (val < 1) {
                val = 1;
            }
            if (qty != val) {
                setProductLoading(true);
                updateItemQty(item, val);
            }
        } catch (e) {
            updateItemQty(item, 1);
        }
    };

    const addItemToBundle = (item: Item, parentItemId?: string) => {
        items = [
            ...items,
            { item: item, qty: qty, parentItemId: parentItemId || undefined },
        ];
    };
    const removeItemFromBundle = (item: Item) => {
        items = items.filter((i: AddToCartItem) => {
            return i.item.id !== item.id;
        });
    };
    const removeItemsFromBundle = (items: Maybe<Item>[]) => {
        items.forEach((i2: Maybe<Item>) => {
            if (i2) {
                removeItemFromBundle(i2);
            }
        });
    };
    const resetBundle = () => {
        items = [{ item: props.item, qty: qty }];
    };

    const getAddToCartItems = () => {
        return items;
    };
    return (
        <ProductQtyContext.Provider
            value={{
                item,
                qty,
                maxQty,
                loading,
                customPricing,
                setCustomPricing,
                setQty,
                setMaxQty,
                setItem,
                updateItemQty,
                disableAddToCartButton,
                setDisableAddToCartButton,
                addItemToBundle,
                removeItemFromBundle,
                removeItemsFromBundle,
                resetBundle,
                getAddToCartItems,
            }}
        >
            {props.children}
        </ProductQtyContext.Provider>
    );
}
