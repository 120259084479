import { ApolloClient, from } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import { Request } from 'express';
import { getEnvironmentVariable, isDevelopment } from '../config';
import getApolloMemoryCache from './ApolloCacheOptions';
import getErrorLink from './ApolloErrorLink';
import getAuthMiddleware from './ApolloMiddleware';
import getPersistedHttpLink from './ApolloPersistedHttpLink';

if (isDevelopment()) {
    // Adds messages only in a dev environment
    loadDevMessages();
    loadErrorMessages();
}

// build our client
export function getApolloClient(
    isSSR = false,
    req: Request | undefined = undefined,
) {
    let links = [
        getErrorLink(isSSR, req),
        getAuthMiddleware(isSSR, req),
        getPersistedHttpLink(isSSR, req),
    ];
    if (isSSR) {
        links = [
            getAuthMiddleware(isSSR, req),
            getPersistedHttpLink(isSSR, req),
        ];
    }

    return new ApolloClient({
        name: getEnvironmentVariable('GRAPHQL_CUSTOM_NAME'),
        version: getEnvironmentVariable('GRAPHQL_CUSTOM_VERSION'),
        ssrMode: isSSR,
        ssrForceFetchDelay: 100,
        cache: getApolloMemoryCache(isSSR, req),
        link: from(links),
        defaultOptions: {
            watchQuery: {
                fetchPolicy: 'network-only',
            },
        },
    });
}

export const client = getApolloClient();
