import { gql, useQuery } from '@apollo/client';
import {
    Chip,
    Link,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Theme,
    Typography,
} from '@mui/material';
import { styled } from '@mui/styles';
import { Box } from '@mui/system';
import {
    DesktopDevice,
    MobileAndTabletDevice,
} from '../../Contexts/DeviceTypeProvider';
import { ITEM_FIELDS } from '../../Graphql/fragments';
import theme from '../../Theme';
import { Item, ItemCompare, Maybe, Specification } from '../../types';
import AddToCart from '../Buttons/AddToCart/AddToCart';
import { Image } from '../Image';
import {
    ProductCard,
    ProductCardLoading,
    ViewItemButton,
} from '../ProductCards/ProductCards';
import { HelpTipIcon, LineEllipsis } from '../_base/BBQGuysComponents';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import React from 'react';
import './ItemComparison.scss';

const CELL_WIDTH = '25%';
const HIGHLIGHT_COLOR = theme.palette.brand.lighttan;

const QL_ITEM_COMPARE = gql`
    ${ITEM_FIELDS}
    query itemCompare($itemIds: ID, $pricingTierId: Int) {
        itemCompare(itemIds: $itemIds, pricingTierId: $pricingTierId) {
            itemId
            item {
                ...ItemFields
            }
            specifications {
                fieldName
                fieldValue
                contentId
            }
        }
    }
`;

export const CurrentItemBadgeChip = styled(Chip)(
    ({ theme }: { theme: Theme }) => ({
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightRegular,
        p: 0.5,
    }),
);

const CurrentItemBadge = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                zIndex: 1,
                left: 0,
                right: 0,
            }}
        >
            <CurrentItemBadgeChip
                variant="filled"
                color="secondary"
                size="small"
                label="Current Item"
            />
        </Box>
    );
};

export const ItemCompareGridLoading = () => {
    return (
        <Table
            size="small"
            aria-label="product comparison table"
            sx={{ tableLayout: 'fixed', width: 'auto' }}
        >
            <TableBody>
                <TableRow
                    sx={{
                        '> td:not(:first-of-type):not(:nth-of-type(2))': {
                            borderLeft: theme.palette.border,
                        },
                    }}
                >
                    <TableCell sx={{ minWidth: { xs: '100px', md: '200px' } }}>
                        &nbsp;
                    </TableCell>
                    {[...Array(4)].map((ic: number, i: number) => {
                        return (
                            <TableCell
                                key={'compare-table-product-loading-row-' + i}
                                width={CELL_WIDTH}
                            >
                                <ProductCardLoading />
                            </TableCell>
                        );
                    })}
                </TableRow>

                {[...Array(8)].map((spec: number, i: number) => {
                    return (
                        <TableRow
                            key={'compare-table-loading-row-' + i}
                            sx={{
                                '> td:not(:first-of-type)': {
                                    borderLeft: theme.palette.border,
                                },
                                ':hover td, :hover th': {
                                    backgroundColor:
                                        theme.palette.brand.lightgreen,
                                },
                            }}
                        >
                            <TableCell
                                sx={{ minWidth: { xs: '100px', md: '200px' } }}
                                component="th"
                            >
                                <Skeleton
                                    variant="text"
                                    width="90%"
                                    height={30}
                                />
                            </TableCell>
                            {[...Array(4)].map((ic2: number, z: number) => {
                                return (
                                    <TableCell
                                        key={
                                            'compare-table-attribute-loading-row-' +
                                            z
                                        }
                                        width={CELL_WIDTH}
                                    >
                                        <Skeleton
                                            variant="text"
                                            width="90%"
                                            height={30}
                                        />
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

const StickyTableColumn = styled(TableCell)({
    wordBreak: 'break-word',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifyContent: 'start',
    display: 'table-cell',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        position: 'sticky',
        width: 'auto',
        left: 0,
        top: 'auto',
        borderTopWidth: '1px',
        marginTop: '-8px',
        zIndex: 1000,
    },
});

const SpecTableRow = styled(TableRow)({
    '&> td': {
        borderLeft: theme.palette.border,
        [theme.breakpoints.up('md')]: {
            width: 250,
        },
    },
    '&> th': {
        width: 'auto',
        [theme.breakpoints.up('md')]: {
            width: 250,
        },
        position: 'relative',
        justifyContent: 'start',
        [theme.breakpoints.down('md')]: {
            position: 'sticky',
            display: 'flex',
            '&> td': {
                borderLeft: 0,
            },
            '&> td:not(:first-of-type):not(:nth-of-type(2))': {
                borderLeft: theme.palette.border,
            },
        },
        '&> span.sr-ignore': {
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 0,
        },
    },
    [theme.breakpoints.up('md')]: {
        '&:hover:not(.comp-head-data) th, &:hover:not(.comp-head-data) td': {
            backgroundColor: theme.palette.brand.lightgreen,
        },
    },
});

const renderSpecTableCells = (
    specName: string,
    specItemIds: string[],
    specFieldNames: KeyValue,
    firstSpec: Specification,
) => {
    return specItemIds.map((itmId: string, z: number) => {
        const specItemValue = specFieldNames[specName][itmId]
            ? specFieldNames[specName][itmId].fieldValue
            : '--';
        return (
            <TableCell
                key={'spec-' + specName + '-' + itmId + '-' + z}
                sx={{
                    backgroundColor:
                        specItemValue !== firstSpec.fieldValue
                            ? HIGHLIGHT_COLOR
                            : null,
                }}
            >
                {specItemValue}
            </TableCell>
        );
    });
};

const SpecNameCell = ({ firstSpec }: { firstSpec: Specification }) => {
    return (
        <>
            <Typography
                sx={{
                    pr: theme.spacing(2),
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightBold,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    wordWrap: 'break-word',
                }}
            >
                {firstSpec.fieldName}
            </Typography>
            {firstSpec.contentId > 0 && (
                <HelpTipIcon
                    contentId={firstSpec.contentId as number}
                    title={firstSpec.fieldName}
                />
            )}
        </>
    );
};

const getSpecData = (
    itemsToCompare: ItemCompare[],
    maxItems: number,
    currentItem?: Item,
) => {
    const itemCompare = itemsToCompare
        .sort((a: ItemCompare, b: ItemCompare) =>
            a.item.id === currentItem?.id
                ? -1
                : b.item.id === currentItem?.id
                  ? 1
                  : 0,
        )
        .slice(0, maxItems);
    const specFieldNames: KeyValue = {};
    const specItemIds: string[] = [];

    if (itemCompare[0]) {
        itemCompare[0].specifications.forEach((spec: Maybe<Specification>) => {
            if (spec && spec.fieldName.length > 0) {
                // skip UPC and Model Number
                if (['Model Number', 'UPC'].indexOf(spec.fieldName) !== -1) {
                    return;
                }
                if (specFieldNames[spec.fieldName] === undefined) {
                    specFieldNames[spec.fieldName] = {};
                }
                if (specItemIds.indexOf(itemCompare[0].itemId) === -1) {
                    specItemIds.push(itemCompare[0].itemId);
                }
                specFieldNames[spec.fieldName][itemCompare[0].itemId] = spec;
            }
        });
    }
    const specObjectKeys = Object.keys(specFieldNames);

    itemCompare.forEach((itm: ItemCompare, k: number) => {
        if (k === 0) {
            return;
        }
        itm.specifications.forEach((spec: Maybe<Specification>) => {
            if (spec && spec.fieldName.length > 0) {
                if (specFieldNames[spec.fieldName] === undefined) {
                    specFieldNames[spec.fieldName] = {};
                }
                if (specItemIds.indexOf(itm.itemId) === -1) {
                    specItemIds.push(itm.itemId);
                }
                specFieldNames[spec.fieldName][itm.itemId] = spec;
            }
        });
    });
    return {
        itemCompare,
        specFieldNames,
        specItemIds,
        specObjectKeys,
    };
};

const getFirstSpec = (
    itemCompare: ItemCompare,
    specName: string,
): Specification => {
    const firstSpec = itemCompare.specifications?.filter(
        (spec: Maybe<Specification>) => spec?.fieldName === specName,
    )[0];
    if (firstSpec) {
        return firstSpec;
    }
    return {
        contentId: 0,
        fieldName: specName,
        fieldValue: '--',
    } as Specification;
};

export const DesktopItemCompareGrid = (props: {
    itemCompare: ItemCompare[];
    currentItem?: Item;
    maxItems?: number;
}) => {
    const { maxItems = 4 } = props;
    const { itemCompare, specFieldNames, specItemIds, specObjectKeys } =
        getSpecData(props.itemCompare, maxItems, props.currentItem);
    return (
        <Table
            size="small"
            aria-label="product comparison table"
            className="itemComparison"
            sx={{ tableLayout: 'fixed' }}
        >
            <TableBody>
                <SpecTableRow className="comp-head-data">
                    <StickyTableColumn
                        width={CELL_WIDTH}
                        sx={{ borderLeft: '0 !important' }}
                    >
                        &nbsp;
                    </StickyTableColumn>
                    {itemCompare.map((ic: ItemCompare, i: number) => {
                        return (
                            <TableCell
                                key={'prod-compare-' + ic.item.id}
                                sx={{ position: 'relative' }}
                            >
                                {props.currentItem &&
                                    ic.item.id === props.currentItem.id && (
                                        <CurrentItemBadge />
                                    )}
                                <ProductCard
                                    item={ic.item}
                                    withAddToCartButton={i === 0}
                                    withViewItemButton={i > 0}
                                />
                            </TableCell>
                        );
                    })}
                </SpecTableRow>
                {specFieldNames ? (
                    <>
                        {specObjectKeys.map((specName: string, i: number) => {
                            const firstSpec = getFirstSpec(
                                itemCompare[0],
                                specName,
                            );
                            return (
                                <SpecTableRow
                                    key={'name-' + firstSpec.fieldName}
                                >
                                    <StickyTableColumn
                                        width={CELL_WIDTH}
                                        component="th"
                                    >
                                        <SpecNameCell firstSpec={firstSpec} />
                                    </StickyTableColumn>
                                    {renderSpecTableCells(
                                        specName,
                                        specItemIds,
                                        specFieldNames,
                                        firstSpec,
                                    )}
                                </SpecTableRow>
                            );
                        })}
                    </>
                ) : null}
            </TableBody>
        </Table>
    );
};

const MobileItemCompareGrid = (props: {
    itemCompare: ItemCompare[];
    currentItem?: Item;
}) => {
    const { itemCompare, specFieldNames, specItemIds, specObjectKeys } =
        getSpecData(props.itemCompare, 4, props.currentItem);
    return (
        <Box sx={{ overflowX: 'scroll' }}>
            <Table size="small" aria-label="product comparison table">
                <TableBody sx={{ overflowX: 'scroll' }}>
                    <SpecTableRow className="comp-head-data">
                        <StickyTableColumn component="th"></StickyTableColumn>
                        {itemCompare.map((ic: ItemCompare, i: number) => {
                            return (
                                <TableCell
                                    key={'prod-compare-' + ic.item.id}
                                    sx={{
                                        position: 'relative',
                                        textAlign: 'center',
                                    }}
                                >
                                    {props.currentItem &&
                                        ic.item.id === props.currentItem.id && (
                                            <CurrentItemBadge />
                                        )}
                                    <Link href={ic.item.url}>
                                        <Image
                                            src={ic.item.imageUrl}
                                            alt=""
                                            width={100}
                                            height={100}
                                        />
                                        <LineEllipsis
                                            maxLines={3}
                                            text={ic.item.name}
                                            component="span"
                                        />
                                    </Link>
                                    {props.currentItem &&
                                    ic.item.id === props.currentItem.id ? (
                                        <AddToCart
                                            item={ic.item}
                                            size="small"
                                        />
                                    ) : (
                                        <ViewItemButton
                                            item={ic.item}
                                            size="small"
                                        />
                                    )}
                                </TableCell>
                            );
                        })}
                    </SpecTableRow>
                    {specFieldNames ? (
                        <>
                            {specObjectKeys.map(
                                (specName: string, i: number) => {
                                    const firstSpec = getFirstSpec(
                                        itemCompare[0],
                                        specName,
                                    );
                                    return (
                                        <SpecTableRow
                                            key={'name-' + firstSpec.fieldName}
                                        >
                                            <TableCell component={'th'}>
                                                <SpecNameCell
                                                    firstSpec={firstSpec}
                                                />
                                            </TableCell>
                                            {renderSpecTableCells(
                                                specName,
                                                specItemIds,
                                                specFieldNames,
                                                firstSpec,
                                            )}
                                        </SpecTableRow>
                                    );
                                },
                            )}
                        </>
                    ) : null}
                </TableBody>
            </Table>
        </Box>
    );
};
export const ItemCompareGrid = (props: {
    itemCompare: ItemCompare[];
    currentItem?: Item;
}) => {
    return (
        <>
            <DesktopDevice>
                <DesktopItemCompareGrid {...props} maxItems={4} />
            </DesktopDevice>
            <MobileAndTabletDevice>
                <MobileItemCompareGrid {...props} />
            </MobileAndTabletDevice>
        </>
    );
};

export default function ItemComparison(props: {
    item?: Item;
    itemIds?: string[];
}) {
    const { getPricingTierId } = React.useContext(UserAuth);
    let itemIds = '';
    let sortedItemCompare = [];
    if (props.itemIds) {
        itemIds = props.itemIds.join(',');
    } else if (props.item) {
        itemIds = props.item.id;
    }

    const { data, loading, error } = useQuery(QL_ITEM_COMPARE, {
        variables: {
            itemIds: itemIds,
            pricingTierId: getPricingTierId(),
        },
    });

    if (error) {
        console.error(error);
        return <></>;
    }

    if (loading) {
        return <ItemCompareGridLoading />;
    }
    if (data.itemCompare.length < 2) {
        return <></>;
    }

    if (data.itemCompare) {
        sortedItemCompare = [...data.itemCompare].sort(function (
            a: ItemCompare,
            b: ItemCompare,
        ) {
            return itemIds.indexOf(a.itemId) - itemIds.indexOf(b.itemId);
        });
    }

    return (
        <ItemCompareGrid
            currentItem={props.item}
            itemCompare={sortedItemCompare}
        />
    );
}
