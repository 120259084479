import VideoPlayer, {
    YoutubeImageSizes,
} from '../../../../Components/_base/VideoPlayer';
import { LearningCenterItem, Maybe } from '../../../../types';

const LearningCenterVideoGrid = ({
    videos,
    imageSize = 'maxres',
}: {
    videos: Maybe<LearningCenterItem>[];
    imageSize?: YoutubeImageSizes;
}) => {
    return (
        <>
            {videos.map((video: Maybe<LearningCenterItem>, i: number) => {
                return video && video.image ? (
                    <VideoPlayer
                        key={'video-' + video.url}
                        src={video.url}
                        title={video.title}
                        image={video.image}
                        width={225}
                        height={170}
                        imageSize={imageSize}
                    />
                ) : null;
            })}
        </>
    );
};

export default LearningCenterVideoGrid;
