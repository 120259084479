import SearchIcon from '@mui/icons-material/Search';
import {
    Backdrop,
    Box,
    Button,
    Fade,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    Popper,
} from '@mui/material';
import { styled } from '@mui/styles';
import document from 'global/document';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import {
    HawkSearchKeywords,
    HawkSearchProduct,
    SearchBarContext,
} from '../../../../../Contexts/SearchBarProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../../Contexts/DeviceTypeProvider';
import theme from '../../../../../Theme';
import { Item } from '../../../../../types';
import {
    ProductCardBase,
    ProductCardListBase,
} from '../../../../ProductCards/ProductCards';
import CloseIcon from '@mui/icons-material/Close';

const BBQSearchTextField = styled('input')(() => ({
    margin: 0,
    border: 0,
    borderRadius: 0,
    '.MuiInput-root': {
        border: 0,
    },
}));

const TermsList = () => {
    const { searchResults, clearInputBox, highlightedTermIndex } =
        React.useContext(SearchBarContext);
    return (
        <List sx={{ p: 0 }} aria-label="Search Term Suggestions">
            {searchResults.terms.map((l: HawkSearchKeywords, i: number) => {
                return (
                    <ListItem
                        key={l.value}
                        className={
                            i === highlightedTermIndex
                                ? 'highlighted'
                                : undefined
                        }
                    >
                        <Link
                            href={l.url}
                            onClick={() => clearInputBox()}
                            dangerouslySetInnerHTML={{ __html: l.value }}
                        ></Link>
                    </ListItem>
                );
            })}
        </List>
    );
};

const ProductResultsList = () => {
    const { searchResults } = React.useContext(SearchBarContext);
    return (
        <>
            <DesktopAndTabletDevice>
                <Grid container aria-label="Product Suggestions">
                    {searchResults.products.map(
                        (p: HawkSearchProduct, i: number) => {
                            return (
                                <Grid key={p.sku} item xs={6} md={4} lg={4}>
                                    <ProductCardBase
                                        item={p as Item}
                                        withAddToCartButton={false}
                                        withQuickView={false}
                                        withPricingAndInfo={false}
                                    />
                                </Grid>
                            );
                        },
                    )}
                </Grid>
            </DesktopAndTabletDevice>
            <MobileDevice>
                {searchResults.products.map(
                    (p: HawkSearchProduct, i: number) => {
                        return (
                            <React.Fragment key={p.sku}>
                                <ProductCardListBase
                                    item={p as Item}
                                    withAddToCartButton={false}
                                    withLeadTime={false}
                                    withBorderSeparator={false}
                                />
                            </React.Fragment>
                        );
                    },
                )}
            </MobileDevice>
        </>
    );
};

const getGridTerms = (isFullWidth: boolean) => {
    return {
        xs: isFullWidth ? 12 : 12,
        md: isFullWidth ? 12 : 4,
        lg: isFullWidth ? 12 : 3,
    }
}

const getGridProducts = (isFullWidth: boolean) => {
    return {
        xs: isFullWidth ? 12 : 12,
        md: isFullWidth ? 12 : 8,
        lg: isFullWidth ? 12 : 9,
    }
}

const SearchResultsContent = ({
    showProducts, 
    isFullWidth = false ,
}: { 
    showProducts: boolean,
    isFullWidth?: boolean,
}) => {
    const { searchResults } = React.useContext(SearchBarContext);
    const gridTerms = getGridTerms(isFullWidth);
    const gridProducts = getGridProducts(isFullWidth);
    return (
        <>
            {(showProducts ?? true) &&
                (searchResults.terms.length > 0 ||
                    searchResults.products.length > 0) && (
                    <Grid container>
                        {showProducts ?? true ? (
                            <>
                                <Grid
                                    item
                                    sx={{
                                        backgroundColor: theme =>
                                            theme.palette.brand.lightesttan,
                                    }}
                                    borderTop={`1px solid ${theme.palette.brand.lighttan}`}
                                    borderRight={`1px solid ${theme.palette.brand.lighttan}`}
                                    {...gridTerms}
                                >
                                    <TermsList />
                                </Grid>
                                <Grid item sx={{ p: 2 }} {...gridProducts}>
                                    <ProductResultsList />
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={12}>
                                    <TermsList />
                                </Grid>
                            </>
                        )}
                    </Grid>
                )}
        </>
    );
};
const SearchResults = ({
    showProducts, 
    containerRef,
    isFullWidth,
}: { 
    showProducts: boolean;
    containerRef: React.RefObject<HTMLElement>;
    isFullWidth?: boolean;
}) => {
    const { expandSearchBar, autocompleteOpen, anchorEl, clearInputBox } =
        React.useContext(SearchBarContext);

    const canBeOpen = autocompleteOpen && Boolean(anchorEl);
    const id = canBeOpen ? 'transition-popper' : '';

    const popperRef = useRef<HTMLDivElement | null>(null);

    const doNotCloseIds = ['search-bar'];

    const handleClickOutside = (event: MouseEvent) => {
        const eventNode = event.target as HTMLElement;
        if (eventNode && doNotCloseIds.indexOf(eventNode.id) != -1) {
            return;
        }
        if (
            popperRef.current &&
            !popperRef.current.contains(event.target as Node)
        ) {
            clearInputBox();
        }
    };

    React.useEffect(() => {
        if (canBeOpen) {
            document.addEventListener('mouseup', handleClickOutside);
        } else {
            document.removeEventListener('mouseup', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [canBeOpen]);

    return (
        <Popper
            id={id}
            className="search-dropdown"
            open={expandSearchBar && autocompleteOpen}
            anchorEl={anchorEl}
            transition
            container={containerRef.current}
            sx={{ 
                borderBottomWidth: {
                    xs: 0, 
                    md: 1 
                }, 
                position: 'fixed' 
            }}
        >
            {({ TransitionProps }) => (
                <Box
                    bgcolor="background.paper"
                    aria-label="Search Results"
                    ref={popperRef}
                >
                    <Fade {...TransitionProps} timeout={0}>
                        <Box bgcolor="background.paper">
                            <SearchResultsContent showProducts={showProducts} isFullWidth={isFullWidth} />
                            {/* Placing close button at bottom of popover for screen readers so they read the close button last instead of first. This feels more intuitive to me */}
                            <IconButton
                                onClick={() => clearInputBox()}
                                sx={{
                                    color: 'text.secondary',
                                    position: 'absolute',
                                    right: theme.spacing(2),
                                    top: '-2.5rem',
                                    zIndex: 5,
                                    ':hover': {
                                        color: theme.palette.primary.main,
                                        backgroundColor:
                                            'rgba(204, 77, 2, 0.04)',
                                    },
                                }}
                                aria-label="Close Results"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Fade>
                </Box>
            )}
        </Popper>
    );
};

const SearchButton = () => {
    return (
        <Button className="search-icon" aria-label="Search" type="submit">
            <SearchIcon />
        </Button>
    );
};

const SearchBarField = ({
    placeholder,
}: {
    placeholder?: string | undefined;
}) => {
    const {
        expandSearchBar,
        inputRef,
        anchorEl,
        setSearchBarFocused,
        setExpandSearchBar,
        setAnchorEl,
        triggerSearchAutocomplete,
        setEnterClicked,
        openSearchResults,
        closeSearchResults,
        highlightedTermIndex,
        setHighlightedTermIndex,
        searchResults,
        triggerSearch,
        searchTerm,
        setSearchTerm,
    } = React.useContext(SearchBarContext);

    const handleSearch = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
        const val = event.target.value;
        if (anchorEl != event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
        setSearchTerm(val);
        triggerSearchAutocomplete(val, true);
    };

    const handleOnFocus = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
        setEnterClicked(false);
        setSearchBarFocused(true);
        if (e.currentTarget.value.length > 0) {
            openSearchResults();
        }
    };
    const handleOnBlur = (
        e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ): void => {
        if (e.currentTarget.value.length === 0) {
            closeSearchResults();
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        triggerSearch();
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        if (e.key == 'Esc') {
            closeSearchResults();
        } else {
            if (e.key === 'Enter' && e.currentTarget.value.length > 0) {
                if (
                    highlightedTermIndex > -1 &&
                    searchResults.terms[highlightedTermIndex]
                ) {
                    e.currentTarget.value = searchResults.terms[
                        highlightedTermIndex
                    ].value.replace(/<[^>]*>?/gm, '');
                }
                triggerSearch();
            } else {
                if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                    let newIdx = highlightedTermIndex;
                    if (e.key === 'ArrowUp') {
                        newIdx--;
                    } else {
                        newIdx++;
                    }
                    if (newIdx < 0) {
                        newIdx = searchResults.terms.length - 1;
                    } else if (newIdx > searchResults.terms.length - 1) {
                        newIdx = 0;
                    }
                    setHighlightedTermIndex(newIdx);
                    if (searchResults.terms[newIdx]) {
                        const v = searchResults.terms[newIdx].value.replace(
                            /<[^>]*>?/gm,
                            '',
                        );
                        setSearchTerm(v);
                        triggerSearchAutocomplete(v, false);
                    }
                }
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl variant="standard" fullWidth style={{ margin: 0 }}>
                <FormLabel>
                    <span className="visually-hidden">Search</span>
                    <BBQSearchTextField
                        id="search-bar"
                        type="text"
                        value={searchTerm}
                        onChange={handleSearch}
                        onFocus={handleOnFocus}
                        onBlur={handleOnBlur}
                        onClick={() => setExpandSearchBar(true)}
                        placeholder={placeholder}
                        ref={inputRef}
                        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                            handleKeyPress(e)
                        }
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        spellCheck="false"
                        required
                    />
                </FormLabel>
                {!expandSearchBar ? <SearchButton /> : null}
            </FormControl>
        </form>
    );
};

const SearchBackdrop = () => {
    const { expandSearchBar, autocompleteOpen, closeSearchResults } =
        React.useContext(SearchBarContext);
    return (
        <Backdrop
            sx={{
                backgroundColor: 'transparent',
                zIndex: 1,
                position: 'relative',
            }}
            open={expandSearchBar && autocompleteOpen}
            onClick={() => {
                closeSearchResults();
            }}
        />
    );
};

export interface HawkSearchProps{
    placeholder?: string | undefined;
    showProducts?: boolean;
    isFullWidth?: boolean;
}

export const HawkSearchBar: React.FC<HawkSearchProps> = ({
    placeholder,
    showProducts = true,
    isFullWidth = false,
}) => {
    const { clearInputBox } = React.useContext(SearchBarContext);
    const { pathname } = useLocation();
    const searchBoxRef = useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        const unlisten = () => {
            clearInputBox();
        };
        return () => {
            unlisten();
        };
    }, [pathname]);

    return (
        <Box position="relative" ref={searchBoxRef}>
            <SearchBarField placeholder={placeholder} />
            <SearchBackdrop />
            <SearchResults
                showProducts={showProducts}
                containerRef={searchBoxRef}
                isFullWidth={isFullWidth}
            />
        </Box>
    );
}