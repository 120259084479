import { createHttpLink, HttpOptions } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import fetch from 'cross-fetch';
import { OptionsHexOutput } from 'crypto-hash';
import { Request } from 'express';
import { getEnvironmentVariable, isDevelopment } from '../config';

// crypto-hash can not be imported when bundling because its an ES module and we are using commonjs, so we need to wrap it to use an import statement that is compatible with the compiler
const sha256 = (
    input: string | ArrayBuffer | ArrayBufferView,
    options?: OptionsHexOutput,
) => import('crypto-hash').then(({ sha256 }) => sha256(input, options));

export default function getPersistedHttpLink(
    isSSR = false,
    req: Request | undefined,
) {
    let httpOptions: HttpOptions = {};
    if (isSSR) {
        httpOptions = {
            uri: getEnvironmentVariable('GRAPHQL_ENDPOINT_SERVER'),
            credentials: 'same-origin',
            headers: {
                cookie:
                    req && typeof req.header === 'function'
                        ? req.header('Cookie') ?? ''
                        : '',
            },
            fetch,
        };
    } else {
        httpOptions = {
            uri: getEnvironmentVariable('GRAPHQL_ENDPOINT_CLIENT'),
            fetch,
        };
    }
    const httpLink = createHttpLink(httpOptions);

    let persistedHttpLink = httpLink;
    if (!isDevelopment() && !isSSR) {
        // persist queries automatically using hashing
        persistedHttpLink = createPersistedQueryLink({
            sha256,
            useGETForHashedQueries: true,
            // generateHash: (query) => {
            //   const proTierId = '-' + (localStorage.getItem('pro_tier_id') || '0');
            //   return sha256(print(query) + proTierId)
            // }
        }).concat(httpLink);
    }
    return persistedHttpLink;
}
