import React from 'react';
import theme from '../../../../Theme';
import {
    FieldWithLabel,
    FieldWithLabelProps,
} from '../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';
interface NumberWithLabelProps extends FieldWithLabelProps {
    maxQty: number;
    minQty?: number;
    onMinQty?: () => void;
}
let interactiveTimer: NodeJS.Timeout | string | number | undefined;
export const NumberWithLabel = (props: NumberWithLabelProps) => {
    const {
        type = 'number',
        minQty = 0,
        maxQty,
        sx,
        onMinQty,
        ...rest
    } = props;

    const submitForm = (value: number) => {
        if (typeof onMinQty === 'function' && value <= minQty) {
            onMinQty();
        } else {
            rest.formik.handleSubmit();
        }
    };

    const handleChangeTimeoutEvent = (value: number) => {
        clearTimeout(interactiveTimer);
        interactiveTimer = setTimeout(() => {
            submitForm(value);
        }, 1500);
    };

    return (
        <FieldWithLabel
            type={type ?? 'number'}
            sx={{
                '.Mui-error': {
                    textAlign: 'center',
                    display: 'block',
                    margin: 0,
                    width: '100%',
                },
                ...sx,
            }}
            inputProps={{
                min: minQty,
                max: maxQty,
                pattern: '[0-9]*',
                onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
                    const value = parseInt(e.currentTarget.value || '1');
                    handleChangeTimeoutEvent(value);
                },
                onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
                    const value = parseInt(e.currentTarget.value);
                    if (value <= minQty || isNaN(value)) {
                        rest.formik.setFieldValue(rest.name, minQty);
                    } else {
                        rest.formik.setFieldValue(rest.name, value);
                    }
                    clearTimeout(interactiveTimer);
                    submitForm(rest.formik.values[rest.name]);
                },
                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    clearTimeout(interactiveTimer);
                    const value = parseInt(e.currentTarget.value);
                    if (value > minQty) {
                        rest.formik.handleChange(e);
                        handleChangeTimeoutEvent(value);
                    }
                },
                onFocus: (e: React.FocusEvent<HTMLInputElement>) => {
                    const t = e.currentTarget;
                    t.select();
                },
                sx: {
                    textAlign: 'center',
                    padding: theme.spacing(1, 0, 1, 1),
                },
            }}
            {...rest}
        />
    );
};
