import { Box } from '@mui/material';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import { YoutubeVideo } from '../../Components/_base/VideoPlayer';
import { ExpertReviewClass } from '../../types';
import { ContentDisplay } from '../PageContent/PageContent';
import ExpertReviewsRatingsTable from './ExpertReviewRatingsTable';

const ExpertReviewVideo = ({
    expertReview,
    withVideo = true,
}: {
    expertReview: ExpertReviewClass;
    withVideo?: boolean;
}) => {
    return (
        <>
            {withVideo === true &&
                expertReview.videoImageUrl &&
                expertReview.videoUrl && (
                    <>
                        <Box
                            sx={{
                                position: 'relative',
                                width: '100%',
                                paddingBottom: '56.25%' /* 16:9 aspect ratio */,
                                height: 0,
                                overflow: 'hidden',
                            }}
                        >
                            <YoutubeVideo url={expertReview.videoUrl} />
                        </Box>
                    </>
                )}
        </>
    );
};

const ExpertReviewContentWithRatingsTable = (props: {
    expertReview: ExpertReviewClass;
    withVideo?: boolean;
}) => {
    const { expertReview, withVideo = true } = props;
    return (
        <TwoColumnLayout
            reverseLayout={true}
            columnWidth={{
                xs: '1fr',
                md: '5fr 2fr',
            }}
            leftSideComponent={
                <Box>
                    <ExpertReviewVideo
                        expertReview={expertReview}
                        withVideo={withVideo}
                    />
                    {expertReview.content && (
                        <ContentDisplay
                            content={expertReview.content}
                            withIntro={false}
                        />
                    )}
                </Box>
            }
            rightSideComponent={
                <Box position="sticky" top="1rem">
                    <ExpertReviewsRatingsTable expertReview={expertReview} />
                </Box>
            }
        />
    );
};

export default ExpertReviewContentWithRatingsTable;
