import { Stack, Typography } from '@mui/material';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { IconProp65Warning } from '../../../../Components/_base/icons/icons';
import { Item } from '../../../../types';

const Prop65 = (props: { item: Item }) => {
    return props.item.prop65 ? (
        <>
            <Section title="Proposition 65">
                <Stack direction={{ md: 'row' }} sx={{ alignItems: 'center' }}>
                    <Stack
                        direction="row"
                        sx={{
                            mr: 1,
                            alignSelf: 'flex-start',
                            alignItems: 'center',
                        }}
                    >
                        <IconProp65Warning sx={{ mr: 1, fontSize: '1.2rem' }} />
                        <Typography
                            variant="bold"
                            component="strong"
                            sx={{ fontSize: '1rem' }}
                        >
                            WARNING:
                        </Typography>
                    </Stack>
                    <Typography
                        variant="body1"
                        component="p"
                        dangerouslySetInnerHTML={{ __html: props.item.prop65 }}
                    />
                </Stack>
            </Section>
        </>
    ) : null;
};

export default Prop65;
