import styled from '@emotion/styled';
import { Close } from '@mui/icons-material';
import {
    Alert,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    Stack,
    SxProps,
    Typography,
    TypographyProps,
} from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { Image } from '../../../Components/Image';
import {
    ProPriceLabel,
    screenReaderPrice,
} from '../../../Components/Item/Pricing';
import {
    CheckboxWithLabel,
    HelpTipIcon,
    RewardPointAmount,
    SignInButton,
    SignOutButton,
} from '../../../Components/_base/BBQGuysComponents';
import { CartContext } from '../../../Contexts/CartProvider';
import { CheckoutContext } from '../../../Contexts/CheckoutProvider';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    PaymentDeferred,
    DiscountPricingFormatted,
    Maybe,
    Order,
    OrderItem,
    TaxLine,
    TaxLineFormatted,
} from '../../../types';
import { LeavesWarehouseIn } from '../../../Components/Item/ItemInfo';
import * as PaymentDeferredUtils from '../../../Contexts/PaymentDeferredUtils';
import theme from '../../../Theme';

const OrderItemsSummaryRow = styled(Box)(() => ({
    '&> div': {
        paddingTop: '1rem',
        paddingBottom: '1rem',
        borderTop: theme.palette.border,
        '&:first-child': {
            borderTopWidth: 0,
        },
    },
}));

export const OrderItemsSummary = (props: {
    isCheckoutCompletePage?: boolean;
    order?: Order;
}) => {
    let { order } = React.useContext(CartContext);
    const isCheckoutCompletePage = props.isCheckoutCompletePage ?? false;

    if (props.order) {
        order = props.order;
    }
    return (
        <OrderItemsSummaryRow>
            {order.orderItems && order.orderItems.constructor === Array && (order.orderItems as OrderItem[]).map((oi: OrderItem, i: number) => {
                return (
                    <Box
                        key={'order-item-' + oi.id}
                        sx={{
                            borderTopWidth: `${oi.isChildItem ? 0 : 1} !important`,
                        }}
                    >
                        <Box
                            display="grid"
                            gap={2}
                            gridTemplateColumns="75px 1fr"
                            sx={{
                                pl: oi.isChildItem ? 4 : 0,
                            }}
                        >
                            <Image src={oi.item.imageUrl} width={60} rounded />
                            <Box
                                display="grid"
                                gap={2}
                                gridTemplateColumns="1fr 100px"
                            >
                                <Box>
                                    <ListItemText primary={oi.name} />
                                    {isCheckoutCompletePage && (
                                        <ListItemText
                                            primary={
                                                <LeavesWarehouseIn
                                                    size="medium"
                                                    shipsIn={oi.item.shipsIn}
                                                />
                                            }
                                        />
                                    )}
                                    <ListItemText
                                        primary={'Qty: ' + oi.qty}
                                        aria-label={'Quantity of ' + oi.qty}
                                    />
                                </Box>
                                <Box>
                                    <ListItemText
                                        primary={oi.pricingFormatted.price}
                                        aria-label={screenReaderPrice(
                                            oi.pricingFormatted.price,
                                        )}
                                        sx={{
                                            textAlign: 'right',
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        {isCheckoutCompletePage && (
                            <OrderItemSummaryRowAlerts orderItem={oi} />
                        )}
                    </Box>
                );
            })}
        </OrderItemsSummaryRow>
    );
};
const OrderItemSummaryRowAlerts = ({ orderItem }: { orderItem: OrderItem }) => {
    return (
        <>
            {orderItem.tdiId && (
                <>
                    <Alert variant="text" severity="warning">
                        Open-Box sales are final and not eligible for return.
                    </Alert>
                </>
            )}
        </>
    );
};
const TextWithChipSubtext = ({
    text,
    subtext,
}: {
    text: string;
    subtext: string | undefined;
}) => {
    return (
        <Box
            sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '70%',
            }}
        >
            {text}
            {subtext !== undefined && (
                <Chip
                    variant="outlined"
                    color="success"
                    label={subtext}
                    size="small"
                    sx={{ mt: 0, ml: 1, fontSize: 12 }}
                />
            )}
        </Box>
    );
};

const OrderTotalsLoading = () => {
    const WORD_WIDTHS = [150, 100];
    const AMOUNT_WIDTHS = [90, 95];
    const { order, hasOrder } = React.useContext(CartContext);

    if (hasOrder()) {
        if (order.pricing.discounts.coupons.length > 0) {
            WORD_WIDTHS.push(120);
            AMOUNT_WIDTHS.push(80);
        }
        if (order.pricing.discounts.giftCards.length > 0) {
            WORD_WIDTHS.push(110);
            AMOUNT_WIDTHS.push(80);
        }
        if (order.pricing.discounts.rewardPoints.length > 0) {
            WORD_WIDTHS.push(115);
            AMOUNT_WIDTHS.push(80);
        }
        if (order.pricing.taxLines.length > 0) {
            order.pricing.taxLines.forEach((v: Maybe<TaxLine>) => {
                WORD_WIDTHS.push(130);
                AMOUNT_WIDTHS.push(80);
            });
        }
    }
    return (
        <List>
            {WORD_WIDTHS.map((v: number, i: number) => {
                return (
                    <ListItem
                        key={'order-total-line-' + i}
                        secondaryAction={
                            <Skeleton variant="text" width={AMOUNT_WIDTHS[i]} />
                        }
                    >
                        <Skeleton variant="text" width={v} />
                    </ListItem>
                );
            })}
            <Divider />
            <ListItem secondaryAction={<Skeleton variant="text" width={90} />}>
                <Skeleton variant="text" width={90} />
            </ListItem>
        </List>
    );
};

export const OrderTotals = (props: {
    order?: Order;
    alwaysShowTax?: boolean;
    totalOwed?: boolean;
    isCheckoutCompletePage?: boolean;
}) => {
    const { isPro } = React.useContext(UserAuth);
    const { orderUpdating } = React.useContext(CheckoutContext);
    let { order } = React.useContext(CartContext);
    const isCheckoutCompletePage: boolean =
        props?.isCheckoutCompletePage ?? false;
    const totalOwed = props?.totalOwed ?? true;

    if (props.order) {
        order = props.order;
    }
    const listItemSx = {
        px: 0,
        '.MuiListItemSecondaryAction-root': {
            right: 0,
        },
    } as SxProps;
    let total = totalOwed
        ? order.pricingFormatted.totalOwed
        : order.pricingFormatted.total;

    if (isCheckoutCompletePage && totalOwed) {
        total = PaymentDeferredUtils.currencyFormat(
            PaymentDeferredUtils.getTotalMinusServerSideDeferred(false, order),
        );
    }

    const hasTaxData =
        order.pricing.tax > 0 ||
        (order.shippingAddress &&
            order.shippingAddress.zip &&
            order.shippingAddress.zip.length > 0)
            ? true
            : false;
    if (orderUpdating) {
        return <OrderTotalsLoading />;
    }

    const giftCardPaymentDeferreds = isCheckoutCompletePage
        ? PaymentDeferredUtils.getServerSidePaymentDeferredsByCard(order)
        : PaymentDeferredUtils.getPaymentDeferredsByCard();
    const pointsPaymentDeferreds = isCheckoutCompletePage
        ? PaymentDeferredUtils.getServerSidePaymentDeferredsByPoints(order)
        : PaymentDeferredUtils.getPaymentDeferredsByPoints();

    return order.pricing ? (
        <List>
            {/* SUBTOTAL */}
            {order.pricing.subtotal > 0 && (
                <ListItem
                    sx={listItemSx}
                    secondaryAction={
                        <span
                            aria-label={screenReaderPrice(
                                order.pricingFormatted.subtotal,
                            )}
                        >
                            {order.pricingFormatted.subtotal}
                        </span>
                    }
                >
                    Subtotal
                </ListItem>
            )}
            {/* SHIPPING */}
            <ListItem
                sx={listItemSx}
                secondaryAction={
                    order.pricing.shipping > 0 ? (
                        <span
                            aria-label={screenReaderPrice(
                                order.pricingFormatted.shipping,
                            )}
                        >
                            {order.pricingFormatted.shipping}
                        </span>
                    ) : (
                        <Typography
                            sx={{ fontSize: '1rem' }}
                            component="span"
                            variant="freeShippingSmall"
                            aria-label={screenReaderPrice(
                                order.pricingFormatted.shipping,
                            )}
                        >
                            {order.pricingFormatted.shipping}
                        </Typography>
                    )
                }
            >
                Shipping
            </ListItem>

            {/* TAXES */}
            {hasTaxData && order.pricing.taxLines.length > 0 ? (
                order.pricingFormatted.taxLines.map(
                    (line: Maybe<TaxLineFormatted>, i: number) => {
                        return (
                            <React.Fragment key={`tax-line-${i}`}>
                                {line && line.amount !== '$0.00' ? (
                                    <ListItem
                                        sx={listItemSx}
                                        secondaryAction={
                                            <span
                                                aria-label={screenReaderPrice(
                                                    line.amount,
                                                )}
                                            >
                                                {line.amount}
                                            </span>
                                        }
                                    >
                                        {line.name}
                                    </ListItem>
                                ) : (
                                    <></>
                                )}
                            </React.Fragment>
                        );
                    },
                )
            ) : hasTaxData && order.pricing.tax > 0 ? (
                <ListItem
                    sx={listItemSx}
                    secondaryAction={
                        <span
                            aria-label={screenReaderPrice(
                                order.pricingFormatted.tax,
                            )}
                        >
                            {order.pricingFormatted.tax}
                        </span>
                    }
                >
                    Tax
                </ListItem>
            ) : (
                props.alwaysShowTax && (
                    <ListItem
                        sx={listItemSx}
                        secondaryAction={
                            <Typography variant="mutedEmphasis">
                                Calculated at Checkout
                            </Typography>
                        }
                    >
                        Tax
                    </ListItem>
                )
            )}

            {/* DISCOUNTS */}
            {/* COUPONS */}
            {order.pricingFormatted.discounts.coupons.length > 0 &&
                order.pricingFormatted.discounts.coupons.map(
                    (coupon: Maybe<DiscountPricingFormatted>, i: number) => {
                        return coupon ? (
                            <ListItem
                                sx={listItemSx}
                                key={`coupon-${i}`}
                                secondaryAction={
                                    <span
                                        aria-label={screenReaderPrice(
                                            coupon.amount,
                                        )}
                                    >
                                        -{coupon.amount}
                                    </span>
                                }
                            >
                                <TextWithChipSubtext
                                    text="Coupon"
                                    subtext={coupon.code}
                                />
                            </ListItem>
                        ) : null;
                    },
                )}
            {/* GIFT CARDS */}
            {giftCardPaymentDeferreds.length > 0 &&
                giftCardPaymentDeferreds.map(
                    (gc: Maybe<PaymentDeferred>, i: number) => {
                        return gc ? (
                            <ListItem
                                sx={listItemSx}
                                key={`gift-card-${i}`}
                                secondaryAction={
                                    <span
                                        aria-label={screenReaderPrice(
                                            PaymentDeferredUtils.getAmountFormatted(
                                                gc,
                                            ),
                                        )}
                                    >
                                        -
                                        {PaymentDeferredUtils.getAmountFormatted(
                                            gc,
                                        )}
                                    </span>
                                }
                            >
                                <TextWithChipSubtext
                                    text="Gift Card"
                                    subtext={gc.token ?? ''}
                                />
                            </ListItem>
                        ) : null;
                    },
                )}
            {/* REWARD POINTS */}
            {pointsPaymentDeferreds.length > 0 &&
                pointsPaymentDeferreds.map(
                    (rp: Maybe<PaymentDeferred>, i: number) => {
                        return rp ? (
                            <ListItem
                                key={`reward-points-${i}`}
                                sx={listItemSx}
                                secondaryAction={
                                    <span
                                        aria-label={screenReaderPrice(
                                            PaymentDeferredUtils.getAmountFormatted(
                                                rp,
                                            ),
                                        )}
                                    >
                                        -
                                        {PaymentDeferredUtils.getAmountFormatted(
                                            rp,
                                        )}
                                    </span>
                                }
                            >
                                BBQGuys Rewards
                            </ListItem>
                        ) : null;
                    },
                )}
            <Divider />
            <ListItem
                sx={listItemSx}
                secondaryAction={
                    <strong aria-label={screenReaderPrice(total)}>
                        {total}
                    </strong>
                }
            >
                {isPro() && order.isProOrder ? (
                    <ProPriceLabel forceDisplay={true} label="Total" />
                ) : (
                    <>Total</>
                )}
            </ListItem>
            {isPro() && order.isProOrder && order.pricing.proSavings > 0 && (
                <ListItem
                    sx={listItemSx}
                    secondaryAction={
                        <Typography
                            component="span"
                            color="green"
                            aria-label={screenReaderPrice(
                                order.pricingFormatted.proSavings,
                            )}
                        >
                            You saved {order.pricingFormatted.proSavings}
                        </Typography>
                    }
                ></ListItem>
            )}
        </List>
    ) : (
        <></>
    );
};

export const OrderSummaryTotals = (props: {
    order?: Order;
    isCheckoutCompletePage?: boolean;
}) => {
    let { order } = React.useContext(CartContext);
    if (props.order) {
        order = props.order;
    }
    const isCheckoutCompletePage = props?.isCheckoutCompletePage ?? false;
    return (
        <Grid container>
            <Grid item xs={2} md={6}></Grid>
            <Grid item xs={10} md={6}>
                {order.pricing ? (
                    <OrderTotals
                        order={order}
                        isCheckoutCompletePage={isCheckoutCompletePage}
                    />
                ) : null}
            </Grid>
        </Grid>
    );
};

export const ShippingAddressDisplay = () => {
    let { order } = React.useContext(CartContext);
    const { getLastOrder } = React.useContext(CartContext);
    if (!order || !order.id) {
        order = getLastOrder();
    }
    const orderName =
        order.shippingAddress?.firstName + ' ' + order.shippingAddress.lastName;
    return order.shippingAddress ? (
        <>
            {orderName.length > 2 ? (
                <>
                    {orderName}
                    <br />
                </>
            ) : null}
            {order.shippingAddress?.company ? (
                <>
                    {order.shippingAddress.company}
                    <br />
                </>
            ) : null}
            {order.shippingAddress?.address1}
            <br />
            {order.shippingAddress?.address2 ? (
                <>
                    {order.shippingAddress.address2}
                    <br />
                </>
            ) : null}
            {order.shippingAddress?.city ? (
                <>{order.shippingAddress.city},&nbsp;</>
            ) : null}
            {order.shippingAddress?.state + ' ' + order.shippingAddress?.zip}
        </>
    ) : (
        <></>
    );
};

export const CartCheckboxLabelToggleComponent = (props: {
    label: string;
    children: JSX.Element;
    onClose?: () => void;
    sectionType: 'coupon' | 'giftCard';
}) => {
    const { label, children, sectionType } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
    };
    if (open) {
        return (
            <Stack direction="row" spacing={1} alignItems="flex-start">
                <IconButton
                    onClick={() => handleClose()}
                    aria-label={
                        sectionType === 'coupon'
                            ? 'Close coupon code'
                            : 'Close gift card code'
                    }
                >
                    <Close color="error" sx={{ mt: 1.25 }} />
                </IconButton>
                {children}
            </Stack>
        );
    }

    return (
        <CheckboxWithLabel
            tabIndex={0}
            label={label}
            onChange={() => {
                setOpen(true);
            }}
            onKeyDown={event => {
                if (event.key === 'Enter') {
                    setOpen(true);
                }
            }}
        />
    );
};

export const BBQGuysRewardsPointsLogo = ({
    withHelpTip = false,
    width = 150,
    height = 16,
}: {
    withHelpTip?: boolean;
    width?: number;
    height?: number;
}) => {
    return (
        <>
            <Image
                src="https://cdn.shocho.co/sc-site/bbqguys/branding/logos/Rewards-Logo.svg?disable_opts=1"
                width={width}
                height={height}
                alt="BBQGuys Rewards"
                sx={{ verticalAlign: 'middle' }}
            />
            {withHelpTip && (
                <>
                    &nbsp;
                    <HelpTipIcon contentId="42339" sx={{ p: 0 }} size="sm" />
                </>
            )}
        </>
    );
};

export const SignInForRewardPointsLink = (props?: TypographyProps) => {
    const { ...rest } = props;
    const { isLoggedIn } = React.useContext(UserAuth);
    const { getOrder } = React.useContext(CartContext);
    const o = getOrder();
    return o?.pricingFormatted?.discounts?.rewardPoints ? (
        <Typography variant="body1" {...rest}>
            {isLoggedIn() ? (
                <>
                    You will earn{' '}
                    <RewardPointAmount>
                        {
                            o.pricingFormatted.rewardPointsAvailable.inPoints
                                .earnable
                        }
                    </RewardPointAmount>{' '}
                    <BBQGuysRewardsPointsLogo /> points on this order.{' '}
                    <SignOutButton />
                </>
            ) : (
                <>
                    <SignInButton
                        type="login"
                        button={<Button variant="link">Sign in</Button>}
                    />{' '}
                    or{' '}
                    <SignInButton
                        type="register"
                        button={
                            <Button variant="link">create an account</Button>
                        }
                    />{' '}
                    for <BBQGuysRewardsPointsLogo />
                </>
            )}
        </Typography>
    ) : (
        <></>
    );
};

export const CheckoutLegalText = () => {
    return (
        <Typography component="p">
            By placing my order, I agree to BBQGuys.com&apos;s{' '}
            <Link href="/policies" target="_blank">
                terms and conditions
            </Link>{' '}
            and the use of my personal data as explained in the{' '}
            <Link href="/policies#privacy" target="_blank">
                Privacy Policy
            </Link>
            .
        </Typography>
    );
};
