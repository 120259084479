import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { Box, GridSpacing, ResponsiveStyleValue } from '@mui/system';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
    ProductCard,
    ProductCardList,
    ProductCardListLoading,
    ProductCardLoading,
    ProductCardType,
} from '../../Components/ProductCards/ProductCards';
import {
    Select,
    BBQGuysBottomDrawerWrapper,
} from '../../Components/_base/BBQGuysComponents';
import { ProductGridRefinement } from '../../Contexts/ProductGridRefinementProvider';
import { SSRContext } from '../../Contexts/SSRProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { ResultsNotFoundMessaging } from '../../Pages/Page404/Page404';
import { Item, Maybe, PaginatedItemsResponse, SortOption } from '../../types';
import { BBQPagination } from '../Pagination/BBQPagination';
import './ProductGridItems.scss';
import { DigitalDataContext } from '../../Contexts/DigitalDataProvider';
import { SelectedFacets, SelectedAttribute } from './ProductFacets';

export const RefinementsBarLoading = () => {
    return (
        <>
            <DesktopAndTabletDevice>
                <Grid container pb={1} spacing={1}>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={5}>
                        <Stack direction="row" spacing={3}>
                            <Skeleton
                                variant="rectangular"
                                height={41}
                                width={'100%'}
                            />
                            <Skeleton
                                variant="rectangular"
                                height={41}
                                width={'100%'}
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                    <Skeleton
                        variant="rectangular"
                        height={41}
                        width={'100%'}
                    />
                    <Skeleton
                        variant="rectangular"
                        height={41}
                        width={'100%'}
                    />
                </Stack>
            </MobileDevice>
        </>
    );
};

const RefinementsBarDesktop = (props: {
    sortOptions: { value: string; label: string }[];
    showGridViewOptions?: boolean;
    deptId?: string;
    checkedFacets?: SelectedAttribute[];
}) => {
    const {
        sortValue,
        setSortValue,
        getGridViewValue,
        setGridViewValue,
        showGridViewOptions = true,
    } = React.useContext(ProductGridRefinement);
    const location = useLocation();
    const { sortOptions } = props;
    const [selectedGridView, setSelectedGridView] =
        React.useState(getGridViewValue());

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sort = searchParams.get('sort') || '';
        if (sort && sort !== sortValue) {
            setSortValue(sort);
        }
    }, [sortValue, location.search]);

    const handleChange = (event: SelectChangeEvent) => {
        setSortValue(event.target.value as string);
    };

    const handleGridViewChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value === 'grid' ? 'grid' : 'list';
        setGridViewValue(value);
        setSelectedGridView(value);
    };

    const getLabelForValue = (value: string) => {
        const option = sortOptions.find(option => option.value === value);
        return option ? option.label : value;
    };

    const ICON_GRID = (
        <GridViewIcon
            htmlColor="#333"
            sx={{ verticalAlign: 'middle', fontSize: 24, mr: 1 }}
        />
    );
    const ICON_LIST = (
        <ViewListIcon
            htmlColor="#333"
            sx={{ verticalAlign: 'middle', fontSize: 24, mr: 1 }}
        />
    );

    const selectedAttributes =
        props.checkedFacets ?? ([] as SelectedAttribute[]);

    return (
        <>
            <Grid container pb={1} spacing={1}>
                <Grid item xs={7}></Grid>
                <Grid item xs={5} justifyContent="flex-end" display="flex">
                    <Stack direction="row" spacing={3}>
                        {showGridViewOptions && (
                            <FormControl sx={{ width: 110, textAlign: 'left' }}>
                                <Select
                                    labelId="gridview-select-label"
                                    displayColon={false}
                                    id="gridview-select"
                                    value={selectedGridView}
                                    onChange={handleGridViewChange}
                                    aria-hidden={true}
                                    sx={{ lineHeight: 1.5 }}
                                    renderValue={(value: any) => (
                                        <Box
                                            display="flex"
                                            alignItems="center"
                                            marginTop={-3}
                                        >
                                            {value === 'grid'
                                                ? ICON_GRID
                                                : ICON_LIST}
                                            {value === 'grid' ? 'Grid' : 'List'}
                                        </Box>
                                    )}
                                >
                                    {['grid', 'list'].map(
                                        (option: string, i: number) => (
                                            <MenuItem
                                                key={i}
                                                value={option}
                                                sx={{ paddingRight: 0 }}
                                            >
                                                <Box
                                                    display="flex"
                                                    alignItems="center"
                                                >
                                                    {option === 'grid'
                                                        ? ICON_GRID
                                                        : ICON_LIST}
                                                    {option === 'grid'
                                                        ? ' Grid'
                                                        : ' List'}
                                                </Box>
                                            </MenuItem>
                                        ),
                                    )}
                                </Select>
                            </FormControl>
                        )}
                        <FormControl>
                            <Select
                                labelId="sort-select-label"
                                id="sort-select"
                                value={sortValue}
                                label="Sort By"
                                onChange={handleChange}
                                renderValue={getLabelForValue} // Map value to label here
                            >
                                {sortOptions.map((option, i) => (
                                    <MenuItem key={i} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                </Grid>
            </Grid>
            <SelectedFacets selectedAttributes={selectedAttributes} />
        </>
    );
};

const getInitSortValue = (options: { value: string; label: string }[]) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('sort') || options[0].value;
};
const RefinementsBarMobile = (props: {
    sortOptions: SortOption[];
    showGridViewOptions?: boolean;
    deptId?: string;
    checkedFacets?: SelectedAttribute[];
}) => {
    const { sortValue, setSortValue, showFacets, setShowFacets } =
        React.useContext(ProductGridRefinement);
    const { sortOptions, checkedFacets = [] } = props;
    const [showSortDrawer, setShowSortDrawer] = React.useState(false);
    const [selectedValue, setSelectedValue] = React.useState(
        getInitSortValue(sortOptions),
    );
    const label = '';

    const location = useLocation();

    React.useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sort = searchParams.get('sort') || '';
        if (!sort && sortValue !== selectedValue) {
            setSelectedValue(sortValue);
        }
    }, [sortValue, location.search]);

    /* change events on the select component */
    const handleChange = (value: string) => {
        setSelectedValue(value as string);
    };
    /* handling the drawer closing to reset or commit the changes */
    const handleClose = React.useCallback(
        (type: 'cancel' | 'confirm') => {
            if (type === 'cancel') {
                setShowSortDrawer(false);
            } else if (type === 'confirm') {
                setSortValue(selectedValue);
                setShowSortDrawer(false);
            }
        },
        [selectedValue, setSortValue],
    );

    let filterText = 'Filter';

    if (checkedFacets.length > 0) {
        filterText += ' (' + checkedFacets.length + ')';
    }

    return (
        <>
            <Stack direction="row" spacing={2} sx={{ my: 2 }}>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => setShowFacets(!showFacets as boolean)}
                >
                    {filterText}
                </Button>
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => setShowSortDrawer(!showSortDrawer)}
                >
                    Sort
                </Button>
            </Stack>
            <BBQGuysBottomDrawerWrapper
                open={showSortDrawer}
                label={label}
                title="Sort By"
                onClose={handleClose}
            >
                <RadioGroup
                    aria-labelledby={`radio-${label}-group-label`}
                    value={selectedValue}
                    name={`radio-${label}-group`}
                    onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        value: string,
                    ) => handleChange(value)}
                >
                    {sortOptions.map((option: SortOption, i: number) => {
                        return (
                            <FormControlLabel
                                key={label + i}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                            />
                        );
                    })}
                </RadioGroup>
            </BBQGuysBottomDrawerWrapper>
        </>
    );
};

export const RefinementsBar = (props: {
    sortOptions: SortOption[];
    deptId?: string;
    checkedFacets?: SelectedAttribute[];
}) => {
    return (
        <>
            <DesktopAndTabletDevice>
                <RefinementsBarDesktop showGridViewOptions={true} {...props} />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <RefinementsBarMobile showGridViewOptions={true} {...props} />
            </MobileDevice>
        </>
    );
};

export const ProductPagination = (props: {
    page: number;
    totalPages: number;
}) => {
    const { setPageNumber } = React.useContext(ProductGridRefinement);
    const { isClientSide } = React.useContext(SSRContext);
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        if (isClientSide()) {
            window.scrollTo(0, 0);
        }
        setPageNumber(value);
    };

    return props.totalPages > 1 ? (
        <BBQPagination
            count={props.totalPages}
            page={props.page}
            onChange={handleChange}
            sx={{ display: 'inline-flex' }}
        />
    ) : null;
};

export const ProductsGridLoading = (props: {
    showRefinementsBar?: boolean;
    showPagination?: boolean;
    spacing?: ResponsiveStyleValue<GridSpacing>;
    withGrid?: boolean;
    type?: 'grid' | 'list' | undefined;
}) => {
    const { getGridViewValue } = React.useContext(ProductGridRefinement);
    const {
        showRefinementsBar = true,
        spacing = 0,
        withGrid = true,
        type,
    } = props;

    const renderProductGridLoading = (type: 'grid' | 'list') => {
        if (type === 'list') {
            return (
                <Box
                    className={withGrid ? 'product-grid-list' : ''}
                    gap={spacing}
                >
                    {[...Array(15)].map((v: unknown, i: number) => {
                        return (
                            <ProductCardListLoading
                                key={'product-list-loading-' + i}
                            />
                        );
                    })}
                </Box>
            );
        } else {
            return (
                <Box
                    className={withGrid ? 'product-grid' : ''}
                    display="grid"
                    gap={spacing}
                    gridTemplateColumns={{
                        xs: '1fr 1fr',
                        md: '1fr 1fr 1fr',
                        lg: '1fr 1fr 1fr 1fr',
                    }}
                >
                    {[...Array(30)].map((v: unknown, i: number) => {
                        return (
                            <ProductCardLoading
                                key={'product-grid-loading-' + i}
                            />
                        );
                    })}
                </Box>
            );
        }
    };

    return (
        <>
            {showRefinementsBar && <RefinementsBarLoading />}
            {renderProductGridLoading(type ?? getGridViewValue())}
        </>
    );
};

export const ProductGrid = (props: {
    items: Item[];
    type?: 'grid' | 'list';
    ignoreLazyLoadOnFirstXItems?: number;
    withAddToCartButton?: boolean;
    withMoreOptionsAvailable?: boolean;
    cardType?: ProductCardType;
    spacing?: ResponsiveStyleValue<GridSpacing>;
    withGrid?: boolean;
    onDelete?: (item: Item) => void;
}) => {
    const {
        items,
        type = 'grid',
        ignoreLazyLoadOnFirstXItems = 0,
        withAddToCartButton = false,
        withMoreOptionsAvailable = false,
        cardType = 'default' as ProductCardType,
        spacing = 0,
        withGrid = true,
        onDelete,
    } = props;
    if (type === 'list') {
        return (
            <Box className={withGrid ? 'product-grid-list' : ''} gap={spacing}>
                {items.map((item: Maybe<Item>, i: number) => {
                    return item ? (
                        <ProductCardList
                            cardType={cardType}
                            key={'product-list-item-' + item.id}
                            item={item}
                            withAddToCartButton={withAddToCartButton}
                            withMoreOptionsAvailable={withMoreOptionsAvailable}
                            lazyLoad={i > ignoreLazyLoadOnFirstXItems}
                            onDelete={onDelete}
                        />
                    ) : null;
                })}
            </Box>
        );
    } else {
        return (
            <Box
                className={withGrid ? 'product-grid' : ''}
                display="grid"
                gap={spacing}
                gridTemplateColumns={{
                    xs: '1fr 1fr',
                    md: '1fr 1fr 1fr',
                    lg: '1fr 1fr 1fr 1fr',
                }}
            >
                {items.map((item: Maybe<Item>, i: number) => {
                    return item ? (
                        <ProductCard
                            key={'product-grid-item-' + item.id}
                            cardType={cardType}
                            item={item}
                            withAddToCartButton={withAddToCartButton}
                            withMoreOptionsAvailable={withMoreOptionsAvailable}
                            lazyLoad={i > ignoreLazyLoadOnFirstXItems}
                            onDelete={onDelete}
                        />
                    ) : null;
                })}
            </Box>
        );
    }
};

const ProductGridUpdate = (props: {
    data: PaginatedItemsResponse;
    sortOptions?: SortOption[];
    showRefinementsBar?: boolean;
    showPagination?: boolean;
    withAddToCartButton?: boolean;
    deptId?: string;
    cardType?: ProductCardType;
}) => {
    const {
        setTotalItems,
        setTotalPages,
        getGridViewValue,
        setGridViewValue,
        setSelectedAttributes,
        ignoreLazyLoadOnFirstXItems,
    } = React.useContext(ProductGridRefinement);

    const {
        data,
        sortOptions = [],
        showRefinementsBar = true,
        showPagination = true,
        withAddToCartButton = false,
        deptId = '',
        cardType = 'default' as ProductCardType,
    } = props;

    React.useEffect(() => {
        setTotalItems(data.totalResults);
        setTotalPages(data.totalPages);
        setSelectedAttributes(
            (data?.checkedFacets ?? []) as SelectedAttribute[],
        );
        const view = getGridViewValue();
        if (view) {
            setGridViewValue(view);
        }
    }, [
        data.totalResults,
        data.totalPages,
        getGridViewValue,
        setGridViewValue,
        setTotalItems,
        setTotalPages,
    ]);

    const moreOptionsAvailable =
        data.metaData?.moreOptionsRenderStyle == 'moreOptions';

    const selectedAttributes = (data?.checkedFacets ??
        []) as SelectedAttribute[];

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    {showRefinementsBar && sortOptions && (
                        <RefinementsBar
                            sortOptions={sortOptions}
                            deptId={deptId}
                            checkedFacets={selectedAttributes}
                        />
                    )}
                    {data && data.results && data.results.length > 0 && (
                        <ProductGrid
                            items={data.results as Item[]}
                            type={
                                cardType == 'deal' ? 'grid' : getGridViewValue()
                            }
                            ignoreLazyLoadOnFirstXItems={
                                ignoreLazyLoadOnFirstXItems
                            }
                            withAddToCartButton={withAddToCartButton}
                            withMoreOptionsAvailable={moreOptionsAvailable}
                            cardType={cardType}
                        />
                    )}
                    {showPagination && (
                        <Box textAlign="center" padding={2}>
                            <ProductPagination
                                page={data.currentPage}
                                totalPages={data.totalPages}
                            />
                        </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

const SearchResultsTitle = (props: {
    title: string | null;
    hideTitleIfNoResults: boolean;
    hasProducts: boolean;
}) => {
    if (
        props.title === null ||
        (props.hideTitleIfNoResults && !props.hasProducts)
    ) {
        return <></>;
    } else {
        return <Typography variant="subtitle">{props.title}</Typography>;
    }
};

const NullSearchResults = (props: {
    hideTitleIfNoResults?: boolean;
    hideAlertIfNoResults?: boolean;
    title?: string | null;
    term?: string;
}) => {
    const {
        hideTitleIfNoResults = true,
        hideAlertIfNoResults = false,
        title = null,
        term = '',
    } = props;
    return (
        <>
            {hideAlertIfNoResults === false ? (
                <ResultsNotFoundMessaging
                    title={
                        <>
                            We&apos;re sorry, we couldn&apos;t find results for{' '}
                            <q>{term}</q>
                        </>
                    }
                />
            ) : (
                hideTitleIfNoResults === false && (
                    <SearchResultsTitle
                        title={title}
                        hideTitleIfNoResults={hideTitleIfNoResults}
                        hasProducts={false}
                    />
                )
            )}
        </>
    );
};

export const ProductsGridDisplay = (props: {
    data: any;
    sortOptions?: any;
    showRefinementsBar?: boolean;
    showPagination?: boolean;
    hideTitleIfNoResults?: boolean;
    hideAlertIfNoResults?: boolean;
    title?: string | null;
    withAddToCartButton?: boolean;
    searchTerm?: string;
    setNoResults?: React.Dispatch<React.SetStateAction<any>>;
    cardType?: ProductCardType;
}) => {
    const {
        data,
        hideTitleIfNoResults = true,
        hideAlertIfNoResults = false,
        title = null,
        searchTerm = '',
        setNoResults,
    } = props;
    const { pushItemDependency } = React.useContext(DigitalDataContext);
    const { setDisplayTitle } = React.useContext(ProductGridRefinement);
    React.useEffect(() => {
        pushItemDependency(data);
    }, [data.results]);
    React.useEffect(() => {
        if (data && data.results && data.results.length === 0) {
            setNoResults && setNoResults(true);
        } else {
            setDisplayTitle(`${searchTerm}(${data.totalResults} products)`);
        }
    }, []);

    if (data && data.results && data.results.length === 0) {
        return (
            <NullSearchResults
                term={searchTerm}
                title={title}
                hideTitleIfNoResults={hideTitleIfNoResults}
                hideAlertIfNoResults={hideAlertIfNoResults}
            />
        );
    }
    return (
        <>
            <SearchResultsTitle
                title={title}
                hideTitleIfNoResults={hideTitleIfNoResults}
                hasProducts={data && data.results && data.results.length > 0}
            />
            <ProductGridUpdate {...props} />
        </>
    );
};
