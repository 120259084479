import * as React from 'react';

export const ABTestContext = React.createContext({
    abTests: [] as string[],
    setABTest: (id: string) => {
        return;
    },
    inABTest: (id: string): boolean => {
        return false;
    },
});

export function ABTestProvider(props: {
    defaultTestIds?: string[];
    children: any;
}) {
    const { defaultTestIds = [] } = props;
    const [abTests, setABTests] = React.useState(defaultTestIds);

    const setABTest = (id: string) => {
        abTests.push(id);
        setABTests(abTests);
    };

    const inABTest = (id: string) => {
        return abTests.indexOf(id) > -1;
    };

    return (
        <ABTestContext.Provider
            value={{
                abTests,
                setABTest,
                inABTest,
            }}
        >
            {props.children}
        </ABTestContext.Provider>
    );
}
