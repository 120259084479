import { Box } from '@mui/system';
import { memo } from 'react';

function MainBodyContent(props: any) {
    // const { checkForReauth } = useContext(UserAuth);
    // const navigate = useNavigate();

    // useEffect(() => {
    //     /**
    //      * This method is used to detect all Anchor tag element clicks that are not built in React using the Link component (i.e. anchor tags <a> in content pages)
    //      * and then intercepting the click and forcing them to render using the internal link routing to prevent a page refresh
    //      * @param e MouseEvent
    //      */
    //     const NavigateInternalLinks = (e: MouseEvent) => {
    //         let target = e.target as HTMLElement | null;
    //         while (target) {
    //             if (target.tagName === 'A') {
    //                 const href = target?.getAttribute('href') || '';
    //                 const reg = new RegExp(
    //                     '(^/|((https?://)?)www.bbqguys.com/)',
    //                 );
    //                 const ourDomain = reg.test(href);
    //                 if (ourDomain) {
    //                     if (hasParent(target, 'content')) {
    //                         e.preventDefault();
    //                         navigate(href);
    //                     }
    //                 }
    //                 break;
    //             }

    //             target = target.parentElement;
    //         }
    //     };
    //     document.onclick = NavigateInternalLinks;

    //     checkForReauth();
    // }, [navigate]);

    return (
        <Box
            component="div"
            id="main-content"
            sx={{
                minHeight: '80vh',
                '> .container': { width: '90%', mx: 'auto' },
            }}
        >
            {props.children}
        </Box>
    );
}
export default memo(MainBodyContent);
