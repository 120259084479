import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import ScContainer from '../../../Components/Layout/ScContainer/ScContainer';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../Contexts/DeviceTypeProvider';
import theme from '../../../Theme';
import { LoginForm } from './LoginForm';
import { RegistrationForm } from './RegistrationForm';

const LoginSectionTitle = (props: { type: 'login' | 'registration' }) => {
    return (
        <Typography
            variant="h2"
            component={props.type === 'login' ? 'h1' : 'h2'}
            sx={{ my: 4 }}
        >
            {props.type === 'login'
                ? 'Sign In to My Account'
                : 'Create an Account'}
        </Typography>
    );
};

export default function PageLogin() {
    const [view, setView] = React.useState('login');

    return (
        <ScContainer className="login">
            <Helmet>
                <title>Login</title>
            </Helmet>
            <DesktopAndTabletDevice>
                <Box display="grid" gridTemplateColumns={'1fr 1px 1fr'} gap={4}>
                    <Box pt={6} pb={12}>
                        <ScContainer size="small">
                            <LoginSectionTitle type="login" />
                            <Typography variant="body1">
                                Sign in below for BBQGuys Rewards accounts, and
                                BBQGuys Pro accounts.
                            </Typography>
                            <LoginForm />
                        </ScContainer>
                    </Box>
                    <Box
                        sx={{
                            borderLeft: `1px dotted ${theme.palette.divider}`,
                        }}
                    />
                    <Box pt={6} pb={12}>
                        <ScContainer size="small">
                            <LoginSectionTitle type="registration" />
                            <RegistrationForm />
                        </ScContainer>
                    </Box>
                </Box>
            </DesktopAndTabletDevice>
            <MobileDevice>
                {view === 'registration' ? (
                    <>
                        <LoginSectionTitle type="registration" />
                        <RegistrationForm />
                        <Box sx={{ mt: 2, mb: 8, textAlign: 'center' }}>
                            <Button
                                variant="link"
                                onClick={() => setView('login')}
                            >
                                Already have an account? Sign in.
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        <LoginSectionTitle type="login" />
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            Sign in below for BBQGuys Rewards accounts, and
                            BBQGuys Pro accounts.
                        </Typography>
                        <LoginForm />
                        <Box sx={{ mt: 2, mb: 4, textAlign: 'center' }}>
                            <Button
                                variant="link"
                                onClick={() => setView('registration')}
                            >
                                Don&apos;t have an Account? Sign up here.
                            </Button>
                        </Box>
                    </>
                )}
            </MobileDevice>
        </ScContainer>
    );
}
