import {
    Box,
    Card,
    CardContent,
    Skeleton,
    SxProps,
    Typography,
} from '@mui/material';
import React from 'react';
import { getEnvironmentVariable } from '../../config';
import { Maybe } from '../../types';
import { Image, ImageProps } from '../../Components/Image';
import { LineEllipsis } from '../../Components/_base/BBQGuysComponents';

interface PromotionsImageCardProps {
    url?: string;
    image?: string | Maybe<string>;
    hoverImage?: string;
    title: string;
    width?: number;
    height?: number;
    maxLines?: number;
    cardProps?: SxProps;
    cardContentProps?: SxProps;
    imageProps?: ImageProps;
    withBorder?: boolean;
    withTitle?: boolean;
    textAlign?: 'left' | 'right' | 'center';
    titleAlign?: 'left' | 'right' | 'center';
    children?: any;
}

export function PromotionsImageCard({
    url,
    image = getEnvironmentVariable('NO_IMAGE_URL'),
    title,
    hoverImage,
    withBorder = true,
    withTitle = true,
    textAlign = 'left',
    titleAlign = 'center',
    width = 240,
    height = 240,
    cardProps,
    cardContentProps = {},
    imageProps = {},
    maxLines,
    children,
}: PromotionsImageCardProps) {
    const content = (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                }}
            >
                <Image
                    width={width}
                    height={height}
                    sx={{
                        pt: 1,
                        minHeight: 125,
                        width: '100%',
                        height: height,
                        objectFit: 'contain',
                        transition: '.15s transform ease-in',
                    }}
                    src={image ?? getEnvironmentVariable('NO_IMAGE_URL')}
                    hoverSrc={hoverImage}
                    rounded
                    {...imageProps}
                />
            </Box>
            {withTitle &&
                title &&
                (maxLines && maxLines > 0 ? (
                    <Typography
                        variant="bold"
                        title={title}
                        aria-label={title}
                        textAlign={textAlign}
                        className="title-hover"
                        sx={{
                            transition: '.15s color ease-in',
                            width: '100%',
                            pb: 3,
                        }}
                    >
                        <LineEllipsis text={title} maxLines={maxLines} />
                    </Typography>
                ) : (
                    <Typography
                        variant="bold"
                        title={title}
                        aria-label={title}
                        textAlign={titleAlign}
                        className="title-hover"
                        sx={{
                            transition: '.15s color ease-in',
                            width: '100%',
                            pb: 3,
                        }}
                    >
                        {title}
                    </Typography>
                ))}
        </>
    );

    return (
        <Card
            sx={{
                p: 0,
                borderWidth: withBorder ? 1 : 0,
                cursor: 'pointer',
                '&:hover .title-hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                },
                '&:hover img': {
                    transform: 'scale(1.025)',
                },
                ...cardProps,
            }}
            role="button"
            aria-label={`Promotion: ${title}`}
            onKeyDown={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                    if (url) {
                        window.location.href = url;
                    }
                }
            }}
        >
            <CardContent
                sx={{
                    ...cardContentProps,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: 0,
                        gap: 0,
                        width: '100%',
                    }}
                >
                    {content}
                </Box>
                {children && (
                    <Box
                        sx={{
                            m: 0,
                            width: '100%',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 10,
                            textOverflow: 'ellipsis',
                        }}
                    >
                        <Typography
                            component="div"
                            sx={{
                                fontSize: '14px',
                            }}
                        >
                            {typeof children.replaceAll === 'function' ? (
                                <LineEllipsis
                                    text={children.replaceAll('<br>', '')}
                                    maxLines={maxLines}
                                />
                            ) : (
                                children
                            )}
                        </Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}

export function PromotionsImageCardLoading() {
    return (
        <Box textAlign="left">
            <Skeleton
                variant="rectangular"
                width={'100%'}
                sx={{ height: 240, display: 'inline-block' }}
            />
            <Skeleton
                variant="text"
                width="90%"
                sx={{ display: 'inline-block' }}
            />
        </Box>
    );
}
