import React from 'react';
import Countdown from 'react-countdown';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import theme from '../../Theme';
import { dateShort } from '../../functions';
import { Item, Promotion } from '../../types';
import { ProPriceLabel } from '../Item/Pricing';
import { Popover } from '../_base/BBQGuysComponents';

function PromoCountdown(props: {
    promotion: Promotion;
    withBorderLeft?: boolean;
}) {
    const { promotion, withBorderLeft = false } = props;

    if (!promotion.showCountdown) {
        return null;
    }

    const promoEndDate = new Date(promotion.dateEnd);
    const fiveDaysFromNow = new Date();
    const thirtyDaysFromNow = new Date();
    fiveDaysFromNow.setDate(fiveDaysFromNow.getDate() + 5);
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

    const countdownStyles = {
        whiteSpace: 'nowrap',
        paddingLeft: '3px',
    };

    const renderCountdownDisplay = () => {
        if (promoEndDate <= fiveDaysFromNow) {
            return (
                <Countdown
                    date={promoEndDate}
                    renderer={({
                        days,
                        hours,
                        minutes,
                        seconds,
                        completed,
                    }) => {
                        const padZero = (num: number) =>
                            num.toString().padStart(2, '0');
                        return (
                            <>
                                {completed ? (
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color={promotion.displayTextColor}
                                        sx={countdownStyles}
                                    >
                                        This promo has ended
                                    </Typography>
                                ) : days == 0 ? (
                                    <>
                                        <DesktopAndTabletDevice>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color={
                                                    promotion.displayTextColor
                                                }
                                                sx={countdownStyles}
                                            >
                                                Ends in {padZero(hours)}:
                                                {padZero(minutes)}:
                                                {padZero(seconds)}
                                            </Typography>
                                        </DesktopAndTabletDevice>
                                        <MobileDevice>
                                            <Typography
                                                component="span"
                                                variant="body2"
                                                color={
                                                    promotion.displayTextColor
                                                }
                                                sx={countdownStyles}
                                            >
                                                Ends in {padZero(hours)}:
                                                {padZero(minutes)}
                                            </Typography>
                                        </MobileDevice>
                                    </>
                                ) : days < 5 && days > 0 ? (
                                    <Typography
                                        component="span"
                                        variant="body2"
                                        color={promotion.displayTextColor}
                                        sx={countdownStyles}
                                    >
                                        Ends in {days}{' '}
                                        {days === 1 ? 'day' : 'days'}
                                    </Typography>
                                ) : null}
                            </>
                        );
                    }}
                />
            );
        } else if (promoEndDate <= thirtyDaysFromNow) {
            return (
                <Typography
                    component="span"
                    variant="body2"
                    color={promotion.displayTextColor}
                    sx={countdownStyles}
                >
                    Ends {dateShort.format(promoEndDate)}
                </Typography>
            );
        } else {
            return null;
        }
    };

    if (withBorderLeft && promoEndDate <= thirtyDaysFromNow) {
        return (
            <Box sx={{ ml: 1, pl: 1, borderLeft: theme.palette.border }}>
                {renderCountdownDisplay()}
            </Box>
        );
    }
    return renderCountdownDisplay();
}
export const PromoFlag = (props: { promotion: Promotion }) => {
    const { promotion } = props;

    const promoFlagStyles = {
        display: 'inline-block',
        padding: '0 .5rem',
        backgroundColor: promotion.displayTextColor,
        verticalAlign: 'text-bottom',
        whiteSpace: 'nowrap',
        borderRight: '1px solid white',
        borderBottom: '1px solid white',
    };

    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={promoFlagStyles}>
                <Typography
                    variant="caption"
                    color="white"
                    component="span"
                    fontWeight="bold"
                >
                    {promotion.displayText}
                </Typography>
            </Box>
            {promotion.showCountdown && (
                <PromoCountdown promotion={promotion} />
            )}
        </Box>
    );
};

type PromoVariants = 'small' | 'large';

export const Promo = (props: { item: Item; variant?: PromoVariants }) => {
    const { item, variant = 'large' } = props;
    const promotions: Promotion[] = item.promotions as Promotion[];
    if (item && promotions[0]) {
        return (
            <PromotionDisplay
                item={item}
                promotion={promotions[0]}
                variant={variant}
            />
        );
    }
    return <></>;
};

export const PromotionDisplay = (props: {
    item: Item;
    promotion: Promotion;
    variant?: PromoVariants;
}) => {
    const { item, promotion, variant = 'large' } = props;
    const { isPro } = React.useContext(UserAuth);

    if (item.pricingStrategy === 'nopromo') {
        return <></>;
    } else if (isPro() && item.showProLogo) {
        return <ProPriceLabel item={item} sx={{ display: 'block' }} />;
    } else if (
        promotion.title.indexOf('Pro ') > -1 ||
        promotion.displayText.indexOf('Pro ') > -1
    ) {
        return <></>;
    }

    if (variant === 'small') {
        return (
            <Typography variant="promoText" color={promotion.displayTextColor}>
                {promotion.title}
            </Typography>
        );
    }

    return (
        <Box sx={{ my: 2 }}>
            <DesktopAndTabletDevice>
                <Stack direction="row">
                    <Typography
                        variant="promoText"
                        color={promotion.displayTextColor}
                    >
                        {promotion.displayText}
                    </Typography>
                    <PromoCountdown promotion={promotion} withBorderLeft />
                </Stack>
                <Box display="flex" flexWrap="wrap">
                    <Typography sx={{ paddingRight: '8px' }}>
                        {promotion.title}
                    </Typography>
                    <Popover
                        title={promotion.title}
                        buttonText={'Learn More'}
                        buttonProps={{
                            variant: 'link',
                            sx: {
                                '& span': {
                                    textDecoration: 'underline',
                                    '&:hover': {
                                        textDecoration: 'none',
                                    },
                                },
                            },
                        }}
                    >
                        <p
                            dangerouslySetInnerHTML={{
                                __html: promotion.description,
                            }}
                        />
                    </Popover>
                </Box>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Typography
                    component="div"
                    variant="promoText"
                    color={promotion.displayTextColor}
                >
                    {promotion.displayText}
                </Typography>
                <Typography component="div" variant="bold">
                    {promotion.title}
                </Typography>
                <p
                    dangerouslySetInnerHTML={{ __html: promotion.description }}
                />
                <PromoCountdown promotion={promotion} />
                <Divider />
            </MobileDevice>
        </Box>
    );
};
export default PromoCountdown;
