import { Button } from '@mui/material';
import { ImageType, Maybe } from '../../types';
import { openPhotoSwipe } from './ImageCarousel';
import { ImageSearch } from '@mui/icons-material';

export default function ImageGalleryViewPhotosLink({
    images,
}: {
    images: Maybe<ImageType>[];
}) {
    return (
        <>
            {images && images.length > 1 && (
                <Button
                    variant="link"
                    sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
                    onClick={() => {
                        if (images.length > 1) {
                            // no need to show the first image since its the main product (non open-box) image
                            openPhotoSwipe(0, images.slice(1));
                        }
                    }}
                >
                    <ImageSearch
                        sx={{ fontSize: theme => theme.typography.fontSize }}
                    />
                    View Photos
                </Button>
            )}
        </>
    );
}
