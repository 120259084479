import { gql, useQuery } from '@apollo/client';
import ProductCarousel, {
    ProductCarouselLoading,
} from '../../../../Components/ProductCarousels/ProductCarousel/ProductCarousel';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { PAGINATED_ITEMS } from '../../../../Graphql/fragments';
import { Item } from '../../../../types';
import React from 'react';
import { DigitalDataContext } from '../../../../Contexts/DigitalDataProvider';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';

const QL_ITEM_SIMILAR_ITEMS = gql`
    ${PAGINATED_ITEMS}
    query getItemSimilarItems($itemId: ID, $pricingTierId: Int) {
        itemSimilarItems(itemId: $itemId, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

export default function SimilarItems(props: { item: Item }) {
    const { item } = props;
    const { getPricingTierId } = React.useContext(UserAuth);
    const { pushCarouselDependency } = React.useContext(DigitalDataContext);
    const { data, loading } = useQuery(QL_ITEM_SIMILAR_ITEMS, {
        variables: {
            itemId: item.id,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return (
            <ProductCarouselLoading title="This product is sold out. May we recommend..." />
        );
    }

    const dependencyName = 'similar-items-carousel';

    if (data && data.itemSimilarItems) {
        if (data.itemSimilarItems.results.length > 0) {
            return (
                <Section title="This product is sold out. May we recommend...">
                    <ProductCarousel
                        items={data.itemSimilarItems.results}
                        trackingType={'carousel'}
                        trackingGroup={dependencyName}
                        dependencyName={dependencyName}
                    />
                </Section>
            );
        } else {
            pushCarouselDependency({
                carouselName: dependencyName,
                results: data.itemSimilarItems.results,
                itemWidth: 6,
            });
            return null;
        }
    }
    return null;
}
