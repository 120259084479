import { Typography } from '@mui/material';
import { OpenBoxItem } from '../../../../../../../types';

const OpenBoxConditionDetails = (props: {
    item: OpenBoxItem;
    size: 'medium' | 'small';
}) => {
    const { item, size } = props;
    let fontSize = '1rem';
    switch (size) {
        case 'small':
            fontSize = '.8125rem';
            break;
        case 'medium':
            fontSize = '.9375rem';
            break;
    }
    return (
        <>
            <Typography variant="bold" component="div">
                Condition Details:
            </Typography>
            <Typography
                variant="body1"
                component="p"
                fontSize={fontSize}
                sx={{ mb: 2 }}
            >
                {item.description}
            </Typography>
        </>
    );
};

export default OpenBoxConditionDetails;
