import { ApolloProvider, useQuery } from '@apollo/client';
import { CacheProvider } from '@emotion/react';
import loadable from '@loadable/component';
import {
    Alert,
    AlertTitle,
    Button,
    Drawer,
    Paper,
    Slide,
    Snackbar,
    Typography,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/system';
import window from 'global/window';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useNavigate,
} from 'react-router-dom';
import ContactUs from './Components/ContactUs/ContactUs';
import VendorRegistration from './Components/VendorRegistration/VendorRegistration';
import Footer from './Components/Layout/Footer/Footer';
import Header from './Components/Layout/Header/Header';
import MainBodyContent from './Components/Layout/MainBodyContent/MainBodyContent';
import PageMetaData from './Components/Layout/PageMetaData';
import PageLoad from './Components/PageLoad/PageLoad';
import RecentlyViewedItems from './Components/ProductCarousels/RecentlyViewedItems/RecentlyViewedItems';
import { YoutubeDetect } from './Components/_base/VideoPlayer';
import { ABTestProvider } from './Contexts/ABTestProvider';
import { CheckoutProvider } from './Contexts/CheckoutProvider';
import { SSRProvider } from './Contexts/SSRProvider';
import { SiteContext, SiteProvider } from './Contexts/SiteProvider';
import { TrapModalProvider } from './Contexts/TrapModalProvider';
import { UserAuth, UserAuthProvider } from './Contexts/UserAuthProvider';
import { getApolloClient } from './Graphql/ApolloClient';
import PageDepartment from './Pages/PageDepartment/PageDepartment';
import PageDepartmentHub from './Pages/PageDepartmentHub/PageDepartmentHub';
import PageItem from './Pages/PageItem/PageItem';
import theme from './Theme';
import {
    getEnvironmentVariable,
    isDevelopment,
    isErrorAs404,
    isProduction,
    isTesting,
} from './config';
import { MenuItem } from './types';

import ScContainer from './Components/Layout/ScContainer/ScContainer';
import { CartProvider } from './Contexts/CartProvider';
import { QL_SITE } from './Graphql/queries';
import PageForgotPassword from './Pages/Authentication/PageForgotPassword/PageForgotPassword';
import PageLogin from './Pages/Authentication/PageLogin/PageLogin';
import PageUpdatePassword from './Pages/Authentication/PageUpdatePassword/PageUpdatePassword';
import PageExpertReview from './Pages/ExpertReviews/PageExpertReview';
import PageExpertReviewClass from './Pages/ExpertReviews/PageExpertReviewClass';
import PageExpertReviewHub from './Pages/ExpertReviews/PageExpertReviewHub';
import Page404 from './Pages/Page404/Page404';
import PageBrand, {
    BrandPage,
    BrandPageHub,
} from './Pages/PageBrand/PageBrand';
import PageContent from './Pages/PageContent/PageContent';
import PageHome from './Pages/PageHome/PageHome';
import PageOrderStatus from './Pages/PageOrderStatus/PageOrderStatus';
import PagePromotion from './Pages/PagePromotion/PagePromotion';
import PageSearch from './Pages/PageSearch/PageSearch';
import PageWarehouseDeals from './Pages/WarehouseDeals/PageWarehouseDeals';
import createEmotionCache from './createEmotionCache';
import { DigitalDataProvider } from './Contexts/DigitalDataProvider';
import PageCartExternalLink from './Pages/Cart/PageCartExternalLink/PageCartExternalLink';
import {
    DeviceTypeProvider,
    getIsDesktop,
    getIsMobile,
    getIsTablet,
} from './Contexts/DeviceTypeProvider';

const TrapModal = loadable(
    () => import('./Components/Buttons/AddToCart/TrapModal'),
);
const CartHeader = loadable(
    () => import('./Components/Layout/Cart/Header/Header'),
);
const PageAccount = loadable(() => import('./Pages/Account/PageAccount'));
const PageCart = loadable(() => import('./Pages/Cart/PageCart/PageCart'));
const PageCheckout = loadable(
    () => import('./Pages/Cart/PageCheckout/PageCheckout'),
);
const PageCheckoutComplete = loadable(
    () => import('./Pages/Cart/PageCheckoutComplete/PageCheckoutComplete'),
);
const PageCheckoutReview = loadable(
    () => import('./Pages/Cart/PageCheckoutReview/PageCheckoutReview'),
);

const BBQGuysSyntaxHighlighter = loadable(
    () => import('./Components/_base/BBQGuysSyntaxHighligher'),
);

function ErrorFallback(props: {
    error: Error;
    resetErrorBoundary: () => void;
    children?: any;
}) {
    const { logout } = React.useContext(UserAuth);
    const [showDetailsModal, setShowDetailsModal] = React.useState(false);
    const [alert, setAlert] = React.useState('');

    React.useEffect(() => {
        const matches = props.error.message?.match(/^(\d+):/);
        const statusCode = matches ? matches[1] : undefined;
        switch (statusCode) {
            case '403':
                logout();
                setAlert('You have been logged out due to inactivity');
                break;
            case '404':
                setAlert('The page you are trying to access does not exist');
                break;
            default:
                setAlert('Internal server error');
        }
    }, [props.error]);

    const renderAlertPopup = () => {
        return (
            <Alert
                role="alert"
                variant="standard"
                severity="error"
                icon={false}
            >
                <AlertTitle>
                    Error: {alert}{' '}
                    <Button
                        onClick={() => {
                            setTimeout(() => {
                                setShowDetailsModal(true);
                            }, 200);
                        }}
                    >
                        Details
                    </Button>
                    <Button onClick={() => props.resetErrorBoundary()}>
                        Try again
                    </Button>
                </AlertTitle>
            </Alert>
        );
    };

    const renderAlertDrawer = () => {
        return (
            <Paper
                square={true}
                sx={{ background: 'rgb(40, 44, 52)', border: 0 }}
                elevation={0}
            >
                <AlertTitle sx={{ borderBottom: '1px solid rgba(0,0,0,.5)' }}>
                    <Box display="flex" padding={2}>
                        <Typography
                            flex={1}
                            alignSelf="center"
                            color="rgb(152, 195, 121)"
                        >
                            {alert}
                        </Typography>
                        <Box display="flex" justifyContent="end">
                            <Button
                                onClick={() => props.resetErrorBoundary()}
                                sx={{ color: 'lightgray' }}
                            >
                                Try again
                            </Button>
                            <Button
                                onClick={() => setShowDetailsModal(false)}
                                sx={{ color: 'lightgray' }}
                            >
                                Close
                            </Button>
                        </Box>
                    </Box>
                </AlertTitle>
                {isDevelopment() ||
                    (isTesting() && (
                        <Box sx={{ overflow: 'scroll' }}>
                            <BBQGuysSyntaxHighlighter>
                                {JSON.stringify(props.error, null, 2)}
                            </BBQGuysSyntaxHighlighter>
                        </Box>
                    ))}
            </Paper>
        );
    };

    return (
        <LayoutBase>
            {!isProduction() && props.error ? (
                <>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        open={!showDetailsModal}
                        onClose={() => setShowDetailsModal(false)}
                        message={alert}
                        TransitionComponent={props => (
                            <Slide {...props} direction="up" />
                        )}
                    >
                        {renderAlertPopup()}
                    </Snackbar>
                    <Drawer
                        variant={showDetailsModal ? 'permanent' : 'temporary'}
                        anchor={'bottom'}
                        open={showDetailsModal}
                        // onClose={()=>setShowDetailsModal(false)}
                        sx={{ marginTop: '25%' }}
                    >
                        {renderAlertDrawer()}
                    </Drawer>
                </>
            ) : (
                <></>
            )}
        </LayoutBase>
    );
}

/**
 * Scrolls to a section specified by the URL hash (#example) on page load.
 * If the user scrolls manually before the automatic scroll is triggered,
 * the DOM observer will be disabled to prevent auto-scrolling.
 */
function ScrollToSection() {
    const navigate = useNavigate();
    const [hasScrolled, setHasScrolled] = React.useState(false);

    React.useEffect(() => {
        //prevents the browser from jumping back to the top of the page
        history.scrollRestoration = 'manual';

        // return #hash without query string
        const targetId = window.location.hash.split('?')[0].replace('#', '');

        if (!targetId) {
            window.scrollTo(0, 0);
            return;
        }

        const scrollHandler = () => {
            setHasScrolled(true);
        };

        // detect manual scroll so we can cancel early if needed
        window.addEventListener('scroll', scrollHandler);

        const observer = new MutationObserver(() => {
            if (!hasScrolled) {
                scrollToSection();
            }
        });
        observer.observe(document.body, { childList: true, subtree: true });

        const timeoutId = setTimeout(() => {
            observer.disconnect();
        }, 30000);

        const scrollToSection = () => {
            const targetElement = document.getElementById(targetId);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'auto' });
                clearTimeout(timeoutId);
                observer.disconnect();
                window.removeEventListener('scroll', scrollHandler);
            }
        };

        return () => {
            clearTimeout(timeoutId);
            observer.disconnect();
            window.removeEventListener('scroll', scrollHandler);
        };
    }, [navigate]);

    return <React.Fragment></React.Fragment>;
}

const AccessibilitySkipLinks = () => {
    return (
        <nav id="ada-skip-links">
            <a
                href="#header"
                className="ada-only ada-skip-link"
                aria-label="Skip to Header"
            >
                Skip to Header
            </a>
            <a
                href="#main-menu"
                className="ada-only ada-skip-link"
                aria-label="Skip to Main Menu"
            >
                Skip to Main Menu
            </a>
            <a
                href="#my-account-btn"
                className="ada-only ada-skip-link"
                aria-label="Skip to My Account"
            >
                Skip to My Account
            </a>
            <a
                href="#my-cart-btn"
                className="ada-only ada-skip-link"
                aria-label="Skip to My Cart"
            >
                Skip to My Cart
            </a>
            <a
                href="#main-content"
                className="ada-only ada-skip-link"
                aria-label="Skip to Page Content"
            >
                Skip to Page Content
            </a>
            <a
                href="#footer"
                className="ada-only ada-skip-link"
                aria-label="Skip to Footer"
            >
                Skip to Footer
            </a>
        </nav>
    );
};

function App() {
    const cache = createEmotionCache();
    const client = getApolloClient();
    return (
        <DeviceTypeProvider>
            <SSRProvider
                userAgent={window.navigator.userAgent}
                isMobile={getIsMobile()}
                isTablet={getIsTablet()}
                isDesktop={getIsDesktop()}
                isServerSideRendered={false}
            >
                <ApolloProvider client={client}>
                    <CacheProvider value={cache}>
                        <ThemeProvider theme={theme}>
                            <BrowserRouter>
                                <HelmetProvider>
                                    <ScrollToSection />
                                    <DigitalDataProvider>
                                        <MainApp />
                                        <PageMetaData />
                                    </DigitalDataProvider>
                                </HelmetProvider>
                            </BrowserRouter>
                        </ThemeProvider>
                    </CacheProvider>
                </ApolloProvider>
            </SSRProvider>
        </DeviceTypeProvider>
    );
}
/**
 * Creates a route that requires authentication before viewing. If not authenticated, redirects to the login page
 * @param props
 * @returns
 */
function PrivateRoute(props: { children: React.ReactNode }) {
    const { isLoggedIn } = React.useContext(UserAuth);
    return <>{isLoggedIn() ? props.children : <Navigate to="/login" />}</>;
}

function NoLoginOnlyRoute(props: { children: React.ReactNode }) {
    const { isLoggedIn, getLoginRedirectUrl } = React.useContext(UserAuth);
    return (
        <>
            {!isLoggedIn() ? (
                props.children
            ) : (
                <Navigate replace={true} to={getLoginRedirectUrl()} />
            )}
        </>
    );
}

export function MainApp({ children }: { children?: React.ReactNode }) {
    const { data, loading } = useQuery(QL_SITE);
    if (loading) {
        return null;
    } else {
        let topMenu = [] as MenuItem[],
            mainMenu = [] as MenuItem[],
            footerMenu = [] as MenuItem[];
        data.menus.forEach((m: any) => {
            switch (m.name) {
                case 'secondary':
                    topMenu = m.menuItems;
                    break;
                case 'primary':
                    mainMenu = m.menuItems;
                    break;
                case 'footer':
                    footerMenu = m.menuItems;
                    break;
            }
        });
        return (
            <>
                <SiteProvider
                    site={data.site}
                    topMenu={topMenu}
                    mainMenu={mainMenu}
                    footerMenu={footerMenu}
                    headerPromo={data.siteHeaderPromotions.results[0]}
                >
                    <Helmet
                        titleTemplate={getEnvironmentVariable(
                            'HEAD_TITLE_TEMPLATE',
                        )}
                    />
                    <CartProvider>
                        <UserAuthProvider>
                            <ErrorBoundary
                                FallbackComponent={ErrorFallback}
                                onReset={() => {
                                    // reset the state of your app so the error doesn't happen again
                                    return true;
                                }}
                            >
                                <ABTestProvider>
                                    {children ? (
                                        <LayoutBase>{children}</LayoutBase>
                                    ) : (
                                        <Routes>
                                            <Route
                                                path="/cart"
                                                element={<LayoutCart />}
                                            />
                                            <Route
                                                path="/cart/*"
                                                element={<LayoutCartCheckout />}
                                            />
                                            <Route
                                                path="*"
                                                element={<LayoutDefault />}
                                            />
                                        </Routes>
                                    )}
                                </ABTestProvider>
                            </ErrorBoundary>
                        </UserAuthProvider>
                    </CartProvider>
                </SiteProvider>
            </>
        );
    }
}

export const ActionRoute = (props: { data: any }) => {
    const { action, actionId } = props.data?.page || {};
    if (typeof action === 'string') {
        switch (action.toLowerCase()) {
            case 'item':
                return <PageItem itemId={actionId} />;
            case 'category':
            case 'department':
                return <PageDepartment deptId={actionId} />;
            case 'hubpage':
                return <PageDepartmentHub deptId={actionId} />;
            case 'content':
                return <PageContent contentId={actionId} />;
            case 'expertreview':
                return <PageExpertReview expertReviewId={actionId} />;
            case 'manufacturer':
            case 'brand':
                if (actionId > 0) {
                    return <BrandPage brandId={actionId} />;
                } else {
                    return <BrandPageHub />;
                }
            default:
                return <Page404 />;
        }
    }
    return <Page404 />;
};

const LayoutBase = (props: { children: any }) => {
    return (
        <>
            <TrapModalProvider>
                <AccessibilitySkipLinks />
                <Header />
                <MainBodyContent>{props.children}</MainBodyContent>
                <ScContainer sx={{ width: '90%', mx: 'auto' }}>
                    <RecentlyViewedItems />
                </ScContainer>
                <YoutubeDetect />
                <Footer />
                <PageMetaData />
                <TrapModal />
            </TrapModalProvider>
        </>
    );
};

const LayoutDefault = () => {
    const { hasError } = React.useContext(SiteContext);
    if (hasError && isErrorAs404()) {
        return (
            <LayoutBase>
                <Page404 />
            </LayoutBase>
        );
    }
    return (
        <LayoutBase>
            <Routes>
                <Route path="/" element={<PageHome />} />
                <Route path="/promotions" element={<PagePromotion />} />
                <Route path="/i/:itemId/*" element={<PageItem />} />
                <Route path="/brands" element={<BrandPageHub />} />
                <Route path="/b/:brandId/*" element={<PageBrand />} />
                <Route path="/a/:contentId/*" element={<PageContent />} />
                <Route
                    path="/update-password"
                    element={<PageUpdatePassword />}
                />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route
                    path="/vendor-registration"
                    element={<VendorRegistration />}
                />
                <Route path="/order-status" element={<PageOrderStatus />} />
                <Route path="/search/*" element={<PageSearch />} />
                <Route path="/d/:deptId/*" element={<PageDepartment />} />
                <Route path="/h/:deptId/*" element={<PageDepartmentHub />} />
                <Route
                    path="/r/:expertReviewId/expert-reviews/:expRevClass/:expReview"
                    element={<PageExpertReview />}
                />
                <Route
                    path="/r/:expertReviewId/expert-reviews/:expRevClass"
                    element={<PageExpertReviewClass />}
                />
                <Route
                    path="/expert-reviews/:expRevClass/:expReview"
                    element={
                        <PageLoad
                            component={({ data }: { data: any }) => (
                                <PageExpertReview
                                    expertReviewId={data.page.actionId ?? ''}
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/expert-reviews/:expRevClass"
                    element={
                        <PageLoad
                            component={({ data }: { data: any }) => (
                                <PageExpertReviewClass
                                    expertReviewId={data.page.actionId ?? ''}
                                />
                            )}
                        />
                    }
                />
                <Route
                    path="/expert-reviews"
                    element={<PageExpertReviewHub />}
                />
                <Route
                    path="/deals/used-and-refurbished"
                    element={<PageWarehouseDeals />}
                />
                <Route
                    path="/login"
                    element={
                        <NoLoginOnlyRoute>
                            <PageLogin />
                        </NoLoginOnlyRoute>
                    }
                />
                <Route path="/forgot" element={<PageForgotPassword />} />
                <Route
                    path="/account/*"
                    element={
                        <PrivateRoute>
                            <PageAccount />
                        </PrivateRoute>
                    }
                />
                <Route
                    path={'*'}
                    element={
                        <PageLoad
                            component={(props: { data: any }) => (
                                <ActionRoute data={props.data} />
                            )}
                        />
                    }
                />
                <Route
                    path="/cartexternallink.html"
                    element={<PageCartExternalLink />}
                />
            </Routes>
        </LayoutBase>
    );
};

const LayoutCart = () => {
    return (
        <CheckoutProvider>
            <LayoutBase>
                <PageCart />
            </LayoutBase>
        </CheckoutProvider>
    );
};

const LayoutCartCheckoutRoutes = () => {
    return (
        <CheckoutProvider>
            <Routes>
                <Route path="/checkout" element={<PageCheckout />} />
                <Route path="/review" element={<PageCheckoutReview />} />
                <Route path="/complete" element={<PageCheckoutComplete />} />
            </Routes>
        </CheckoutProvider>
    );
};

const LayoutCartCheckout = () => {
    return (
        <>
            <CartHeader />
            <LayoutCartCheckoutRoutes />
        </>
    );
};

export default App;
