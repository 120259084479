import { Box, Grid, Icon, Typography } from '@mui/material';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useEffect, useState } from 'react';
import { QuestionAndAnswersContext } from '../../../../../Contexts/QuestionAndAnswersProvider';
import {
    DesktopAndTabletDevice,
    isMobile,
    MobileDevice,
} from '../../../../../Contexts/DeviceTypeProvider';
import { highlightString } from '../../../../../functions';
import { Answer, Question } from '../../../../../types';
import QuestionsAndAnswersHelpfulButton from '../Components/Buttons/QuestionsAndAnswersHelpfulButton';
import QuestionsAndAnswersUnhelpfulButton from '../Components/Buttons/QuestionsAndAnswersUnhelpfulButton';
import { CloseOutlined } from '@mui/icons-material';
import theme from '../../../../../Theme';

const QuestionsAndAnswersContent = ({ question }: { question: Question }) => {
    const { searchTerm, hasInteractedWithHelpfulUnhelpful } = React.useContext(
        QuestionAndAnswersContext,
    );
    const [interactions, setInteractions] = useState(new Map());

    const handleInteraction = (answerId: number, type: string) => {
        setInteractions(new Map(interactions).set(answerId, type));
    };

    useEffect(() => {
        const fetchedInteractions = new Map();
        question.answers.forEach(answer => {
            if (answer) {
                const answerId = parseInt(answer.id);
                const isHelpful = hasInteractedWithHelpfulUnhelpful(
                    answerId,
                    'helpful',
                );
                const isUnhelpful = hasInteractedWithHelpfulUnhelpful(
                    answerId,
                    'unhelpful',
                );
                if (isHelpful) {
                    fetchedInteractions.set(answerId, 'helpful');
                } else if (isUnhelpful) {
                    fetchedInteractions.set(answerId, 'unhelpful');
                }
            }
        });
        setInteractions(fetchedInteractions);
    }, [question.answers]);

    const MarkedHelpful = () => {
        return (
            <>
                <DesktopAndTabletDevice>
                    <Grid
                        container
                        flexWrap="nowrap"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs="auto">
                            <Icon
                                aria-label="Marked Helpful"
                                sx={{
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    border: `5px solid ${theme.palette.success.main}`,
                                    width: 'unset',
                                    height: 'unset',
                                }}
                            >
                                <CloseOutlined
                                    fontSize="large"
                                    color="success"
                                />
                            </Icon>
                        </Grid>
                        <Grid item xs="auto" style={{ width: '160px' }}>
                            <Typography
                                color={theme.palette.success.main}
                                fontSize={18}
                                noWrap={false}
                                style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                }}
                            >
                                You marked this as <strong>Helpful</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </DesktopAndTabletDevice>
                <MobileDevice>You marked this as Helpful</MobileDevice>
            </>
        );
    };

    const MarkedUnhelpful = () => {
        return (
            <>
                <DesktopAndTabletDevice>
                    <Grid
                        container
                        flexWrap="nowrap"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs="auto">
                            <Icon
                                aria-label="Marked Unhelpful"
                                sx={{
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    border: `5px solid ${theme.palette.error.main}`,
                                    width: 'unset',
                                    height: 'unset',
                                }}
                            >
                                <CloseOutlined fontSize="large" color="error" />
                            </Icon>
                        </Grid>
                        <Grid item xs="auto" style={{ width: '160px' }}>
                            <Typography
                                color={theme.palette.error.main}
                                fontSize={18}
                                noWrap={false}
                                style={{
                                    whiteSpace: 'normal',
                                    wordWrap: 'break-word',
                                }}
                            >
                                You marked this as <strong>Unhelpful</strong>
                            </Typography>
                        </Grid>
                    </Grid>
                </DesktopAndTabletDevice>
                <MobileDevice>You marked this as Unhelpful</MobileDevice>
            </>
        );
    };

    return (
        <>
            <Grid
                container
                sx={{
                    py: 1.5,
                    borderTop: '0.125rem solid #d7dbdd',
                    borderBottom: '0.0625rem dashed #d7dbdd',
                }}
            >
                <Grid item xs={1}>
                    <DesktopAndTabletDevice>Question</DesktopAndTabletDevice>
                    <MobileDevice>Q</MobileDevice>:
                </Grid>
                <Grid
                    item
                    xs={11}
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Typography
                        variant="bold"
                        dangerouslySetInnerHTML={{
                            __html: highlightString(question.text, searchTerm),
                        }}
                    />
                    <DesktopAndTabletDevice>
                        <Typography
                            variant="bold"
                            whiteSpace="nowrap"
                            paddingX="24px"
                        >
                            Was This Helpful?
                        </Typography>
                    </DesktopAndTabletDevice>
                </Grid>
            </Grid>
            <Grid container sx={{ py: 1.5 }}>
                <Grid item xs={1}>
                    <DesktopAndTabletDevice>Answers</DesktopAndTabletDevice>
                    <MobileDevice>A</MobileDevice>:
                </Grid>
                <Grid item xs={11}>
                    {question.answers.length > 0
                        ? question.answers.map(
                              (answer: Maybe<Answer>, i: number) => {
                                  return answer ? (
                                      <Box
                                          key={'qna-answer-' + answer.id}
                                          sx={{
                                              borderTop:
                                                  i > 0
                                                      ? '1px dashed #ccc'
                                                      : null,
                                              marginTop: i > 0 ? 1 : null,
                                              paddingTop: i > 0 ? 1 : null,
                                          }}
                                      >
                                          <Grid
                                              container
                                              justifyContent="space-between"
                                              wrap="nowrap"
                                          >
                                              <Grid
                                                  item
                                                  display="flex"
                                                  flexDirection="column"
                                                  flexShrink={2}
                                                  lineHeight="1rem"
                                              >
                                                  <Typography
                                                      variant="body1"
                                                      dangerouslySetInnerHTML={{
                                                          __html: highlightString(
                                                              answer.text,
                                                              searchTerm,
                                                          ),
                                                      }}
                                                  />
                                                  <Typography
                                                      variant="italic"
                                                      marginY="0.5rem"
                                                      fontSize={
                                                          isMobile
                                                              ? '0.875rem'
                                                              : 'inherit'
                                                      }
                                                  >
                                                      Asked by{' '}
                                                      {question.name === ''
                                                          ? 'BBQGuys User'
                                                          : question.name}{' '}
                                                      | Answered by{' '}
                                                      {answer.name === ''
                                                          ? 'Joe, BBQGuys Employee'
                                                          : question.name}
                                                  </Typography>
                                                  <MobileDevice>
                                                      {interactions.has(
                                                          parseInt(answer.id),
                                                      ) ? (
                                                          <Typography
                                                              variant="body2"
                                                              whiteSpace="nowrap"
                                                          >
                                                              {interactions.get(
                                                                  parseInt(
                                                                      answer.id,
                                                                  ),
                                                              ) ===
                                                              'helpful' ? (
                                                                  <MarkedHelpful />
                                                              ) : (
                                                                  <MarkedUnhelpful />
                                                              )}
                                                          </Typography>
                                                      ) : (
                                                          <Grid
                                                              container
                                                              alignItems="baseline"
                                                          >
                                                              <Grid item>
                                                                  <Typography
                                                                      variant="body2"
                                                                      whiteSpace="nowrap"
                                                                  >
                                                                      Was This
                                                                      Helpful?
                                                                  </Typography>
                                                              </Grid>
                                                              <Grid item>
                                                                  <QuestionsAndAnswersHelpfulButton
                                                                      answerId={parseInt(
                                                                          answer.id,
                                                                      )}
                                                                      onClick={() =>
                                                                          handleInteraction(
                                                                              parseInt(
                                                                                  answer.id,
                                                                              ),
                                                                              'helpful',
                                                                          )
                                                                      }
                                                                  />
                                                              </Grid>
                                                              <Grid item>
                                                                  <QuestionsAndAnswersUnhelpfulButton
                                                                      answerId={parseInt(
                                                                          answer.id,
                                                                      )}
                                                                      onClick={() =>
                                                                          handleInteraction(
                                                                              parseInt(
                                                                                  answer.id,
                                                                              ),
                                                                              'unhelpful',
                                                                          )
                                                                      }
                                                                  />
                                                              </Grid>
                                                          </Grid>
                                                      )}
                                                  </MobileDevice>
                                              </Grid>
                                              <DesktopAndTabletDevice>
                                                  {interactions.has(
                                                      parseInt(answer.id),
                                                  ) ? (
                                                      <Typography
                                                          variant="body2"
                                                          whiteSpace="nowrap"
                                                          paddingX="0.75rem"
                                                      >
                                                          {interactions.get(
                                                              parseInt(
                                                                  answer.id,
                                                              ),
                                                          ) === 'helpful' ? (
                                                              <MarkedHelpful />
                                                          ) : (
                                                              <MarkedUnhelpful />
                                                          )}
                                                      </Typography>
                                                  ) : (
                                                      <Grid
                                                          container
                                                          paddingX="2.25rem"
                                                          spacing={2}
                                                          wrap="nowrap"
                                                          width="unset"
                                                      >
                                                          <Grid
                                                              item
                                                              textAlign="right"
                                                          >
                                                              <QuestionsAndAnswersHelpfulButton
                                                                  answerId={parseInt(
                                                                      answer.id,
                                                                  )}
                                                                  onClick={() =>
                                                                      handleInteraction(
                                                                          parseInt(
                                                                              answer.id,
                                                                          ),
                                                                          'helpful',
                                                                      )
                                                                  }
                                                              />
                                                          </Grid>
                                                          <Grid
                                                              item
                                                              textAlign="left"
                                                          >
                                                              <QuestionsAndAnswersUnhelpfulButton
                                                                  answerId={parseInt(
                                                                      answer.id,
                                                                  )}
                                                                  onClick={() =>
                                                                      handleInteraction(
                                                                          parseInt(
                                                                              answer.id,
                                                                          ),
                                                                          'unhelpful',
                                                                      )
                                                                  }
                                                              />
                                                          </Grid>
                                                      </Grid>
                                                  )}
                                              </DesktopAndTabletDevice>
                                          </Grid>
                                      </Box>
                                  ) : null;
                              },
                          )
                        : null}
                </Grid>
            </Grid>
        </>
    );
};

export default QuestionsAndAnswersContent;
