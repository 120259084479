import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import {
    DesktopAndTabletDevice,
    isMobile,
} from '../../../../../Contexts/DeviceTypeProvider';
import { SearchTermSchema } from '../../../../../Schemas/Schemas';
import { FieldWithLabel } from '../../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';

interface CustomerReviewsSearchValues {
    term: string;
}

const CustomerReviewsSearchBar = () => {
    const { setSearchTerm, searchTerm } = React.useContext(
        CustomerReviewsContext,
    );

    const formik = useFormik({
        initialValues: {
            term: searchTerm || '',
        },
        validationSchema: SearchTermSchema,
        onSubmit: (values: CustomerReviewsSearchValues, actions: any) => {
            actions.setSubmitting(true);
            setSearchTerm(values.term);
            setTimeout(() => {
                actions.setSubmitting(false);
            }, 500);
        },
    });
    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <FieldWithLabel
                        disabled={formik.isSubmitting}
                        id="CustomerReviewsTermField"
                        name="term"
                        labelHidden={true}
                        label="Search All Customer Reviews"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        placeholder="Search All Customer Reviews"
                        type="text"
                        formik={formik}
                        sx={{
                            mb: 0,
                        }}
                        inputProps={{
                            sx: {
                                height: 24,
                                mb: 0,
                                py: 1,
                            },
                        }}
                    />
                    <LoadingButton
                        type="submit"
                        disableElevation
                        variant="contained"
                        color="secondary"
                        loading={formik.isSubmitting}
                        sx={{
                            width: isMobile ? '86px' : '20%',
                            fontWeight: 'bolder',
                            height: 42,
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }}
                    >
                        GO
                        <DesktopAndTabletDevice>
                            <KeyboardArrowRightIcon />
                        </DesktopAndTabletDevice>
                    </LoadingButton>
                </Box>
            </form>
        </FormikProvider>
    );
};

export default CustomerReviewsSearchBar;
