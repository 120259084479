import { MenuItem, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { Select } from '../../../../../Components/_base/BBQGuysComponents';
import { QuestionAndAnswersContext } from '../../../../../Contexts/QuestionAndAnswersProvider';

const QuestionsAndAnswersSortDropdown = () => {
    const { setSort, sort, sortOptions } = React.useContext(
        QuestionAndAnswersContext,
    );

    const handleChange = (e: SelectChangeEvent) => {
        setSort(e.target.value);
    };

    const getSortValue = () => {
        let s = sortOptions[0].value;
        for (const v of sortOptions) {
            if (v.value === sort) {
                s = v.value;
            }
        }
        return s;
    };

    const getSortLabel = (val: string): string => {
        for (const v of sortOptions) {
            if (val === v.value) {
                return v.name;
            }
        }
        return val;
    };

    return (
        <Select
            labelId={'qna-sort'}
            label={'Sort By'}
            id={'qna-sort'}
            value={getSortValue()}
            onChange={handleChange}
            renderValue={(val: string) => getSortLabel(val)}
            aria-description="Sort Questions and Answers"
        >
            {sortOptions.map((opt: any) => (
                <MenuItem key={opt.value} value={opt.value}>
                    {opt.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default QuestionsAndAnswersSortDropdown;
