import { LoadingButton } from '@mui/lab';
import { Alert, Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { PageHeader } from '../../../Components/_base/BBQGuysComponents';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import { ForgotPasswordSchema } from '../../../Schemas/Schemas';
import { User } from '../../../types';
import { EmailWithLabel } from '../../Account/PageEditMyAccount/Components/EmailWithLabel';

interface ForgotPasswordValues {
    email: string;
}

export const ForgotPasswordForm = () => {
    const {
        passwordReset,
        errorMessage,
        setErrorMessage,
        successMessage,
        setSuccessMessage,
        user,
        setUser,
    } = React.useContext(UserAuth);
    const navigate = useNavigate();
    React.useEffect(() => {
        setSuccessMessage('');
        setErrorMessage('');
    }, []);
    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: ForgotPasswordSchema,
        onSubmit: (values: ForgotPasswordValues, actions: any) => {
            actions.setSubmitting(true);
            passwordReset(values.email)
                .then(expires => {
                    const newUser: User = Object.assign(
                        {
                            email: values.email,
                        },
                        user,
                    );
                    setSuccessMessage(
                        'A temporary password has been sent you your email account.',
                    );
                    setUser(newUser);
                    //navigate('/login');
                    navigate('/update-password');
                })
                .catch(message => {
                    setErrorMessage(message);
                    actions.setSubmitting(false);
                });
        },
    });

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <PageHeader
                    title="Forgot Password"
                    subtitle="We'll send a new one directly to your inbox."
                />

                <Typography variant="body1" component="p" sx={{ my: 2 }}>
                    Enter the email address associated with your account and
                    we&apos;ll email you instructions on how to reset your
                    password.{' '}
                    <em>
                        You may need to check your spam folder or unblock emails
                        from bbqguys.com.
                    </em>
                </Typography>

                {errorMessage.length > 0 ? (
                    <Alert severity="error">{errorMessage}</Alert>
                ) : null}
                {successMessage.length > 0 ? (
                    <Alert severity="success">{successMessage}</Alert>
                ) : null}
                <EmailWithLabel formik={formik} name="email" id="email" />
                <Box
                    display="inline flex"
                    pt={2}
                    alignItems={'center'}
                    flexWrap="wrap"
                >
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="secondary"
                        loading={formik.isSubmitting}
                        sx={{
                            mr: 5,
                            mb: { xs: 2, sm: 0 },
                        }}
                    >
                        Reset Password
                    </LoadingButton>
                    <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                        Remember your password?{' '}
                    </Typography>
                    <Link href="/login" sx={{ ml: 0.5, whiteSpace: 'nowrap' }}>
                        Sign In
                    </Link>
                </Box>
            </form>
        </FormikProvider>
    );
};
