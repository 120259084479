import * as React from 'react';
import { Maybe, MenuItem } from '../types';

export const MenuContext = React.createContext({
    menu: {} as Maybe<MenuItem>,
    activeMenu: {} as Maybe<MenuItem>,
    setActiveMenuData: (val: Maybe<MenuItem>) => {
        return;
    },
    getParentMenu: (val: MenuItem | null): Maybe<MenuItem> | undefined => {
        return {} as MenuItem;
    },
});

export function MenuProvider(props: { menu: Maybe<MenuItem>; children: any }) {
    const [menu] = React.useState<Maybe<MenuItem>>(props.menu);
    const [activeMenu, setActiveMenu] = React.useState<Maybe<MenuItem>>(
        props.menu,
    );

    const setActiveMenuData = (val: Maybe<MenuItem>) => {
        if (val) {
            setActiveMenu(val);
        }
    };

    const findChildMenu = (
        child: Maybe<MenuItem>,
        target: MenuItem,
        parent: Maybe<MenuItem>,
    ): Maybe<MenuItem> | undefined => {
        if (child?.children === undefined) return parent;
        for (const node of child.children) {
            if (node && node.id === target.id) {
                return child;
            }
            if (node && node.children && node.children.length > 0) {
                const found = findChildMenu(node, target, parent);
                if (found) {
                    return found;
                }
            }
        }
        return undefined;
    };

    const getParentMenu = (
        childItem: MenuItem | null,
    ): Maybe<MenuItem> | undefined => {
        const parent = activeMenu as Maybe<MenuItem>;
        if (childItem === null) {
            return parent;
        }
        return findChildMenu(menu, childItem, parent);
    };
    return (
        <MenuContext.Provider
            value={{
                menu,
                activeMenu,
                setActiveMenuData,
                getParentMenu,
            }}
        >
            {props.children}
        </MenuContext.Provider>
    );
}
