import React from 'react';
import { Helmet } from 'react-helmet-async';
import { SiteContext } from '../../Contexts/SiteProvider';
import { getEnvironmentVariable } from '../../config';
import { Content, Item, Site, Maybe } from '../../types';

const sanitizeContent = (content: Maybe<string> | undefined): string => {
    if (typeof content !== 'string') return '';
    return (
        content
            /* Remove all occurances of script tags and their content */
            .replace(
                /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script\s*>/gi,
                ' ',
            )
            /* Remove all occurances of HTML tags */
            .replace(/<\s*\/?\s*[^>]+(>|$)/g, ' ')
            /* Remove all occurances of invisible or white space characters */
            .replace(/[\r\n\t\v\f\u200B\uFEFF]/g, ' ')
            /* Remove all excess white spaces (clean up) */
            .replace(/  +/g, ' ')
    );
};

export const CreateWebsiteSchema = (site: Site) => {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://' + site.domain,
        potentialAction: {
            '@type': 'SearchAction',
            target: {
                '@type': 'EntryPoint',
                urlTemplate:
                    'https://' +
                    site.domain +
                    '/search?sstring={search_term_string}',
            },
            'query-input': {
                '@type': 'PropertyValueSpecification',
                valueRequired: 'http://schema.org/True',
                valueName: 'search_term_string',
            },
        },
    });
};
export const AddWebsiteSchema = () => {
    const { site } = React.useContext(SiteContext);
    return (
        <Helmet>
            <script type="application/ld+json">
                {CreateWebsiteSchema(site)}
            </script>
        </Helmet>
    );
};
export const CreateProductSchema = (site: Site, item: Item) => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    const offersSchema: any = {
        '@type': 'Offer',
        url: 'https://' + site.domain + item.url,
        priceCurrency: 'USD',
        price: item.pricing.current,
        itemCondition: 'http://schema.org/NewCondition',
        priceValidUntil: `${item.pricingValidUntil ?? tomorrow.toISOString().split('T')[0] ?? ''}`,
        seller: {
            '@type': 'Organization',
            name: 'BBQGuys',
        },
        shippingDetails: {
            '@type': 'OfferShippingDetails',
            shippingRate: {
                '@type': 'MonetaryAmount',
                value: item.pricing.shipping ?? 0,
                currency: 'USD',
            },
        },
    };
    switch (item.stockStatus) {
        case 'OUT_OF_STOCK':
            offersSchema.availability = 'https://schema.org/OutOfStock';
            break;
        case 'DISCONTINUED':
            offersSchema.availability = 'https://schema.org/Discontinued';
            break;
        case 'LIMITED_SUPPLY':
            offersSchema.availability =
                'https://schema.org/LimitedAvailability';
            break;
        default:
            offersSchema.availability = 'https://schema.org/InStock';
            break;
    }

    if (item.pricing.list > item.pricing.current) {
        offersSchema.priceSpecification = {
            '@type': 'UnitPriceSpecification',
            priceType: 'https://schema.org/ListPrice',
            price: item.pricing.list ?? 0.0,
            priceCurrency: 'USD',
        };
    }
    // return policy
    offersSchema.hasMerchantReturnPolicy = {
        '@type': 'MerchantReturnPolicy',
        applicableCountry: 'US',
        returnPolicyCategory:
            'https://schema.org/MerchantReturnFiniteReturnWindow',
        merchantReturnDays: 30,
        returnMethod: 'https://schema.org/ReturnByMail',
        returnFees: 'https://schema.org/FreeReturn',
    };

    // product schema must have either 'offers', 'review' or 'aggregateRating' specified
    const prodSchema: any = {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: item.name,
        description: sanitizeContent(item.description),
        sku: item.id,
        mpn: item.modelNumber,
        image: [item.imageUrl],
        brand: {
            '@type': 'Brand',
            name: item.manufacturerName,
        },
        offers: offersSchema,
    };

    // do not show aggregate rating if review count is 0 -- this will invalidate the schema
    if (item.userReviewsCount > 0) {
        prodSchema.aggregateRating = {
            '@type': 'AggregateRating',
            ratingValue: item.userReviewsRating.toPrecision(2),
            bestRating: 5,
            worstRating: 1,
            reviewCount: item.userReviewsCount ?? 0,
        };
    }

    return JSON.stringify(prodSchema);
};
export const AddProductSchema = ({ item }: { item: Item }) => {
    const { site } = React.useContext(SiteContext);
    return (
        <Helmet>
            <script type="application/ld+json">{`${CreateProductSchema(site, item)}`}</script>
        </Helmet>
    );
};
export const CreateReviewSchema = (items: Item[]) => {
    const reviews: any[] = [];
    items.forEach(item => {
        reviews.push({
            '@type': 'Rating',
            ratingValue: item.userReviewsRating.toPrecision(2),
            bestRating: 5,
            worstRating: 1,
        });
    });
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Review',
        reviewRating: reviews,
    });
};
export const AddReviewSchema = ({ items }: { items: Item[] }) => {
    return (
        <Helmet>
            <script type="application/ld+json">{`${CreateReviewSchema(items)}`}</script>
        </Helmet>
    );
};
export const AddSchemaString = ({ schema }: { schema: string }) => {
    return (
        <Helmet>
            <script type="application/ld+json">{schema}</script>
        </Helmet>
    );
};
export const CreateArticleSchema = (content: Content, site: Site) => {
    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Article',
        headline: content.title,
        description: sanitizeContent(content.description),
        image: content.imageUrl ?? getEnvironmentVariable('NO_IMAGE_URL_META'),
        datePublished: content.datePublished,
        dateModified: content.dateModified,
        mainEntityOfPage: {
            '@type': 'WebPage',
            '@id': 'https://' + site.domain + content.url,
        },
        author: {
            '@type': 'Organization',
            name: 'BBQGuys Staff',
            url: 'https://' + site.domain,
        },
        publisher: {
            '@type': 'Organization',
            name: site.name,
            logo: {
                '@type': 'ImageObject',
                url: getEnvironmentVariable('IMAGE_LOGO_URL'),
            },
        },
    });
};
export const AddArticleSchema = ({ content }: { content: Content }) => {
    const { site } = React.useContext(SiteContext);
    return (
        <Helmet>
            <script type="application/ld+json">
                {CreateArticleSchema(content, site)}
            </script>
        </Helmet>
    );
};
