import { Button, SxProps } from '@mui/material';
import React from 'react';
import { Item } from '../../../../../../types';
import PowerReviewsWriteModal from '../../PowerReviewsWriteModal';

export const CustomerReviewsWriteReviewButton = (props: {
    item: Item;
    sx?: SxProps;
    button?: any;
}) => {
    const { item, sx = {} } = props;
    let { button } = props;
    const [writeReviewOpen, setWriteReviewOpen] = React.useState(false);

    const handleWriteReview = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        return setWriteReviewOpen(true);
    };
    if (button === undefined) {
        button = (
            <Button color="secondary" variant="contained">
                Write A Review
            </Button>
        );
    }
    button = React.cloneElement(button, {
        onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
            handleWriteReview(e),
        sx: sx,
    });

    return (
        <>
            {button}
            <PowerReviewsWriteModal
                type="review"
                item={item}
                open={writeReviewOpen}
                handleClose={() => setWriteReviewOpen(false)}
            />
        </>
    );
};

export default CustomerReviewsWriteReviewButton;
