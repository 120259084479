import { useQuery } from '@apollo/client';
import { Box, Link, List, ListItem, Typography } from '@mui/material';
import { LoadingSpinner } from '../../../Components/_base/BBQGuysComponents';
import { QL_BRANDS_COUNT } from '../../../Graphql/queries';
import { BrandsCount } from '../../../types';

interface BrandListProps {
    startingCharacter: string;
    brands: BrandsCount[];
}
interface BrandListObject {
    [key: string]: BrandListProps;
}

const BrandsList = () => {
    const { data, loading, error } = useQuery(QL_BRANDS_COUNT);
    if (error) {
        throw error;
    }
    if (loading) {
        return <LoadingSpinner center />;
    }

    const brandList: BrandListObject = {};

    data.brandsCount.results.forEach((brand: BrandsCount, i: number) => {
        const char = brand.name.substring(0, 1).toUpperCase();
        if (brandList[char]) {
            brandList[char].brands.push(brand);
        } else {
            brandList[char] = {
                startingCharacter: char,
                brands: [brand],
            };
        }
    });

    const orderedBrandList = Object.keys(brandList)
        .sort()
        .reduce((obj: BrandListObject, key: string) => {
            obj[key] = brandList[key];
            return obj;
        }, {});

    return (
        <Box
            display="flow"
            sx={{
                columns: {
                    xs: 1,
                    md: 3,
                    lg: 4,
                },
            }}
        >
            {Object.keys(orderedBrandList).map(
                (startingChar: string, k: number) => {
                    return (
                        <Box key={'brand-char-' + startingChar}>
                            <Typography variant="h3">{startingChar}</Typography>
                            <List>
                                {orderedBrandList[startingChar].brands
                                    .sort((a: BrandsCount, b: BrandsCount) =>
                                        a.name.localeCompare(b.name),
                                    )
                                    .map((brand: BrandsCount, i: number) => {
                                        return (
                                            <ListItem key={'brand-' + brand.id}>
                                                <Link href={brand.url ?? ''}>
                                                    {brand.name}
                                                </Link>
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        </Box>
                    );
                },
            )}
        </Box>
    );
};

export default BrandsList;
