import VerifiedIcon from '@mui/icons-material/Verified';
import { Chip } from '@mui/material';
import { CustomerReview } from '../../../../../types';

const CustomerReviewsVerifiedPurchaser = (props: {
    review: CustomerReview;
}) => {
    const { review } = props;
    if (review.buyerStatus === 'Verified Purchaser') {
        return (
            <Chip
                label={review.buyerStatus}
                color="success"
                variant="outlined"
                size="small"
                icon={<VerifiedIcon />}
            />
        );
    }
    return <></>;
};

export default CustomerReviewsVerifiedPurchaser;
