import { Link } from '@mui/material';
import { Image } from '../../../../../Components/Image';
import theme from '../../../../../Theme';
import { CustomerReview } from '../../../../../types';

const CustomerReviewsSyndicationLink = ({
    review,
}: {
    review: CustomerReview;
}) => {
    return (
        <>
            {review.isSyndicated === true && review.syndicationImage ? (
                <Link href={review.syndicationLink as string} display="block">
                    <Image
                        src={review.syndicationImage as string}
                        alt={
                            'the source of this review is from ' +
                            review.syndicationSource
                        }
                        width={75}
                        height={25}
                        sx={{
                            marginLeft: theme.spacing(-0.3),
                            marginTop: theme.spacing(0.5),
                        }}
                    />
                </Link>
            ) : null}
        </>
    );
};

export default CustomerReviewsSyndicationLink;
