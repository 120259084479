import { CancelRounded } from '@mui/icons-material';
import {
    Button,
    ButtonProps,
    Grid,
    IconButton,
    Skeleton,
    Stack,
} from '@mui/material';
import { Box } from '@mui/system';
import {
    DesktopAndTabletDevice,
    isDesktop,
    isMobile,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import theme from '../../Theme';
import {
    ColorSwatch,
    Item,
    Maybe,
    OptionsAvailableDisplayType,
} from '../../types';
import {
    AtcTrackingGroup,
    AtcTrackingType,
} from '../Buttons/AddToCart/AddToCart';
import {
    ColorSwatchContainer,
    ColorSwatchContainerInner,
    ColorSwatches,
    ColorSwatchLinkRemaining,
    ColorSwatchSpan,
} from '../_base/BBQGuysStyledComponents';
import ProductCardDeal from './CardTypes/Deal/ProductCardDeal';
import ProductCardDefault from './CardTypes/Default/ProductCardDefault';
import ProductCardDefaultList from './CardTypes/Default/ProductCardDefaultList';
import './ProductCards.scss';
import { useContext, useState } from 'react';
import { ProductPageContext } from '../../Contexts/ProductPageProvider';

export type ProductCardType = 'default' | 'deal';
export interface ItemColorSwatch {
    [key: string]: ColorSwatch;
}
export interface ProductCardOptions {
    item: Item;
    withAddToCartButton?: boolean;
    withViewItemButton?: boolean;
    withLeadTime?: boolean;
    lazyLoad?: boolean;
    withItemId?: boolean;
    withQuickView?: boolean;
    withPricingAndInfo?: boolean;
    withMoreOptionsAvailable?: boolean;
    cardType?: ProductCardType;
    withBorderSeparator?: boolean;
    trackingType?: AtcTrackingType;
    trackingGroup?: AtcTrackingGroup;
    trackingItemOrder?: number;
    onDelete?: ((item: Item) => void) | null | undefined;
}

export const ProductCardColorSwatches = (props: {
    item: Item;
    onlyShowIfSwatchesExist?: boolean;
    swatchesToShow?: number;
    onHover: (swatch: ColorSwatch, swatches: ItemColorSwatch) => void;
}) => {
    const {
        item,
        onlyShowIfSwatchesExist = false,
        swatchesToShow = 4,
        onHover,
    } = props;

    if (
        item.optionsAvailableDisplayType !==
        OptionsAvailableDisplayType.ColorSwatches
    ) {
        return <ColorSwatchContainer></ColorSwatchContainer>;
    }

    if (
        !(typeof item.optionsAvailable === 'object') ||
        item.optionsAvailable.length < 1
    ) {
        return onlyShowIfSwatchesExist ? null : <ColorSwatchContainer />;
    }

    const colorSwatches: ColorSwatch[] = item.optionsAvailable as ColorSwatch[];

    const colorSwatchesByItemId: ItemColorSwatch = {};
    colorSwatches.forEach((c: ColorSwatch, i) => {
        if (c) {
            colorSwatchesByItemId[c.itemId] = c;
        }
    });
    let swatches = [...colorSwatches];
    const firstSwatchItem: ColorSwatch | undefined = swatches.find(
        (swatch: ColorSwatch) => swatch.itemId === item.id,
    );

    if (firstSwatchItem) {
        swatches = swatches.filter(
            (swatch: ColorSwatch) => swatch.itemId !== item.id,
        );
    }
    swatches.sort((a: ColorSwatch, b: ColorSwatch) => {
        if (
            a.title === undefined ||
            a.title === null ||
            b.title === undefined ||
            b.title === null
        ) {
            return 0;
        }
        if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
        }
        if (a.title.toLocaleLowerCase() < b.title.toLowerCase()) {
            return -1;
        }
        return 0;
    });
    if (firstSwatchItem) {
        swatches.unshift(firstSwatchItem);
    }
    const [activeSwatch, setActiveSwatch] = useState(
        firstSwatchItem as ColorSwatch | undefined,
    );

    const renderSwatchColor = (
        swatch: ColorSwatch,
        activeSwatch: ColorSwatch | undefined,
    ) => {
        return (
            <ColorSwatches
                component="span"
                title={swatch.title || ''}
                style={{ backgroundColor: swatch.hexCode as string }}
                className={swatch === activeSwatch ? 'active' : ''}
                onMouseOver={() => {
                    onHover(swatch, colorSwatchesByItemId);
                }}
                onClick={e => {
                    e.preventDefault();
                    setActiveSwatch(swatch);
                }}
            />
        );
    };

    return (
        <ColorSwatchContainer
            onMouseLeave={() => {
                if (activeSwatch) {
                    onHover(activeSwatch, colorSwatchesByItemId);
                }
            }}
        >
            {swatches && (
                <>
                    <ColorSwatchContainerInner>
                        {swatches
                            .filter(
                                (swatch: ColorSwatch, i: number) =>
                                    i < swatchesToShow,
                            )
                            .map((swatch: Maybe<ColorSwatch>, i: number) => {
                                return (
                                    swatch && (
                                        <ColorSwatchSpan
                                            key={
                                                'color-swatch-' + swatch.itemId
                                            }
                                        >
                                            {renderSwatchColor(
                                                swatch,
                                                activeSwatch,
                                            )}
                                        </ColorSwatchSpan>
                                    )
                                );
                            })}
                    </ColorSwatchContainerInner>
                    {swatches.length > swatchesToShow && (
                        <ColorSwatchLinkRemaining>
                            +{swatches.length - swatchesToShow}
                            <DesktopAndTabletDevice>
                                &nbsp;options
                            </DesktopAndTabletDevice>
                        </ColorSwatchLinkRemaining>
                    )}
                </>
            )}
        </ColorSwatchContainer>
    );
};

const ProductCardLoading = () => {
    return (
        <Box className="product-card">
            <span className="img-container">
                <MobileDevice>
                    <Skeleton
                        className="img"
                        variant="rectangular"
                        width={'100%'}
                        height={180}
                    />
                </MobileDevice>
                <DesktopAndTabletDevice>
                    <Skeleton
                        className="img"
                        variant="rectangular"
                        width={'100%'}
                        height={240}
                    />
                </DesktopAndTabletDevice>
            </span>
            <Skeleton variant="text" width={'85%'} />
            <Skeleton variant="text" width={'60%'} />
            <br />
            <Skeleton variant="text" width={'50%'} />
            <Skeleton variant="text" width={'80%'} />
            <br />
            <Skeleton
                variant="rectangular"
                width={'100%'}
                height={'2.5rem'}
                style={{ borderRadius: 4 }}
            />
        </Box>
    );
};

const ProductCardListLoading = () => {
    return (
        <Grid
            container
            className="product-card-list"
            sx={{ pb: 2, pt: 2, borderTop: theme.palette.border }}
        >
            <Grid item xs={3}>
                <MobileDevice>
                    <Skeleton variant="rectangular" width={'90%'} height={85} />
                </MobileDevice>
                <DesktopAndTabletDevice>
                    <Skeleton
                        variant="rectangular"
                        width={'90%'}
                        height={240}
                    />
                </DesktopAndTabletDevice>
            </Grid>
            <Grid item xs={9}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={9}>
                        <Skeleton variant="text" width={'85%'} />
                        <Skeleton variant="text" width={'60%'} />
                        <Stack direction="row" sx={{ marginLeft: '-2px' }}>
                            <Stack direction="row">
                                <Skeleton
                                    variant="text"
                                    width={'35px'}
                                    component="span"
                                    sx={{
                                        height: '35px',
                                        marginRight: '.25rem',
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    width={'35px'}
                                    component="span"
                                    sx={{
                                        height: '35px',
                                        marginRight: '.25rem',
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    width={'35px'}
                                    component="span"
                                    sx={{
                                        height: '35px',
                                        marginRight: '.25rem',
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    width={'35px'}
                                    component="span"
                                    sx={{
                                        height: '35px',
                                        marginRight: '.25rem',
                                    }}
                                />
                                <Skeleton
                                    variant="text"
                                    width={'35px'}
                                    component="span"
                                    sx={{
                                        height: '35px',
                                        marginRight: '.25rem',
                                    }}
                                />
                            </Stack>
                        </Stack>
                        <DesktopAndTabletDevice>
                            <br />
                            <Skeleton
                                variant="text"
                                width={'70%'}
                                sx={{ ml: 2 }}
                            />
                            <Skeleton
                                variant="text"
                                width={'70%'}
                                sx={{ ml: 2 }}
                            />
                            <Skeleton
                                variant="text"
                                width={'70%'}
                                sx={{ ml: 2 }}
                            />
                            <Skeleton
                                variant="text"
                                width={'70%'}
                                sx={{ ml: 2 }}
                            />
                            <Skeleton
                                variant="text"
                                width={'70%'}
                                sx={{ ml: 2 }}
                            />
                        </DesktopAndTabletDevice>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={3}
                        textAlign={isMobile ? 'left' : 'right'}
                    >
                        <Skeleton
                            variant="text"
                            sx={{
                                marginLeft: isDesktop ? '40%' : 0,
                                width: isMobile ? '60%' : 0,
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                marginLeft: isDesktop ? '30%' : 0,
                                width: isMobile ? '70%' : 0,
                            }}
                            height={'3rem'}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                marginLeft: isDesktop ? '10%' : 0,
                                width: isMobile ? '90%' : 0,
                            }}
                        />
                        <br />
                        <Skeleton
                            variant="text"
                            sx={{
                                marginLeft: isDesktop ? '40%' : 0,
                                width: isMobile ? '60%' : 0,
                            }}
                        />
                        <Skeleton
                            variant="text"
                            sx={{
                                marginLeft: isDesktop ? '60%' : 0,
                                width: isMobile ? '40%' : 0,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export const ViewItemButton = ({
    item,
    ...rest
}: { item: Item } & ButtonProps) => {
    const { setItemId } = useContext(ProductPageContext);
    return (
        <Button
            variant="outlined"
            fullWidth
            href={item.url}
            className="bbq-icon--chevron-right"
            sx={{ textAlign: 'center' }}
            onClick={() => {
                setItemId(item.id);
            }}
            {...rest}
        >
            View Item
        </Button>
    );
};

const ProductCardBase = (props: ProductCardOptions) => {
    const { cardType = 'default' } = props;
    if (cardType === 'deal') {
        return <ProductCardDeal {...props} />;
    }
    return <ProductCardDefault {...props} />;
};

export const ProductCardListBase = (props: ProductCardOptions) => {
    return <ProductCardDefaultList {...props} />;
};

export const DeleteProductCardButton = ({
    item,
    onDelete,
}: {
    item: Item;
    onDelete: ((item: Item) => void) | null | undefined;
}) => {
    if (typeof onDelete === 'function') {
        return (
            <IconButton
                onClick={() => onDelete(item)}
                sx={{ position: 'absolute', top: -10, right: -10 }}
            >
                <CancelRounded color="error" />
            </IconButton>
        );
    }
    return <></>;
};

const ProductCard = (props: ProductCardOptions) => {
    return <ProductCardBase {...props} />;
};
const ProductCardList = (props: ProductCardOptions) => {
    return <ProductCardListBase {...props} />;
};
export {
    ProductCard,
    ProductCardBase,
    ProductCardList,
    ProductCardListLoading,
    ProductCardLoading,
};
