import { FieldWithLabelProps } from '../../../Pages/Cart/PageCheckout/Forms/Components/FieldWithLabel';
import { SelectWithLabel } from '../../../Pages/Account/PageEditMyAccount/Components/SelectWithLabel';
interface CategoryOptions {
    label: string;
    value: string;
}
interface CategoriesWithLabelProps extends FieldWithLabelProps {
    data?: CategoryOptions[];
}
export const CategoriesWithLabel = (props: CategoriesWithLabelProps) => {
    const {
        id,
        name = 'category',
        label = 'Category',
        formik,
        sx,
        ...rest
    } = props;

    const products: string[] = [
        'Grills',
        'Grilling Accessories',
        'Refrigeration',
        'Air Quality',
        'Pizza Ovens',
        'Outdoor Kitchens',
        'Outdoor Storage',
        'Patio Furniture',
        'Cookware',
        'Cutlery',
        'Fireplaces',
        'Gas Logs',
        'Patio Heaters',
        'Fire Pits',
        'Shade',
        'Fireplace Accessories',
        'Outdoor Decor',
        'Cooking Appliances',
        'Dishwashers',
        'Apparel',
        'Sports & Outdoors',
        'Other',
    ];

    const newProducts = products.map(p => ({ label: p, value: p }));

    return (
        <SelectWithLabel
            id={id ?? name}
            name={name}
            formik={formik}
            label={label}
            sx={sx}
            data={newProducts}
            {...rest}
        />
    );
};
