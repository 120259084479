import { useQuery } from '@apollo/client';
import { QL_DEPT_CHILDREN } from '../../../Graphql/queries';
import {
    ChildDepartmentLinks,
    ChildDepartmentLinksLoading,
} from '../../../Components/_base/BBQGuysComponents';

export default function HubPageDepartments({ deptId }: { deptId: string }) {
    const { data, loading } = useQuery(QL_DEPT_CHILDREN, {
        variables: {
            departmentId: deptId,
            depth: 2,
        },
    });

    if (loading) {
        return <ChildDepartmentLinksLoading />;
    }

    return (
        <>
            {data.departmentChildren &&
            data.departmentChildren.countResults > 0 ? (
                <ChildDepartmentLinks
                    departments={data.departmentChildren.results}
                />
            ) : null}
        </>
    );
}
