import { useQuery } from '@apollo/client';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { Link, Paper, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import * as React from 'react';
import { SSRContext } from '../../../Contexts/SSRProvider';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    MobileDevice,
    DesktopAndTabletDevice,
} from '../../../Contexts/DeviceTypeProvider';
import { date } from '../../../functions';
import { Order } from '../../../types';
import { StatusLabel } from '../../_base/BBQGuysComponents';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '../../_base/BBQGuysStyledComponents';
import { BBQPagination } from '../../Pagination/BBQPagination';
import { QL_ORDERS } from '../../../Graphql/queries';

const OrderRow = (props: { order: Order; isCurrentOrder?: boolean }) => {
    const o = props.order;
    const isCurrentOrder = props.isCurrentOrder ?? false;
    const orderStatusUrl =
        '/order-status?' +
        // + "last_name=" + encodeURIComponent(o.billingAddress?.lastName as string) + '&'
        'orderNumber=' +
        encodeURIComponent(o.orderNumber) +
        '&' +
        'zipCode=' +
        encodeURIComponent(o.billingAddress?.zip as string);

    return (
        <TableRow>
            <TableCell>
                {isCurrentOrder ? (
                    <>{o.orderNumber} (viewing)</>
                ) : (
                    <Link href={orderStatusUrl}>{o.orderNumber}</Link>
                )}
                <MobileDevice>
                    {' '}
                    <StatusLabel status={o.orderStatus} />
                </MobileDevice>
            </TableCell>
            <TableCell align="center">{o.pricingFormatted.total}</TableCell>
            <DesktopAndTabletDevice>
                <TableCell align="center">
                    <StatusLabel status={o.orderStatus} />
                </TableCell>
            </DesktopAndTabletDevice>
            <TableCell align="center">
                {date.format(new Date(o.orderDate))}
            </TableCell>
        </TableRow>
    );
};

const NoOrdersFound = () => {
    return (
        <Paper variant="outlined" sx={{ padding: '3rem' }}>
            <Box textAlign="center">
                <ShoppingBasketIcon sx={{ fontSize: 128 }} />
                <Typography variant="h4" sx={{ textTransform: 'uppercase' }}>
                    No orders found
                </Typography>
            </Box>
        </Paper>
    );
};

const OrderTableHead = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell align="center">Amount</TableCell>
                <DesktopAndTabletDevice>
                    <TableCell align="center">Status</TableCell>
                </DesktopAndTabletDevice>
                <TableCell align="center">Order Date</TableCell>
            </TableRow>
        </TableHead>
    );
};

const OrderTableLoading = () => {
    return (
        <Table>
            <OrderTableHead />
            <TableBody>
                {[...Array(10)].map((v: number, i: number) => {
                    return (
                        <TableRow key={i}>
                            <TableCell>
                                <Skeleton variant="text" width="80%" />
                            </TableCell>
                            <TableCell align="center">
                                <Skeleton variant="text" width="80%" />
                            </TableCell>
                            <DesktopAndTabletDevice>
                                <TableCell align="center">
                                    <Skeleton variant="text" width="80%" />
                                </TableCell>
                            </DesktopAndTabletDevice>
                            <TableCell align="center">
                                <Skeleton variant="text" width="80%" />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

function Orders({ currentOrder }: { currentOrder?: Order }) {
    const { user } = React.useContext(UserAuth);
    const { isClientSide } = React.useContext(SSRContext);
    const [page, setPage] = React.useState<number>(1);

    const handlePaginationChange = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        if (isClientSide()) {
            window.scrollTo(0, 0);
        }
        setTimeout(() => {
            setPage(value);
        }, 5);
    };
    const { data, loading } = useQuery(QL_ORDERS, {
        variables: {
            userId: user.id,
            page: page,
        },
    });
    return (
        <>
            {loading ? (
                <OrderTableLoading />
            ) : (
                <>
                    {data?.userOrders?.totalResults === 0 ? (
                        <NoOrdersFound />
                    ) : (
                        <>
                            <Table>
                                <OrderTableHead />
                                <TableBody>
                                    {data?.userOrders.results.map(
                                        (o: Order, i: number) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <OrderRow
                                                        order={o}
                                                        isCurrentOrder={
                                                            currentOrder &&
                                                            currentOrder.id ===
                                                                o.id
                                                        }
                                                    />
                                                </React.Fragment>
                                            );
                                        },
                                    )}
                                </TableBody>
                            </Table>
                            <Box sx={{ my: 2 }} textAlign="center">
                                <BBQPagination
                                    count={data?.userOrders.totalPages}
                                    page={data?.userOrders.currentPage}
                                    onChange={handlePaginationChange}
                                />
                            </Box>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Orders;
