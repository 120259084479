import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import { CUSTOMER_REVIEW_FIELDS } from '../../../../../Graphql/fragments';
import CustomerReviewsWriteReviewButton from '../Components/Buttons/CustomerReviewsWriteReviewButton';
import CustomerReviewsLoading from '../CustomerReviewsLoading';
import CustomerReviewsDisplay from './CustomerReviewsDisplay';

export const QL_CUST_REVIEWS = gql`
    ${CUSTOMER_REVIEW_FIELDS}
    query CustomerReviews(
        $itemId: ID
        $page: Int
        $sort: String
        $rating: Int
        $searchTerm: String
    ) {
        customerReviews(
            itemId: $itemId
            page: $page
            sort: $sort
            rating: $rating
            searchTerm: $searchTerm
        ) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            offset
            results {
                ...CustomerReviewFields
            }
        }
    }
`;

const CustomerReviewsGrid = () => {
    const { page, sort, searchTerm, rating, item } = React.useContext(
        CustomerReviewsContext,
    );
    const { data, loading } = useQuery(QL_CUST_REVIEWS, {
        variables: {
            itemId: item.id,
            page: page,
            sort: sort,
            rating: rating || 0,
            searchTerm: searchTerm,
        },
    });

    if (loading) {
        return <CustomerReviewsLoading />;
    }

    if (data && data.customerReviews && data.customerReviews.countResults > 0) {
        return (
            <CustomerReviewsDisplay
                item={item}
                reviews={data.customerReviews.results}
                totalPages={data.customerReviews.totalPages}
                currentPage={data.customerReviews.currentPage}
            />
        );
    } else {
        return (
            <>
                <Box>
                    <Typography variant="body1">
                        Be the first to write a review!
                    </Typography>
                    <Box mt={2}>
                        <CustomerReviewsWriteReviewButton item={item} />
                    </Box>
                </Box>
            </>
        );
    }
};

export default CustomerReviewsGrid;
