import React from 'react';
import { Box, Typography, Skeleton } from '@mui/material';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { Item, ImageType } from '../../../../types';
import {
    ImageCarouselContext,
    ImageCarouselProvider,
} from '../../../../Contexts/ImageCarouselContext';
import { Image } from '../../../../Components/Image';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';

const WARRANTY_THUMBNAIL_SIZE = 150;

const WarrantyInfoLoading: React.FC = () => {
    return (
        <>
            <Skeleton variant="text" height={'1.5rem'} width={'10%'} />
            <Skeleton variant="text" height={'3rem'} width={'25%'} />
            <Skeleton variant="text" height={'1.5rem'} width={'70%'} />
            <br />
            <br />
            <Skeleton variant="text" height={'1.5rem'} width={'40%'} />
            <Skeleton variant="text" height={'1.5rem'} width={'60%'} />
            <br />
        </>
    );
};

const WarrantyInfoThumbnails: React.FC = () => {
    const { item, images, loading } = React.useContext(ImageCarouselContext);
    const [warrantyImage, setWarrantyImage] = React.useState<ImageType | null>(
        null,
    );

    React.useEffect(() => {
        if (!loading) {
            /* find the image first image matching the alt text description with 'warranty' */
            const warrantyFindImage = images.find(image =>
                image.description?.toLowerCase().includes('warranty'),
            );
            /* ensure that warrantyInfo has a value */
            const warrantyInfo = item.warrantyInfo?.toLowerCase() ?? '';
            /* defines the default image info for products with a 'lifetime warranty'; add the brand name to the text */
            const defaultImage = {
                id: '0',
                description: `${item.manufacturerName} Offers a Lifetime Warranty`,
                url: 'https://cdn.shocho.co/sc-site/logos/lifetime-warranty-badge.png',
            };
            /* sets the selected image to either the one found in the carousel, or defaults if 'lifetime' and 'warranty' are present in description */
            const selectedImage =
                warrantyFindImage !== undefined
                    ? warrantyFindImage
                    : warrantyInfo.includes('lifetime') &&
                        warrantyInfo.includes('warranty')
                      ? defaultImage
                      : null;
            setWarrantyImage(selectedImage);
        }
    }, [images, loading]);

    if (loading) {
        return <WarrantyInfoLoading />;
    }
    return (
        <>
            <MobileDevice>
                <WarrantyInfoMobile warrantyImage={warrantyImage} />
            </MobileDevice>
            <DesktopAndTabletDevice>
                <WarrantyInfoDesktop warrantyImage={warrantyImage} />
            </DesktopAndTabletDevice>
        </>
    );
};

const WarrantyInfoDesktop: React.FC<{ warrantyImage: ImageType | null }> = ({
    warrantyImage,
}) => {
    const { item } = React.useContext(ImageCarouselContext);
    return item.warrantyInfo ? (
        <Section title="Warranty">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {warrantyImage && (
                    <Image
                        src={warrantyImage.url}
                        alt={warrantyImage.description ?? ''}
                        width={WARRANTY_THUMBNAIL_SIZE}
                        height={WARRANTY_THUMBNAIL_SIZE}
                        lazyLoadImage={false}
                        sx={{ marginRight: 2 }}
                    />
                )}
                <Box sx={{ flexGrow: 1 }}>
                    <Typography
                        variant="body1"
                        component="p"
                        dangerouslySetInnerHTML={{ __html: item.warrantyInfo }}
                    />
                </Box>
            </Box>
        </Section>
    ) : null;
};

const WarrantyInfoMobile: React.FC<{ warrantyImage: ImageType | null }> = ({
    warrantyImage,
}) => {
    const { item } = React.useContext(ImageCarouselContext);
    return item.warrantyInfo ? (
        <Section title="Warranty">
            {warrantyImage && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        boxShadow: '6px 3px 0 0 #babec1',
                        border: '1px solid #e86412',
                        margin: '0 0 1rem 0',
                        padding: '1rem',
                    }}
                >
                    {warrantyImage && (
                        <Image
                            src={warrantyImage.url}
                            alt={warrantyImage.description ?? ''}
                            width={WARRANTY_THUMBNAIL_SIZE}
                            height={WARRANTY_THUMBNAIL_SIZE}
                            lazyLoadImage={false}
                            sx={{ marginRight: 2 }}
                        />
                    )}
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography
                            variant="h3"
                            component="h3"
                            dangerouslySetInnerHTML={{
                                __html: warrantyImage.description ?? '',
                            }}
                        />
                    </Box>
                </Box>
            )}
            <Typography
                variant="body1"
                component="p"
                dangerouslySetInnerHTML={{ __html: item.warrantyInfo }}
            />
        </Section>
    ) : null;
};

const WarrantyInfo: React.FC<{ item: Item; itemImages?: ImageType[] }> = ({
    item,
    itemImages = [],
}) => {
    return (
        <ImageCarouselProvider item={item} itemImages={itemImages}>
            <WarrantyInfoThumbnails />
        </ImageCarouselProvider>
    );
};

export default WarrantyInfo;
