import ItemComparison from '../../../../Components/ItemComparison/ItemComparison';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { Item } from '../../../../types';

const ItemComparisonSection = ({ item }: { item: Item }) => {
    return (
        <>
            <DesktopAndTabletDevice>
                <Section
                    title="Compare Similar Items"
                    id="compare-similar-items"
                    withTopBorder={true}
                    open={false}
                    expandable={true}
                    initialHeight={800}
                >
                    <ItemComparison item={item} />
                </Section>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Section
                    title="Compare Similar Items"
                    id="compare-similar-items"
                    open={false}
                    withTopBorder={true}
                    expandable={true}
                >
                    <ItemComparison item={item} />
                </Section>
            </MobileDevice>
        </>
    );
};

export default ItemComparisonSection;
