import { Box, Skeleton } from '@mui/material';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';

const CustomerReviewsContentMobileLoading = () => {
    return (
        <Box sx={{ mb: 2, pt: 2, pb: 4 }}>
            <StarRatings
                rating={5}
                readOnly={true}
                showCount={false}
                size="large"
                loading={true}
            />
            <Skeleton variant="text" width="70%" height="2rem" />
            <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2 }}>
                <Skeleton variant="text" width="40%" sx={{ mr: 1 }} />
                <Skeleton variant="text" width="20%" sx={{ mr: 1 }} />
                <Skeleton variant="text" width="30%" />
            </Box>
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="60%" />
        </Box>
    );
};

export default CustomerReviewsContentMobileLoading;
