import { Box, Skeleton } from '@mui/material';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';
import CustomerReviewsMediaCarouselLoading from '../Components/CustomerReviewsMediaCarouselLoading';

const CustomerReviewsHeaderDesktopLoading = () => {
    return (
        <>
            <Box display="grid" gap={3} gridTemplateColumns={'1fr 1fr 1fr'}>
                <Box>
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="80%" />
                    <Skeleton variant="text" width="80%" />
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Skeleton variant="text" width={75} height={75} />
                    <StarRatings
                        rating={5}
                        readOnly={true}
                        showCount={false}
                        size="large"
                        loading={true}
                    />
                </Box>
                <Box>
                    <CustomerReviewsMediaCarouselLoading />
                </Box>
            </Box>
            <Skeleton
                variant="rectangular"
                width="100%"
                height={55}
                sx={{ my: 2 }}
            />
        </>
    );
};
export default CustomerReviewsHeaderDesktopLoading;
