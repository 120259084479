import { gql, useQuery } from '@apollo/client';
import { PAGINATED_ITEMS } from '../../Graphql/fragments';
import ProductCarousel, {
    ProductCarouselLoading,
} from '../ProductCarousels/ProductCarousel/ProductCarousel';
import { Section } from '../_base/BBQGuysComponents';
import {
    AtcTrackingGroup,
    AtcTrackingType,
} from '../Buttons/AddToCart/AddToCart';
import React from 'react';
import { DigitalDataContext } from '../../Contexts/DigitalDataProvider';
import { UserAuth } from '../../Contexts/UserAuthProvider';

const QL_ITEM_CABS = gql`
    ${PAGINATED_ITEMS}
    query getItemCABs($itemIds: ID, $pricingTierId: Int) {
        itemCABs(itemIds: $itemIds, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

export const CustomersAlsoBoughtCarousel = (props: {
    itemId?: string;
    itemIds?: [string];
    title?: string;
    count?: number;
    loading?: boolean;
    withBorderTop?: boolean;
    trackingType?: AtcTrackingType;
    trackingGroup?: AtcTrackingGroup;
    dependencyName?: string;
}) => {
    const {
        itemId = '',
        itemIds = [],
        title = 'Customers Also Bought',
        count = 6,
        loading: isLoading = false,
        withBorderTop = true,
        trackingType = 'carousel',
        trackingGroup,
        dependencyName,
    } = props;

    const { getPricingTierId } = React.useContext(UserAuth);
    let mergedItemIds = [];

    if (itemId.length > 0) {
        mergedItemIds.push(itemId);
    } else {
        mergedItemIds = itemIds;
    }

    const { data, loading } = useQuery(QL_ITEM_CABS, {
        variables: {
            itemIds: mergedItemIds.join(','),
            pricingTierId: getPricingTierId(),
        },
    });
    if (isLoading || loading) {
        return (
            <Section title={''} withTopBorder={withBorderTop}>
                <ProductCarouselLoading title={title} />
            </Section>
        );
    }

    if (data.itemCABs.results.length > 1) {
        return (
            <Section
                className="section-also-bought"
                title={''}
                withTopBorder={withBorderTop}
            >
                <ProductCarousel
                    items={data.itemCABs.results}
                    count={count}
                    title={title}
                    trackingType={trackingType}
                    trackingGroup={trackingGroup}
                    dependencyName={dependencyName}
                />
            </Section>
        );
    } else if (dependencyName) {
        const { pushCarouselDependency } = React.useContext(DigitalDataContext);
        pushCarouselDependency({
            carouselName: dependencyName,
            results: data.itemCABs.results,
            itemWidth: count,
        });
    }
    return <></>;
};
