import { useQuery } from '@apollo/client';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { QL_ITEM_EXPERT_REVIEW } from '../../../../Graphql/queries';
import ExpertReviewContentWithRatingsTable from '../../../ExpertReviews/ExpertReviewContentWithRatingsTable';
import { ExpertReviewItemLoading } from '../../../ExpertReviews/ExpertReviewItem';

const ExpertReviewSection = ({ itemId }: { itemId: string }) => {
    const { data, loading } = useQuery(QL_ITEM_EXPERT_REVIEW, {
        variables: {
            itemId: itemId,
        },
    });

    if (loading) {
        return <ExpertReviewItemLoading />;
    }
    return (
        <>
            {data &&
                data.expertReviewClassByItem &&
                data.expertReviewClassByItem.id && (
                    <Section
                        title="Expert Review"
                        anchor="expert-review"
                        id="expert-review"
                        expandable={true}
                    >
                        <ExpertReviewContentWithRatingsTable
                            expertReview={data.expertReviewClassByItem}
                            withVideo={true}
                        />
                    </Section>
                )}
        </>
    );
};

export default ExpertReviewSection;
