import { Box, BoxProps } from '@mui/material';
import React from 'react';
import theme from '../../Theme';

interface BBQGridProps extends BoxProps {
    withBorder?: boolean;
    columns?: any;
    children: React.ReactNode;
}

export const BBQGuysGrid = ({
    sx = {},
    withBorder = false,
    gap = 1,
    columns,
    children,
    ...rest
}: BBQGridProps) => {
    return (
        <Box
            sx={{
                display: 'grid',
                gap: gap,
                overflow: 'auto',
                gridTemplateColumns: columns ?? {
                    xs: '1fr',
                    md: '1fr 1fr 1fr',
                    lg: '1fr 1fr 1fr 1fr',
                },
                '&> div': {
                    border: withBorder ? theme.palette.border : '',
                },
                ...sx,
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};
