import * as React from 'react';
import { ProductCardListLoading } from '../../../../Components/ProductCards/ProductCards';
import {
    LoadingSpinner,
    Section,
} from '../../../../Components/_base/BBQGuysComponents';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { client } from '../../../../Graphql/ApolloClient';
import { QL_ITEM_OPEN_BOX } from '../../../../Graphql/queries';
import { OpenBoxItem } from '../../../../types';
import OpenBoxItemGroupRow from './Components/OpenBoxItemRow/OpenBoxItemGroupRow';

export interface OpenBoxItemGroup {
    [key: string]: OpenBoxItemGroupData;
}

export interface OpenBoxItemGroupData {
    condition: string;
    items: OpenBoxItem[];
}

const loadOpenBoxItems = async (itemId: string): Promise<OpenBoxItemGroup> => {
    return new Promise((resolve, reject) => {
        (async () => {
            const openBoxItemsByGroup = {} as OpenBoxItemGroup;
            const response = await client.query({
                query: QL_ITEM_OPEN_BOX,
                variables: {
                    itemId: itemId,
                },
                fetchPolicy: 'no-cache',
            });

            if (response.error) {
                reject(response.error);
            }

            if (!response.loading) {
                for (
                    let i = 0;
                    i < response.data.openBoxItems.countResults;
                    i++
                ) {
                    const ob = response.data.openBoxItems.results[i];
                    if (ob && ob.condition) {
                        if (openBoxItemsByGroup[ob.condition] === undefined) {
                            openBoxItemsByGroup[ob.condition] = {
                                condition: ob.condition,
                                items: [] as OpenBoxItem[],
                            };
                        }
                        openBoxItemsByGroup[ob.condition].items.push(ob);
                    }
                }
                resolve(openBoxItemsByGroup);
            }
        })();
    });
};

const OpenBoxItems = ({
    itemId,
    showTitle = false,
}: {
    itemId: string;
    showTitle?: boolean;
}) => {
    const [openBoxItems, setOpenBoxItems] =
        React.useState<OpenBoxItemGroup | null>(null);
    React.useEffect(() => {
        loadOpenBoxItems(itemId).then(items => setOpenBoxItems(items));
    }, []);
    let numElms = 0;
    if (openBoxItems) {
        Object.values(openBoxItems).forEach(
            (elem: OpenBoxItemGroupData, i: number) => {
                numElms += elem.items.length;
            },
        );
    }
    const renderRows = () => {
        if (openBoxItems) {
            return Object.values(openBoxItems).map(
                (value: OpenBoxItemGroupData, i: number) => {
                    return value ? (
                        <OpenBoxItemGroupRow
                            key={'obg-' + itemId + value.condition}
                            openBoxItemGroupData={value}
                            onAdded={() => {
                                loadOpenBoxItems(itemId).then(
                                    (openBoxItemsList: OpenBoxItemGroup) => {
                                        setOpenBoxItems(openBoxItemsList);
                                    },
                                );
                            }}
                        />
                    ) : null;
                },
            );
        }
        return <></>;
    };
    const baseProps = {
        title: showTitle ? 'Buying Options' : undefined,
        anchor: 'open-box',
        withTopBorder: showTitle,
    };
    const openBoxProps = {
        ...baseProps,
        expandable: true,
        open: false,
        numberOfElements: numElms,
    };
    return (
        <>
            {openBoxItems !== null ? (
                <>
                    <DesktopAndTabletDevice>
                        <Section
                            {...openBoxProps}
                            elementsPerRow={4}
                            initialHeight={5 * 215}
                        >
                            {renderRows()}
                        </Section>
                    </DesktopAndTabletDevice>
                    <MobileDevice>
                        <Section
                            {...openBoxProps}
                            elementsPerRow={2}
                            initialHeight={3 * 390}
                        >
                            {renderRows()}
                        </Section>
                    </MobileDevice>
                </>
            ) : (
                <Section {...baseProps}>
                    {showTitle ? (
                        <>
                            <ProductCardListLoading />
                            <ProductCardListLoading />
                            <ProductCardListLoading />
                        </>
                    ) : (
                        <LoadingSpinner center />
                    )}
                </Section>
            )}
        </>
    );
};

export default OpenBoxItems;
