export const States = [
    {
        name: 'Alabama',
        code: 'AL',
        lat: 32.3182314,
        lng: -86.902298,
    },
    // {
    //     name: 'Alaska',
    //     code: 'AK',
    //     lat: 64.2008413,
    //     lng: -149.4936733,
    // },
    {
        name: 'Arizona',
        code: 'AZ',
        lat: 34.0489281,
        lng: -111.0937311,
    },
    {
        name: 'Arkansas',
        code: 'AR',
        lat: 35.20105,
        lng: -91.8318334,
    },
    {
        name: 'California',
        code: 'CA',
        lat: 36.778261,
        lng: -119.4179324,
    },
    {
        name: 'Colorado',
        code: 'CO',
        lat: 39.5500507,
        lng: -105.7820674,
    },
    {
        name: 'Connecticut',
        code: 'CT',
        lat: 41.6032207,
        lng: -73.087749,
    },
    {
        name: 'District of Columbia',
        code: 'DC',
        lat: 38.9072,
        lng: 77.0369,
    },
    {
        name: 'Delaware',
        code: 'DE',
        lat: 38.9108325,
        lng: -75.5276699,
    },
    {
        name: 'Florida',
        code: 'FL',
        lat: 27.6648274,
        lng: -81.5157535,
    },
    {
        name: 'Georgia',
        code: 'GA',
        lat: 32.1656221,
        lng: -82.9000751,
    },
    // {
    //     name: 'Hawaii',
    //     code: 'HI',
    //     lat: 19.8967662,
    //     lng: -155.5827818,
    // },
    {
        name: 'Idaho',
        code: 'ID',
        lat: 44.0682019,
        lng: -114.7420408,
    },
    {
        name: 'Illinois',
        code: 'IL',
        lat: 40.6331249,
        lng: -89.3985283,
    },
    {
        name: 'Indiana',
        code: 'IN',
        lat: 40.2671941,
        lng: -86.1349019,
    },
    {
        name: 'Iowa',
        code: 'IA',
        lat: 41.8780025,
        lng: -93.097702,
    },
    {
        name: 'Kansas',
        code: 'KS',
        lat: 39.011902,
        lng: -98.4842465,
    },
    {
        name: 'Kentucky',
        code: 'KY',
        lat: 37.8393332,
        lng: -84.2700179,
    },
    {
        name: 'Louisiana',
        code: 'LA',
        lat: 30.9842977,
        lng: -91.9623327,
    },
    {
        name: 'Maine',
        code: 'ME',
        lat: 45.253783,
        lng: -69.4454689,
    },
    {
        name: 'Maryland',
        code: 'MD',
        lat: 39.0457549,
        lng: -76.6412712,
    },
    {
        name: 'Massachusetts',
        code: 'MA',
        lat: 42.4072107,
        lng: -71.3824374,
    },
    {
        name: 'Michigan',
        code: 'MI',
        lat: 44.3148443,
        lng: -85.6023643,
    },
    {
        name: 'Minnesota',
        code: 'MN',
        lat: 46.729553,
        lng: -94.6858998,
    },
    {
        name: 'Mississippi',
        code: 'MS',
        lat: 32.3546679,
        lng: -89.3985283,
    },
    {
        name: 'Missouri',
        code: 'MO',
        lat: 37.9642529,
        lng: -91.8318334,
    },
    {
        name: 'Montana',
        code: 'MT',
        lat: 46.8796822,
        lng: -110.3625658,
    },
    {
        name: 'Nebraska',
        code: 'NE',
        lat: 41.4925374,
        lng: -99.9018131,
    },
    {
        name: 'Nevada',
        code: 'NV',
        lat: 38.8026097,
        lng: -116.419389,
    },
    {
        name: 'New Hampshire',
        code: 'NH',
        lat: 43.1938516,
        lng: -71.5723953,
    },
    {
        name: 'New Jersey',
        code: 'NJ',
        lat: 40.0583238,
        lng: -74.4056612,
    },
    {
        name: 'New Mexico',
        code: 'NM',
        lat: 34.5199402,
        lng: -105.8700901,
    },
    {
        name: 'New York',
        code: 'NY',
        lat: 40.7127753,
        lng: -74.0059728,
    },
    {
        name: 'North Carolina',
        code: 'NC',
        lat: 35.7595731,
        lng: -79.0192997,
    },
    {
        name: 'North Dakota',
        code: 'ND',
        lat: 47.5514926,
        lng: -101.0020119,
    },
    {
        name: 'Ohio',
        code: 'OH',
        lat: 40.4172871,
        lng: -82.907123,
    },
    {
        name: 'Oklahoma',
        code: 'OK',
        lat: 35.4675602,
        lng: -97.5164276,
    },
    {
        name: 'Oregon',
        code: 'OR',
        lat: 43.8041334,
        lng: -120.5542012,
    },
    {
        name: 'Pennsylvania',
        code: 'PA',
        lat: 41.2033216,
        lng: -77.1945247,
    },
    {
        name: 'Rhode Island',
        code: 'RI',
        lat: 41.5800945,
        lng: -71.4774291,
    },
    {
        name: 'South Carolina',
        code: 'SC',
        lat: 33.836081,
        lng: -81.1637245,
    },
    {
        name: 'South Dakota',
        code: 'SD',
        lat: 43.9695148,
        lng: -99.9018131,
    },
    {
        name: 'Tennessee',
        code: 'TN',
        lat: 35.5174913,
        lng: -86.5804473,
    },
    {
        name: 'Texas',
        code: 'TX',
        lat: 31.9685988,
        lng: -99.9018131,
    },
    {
        name: 'Utah',
        code: 'UT',
        lat: 39.3209801,
        lng: -111.0937311,
    },
    {
        name: 'Vermont',
        code: 'VT',
        lat: 44.5588028,
        lng: -72.5778415,
    },
    {
        name: 'Virginia',
        code: 'VA',
        lat: 37.4315734,
        lng: -78.6568942,
    },
    {
        name: 'Washington',
        code: 'WA',
        lat: 47.7510741,
        lng: -120.7401385,
    },
    {
        name: 'West Virginia',
        code: 'WV',
        lat: 38.5976262,
        lng: -80.4549026,
    },
    {
        name: 'Wisconsin',
        code: 'WI',
        lat: 43.7844397,
        lng: -88.7878678,
    },
    {
        name: 'Wyoming',
        code: 'WY',
        lat: 43.0759678,
        lng: -107.2902839,
    },
    {
        name: 'Armed Forces Americas (AA)',
        code: 'AA',
        lat: 0,
        lng: 0,
    },
    {
        name: 'Armed Forces Europe (AE)',
        code: 'AE',
        lat: 0,
        lng: 0,
    },
    {
        name: 'Armed Forces Pacific (AP)',
        code: 'AP',
        lat: 0,
        lng: 0,
    },
];
