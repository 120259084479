import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { Section } from '../../../../Components/_base/BBQGuysComponents';

const ItemLearningCenterLoading = () => {
    return (
        <Section
            title="Learning Center"
            expandable={false}
            id="learning-center-articles"
        >
            <Box
                gap={4}
                display="grid"
                gridTemplateColumns={{
                    xs: '1fr',
                    md: '1fr 1fr',
                    lg: '1fr 1fr 1fr',
                }}
            >
                <Box>
                    <Typography variant="bold">Related Articles</Typography>
                    <Stack spacing={1}>
                        <Skeleton variant="text" width={'60%'} />
                        <Skeleton variant="text" width={'60%'} />
                        <Skeleton variant="text" width={'60%'} />
                        <Skeleton variant="text" width={'60%'} />
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="bold">
                        Manufacturer Downloads
                    </Typography>
                    <Stack spacing={1}>
                        <Skeleton variant="text" width={'60%'} />
                        <Skeleton variant="text" width={'60%'} />
                        <Skeleton variant="text" width={'60%'} />
                        <Skeleton variant="text" width={'60%'} />
                    </Stack>
                </Box>
                <Box>
                    <Typography variant="bold">Product Videos</Typography>
                    <Stack direction="row" spacing={3}>
                        <Skeleton
                            variant="rectangular"
                            width={150}
                            height={150}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={150}
                            height={150}
                        />
                        <Skeleton
                            variant="rectangular"
                            width={150}
                            height={150}
                        />
                    </Stack>
                </Box>
            </Box>
        </Section>
    );
};
export default ItemLearningCenterLoading;
