import { Box, Skeleton } from '@mui/material';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';
import theme from '../../../../../Theme';

const CustomerReviewsHeaderMobileLoading = () => {
    return (
        <Box sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
            <Skeleton variant="rounded" width="100%" height={40} />
            <Box
                display="grid"
                gap={5}
                gridTemplateColumns={'1fr 1fr'}
                sx={{ my: 3 }}
            >
                <Skeleton variant="circular" height={120} width={120} />
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems={'center'}
                >
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="50%" />
                    <StarRatings
                        rating={5}
                        readOnly={true}
                        showCount={false}
                        size="large"
                        loading={true}
                    />
                    <Skeleton variant="text" width="50%" />
                </Box>
            </Box>
        </Box>
    );
};

export default CustomerReviewsHeaderMobileLoading;
