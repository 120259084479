import { Box, Link, Stack, Typography } from '@mui/material';
import { Maybe, PromotionCategories, PromotionCategoryLink } from '../../types';
import { Label } from '@mui/icons-material';
import React from 'react';

export default function PromotionSidebarDesktop({
    categories,
    tags,
}: {
    categories: PromotionCategories[];
    tags: string[];
}) {
    return (
        <>
            {categories.map((cat: PromotionCategories, i: number) => {
                return (
                    <Stack
                        key={'promo-cat-' + cat.title}
                        spacing={1}
                        sx={{ mb: 2 }}
                    >
                        <Typography variant="subtitle">{cat.title}</Typography>
                        {cat.links.length
                            ? cat.links.map(
                                  (
                                      link: Maybe<PromotionCategoryLink>,
                                      z: number,
                                  ) => {
                                      if (link !== null && link.link.length) {
                                          const selected =
                                              tags.filter(
                                                  t =>
                                                      link.tags
                                                          .map(k =>
                                                              k?.replaceAll(
                                                                  '-',
                                                                  ' ',
                                                              ),
                                                          )
                                                          .indexOf(t) > -1,
                                              ).length > 0;
                                          return (
                                              <Box key={z}>
                                                  <Link
                                                      href={
                                                          selected
                                                              ? '?'
                                                              : link.link
                                                      }
                                                      sx={{
                                                          position: 'relative',
                                                      }}
                                                  >
                                                      {selected && (
                                                          <Label
                                                              sx={{
                                                                  position:
                                                                      'absolute',
                                                                  top: 2,
                                                                  left: -36,
                                                              }}
                                                              color="primary"
                                                          />
                                                      )}
                                                      {link.title}
                                                  </Link>
                                              </Box>
                                          );
                                      } else {
                                          return (
                                              <React.Fragment
                                                  key={z}
                                              ></React.Fragment>
                                          );
                                      }
                                  },
                              )
                            : null}
                        <Link href="/promotions">View All</Link>
                    </Stack>
                );
            })}
        </>
    );
}
