import { Cancel, KeyboardArrowUp } from '@mui/icons-material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
    ClickAwayListener,
    Grid,
    IconButton,
    Popover,
    Typography,
    styled,
} from '@mui/material';
import Link from '@mui/material/Link';
import { Box } from '@mui/system';
import document from 'global/document';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { SiteContext } from '../../../../../../Contexts/SiteProvider';
import theme from '../../../../../../Theme';
import { getEnvironmentVariable } from '../../../../../../config';
import { Maybe, MenuItem } from '../../../../../../types';
import { BBQGuysImageCard } from '../../../../../_base/BBQGuysImageCard';
import ScContainer from '../../../../ScContainer/ScContainer';

const MainMenuButton = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textTransform: 'none',
    verticalAlign: 'baseline',
    lineHeight: 1.2,
    height: '38px',
    padding: '.5rem .5rem 0 0',
    textAlign: 'center',
    overflow: 'hidden',
    '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline',
    },
}));
const MenuLink = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    display: 'block',
    position: 'relative',
    '&> svg': {
        fill: theme.palette.primary.main,
        verticalAlign: 'text-bottom',
        position: 'absolute',
        height: 24,
        transform: 'scale(1.3)',
        marginLeft: 4,
        transition: 'margin .1s ease-in',
    },
    ':hover': {
        '&> svg': {
            marginLeft: 6,
        },
    },
}));

const FeaturedDept = (props: {
    text: string;
    image: Maybe<string> | undefined;
    href: Maybe<string> | undefined;
}) => {
    const { text, image, href } = props;
    return (
        <Box className="menu-feat-dept">
            <BBQGuysImageCard
                url={href as string}
                image={image ?? getEnvironmentVariable('NO_IMAGE_URL')}
                width={150}
                title={text}
                withBorder={false}
            />
        </Box>
    );
};

const FloatingMenuLinks = (props: {
    item: Maybe<MenuItem>;
    level?: number;
}) => {
    const { level = 2 } = props;
    const hasChildren =
        props.item && props.item.children && props.item.children.length > 0;
    return props.item ? (
        <>
            {level === 2 ? (
                (hasChildren || level == 2) && props.item.href ? (
                    <MenuLink
                        href={props.item.href as string}
                        variant={'mainMenuLevel2'}
                    >
                        {props.item.text}
                        {hasChildren && props.item.href && (
                            <KeyboardArrowRight color="primary" />
                        )}
                    </MenuLink>
                ) : (
                    <Typography variant={'mainMenuLevel2'} component="div">
                        {props.item.text}
                    </Typography>
                )
            ) : level === 3 ? (
                <MenuLink
                    href={props.item.href as string}
                    variant={'mainMenuLevel3'}
                >
                    {props.item.text}
                </MenuLink>
            ) : null}
            {hasChildren ? (
                <>
                    {props.item.children.map((childItem: any, i: number) => {
                        return (
                            <FloatingMenuLinks
                                key={i}
                                item={childItem}
                                level={level + 1}
                            />
                        );
                    })}
                </>
            ) : null}
        </>
    ) : null;
};
const getFeatured = (
    data: Maybe<MenuItem>[],
    featItems: MenuItem[] = [],
    greaterThan = 0,
) => {
    if (data === null || data === undefined) return [];
    data.forEach((mi: Maybe<MenuItem>, i: number) => {
        if (mi) {
            if (mi.featItem) {
                const id = parseInt(mi.featItem ?? '0');
                if (id > greaterThan) {
                    featItems[id] = mi;
                }
            }
            if (mi.children !== undefined && mi.children !== null) {
                featItems = getFeatured(mi.children, featItems, greaterThan);
            }
        }
    });
    return featItems;
};

const isValidFeaturedImage = (img: string | null | undefined) => {
    return (
        img &&
        typeof img === 'string' &&
        img.length > 10 &&
        img.indexOf(getEnvironmentVariable('NO_IMAGE_URL'))
    );
};
const FeaturedDeptRow = (props: { items: Maybe<MenuItem>[] }) => {
    const MAX_DEPTS = 5;
    const featDepts = getFeatured(props.items, [], 0);
    if (featDepts.length > MAX_DEPTS) {
        props.items.forEach((item: Maybe<MenuItem>, i: number) => {
            if (item === null) return;
            if (featDepts.length > MAX_DEPTS) return;
            const id = parseInt(item?.featItem ?? '0');

            if (
                id > 0 &&
                featDepts[id] === undefined &&
                isValidFeaturedImage(item.featImage)
            ) {
                featDepts[id] = item;
            } else {
                if (
                    featDepts[i] === undefined &&
                    isValidFeaturedImage(item.featImage)
                ) {
                    featDepts[i] = item;
                }
            }

            item.children.forEach((item2: Maybe<MenuItem>, i2: number) => {
                if (item2 === null) return;
                if (featDepts.length == MAX_DEPTS) return;
                if (
                    featDepts[i2] === undefined &&
                    isValidFeaturedImage(item2.featImage)
                ) {
                    featDepts[i2] = item2;
                }
            });
        });
    }

    return featDepts.length > 0 ? (
        <>
            <Box
                sx={{
                    display: 'grid',
                    gap: 3,
                    gridTemplateColumns:
                        featDepts.filter((v, i) => v !== null).length === 4
                            ? '1fr 1fr 1fr 1fr'
                            : '1fr 1fr 1fr 1fr 1fr',
                    overflow: 'hidden',
                }}
            >
                {featDepts.map((item: MenuItem, i: number) => {
                    return item !== null ? (
                        <React.Fragment key={'menu-item-' + item.id}>
                            <FeaturedDept
                                text={item.text}
                                image={item.featImage}
                                href={item.href}
                            />
                        </React.Fragment>
                    ) : null;
                })}
            </Box>
            <hr />
        </>
    ) : null;
};

const ChildMenuItems = (props: { items: MenuItem[] }) => {
    const { items } = props;
    const menuColumns: { [key: string]: MenuItem[] } = {};
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (menuColumns[item.menuColumn] === undefined) {
            menuColumns[item.menuColumn] = [];
        }
        menuColumns[item.menuColumn].push(item);
    }
    const COL_COUNT = Object.keys(menuColumns).length;
    return (
        <Grid container className="menu-links-container">
            {Object.values(menuColumns).map(
                (menuColItems: MenuItem[], i: number) => {
                    return (
                        <Grid
                            key={i}
                            item
                            sx={{ flexBasis: 100 / COL_COUNT + '%' }}
                        >
                            {menuColItems.map(
                                (childItem: Maybe<MenuItem>, n: number) => {
                                    return (
                                        <Box key={n} className="menu-links">
                                            <FloatingMenuLinks
                                                item={childItem}
                                                key={n}
                                            />
                                            {childItem &&
                                                childItem.children &&
                                                childItem.children.length > 0 &&
                                                childItem.href &&
                                                childItem?.shopAllText ===
                                                    'View All' && (
                                                    <MenuLink
                                                        href={
                                                            childItem?.href as string
                                                        }
                                                        variant={
                                                            'mainMenuLevel3'
                                                        }
                                                    >
                                                        {childItem?.shopAllText}
                                                        <KeyboardArrowRight color="primary" />
                                                    </MenuLink>
                                                )}
                                        </Box>
                                    );
                                },
                            )}
                        </Grid>
                    );
                },
            )}
        </Grid>
    );
};

const FloatingMenu = (props: { item: MenuItem }) => {
    const { item } = props;
    return item && item.children.length > 0 ? (
        <>
            <MenuLink
                href={item.href ?? ''}
                sx={{ my: 1, display: 'inline-block' }}
                data-menu-id={item.text}
            >
                <Typography
                    variant="bold"
                    className="menu-title"
                    component="span"
                >
                    {item.text}
                </Typography>
                <Typography component="span" sx={{ ml: 1 }}>
                    <KeyboardArrowRight
                        color="primary"
                        sx={{
                            verticalAlign: 'middle',
                            transform: 'scale(1.3)',
                            width: 16,
                        }}
                    />
                </Typography>
            </MenuLink>
            <FeaturedDeptRow items={item.children} />
            <ChildMenuItems items={item.children as MenuItem[]} />
        </>
    ) : null;
};

function DesktopMenu() {
    const { mainMenu } = React.useContext(SiteContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [menuTarget, setMenuTarget] = React.useState<null | HTMLElement>(
        null,
    );
    const [selectedItem, setSelectedItem] = React.useState<null | MenuItem>(
        null,
    );
    const MAIN_MENU = document.getElementById('main-menu') ?? null;
    const HEADER_CONTAINER =
        document.getElementById('header-container') ?? null;

    const HEADER_HEIGHT =
        typeof document !== 'undefined' && document.querySelector
            ? document.querySelector('.promo-bar')?.getBoundingClientRect()
                  ?.top ??
              HEADER_CONTAINER?.offsetHeight ??
              0
            : 0;

    const SEARCH_BAR = document.getElementById('search-bar') ?? null;

    const MY_ACCOUNT_BTN = document.getElementById('my-account-btn') ?? null;

    const open = Boolean(anchorEl);

    const location = useLocation();

    React.useEffect(() => {
        handleClose();
    }, [location]);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as HTMLElement;
            const clickSearchBar = SEARCH_BAR?.contains(target);
            const myAccountBtn = MY_ACCOUNT_BTN?.contains(target);

            if (clickSearchBar || myAccountBtn) {
                handleClose();
            } else if (
                open &&
                !MAIN_MENU?.contains(target) &&
                HEADER_CONTAINER.contains(target)
            ) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, MAIN_MENU, HEADER_CONTAINER, SEARCH_BAR, MY_ACCOUNT_BTN]);

    const handleClick = (
        event:
            | React.MouseEvent<HTMLButtonElement>
            | React.KeyboardEvent<HTMLButtonElement>,
        item: MenuItem,
    ) => {
        setMenuTarget(event.currentTarget);
        setAnchorEl(event.currentTarget.parentNode?.parentElement || null);
        if (selectedItem !== null && selectedItem?.id === item.id) {
            handleClose();
        } else {
            setSelectedItem(item);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
        setSelectedItem(null);
        // handle some ADA with tabbing
        if (
            menuTarget &&
            menuTarget.parentElement !== null &&
            menuTarget.nodeName === 'BUTTON'
        ) {
            setTimeout(() => {
                const menuItemButton = document.querySelector(
                    '[data-menu-id="' +
                        menuTarget.parentElement?.getAttribute('data-menu-id') +
                        '"] > button',
                );
                if (menuItemButton) {
                    menuItemButton.focus(); // focus on nav element for ADA
                }
                setMenuTarget(null);
            }, 1);
        } else if (menuTarget && menuTarget.nodeName === 'A') {
            // ADA if navigating in a link, focus on the main document after change
            setTimeout(() => {
                window.focus();
                setMenuTarget(null);
            }, 1);
        }
    };

    const MainMenuButtonLink = React.forwardRef(function MainMenuButtonLink(
        props: any,
        ref,
    ) {
        if (props.href === undefined) {
            return <MainMenuButton {...props} />;
        }
        return <Link {...props} />;
    });

    const DesktopMenuLink = (props: {
        item: MenuItem;
        popoverAnchorElement: null | HTMLElement;
        popoverOpen: boolean;
    }) => {
        const hasChildItems = props.item.children.length > 0;
        const isOpenMenu =
            open && selectedItem !== null && selectedItem.id === props.item.id;
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <MainMenuButtonLink
                    className={
                        'menu-item' +
                        (isOpenMenu ? ' active' : '') +
                        (props.item.cssClasses &&
                        props.item.cssClasses?.length > 0
                            ? ' ' + props.item.cssClasses
                            : '')
                    }
                    data-menu-id={props.item.text}
                    tabIndex={0}
                    href={
                        !hasChildItems && props.item.href
                            ? props.item.href
                            : undefined
                    }
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        if (hasChildItems) {
                            handleClick(e, props.item);
                        } else {
                            setMenuTarget(e.currentTarget);
                        }
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
                        if (e.key === 'Enter' && hasChildItems) {
                            handleClick(e, props.item);
                        }
                    }}
                >
                    {props.item.text}
                    {hasChildItems ? (
                        isOpenMenu ? (
                            <KeyboardArrowUp
                                color="primary"
                                style={{ verticalAlign: 'bottom' }}
                            />
                        ) : (
                            <KeyboardArrowDown
                                style={{ verticalAlign: 'bottom' }}
                            />
                        )
                    ) : null}
                </MainMenuButtonLink>
            </ClickAwayListener>
        );
    };
    return (
        <ScContainer
            containerProps={{
                id: 'main-menu',
                'aria-label': 'main menu navigation',
                component: 'nav',
            }}
            sx={{
                padding: 0,
                wordBreak: 'break-all',
                position: 'relative',
                overflow: 'hidden',
                zIndex: 9,
                height: '2.4rem',
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                gap: '0.1rem',
                a: {
                    color: theme.palette.text.primary,
                },
            }}
        >
            {mainMenu.map((item: MenuItem, i: number) => {
                return (
                    <DesktopMenuLink
                        item={item}
                        key={i}
                        popoverAnchorElement={anchorEl}
                        popoverOpen={open}
                    />
                );
            })}
            <Popover
                open={open}
                onClose={handleClose}
                className="menu-item-popover"
                elevation={0}
                slotProps={{
                    paper: {
                        square: true,
                        style:
                            selectedItem?.href === '/promotions'
                                ? {
                                      left: 'auto',
                                      right: 0,
                                      paddingLeft: theme.spacing(2),
                                      paddingRight: theme.spacing(2),
                                  }
                                : selectedItem?.href === '/brands'
                                  ? {
                                        left: 'auto',
                                        right: 0,
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                    }
                                  : {
                                        left: 0,
                                        right: 'auto',
                                        paddingLeft: theme.spacing(2),
                                        paddingRight: theme.spacing(2),
                                    },
                    },
                }}
                sx={{
                    minWidth: '700px',
                    maxWidth: '1500px',
                    marginTop: HEADER_HEIGHT + 'px',
                    marginLeft: (MAIN_MENU?.offsetLeft ?? 0) + 'px',
                    borderRadius: 0,
                    borderTop: 0,
                    zIndex: 80000000000, // needs to be higher than paypal button in cart
                }}
                BackdropProps={{
                    sx: {
                        // background: 'transparent',
                        marginTop: HEADER_HEIGHT + 'px',
                        background: 'rgba(0, 0, 0, 0.5)',
                    },
                }}
                anchorReference="none"
                // anchorEl={anchorEl}
                // anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                // anchorPosition={{left: ((MAIN_MENU?.offsetLeft ?? 16) -  16), top: (HEADER_HEIGHT-16)  }}
                transitionDuration={0}
            >
                <ScContainer
                    className="menu-item-container"
                    containerProps={{
                        'aria-label': selectedItem?.text + ' menu links',
                    }}
                >
                    {selectedItem && <FloatingMenu item={selectedItem} />}
                    {/* placing close button at bottom of popover for screen readers so they read it last instead of first */}
                    <IconButton
                        className="ada-only"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: theme.spacing(2),
                            top: theme.spacing(0.5),
                        }}
                        aria-label="Close Menu"
                    >
                        <Cancel color="primary" />
                    </IconButton>
                </ScContainer>
            </Popover>
        </ScContainer>
    );
}
export default function DesktopMegaMenu(props: any) {
    return <DesktopMenu />;
}
