import { gql } from '@apollo/client';

export const PROMOTIONS_FIELDS = gql`
    fragment PromotionFields on Promotion {
        id
        title
        shortTitle
        description
        type
        displayType
        dateStart
        dateEnd
        displayText
        displayTextColor
        showCountdown
        imageUrl
        url
    }
`;

export const PAGINATED_ITEMS_META_DATA_FIELDS = gql`
    fragment PaginatedItemsMetaDataFields on PaginatedItemsResponseMetaData {
        requestType
        queryTime
        redirectUrl
        searchTerm
        searchTypeId
        trackingId
        moreOptionsRenderStyle
        finderType
        groupingName
        defaultSort
    }
`;

export const FACET_ATTRIBUES = gql`
    fragment FacetAttributeFields on Attribute {
        label
        labelId
        checked
        selectable
        evar
        url
        parameter
        count
    }
`;
export const FACET_FIELDS = gql`
    ${FACET_ATTRIBUES}
    fragment FacetFields on Facet {
        name
        helpContentId
        attributes {
            ...FacetAttributeFields
        }
    }
`;

export const SELECTED_ATTRIBUTE_DATA_FIELDS = gql`
    ${FACET_ATTRIBUES}
    fragment SelectedAttributeDataFields on SelectedAttribute {
        facetLabel
        attributeLabel
        attribute {
            ...FacetAttributeFields
        }
    }
`;

export const ITEM_FIELDS = gql`
    ${PROMOTIONS_FIELDS}
    fragment ItemFields on Item {
        id
        name
        category
        manufacturerName
        manufacturerUrl
        modelNumber
        openBoxCount
        fuelType
        qtyAvailable
        qtyMax
        hideStockQty
        isDirectShip
        stockStatus
        shippingDescription
        bulletPoints
        showProLogo
        pricingStrategy
        optionsAvailableCount
        hasExtendedWarranty
        optionsAvailableDisplayType
        optionsAvailable {
            title
            type
            hexCode
            iconUrl
            itemId
        }
        pricing {
            type
            current
            retail
            sale
            list
            unit
            financing
            savings
            shipping
            openBox
            installation
        }
        pricingFormatted {
            type
            current
            retail
            list
            sale
            unit
            financing
            savings
            shipping
            savingsPercent
            openBox
            installation
        }
        imageUrl
        url
        shipsIn
        isKit
        inStock
        isFreeShipping
        isQuickShip
        isFreight
        isCallForPricing
        hasCustomPricing
        has360Image
        hasVideo
        isAvailable
        hasInstallationAvailable
        userReviewsCount
        userReviewsRating
        promotions {
            ...PromotionFields
        }
        pricingCustom {
            label
            value
        }
        description
        videoUrl
        warrantyInfo
        prop65
        moreOptionsAvailable
    }
`;

export const DEFERRED_PAYMENT_FIELDS = gql`
    fragment PaymentDeferredFields on PaymentDeferred {
        orderId
        token
        deferredType
        amount
    }
`;

export const ORDER_SUMMARY_TOTAL_FIELDS = gql`
    fragment OrderSummaryTotalFields on OrderPricing {
        subtotal
        shipping
        priceWithTax
        proSavings
        tax
        taxLines {
            name
            amount
        }
        taxRate
        discounts {
            coupons {
                code
                amount
            }
            giftCards {
                code
                amount
            }
            rewardPoints {
                code
                amount
            }
        }
        rewardPointsAvailable {
            inPoints {
                available
                applied
                earnable
                cost
                needed
            }
            inDollars {
                available
                applied
                earnable
                cost
                needed
            }
        }
        total
        totalOwedWithoutDeferred
        totalOwed @client
    }
`;

export const ORDER_SUMMARY_TOTAL_FORMTATTED_FIELDS = gql`
    fragment OrderSummaryTotalFormattedFields on OrderPricingFormatted {
        subtotal
        shipping
        proSavings
        tax
        taxLines {
            name
            amount
        }
        discounts {
            coupons {
                code
                amount
            }
            giftCards {
                code
                amount
            }
            rewardPoints {
                code
                amount
            }
        }
        rewardPointsAvailable {
            inPoints {
                available
                applied
                earnable
                cost
                needed
            }
            inDollars {
                available
                applied
                earnable
                cost
                needed
            }
        }
        total
        totalOwedWithoutDeferred
        totalOwed @client
    }
`;
export const ORDER_ITEM_FIELDS = gql`
    ${ITEM_FIELDS}
    fragment OrderItemFields on OrderItem {
        id
        itemId
        tdiId
        pricing {
            price
            unitPrice
            unitPriceWithTax
            shipping
            tax
            taxRate
        }
        pricingFormatted {
            price
            unitPrice
            shipping
            tax
        }
        qty
        qtyShipped
        name
        item {
            ...ItemFields
        }
        isChildItem
        isParentItem
        hasLinkedWarranty
        canBeRemovedFromOrder
        hasCartLink
        canAddWarrantyToItem
    }
`;

export const CUSTOMER_ORDER_ITEM_FIELDS = gql`
    ${ORDER_ITEM_FIELDS}
    fragment CustomerOrderItemFields on OrderItem {
        ...OrderItemFields
        status
        dateAdded
        deliveryDateRange
        trackingUrl
    }
`;

export const BANNER_FIELDS = gql`
    fragment BannerFields on Banner {
        id
        name
        href
        image
        imageMobile
        altText
        imageWidth
        imageHeight
        imageWidthMobile
        imageHeightMobile
        setTitle
    }
`;
export const BANNER_CAMPAIGN_FIELDS = gql`
    ${BANNER_FIELDS}
    fragment BannerCampaignFields on BannerCampaign {
        id
        name
        action
        actionId
        isDefault
        layout
        schedule {
            id
            start
            end
        }
        banners {
            ...BannerFields
        }
    }
`;
export const BASIC_ORDER_FIELDS = gql`
    ${ITEM_FIELDS}
    ${ORDER_SUMMARY_TOTAL_FIELDS}
    ${ORDER_SUMMARY_TOTAL_FORMTATTED_FIELDS}
    ${ORDER_ITEM_FIELDS}
    ${DEFERRED_PAYMENT_FIELDS}
    fragment BasicOrderFields on Order {
        id
        orderNumber
        orderTotal
        orderStatus
        isProOrder
        editable
        estimatedOrderShipDate
        paymentMethod
        pricing {
            ...OrderSummaryTotalFields
        }
        pricingFormatted {
            ...OrderSummaryTotalFormattedFields
        }
        orderItems {
            ...OrderItemFields
        }
        cartVerificationToken
        paymentDeferreds @client
        serverSidePaymentDeferreds {
            ...PaymentDeferredFields
        }
        totalOrders
    }
`;
export const CUSTOMER_ORDER_FIELDS = gql`
    ${ITEM_FIELDS}
    ${ORDER_SUMMARY_TOTAL_FIELDS}
    ${ORDER_SUMMARY_TOTAL_FORMTATTED_FIELDS}
    ${CUSTOMER_ORDER_ITEM_FIELDS}
    ${DEFERRED_PAYMENT_FIELDS}
    fragment CustomerOrderFields on Order {
        id
        orderNumber
        orderTotal
        orderStatus
        orderDate
        estimatedOrderShipDate
        paymentMethod
        isProOrder
        pricing {
            ...OrderSummaryTotalFields
        }
        pricingFormatted {
            ...OrderSummaryTotalFormattedFields
        }
        orderItems {
            ...CustomerOrderItemFields
        }
        paymentDeferreds @client
        serverSidePaymentDeferreds {
            ...PaymentDeferredFields
        }
        totalOrders
    }
`;

export const NORMALIZED_ORDER_DATA_FIELDS = gql`
    fragment NormalizedOrderDataFields on NormalizedOrderData {
        email
        country
        phone
        firstName
        lastName
        city
        state
        zip
    }
`;

export const CONTACT_FIELDS = gql`
    fragment ContactFields on Contact {
        company
        firstName
        lastName
        title
        address1
        address2
        city
        state
        country
        zip
        phone
        email
        hashedEmail
        fax
    }
`;
export const CONTACT_INFO_ORDER_FIELDS = gql`
    ${BASIC_ORDER_FIELDS}
    ${CONTACT_FIELDS}
    ${NORMALIZED_ORDER_DATA_FIELDS}
    fragment ContactInfoOrderFields on Order {
        ...BasicOrderFields
        orderDate
        normalizedOrderData {
            ...NormalizedOrderDataFields
        }
        deliveryDateRange
        billingAddress {
            ...ContactFields
        }
        shippingAddress {
            ...ContactFields
        }
        totalOrders
    }
`;

export const WISHLIST_FIELDS = gql`
    ${ITEM_FIELDS}
    fragment WishlistFields on Wishlist {
        id
        title
        username
        isPublic
        items {
            id
            qty
            item {
                ...ItemFields
            }
        }
    }
`;
export const PAGINATED_ITEMS = gql`
    ${ITEM_FIELDS}
    ${PAGINATED_ITEMS_META_DATA_FIELDS}
    ${SELECTED_ATTRIBUTE_DATA_FIELDS}
    fragment PaginatedItems on PaginatedItemsResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        metaData {
            ...PaginatedItemsMetaDataFields
        }
        results {
            ...ItemFields
        }
        checkedFacets {
            ...SelectedAttributeDataFields
        }
    }
`;

export const PAGE_INFO = gql`
    fragment PageInfo on Page {
        id
        statusCode
        title
        url
        action
        actionId
        meta {
            name
            content
            property
        }
        headers
        digitalData
        canonical
    }
`;

export const EXPERT_REVIEW_CLASS_FIELD_FIELDS = gql`
    fragment ExpertReviewClassFieldFields on ExpertReviewField {
        id
        name
        rating
        rank
        contentId
    }
`;
export const EXPERT_REVIEW_ITEM_INFO = gql`
    ${EXPERT_REVIEW_CLASS_FIELD_FIELDS}
    ${ITEM_FIELDS}
    fragment ExpertReviewItemInfo on ExpertReviewItem {
        title
        item {
            ...ItemFields
        }
        rating
        ratingDescription
        imageUrl
        summary
        contentSchema
        url
        fields {
            ...ExpertReviewClassFieldFields
        }
    }
`;
export const PAGINATED_EXPERT_REVIEW_ITEMS = gql`
    ${EXPERT_REVIEW_ITEM_INFO}
    fragment PaginatedExpertReviewItems on PaginatedExpertReviewItemResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...ExpertReviewItemInfo
        }
    }
`;

export const CONTENT_FIELDS = gql`
    fragment ContentFields on Content {
        id
        title
        source
        description
        designSystem
        imageUrl
        url
        intro
        body
        template
        scripts {
            loadType
            type
            data
            hash
        }
        stylesheets
        author {
            name
        }
    }
`;
export const EXPERT_REVIEW_CLASS_FIELDS = gql`
    ${CONTENT_FIELDS}
    ${EXPERT_REVIEW_CLASS_FIELD_FIELDS}
    fragment ExpertReviewClassFields on ExpertReviewClass {
        id
        name
        rating
        ratingDescription
        imageUrl
        videoUrl
        videoImageUrl
        url
        type
        content {
            ...ContentFields
        }
        parentClass {
            id
            name
            url
        }
        fields {
            ...ExpertReviewClassFieldFields
        }
    }
`;

export const PAGINATED_EXPERT_REVIEW_CLASSES = gql`
    ${EXPERT_REVIEW_CLASS_FIELDS}
    fragment PaginatedExpertReviewClasses on PaginatedExpertReviewClassesResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...ExpertReviewClassFields
        }
    }
`;
export const CUSTOMER_REVIEW_FIELDS = gql`
    fragment CustomerReviewFields on CustomerReview {
        id
        status
        countHelpful
        datetime
        countUnhelpful
        source
        title
        rating
        text
        name
        location
        buyerStatus
        merchantResponse
        isSyndicated
        syndicationSource
        syndicationLink
        syndicationImage
        userId
        media {
            id
            sourceId
            type
            url
            thumbnailUrl
            caption
        }
    }
`;

export const BASIC_USER_FIELDS = gql`
    fragment BasicUserFields on User {
        id
        firstName
        lastName
        name
        email
        hashedEmail
        birthday
        isPro
        pricingTierId
        isEmployee
        pastCustomer
        actValue
        proAccountRep {
            id
            name
            email
            phone
            photo
        }
        rewardPoints {
            number
            available
            cashValue
        }
    }
`;

export const MENU_ITEM_FIELDS = gql`
    fragment MenuItemFields on MenuItem {
        id
        text
        href
        cssClasses
        featItem
        featImage
        menuColumn
        shopAllText
    }
`;

export const DEPARTMENT_FIELDS = gql`
    fragment DepartmentFields on Department {
        id
        title
        url
        description
        imageUrl
        imageType
        useSearchResults
    }
`;
export const PAGINATED_DEPARTMENTS = gql`
    ${DEPARTMENT_FIELDS}
    fragment PaginatedDepartments on PaginatedDepartmentsResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...DepartmentFields
        }
    }
`;
export const PAGINATED_DEPARTMENTS_WITH_CHILDREN = gql`
    ${DEPARTMENT_FIELDS}
    fragment PaginatedDepartmentsWithChildren on PaginatedDepartmentsResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...DepartmentFields
            childDepartments {
                ...DepartmentFields
                childDepartments {
                    ...DepartmentFields
                }
            }
        }
    }
`;
export const PAGINATED_DEPARTMENTS_COLLECTIONS = gql`
    ${DEPARTMENT_FIELDS}
    fragment PaginatedDepartmentCollections on PaginatedDepartmentCollectionsResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            name
            departments {
                ...DepartmentFields
            }
        }
    }
`;

export const BRAND_FIELDS = gql`
    fragment BrandFields on Brand {
        id
        name
        shortName
        deptId
        description
        about
        logoUrl
        videoUrl
        url
        videoImageUrl
        shopAllUrl
        imageUrl
        features
    }
`;
export const PAGINATED_BRANDS = gql`
    ${BRAND_FIELDS}
    fragment PaginatedBrands on PaginatedBrandsResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...BrandFields
        }
    }
`;

export const BRANDSCOUNT_FIELDS = gql`
    fragment BrandsCountFields on BrandsCount {
        id
        name
        url
    }
`;
export const PAGINATED_BRANDSCOUNT = gql`
    ${BRANDSCOUNT_FIELDS}
    fragment PaginatedBrandsCount on PaginatedBrandsCountResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...BrandsCountFields
        }
    }
`;

export const PAGINATED_LEARNING_CENTER = gql`
    fragment PaginatedLearningCenter on PaginatedLearningCenterLinks {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            title
            url
            imageUrl
            description
        }
    }
`;

export const PAGINATED_PROMOTIONS = gql`
    ${PROMOTIONS_FIELDS}
    fragment PaginatedPromotions on PaginatedPromotionsResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...PromotionFields
        }
    }
`;
export const EXTENDED_WARRANTY = gql`
    ${ITEM_FIELDS}
    fragment ExtendedWarrantyFields on ExtendedWarranty {
        title
        planId
        category
        imageUrl
        highlights
        contentId
        items {
            ...ItemFields
            warrantyLengthMonths
        }
    }
`;
export const PAGINATED_EXTENDED_WARRANTIES = gql`
    ${EXTENDED_WARRANTY}
    fragment PaginatedExtendedWarranties on PaginatedExtendedWarrantyResponse {
        currentPage
        prevPage
        nextPage
        countResults
        totalResults
        totalPages
        results {
            ...ExtendedWarrantyFields
        }
    }
`;

export const SAVED_PAYMENT_METHOD_FIELDS = gql`
    fragment SavedPaymentMethodFields on SavedPaymentMethod {
        type
        typeId
        token
        title
        paymentMethodKey
        isDefault
        isExpired
        isVerified
        last4
        cardType
        expirationDate
        maskedNumber
        userId
        dateAdded
        imageUrl
    }
`;
