import { Box, Divider, Link, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import * as React from 'react';
import StarRatings from '../../Components/StarRatings/StarRatings';
import { BBQGuysGrid } from '../../Components/_base/BBQGuysGrid';
import { BBQGuysImageCard } from '../../Components/_base/BBQGuysImageCard';
import theme, { PRIMARY_FONT_FAMILY, SECONDARY_FONT_FAMILY } from '../../Theme';
import { ExpertReviewField, ExpertReviewItem, Maybe } from '../../types';

const EXPERT_REVIEW_BACKGROUND_COLOR = '#307140';

const ExpertReviewRatingBox = styled(Box)(() => ({
    textAlign: 'center',
    position: 'absolute',
    top: '0.25rem',
    right: '0.25rem',
    backgroundColor: EXPERT_REVIEW_BACKGROUND_COLOR,
    color: 'white',
    padding: 5,
    minWidth: 75,
    minHeight: 65,
    fontFamily: PRIMARY_FONT_FAMILY,
    fontSize: theme.typography.h4.fontSize,
}));

const ExpertReviewRatingTextBox = styled(Box)(({ theme }) => ({
    color: 'white',
    fontFamily: SECONDARY_FONT_FAMILY,
    fontSize: '12px',
    lineHeight: '1.25',
}));

export function ExpertReviewItemLoading(props: { count?: number }) {
    const { count = 4 } = props;

    return (
        <>
            {[...Array(count)].map((z: unknown, i: number) => {
                return (
                    <React.Fragment key={i}>
                        <Skeleton
                            variant="rectangular"
                            width="120px"
                            height="120px"
                        />
                        <Skeleton variant="text" width="120px" />
                    </React.Fragment>
                );
            })}
        </>
    );
}

export function ExpertReviewItems(props: {
    expertReviews: ExpertReviewItem[];
    withSummary?: boolean;
    showRatingForField?: string;
}) {
    const {
        expertReviews,
        withSummary = false,
        showRatingForField = '',
    } = props;
    return (
        <BBQGuysGrid withBorder>
            {expertReviews.map((exp: ExpertReviewItem, i: number) => {
                return (
                    <ExpertReviewCard
                        key={'exp-card-' + exp.url}
                        expertReview={exp}
                        withBorder={false}
                        withSummary={withSummary}
                        showRatingForField={showRatingForField}
                    />
                );
            })}
        </BBQGuysGrid>
    );
}

const ExpertReviewCardSummary = ({
    expertReview,
}: {
    expertReview: ExpertReviewItem;
}) => {
    return (
        <Box
            sx={{
                pr: '2.5rem',
                py: 2,
                '& > span > *:first-child, & .h6': {
                    paddingLeft: '1.5rem',
                },
            }}
        >
            <span dangerouslySetInnerHTML={{ __html: expertReview.summary }} />
            <Link sx={{ pl: 3 }} href={expertReview.url ?? '/expert-reviews'}>
                Read more...
            </Link>
        </Box>
    );
};

const ExpertReviewFieldRating = ({
    expertReviewItem,
    fieldName,
}: {
    expertReviewItem: ExpertReviewItem;
    fieldName: string;
}) => {
    const field = getFieldOfReview(expertReviewItem, fieldName);
    return field ? (
        <>
            <Divider />
            <Stack
                direction="row"
                gap={2}
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 2 }}
            >
                <Typography variant="bold">{field.name}</Typography>
                <StarRatings
                    rating={field.rating}
                    readOnly={true}
                    showCount={false}
                    size="small"
                />
            </Stack>
        </>
    ) : (
        <></>
    );
};

const getFieldOfReview = (
    expertReviewItem: ExpertReviewItem,
    field: string,
) => {
    const foundField = expertReviewItem.fields.filter(
        (f: Maybe<ExpertReviewField>, i: number) => f && f.name === field,
    );
    if (foundField) {
        return foundField[0];
    }
    return null;
};
const hasField = (expertReviewItem: ExpertReviewItem, field: string) => {
    const foundField = getFieldOfReview(expertReviewItem, field);
    return foundField !== null;
};

export function ExpertReviewCard(props: {
    expertReview: ExpertReviewItem;
    withBorder?: boolean;
    withSummary?: boolean;
    showRatingForField?: string;
}) {
    const {
        expertReview,
        withBorder = true,
        withSummary = false,
        showRatingForField = '',
    } = props;
    // const COLOR = EXPERT_REVIEW_COLORS.filter((v) => v.description === expertReview.ratingDescription)[0]?.color ?? "maroon";

    return (
        <BBQGuysImageCard
            url={expertReview.url ?? ''}
            image={expertReview.imageUrl}
            title={expertReview.title}
            withBorder={withBorder}
            cardProps={{ sx: { position: 'relative' } }}
        >
            <ExpertReviewRatingBox>
                {expertReview.rating}
                <ExpertReviewRatingTextBox>
                    {expertReview.ratingDescription}
                </ExpertReviewRatingTextBox>
            </ExpertReviewRatingBox>
            {showRatingForField &&
                hasField(expertReview, showRatingForField) && (
                    <ExpertReviewFieldRating
                        expertReviewItem={expertReview}
                        fieldName={showRatingForField ?? ''}
                    />
                )}
            {withSummary && (
                <ExpertReviewCardSummary expertReview={expertReview} />
            )}
        </BBQGuysImageCard>
    );
}
