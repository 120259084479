import { LoadingButton } from '@mui/lab';
import { Alert, Link } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { CartContext } from '../../../Contexts/CartProvider';
import { LoginResponse, UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    DesktopDevice,
    MobileAndTabletDevice,
} from '../../../Contexts/DeviceTypeProvider';
import { LoginSchema } from '../../../Schemas/Schemas';
import { EmailWithLabel } from '../../Account/PageEditMyAccount/Components/EmailWithLabel';
import { PasswordWithLabel } from '../../Account/PageEditMyAccount/Components/PasswordWithLabel';

interface LoginValues {
    loginEmail: string;
    loginPassword: string;
}

export const LoginForm = (props: {
    inline?: boolean;
    onComplete?: () => void;
    formProps?: Record<string, unknown>;
}) => {
    const {
        onComplete = () => {
            return;
        },
        formProps = {},
    } = props;
    const { login } = React.useContext(UserAuth);
    const { setOrder } = React.useContext(CartContext);
    const [errorMessage, setErrorMessage] = React.useState('');

    const formik = useFormik({
        initialValues: {
            loginEmail: '',
            loginPassword: '',
        },
        validationSchema: LoginSchema,
        onSubmit: (values: LoginValues, actions: any) => {
            actions.setSubmitting(true);
            login(values.loginEmail, values.loginPassword)
                .then((resp: LoginResponse) => {
                    setOrder(resp.order);
                    if (typeof onComplete === 'function') {
                        onComplete();
                    }
                })
                .catch(m => {
                    setErrorMessage(m);
                    actions.setSubmitting(false);
                });
        },
    });

    return (
        <FormikProvider value={formik}>
            <form
                onSubmit={formik.handleSubmit}
                {...formProps}
                autoComplete="on"
            >
                {errorMessage && errorMessage.length > 0 ? (
                    <Alert severity="error" sx={{ mb: 2 }}>
                        {errorMessage}
                    </Alert>
                ) : null}
                <EmailWithLabel
                    formik={formik}
                    id="loginEmail"
                    name="loginEmail"
                />
                <PasswordWithLabel
                    formik={formik}
                    id="loginPassword"
                    name="loginPassword"
                    label="Password"
                    current
                />
                <DesktopDevice>
                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 3fr"
                        gap={2}
                        pt={2}
                    >
                        <LoadingButton
                            className="btn-signin"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            loading={formik.isSubmitting}
                        >
                            Sign In
                        </LoadingButton>
                        <Link
                            className="forgot-password"
                            href="/forgot"
                            alignSelf="center"
                            textAlign="right"
                        >
                            Forgot your password?
                        </Link>
                    </Box>
                </DesktopDevice>
                <MobileAndTabletDevice>
                    <Stack spacing={3} pt={2}>
                        <LoadingButton
                            className="btn-signin"
                            type="submit"
                            variant="contained"
                            color="secondary"
                            loading={formik.isSubmitting}
                        >
                            Sign In
                        </LoadingButton>
                        <Link
                            className="forgot-password"
                            href="/forgot"
                            alignSelf="center"
                            textAlign="right"
                        >
                            Forgot your password?
                        </Link>
                    </Stack>
                </MobileAndTabletDevice>
            </form>
        </FormikProvider>
    );
};
