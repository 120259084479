import { CheckOutlined } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import React from 'react';
import { LoadingSpinner } from '../../../../../../Components/_base/BBQGuysComponents';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../../../Contexts/DeviceTypeProvider';
import theme from '../../../../../../Theme';
import { QuestionAndAnswersContext } from '../../../../../../Contexts/QuestionAndAnswersProvider';

const QuestionsAndAnswersHelpfulButton = (props: {
    answerId: number;
    onClick: () => void;
}) => {
    const { markHelpful } = React.useContext(QuestionAndAnswersContext);
    const [loading, setLoading] = React.useState(false);
    const handleClick = () => {
        props.onClick();
        setLoading(true);
        markHelpful(props.answerId, true).finally(() => {
            setLoading(false);
        });
    };

    return (
        <>
            <DesktopAndTabletDevice>
                <IconButton
                    onClick={() => handleClick()}
                    aria-label="Mark Helpful"
                    sx={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        border: `5px solid ${theme.palette.success.main}`,
                    }}
                >
                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <CheckOutlined
                            fontSize="large"
                            color="success"
                            sx={{
                                fontSize: 40,
                                '&:hover': {
                                    backgroundColor: 'rgba(34, 116, 54, .1)',
                                },
                            }}
                        />
                    )}
                </IconButton>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Button
                    onClick={() => handleClick()}
                    aria-label="Mark Helpful"
                    variant="link"
                    sx={{ fontSize: '0.875rem', paddingLeft: '0.5rem' }}
                >
                    Yes
                </Button>
            </MobileDevice>
        </>
    );
};

export default QuestionsAndAnswersHelpfulButton;
