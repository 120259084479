import { Typography } from '@mui/material';
import React from 'react';

const Asterisk: React.FC<{ required?: boolean }> = ({ required }) => {
    if (required) {
        return (
            <Typography
                component="span"
                color="error"
                variant="body2"
                sx={{ mr: 0.5, fontWeight: 700 }}
            >
                *
            </Typography>
        );
    }
    return null;
};

export default Asterisk;
