import { Helmet } from 'react-helmet-async';
import { AddProductSchema } from '../../../../Components/Meta/CreateSchemaOrg';
import { Item } from '../../../../types';
import { decodeHTMLEntities } from '../../../../functions';

const ItemPageHead = ({ item }: { item: Item }) => {
    const title = decodeHTMLEntities(item?.name ?? '');
    return (
        <>
            <AddProductSchema item={item} />
            <Helmet>
                <meta
                    property="product:brand"
                    content={item.manufacturerName}
                />
                <meta
                    property="product:original_price:amount"
                    content={`${item.pricing.retail}`}
                />
                <meta
                    property="product:original_price:currency"
                    content="USD"
                />
                <meta
                    property="product:price:amount"
                    content={`${item.pricing.current}`}
                />
                <meta property="product:price:currency" content="USD" />
                <meta property="product:weight:units" content="lbs" />
                <meta
                    property="product:availability"
                    content={item.inStock ? 'instock' : 'outofstock'}
                />
                <title>{title}</title>
            </Helmet>
        </>
    );
};

export default ItemPageHead;
