import { Card, Skeleton } from '@mui/material';
import { BBQGuysGrid } from '../../Components/_base/BBQGuysGrid';

export const PromotionCardLoading = () => {
    return (
        <BBQGuysGrid>
            {[...Array(12)].map((v: unknown, i: number) => {
                return (
                    <Card
                        sx={{ height: 500, p: 1 }}
                        key={'promo-card-loading-' + i}
                    >
                        <Skeleton variant="rectangular" height={250} />
                        <Skeleton variant="text" width="100%" height={'2rem'} />
                        <Skeleton variant="text" width="100%" height={'2rem'} />
                        <br />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="50%" />
                    </Card>
                );
            })}
        </BBQGuysGrid>
    );
};
