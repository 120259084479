import { useQuery } from '@apollo/client';
import { ThreeSixty } from '@mui/icons-material';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import window from 'global/window';
import * as React from 'react';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { QL_ITEM_360_IMAGES } from '../../Graphql/queries';
import { Image360Type, ImageType, Maybe } from '../../types';
import { LoadingSpinner } from '../_base/BBQGuysComponents';
import Modal from '../_base/BBQGuysModal';
import { ImageGalleryButton } from '../_base/BBQGuysStyledComponents';
import { Icon360 } from '../_base/icons/icons';

let FRAMES_IN_SET = 24;

const getImagesManafest = (images: Image360Type[]) => {
    let imgUrlList: string[] = [];
    // make one big list of URLs and discard any sets that don't have the correct # of images
    for (const k in images) {
        if (images[k].images && images[k].images.length % FRAMES_IN_SET === 0) {
            images[k].images.map((img: Maybe<ImageType>) => {
                if (img) {
                    imgUrlList = imgUrlList.concat(img.url);
                }
            });
        }
    }
    return imgUrlList;
};

const Image360Viewer = ({
    images,
    onLoaded,
}: {
    images: Image360Type[];
    onLoaded: () => void;
}) => {
    if (images[0]) {
        FRAMES_IN_SET = Math.min(images[0].images.length, FRAMES_IN_SET);
    }
    const imgArray = getImagesManafest(images);
    const numLanes = Math.floor(imgArray.length / FRAMES_IN_SET);
    if (window !== 'undefined' && images.length > 0) {
        // need to dynamically load this for SSR to work
        import('spritespin').then(SpriteSpin => {
            // Do spinner setup+init
            // lanes - The number of separate image sequences contained in this 360Image. (normal, lid open, lights on, etc)
            // frames - The number of frames in each lane.
            // sense - Horizontal input sensitivity that also controls direction of spin. Negative numbers can be used to reverse spin direction.
            // senseLane - Vertical sensitivity for when to change lanes. Larger the number, the more distance needs to be traversed before lane change.
            // frameTime - The amount of time between frames when in "autospin" mode. Larger values mean slower rotation.
            SpriteSpin.createOrUpdate({
                target: '#image-360-container',
                source: imgArray,
                animate: true,
                loop: false,
                lanes: numLanes,
                width: 800,
                height: 800,
                frames: FRAMES_IN_SET,
                sense: 1,
                frameTime: 100,
                fillMode: 'contain',
                onLoad: () => {
                    onLoaded();
                },
            });
        });
    }
    return (
        <>
            <Box
                id="image-360-container"
                sx={{
                    '&> .spritespin-stage': {
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        '&> canvas': {
                            position: 'relative !important',
                            margin: '0 auto !important',
                            cursor: 'grab',
                            '&:active': {
                                cursor: 'grabbing',
                            },
                        },
                    },
                }}
            />
        </>
    );
};

const Image360Modal = (props: { itemId: string; onClose: () => void }) => {
    const { itemId, onClose } = props;
    const [loadingImages, setLoadingImages] = React.useState(true);
    const { data, loading } = useQuery(QL_ITEM_360_IMAGES, {
        variables: {
            itemId: itemId,
        },
    });
    return (
        <>
            <Modal
                open={true}
                handleClose={() => onClose()}
                maxWidth="xl"
                title={'360º Viewer'}
            >
                <DialogContent
                    sx={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        minHeight: 200,
                        minWidth: 200,
                    }}
                >
                    {loadingImages ? (
                        <LoadingSpinner
                            sx={{
                                position: 'absolute',
                                transform: 'translate(50%, 50%)',
                            }}
                        />
                    ) : null}
                    <Image360Viewer
                        images={loading ? [] : data.itemImages360}
                        onLoaded={() => setLoadingImages(false)}
                    />
                </DialogContent>
                <DialogActions sx={{ textAlign: loading ? 'center' : 'right' }}>
                    <Typography variant="body1">
                        {loading
                            ? 'Loading...'
                            : 'Click and drag to rotate the image.'}
                    </Typography>
                </DialogActions>
            </Modal>
        </>
    );
};
export default function Image360(props: {
    itemId: string;
    elementRef: React.MutableRefObject<
        HTMLDivElement[] | HTMLSpanElement[] | null[]
    >;
}) {
    const [openImage360, setOpenImage360] = React.useState(false);

    const handleImage360Click = () => {
        setOpenImage360(!openImage360);
    };
    return (
        <>
            <ImageGalleryButton onClick={() => handleImage360Click()}>
                <DesktopAndTabletDevice>
                    <Icon360 htmlColor="white" fontSize={9} />
                </DesktopAndTabletDevice>
                <MobileDevice>
                    <ThreeSixty />
                    360º View
                </MobileDevice>
            </ImageGalleryButton>
            {openImage360 ? (
                <Image360Modal
                    itemId={props.itemId}
                    onClose={() => {
                        setOpenImage360(false);
                    }}
                />
            ) : (
                <></>
            )}
        </>
    );
}
