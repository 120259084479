import React from 'react';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { scrollToHash } from './functions';

const LinkBehavior = React.forwardRef<
    any,
    Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
    const { href, ...other } = props;

    if (typeof href === 'string') {
        if (
            typeof other.onClick !== 'function' &&
            href.toString().indexOf('#') > -1
        ) {
            other.onClick = function () {
                scrollToHash(href.substring(href.toString().indexOf('#') + 1));
            };
        }
        if (href && href.indexOf('http') === 0) {
            if (href.indexOf('help.bbqguys.com') !== -1) {
                return <a {...other} href={href} />;
            } else if (href.indexOf('bbqguys.com') === -1) {
                return (
                    <a
                        {...other}
                        href={href}
                        target="_blank"
                        rel="noreferrer"
                    />
                );
            } else {
                return <RouterLink ref={ref} to={href} {...other} />;
            }
        } else if (
            href.indexOf('tel:') !== -1 ||
            href.indexOf('mailto:') !== -1
        ) {
            return <a href={href} {...other} />;
        }
    } else {
        return <RouterLink ref={ref} to={''} {...other} />;
    }
    // use this to enable client side routing. Needs some work still before go-live, so only using on development
    // if(!isDevelopment()){
    //     return <a ref={ref} href={href} {...other} />
    // }
    return <RouterLink ref={ref} to={href} {...other} />;
});
LinkBehavior.displayName = 'LinkBehavior';
export default LinkBehavior;
