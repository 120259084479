import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import PageBreadcrumbs from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import ProductFacets from '../../Components/ProductGridItems/ProductFacets';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import { CompareDrawerProvider } from '../../Contexts/CompareDrawerProvider';
import {
    ProductGridRefinement,
    ProductGridRefinementProvider,
} from '../../Contexts/ProductGridRefinementProvider';
import { sanitize } from '../../functions';
import SearchProductsGrid from './SearchProductsGrid';
import { Breadcrumb } from '../../types';
import PageBanners from '../../Components/Banners/Banners';
import { ProductGridPageTitle } from '../../Components/_base/BBQGuysComponents';
import AdobeTargetEvent from '../../Events/AdobeTargetEvent';
import { Box } from '@mui/system';
import { ResultNotFoundDescription } from '../../Components/Component404/Component404';

const PageTitle = (props: {
    title: string;
    subtitle?: string;
    hideTitle?: boolean;
    titleOverride?: string | React.ReactElement;
}) => {
    const { title, subtitle, hideTitle, titleOverride } = props;

    if (hideTitle ?? false) {
        return null;
    }

    let displayTitle: string | React.ReactElement = title;
    if (title !== null) {
        switch (title.toLowerCase()) {
            case 'warehouse deals':
                displayTitle = 'Warehouse Deals';
                break;
            case 'clearance':
                displayTitle = 'Clearance Deals';
                break;
            case '':
                displayTitle = 'Shop All';
                break;
        }
    } else {
        displayTitle = '';
    }
    let displaySubtitle = subtitle;
    if (displayTitle.includes('(')) {
        displaySubtitle = displayTitle.slice(displayTitle.lastIndexOf('('));
        displayTitle = displayTitle.slice(0, displayTitle.lastIndexOf('('));
    }
    const searchString = displayTitle;
    displayTitle = (
        <>
            Top related results for{' '}
            <span>
                <q>{displayTitle}</q>
            </span>{' '}
        </>
    );

    if (titleOverride ?? false) {
        displayTitle = titleOverride ?? '';
    }

    const pageTitleId = 'titleHeader';

    AdobeTargetEvent.dispatch('SearchTitleLoaded', {
        titleId: pageTitleId,
        searchTitle: searchString,
    });
    return (
        <ProductGridPageTitle
            title={displayTitle}
            subtitle={displaySubtitle}
            pageTitleId={pageTitleId}
        />
    );
};

export const SearchLayout = (props: {
    searchTerm: string;
    setNoResults?: React.Dispatch<React.SetStateAction<any>>;
}) => {
    const { searchTerm, setNoResults } = props;

    return (
        <>
            <TwoColumnLayout
                leftSideComponent={<ProductFacets type="search" />}
                rightSideComponent={
                    <SearchProductsGrid
                        term={searchTerm}
                        hideTitleIfNoResults={false}
                        setNoResults={setNoResults}
                    />
                }
            />
        </>
    );
};

const PageSearchInner = React.memo(function PageSearchInner() {
    const { setPageType, setDisplayTitle } = React.useContext(
        ProductGridRefinement,
    );
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const term = sanitize(
        decodeURIComponent(query.get('sstring') ?? ''),
    ) as string;
    const termLowerCase = term.toLowerCase();
    const navigate = useNavigate();
    React.useEffect(() => {
        setPageType('search');
    }, [navigate]);
    const hideTitle = termLowerCase == '' || termLowerCase == 'clearance';
    const [noResults, setNoResults] = React.useState(false);

    React.useEffect(() => {
        setDisplayTitle(term);
    }, [term]);

    const breadcrumbs: Breadcrumb[] | undefined = [
        {
            title: '',
            url: '/',
        },
    ];

    let noResultsTitle: React.ReactElement = <></>;
    if (noResults) {
        noResultsTitle = (
            <Box
                sx={{
                    marginBottom: 4,
                }}
            >
                We’re sorry, we couldn’t find any matches for{' '}
                <span>
                    <q>{term}</q>
                </span>
                .{' '}
            </Box>
        );
    }

    return (
        <>
            <ScContainer>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                    <title>{`Search - ${term}`}</title>
                </Helmet>
                <PageBreadcrumbs type="search" breadcrumbs={breadcrumbs} />
                {noResults ? (
                    <>
                        <PageTitle
                            title={term}
                            titleOverride={noResultsTitle}
                            hideTitle={false}
                        />
                        <ResultNotFoundDescription />
                    </>
                ) : (
                    <>
                        <PageBanners
                            url={location.pathname}
                            query={location.search}
                        />
                        <PageTitle title={term} hideTitle={hideTitle} />
                        <SearchLayout
                            searchTerm={term}
                            setNoResults={setNoResults}
                        />
                    </>
                )}
            </ScContainer>
        </>
    );
});

export default function PageSearch() {
    return (
        <ScContainer className="search">
            <CompareDrawerProvider>
                <ProductGridRefinementProvider defaultSortType="score">
                    <PageSearchInner />
                </ProductGridRefinementProvider>
            </CompareDrawerProvider>
        </ScContainer>
    );
}
