import { SvgIcon, SvgIconProps } from '@mui/material';

export const Icon360 = (props: {
    htmlColor?: string;
    width?: number | string;
    height?: number | string;
    fontSize?: number;
}) => {
    const {
        htmlColor = 'white',
        width = '2rem',
        height = '2rem',
        fontSize = 9,
    } = props;
    return (
        <svg
            className="bbqicon--image-360"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            color={htmlColor}
            fill={htmlColor}
            style={{ width: '100%', height: 40 }}
        >
            <path
                fill="currentColor"
                d="M5.7,12C3.5,12.8,2,14,2,15.3c0,2.4,4.5,4.3,10,4.3c0.1,0,0.2,0,0.3,0V22l3.9-3.4l-3.9-3.4v2.5c-0.1,0-0.2,0-0.3,0c-4.6,0-8.2-1.4-8.2-3c0-0.8,0.8-1.5,2.1-2L5.7,12z M18.8,13c0.9,0.5,1.4,1.1,1.4,1.7c0,1-1.2,1.8-3.2,2.3l2.1,1.3c1.7-0.8,2.8-1.8,2.8-3s-1-2.2-2.7-2.9c-0.2-0.1-0.3-0.2-0.6-0.2L18.8,13z"
            ></path>
            <text
                x="13"
                y="11"
                style={{
                    fontSize: fontSize,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    textAnchor: 'middle',
                }}
            >
                360°
            </text>
            <text
                x="12"
                y="15"
                style={{
                    fontSize: fontSize / 2,
                    textTransform: 'uppercase',
                    fontFamily: 'sans-serif',
                    fontWeight: 'normal',
                    letterSpacing: 0,
                    textAnchor: 'middle',
                }}
            >
                View
            </text>
        </svg>
    );
};

export const IconPdf = (props: {
    htmlColor?: string;
    width?: number | string;
    height?: number | string;
}) => {
    const { htmlColor = 'white', width = '2rem', height = '2rem' } = props;
    return (
        <svg
            className="bbqicon--image-360"
            aria-hidden="true"
            focusable="false"
            viewBox="0 0 24 24"
            width={width}
            height={height}
            color={htmlColor}
            fill={htmlColor}
        >
            <path
                fill="currentColor"
                d="M5.7,12C3.5,12.8,2,14,2,15.3c0,2.4,4.5,4.3,10,4.3c0.1,0,0.2,0,0.3,0V22l3.9-3.4l-3.9-3.4v2.5c-0.1,0-0.2,0-0.3,0c-4.6,0-8.2-1.4-8.2-3c0-0.8,0.8-1.5,2.1-2L5.7,12z M18.8,13c0.9,0.5,1.4,1.1,1.4,1.7c0,1-1.2,1.8-3.2,2.3l2.1,1.3c1.7-0.8,2.8-1.8,2.8-3s-1-2.2-2.7-2.9c-0.2-0.1-0.3-0.2-0.6-0.2L18.8,13z"
            ></path>
            <text
                x="13"
                y="11"
                style={{
                    fontSize: 8,
                    fontFamily: 'sans-serif',
                    fontWeight: 'bold',
                    textAnchor: 'middle',
                }}
            >
                360°
            </text>
            <text
                x="12"
                y="15"
                style={{
                    fontSize: 5,
                    textTransform: 'uppercase',
                    fontFamily: 'sans-serif',
                    fontWeight: 'normal',
                    letterSpacing: 0,
                    textAnchor: 'middle',
                }}
            >
                View
            </text>
        </svg>
    );
};

export const IconFacebook = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>Facebook</title>
            <path
                fill="currentColor"
                d="M9.19795 21.5H13.198V13.4901H16.8021L17.198 9.50977H13.198V7.5C13.198 6.94772 13.6457 6.5 14.198 6.5H17.198V2.5H14.198C11.4365 2.5 9.19795 4.73858 9.19795 7.5V9.50977H7.19795L6.80206 13.4901H9.19795V21.5Z"
            ></path>
        </SvgIcon>
    );
};
export const IconTwitter = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>X (formerly Twitter)</title>
            <path
                fill="currentColor"
                d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"
            ></path>
        </SvgIcon>
    );
};
export const IconInstagram = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>Instagram</title>
            <path
                fill="currentColor"
                d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z"
            ></path>
        </SvgIcon>
    );
};
export const IconPinterest = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>Pinterest</title>
            <path
                fill="currentColor"
                d="M13.25,17.25C12.25,17.25 11.29,16.82 10.6,16.1L9.41,20.1L9.33,20.36L9.29,20.34C9.04,20.75 8.61,21 8.12,21C7.37,21 6.75,20.38 6.75,19.62C6.75,19.56 6.76,19.5 6.77,19.44L6.75,19.43L6.81,19.21L9.12,12.26C9.12,12.26 8.87,11.5 8.87,10.42C8.87,8.27 10.03,7.62 10.95,7.62C11.88,7.62 12.73,7.95 12.73,9.26C12.73,10.94 11.61,11.8 11.61,13C11.61,13.94 12.37,14.69 13.29,14.69C16.21,14.69 17.25,12.5 17.25,10.44C17.25,7.71 14.89,5.5 12,5.5C9.1,5.5 6.75,7.71 6.75,10.44C6.75,11.28 7,12.12 7.43,12.85C7.54,13.05 7.6,13.27 7.6,13.5A1.25,1.25 0 0,1 6.35,14.75C5.91,14.75 5.5,14.5 5.27,14.13C4.6,13 4.25,11.73 4.25,10.44C4.25,6.33 7.73,3 12,3C16.27,3 19.75,6.33 19.75,10.44C19.75,13.72 17.71,17.25 13.25,17.25Z"
            ></path>
        </SvgIcon>
    );
};
export const IconSpotify = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>Spotify</title>
            <path
                fill="currentColor"
                d="M17.9,10.9C14.7,9 9.35,8.8 6.3,9.75C5.8,9.9 5.3,9.6 5.15,9.15C5,8.65 5.3,8.15 5.75,8C9.3,6.95 15.15,7.15 18.85,9.35C19.3,9.6 19.45,10.2 19.2,10.65C18.95,11 18.35,11.15 17.9,10.9M17.8,13.7C17.55,14.05 17.1,14.2 16.75,13.95C14.05,12.3 9.95,11.8 6.8,12.8C6.4,12.9 5.95,12.7 5.85,12.3C5.75,11.9 5.95,11.45 6.35,11.35C10,10.25 14.5,10.8 17.6,12.7C17.9,12.85 18.05,13.35 17.8,13.7M16.6,16.45C16.4,16.75 16.05,16.85 15.75,16.65C13.4,15.2 10.45,14.9 6.95,15.7C6.6,15.8 6.3,15.55 6.2,15.25C6.1,14.9 6.35,14.6 6.65,14.5C10.45,13.65 13.75,14 16.35,15.6C16.7,15.75 16.75,16.15 16.6,16.45M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
            ></path>
        </SvgIcon>
    );
};
export const IconTikTok = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>TikTok</title>
            <path
                fill="currentColor"
                d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"
            ></path>
        </SvgIcon>
    );
};
export const IconYoutube = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props}>
            <title>YouTube</title>
            <path
                fill="currentColor"
                d="M10,16.5V7.5L16,12M20,4.4C19.4,4.2 15.7,4 12,4C8.3,4 4.6,4.19 4,4.38C2.44,4.9 2,8.4 2,12C2,15.59 2.44,19.1 4,19.61C4.6,19.81 8.3,20 12,20C15.7,20 19.4,19.81 20,19.61C21.56,19.1 22,15.59 22,12C22,8.4 21.56,4.91 20,4.4Z"
            ></path>
        </SvgIcon>
    );
};

export const IconPrivacyChoices = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            className="sc-icon sc-icon--cpra"
            viewBox="0 0 30 14"
            aria-hidden="true"
            focusable="false"
        >
            <path
                fill="#FFF"
                d="M7.4 12.8h6.8l3.1-11.6H7.4C4.2 1.2 1.6 3.8 1.6 7s2.6 5.8 5.8 5.8z"
            ></path>
            <path
                fill="#06F"
                d="M22.6 0H7.4c-3.9 0-7 3.1-7 7s3.1 7 7 7h15.2c3.9 0 7-3.1 7-7s-3.2-7-7-7zm-21 7c0-3.2 2.6-5.8 5.8-5.8h9.9l-3.1 11.6H7.4c-3.2 0-5.8-2.6-5.8-5.8z"
            ></path>
            <path
                id="y"
                fill="#FFF"
                d="M24.6 4c.2.2.2.6 0 .8L22.5 7l2.2 2.2c.2.2.2.6 0 .8-.2.2-.6.2-.8 0l-2.2-2.2-2.2 2.2c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L20.8 7l-2.2-2.2c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0l2.2 2.2L23.8 4c.2-.2.6-.2.8 0z"
            ></path>
            <path
                id="x"
                fill="#06F"
                d="M12.7 4.1c.2.2.3.6.1.8L8.6 9.8c-.1.1-.2.2-.3.2-.2.1-.5.1-.7-.1L5.4 7.7c-.2-.2-.2-.6 0-.8.2-.2.6-.2.8 0L8 8.6l3.8-4.5c.2-.2.6-.2.9 0z"
            ></path>
        </SvgIcon>
    );
};

export const IconProp65Warning = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            viewBox="0 0 576.539 508"
            aria-hidden="true"
            focusable="false"
        >
            <path
                fill="#000"
                d="M31.39 494.183h512.191a18.524 18.524 0 0 0 16.043-9.265 18.514 18.514 0 0 0 0-18.524L303.529 22.822a18.526 18.526 0 0 0-32.086 0L15.346 466.394a18.527 18.527 0 0 0 16.044 27.789z"
            ></path>
            <path
                fill="#fe3"
                d="M287.485 69.346l223.827 387.679H63.658z"
            ></path>
            <circle fill="#000" cx="287.485" cy="400" r="25.644"></circle>
            <path
                fill="#000"
                d="M303.068 344.582l18.396-167.807a34.366 34.366 0 0 0-17.297-33.69 34.37 34.37 0 0 0-50.164 38.455l19.122 166.535a15.074 15.074 0 1 0 29.943-3.493"
            ></path>
        </SvgIcon>
    );
};

export const FuelTypeNaturalGas = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            className="icon icon--natural-gas"
            aria-hidden="true"
            focusable="false"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 2C12 2 6 8.12245 6 15.2653C6 19.9796 8.8 22 11.4 22C10.5 20.8776 8.8 19.7551 8.8 17.3061C8.8 13.9388 12 9.14286 12 9.14286C12 9.14286 15.2 13.9388 15.2 17.3061C15.2 19.7551 13.6 20.8776 12.6 22C15.2 22 18 19.9796 18 15.2653C18 8.12245 12 2 12 2Z"
                fill="currentColor "
            />
        </SvgIcon>
    );
};

export const FuelTypePropane = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            className="icon icon--propane"
            aria-hidden="true"
            focusable="false"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 6V4C16 2.9 15.11 2 14 2H10C8.9 2 8 2.9 8 4V6C5.79 6 4 7.79 4 10V17C4 18.86 5.28 20.41 7 20.86V22H17V20.86C18.72 20.42 20 18.86 20 17V10C20 7.79 18.21 6 16 6ZM10 4H14V6H10V4Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export const PayPalLogo = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.60815 23.1864L8.02409 20.5444L7.09756 20.5229H2.67326L5.74792 1.02757C5.75747 0.968712 5.78848 0.913836 5.83382 0.874866C5.87915 0.835896 5.93721 0.814423 5.99765 0.814423H13.4576C15.9342 0.814423 17.6433 1.32978 18.5357 2.34698C18.954 2.82416 19.2204 3.32282 19.3493 3.87159C19.4845 4.44739 19.4869 5.13533 19.3548 5.97438L19.3453 6.03562V6.57325L19.7636 6.81025C20.116 6.99714 20.3959 7.21108 20.6106 7.45604C20.9685 7.86403 21.2 8.38257 21.2978 8.99734C21.3988 9.62961 21.3654 10.382 21.2 11.2337C21.0091 12.2136 20.7005 13.0669 20.2838 13.7652C19.9004 14.4086 19.4121 14.9423 18.8323 15.3558C18.2788 15.7487 17.6211 16.0469 16.8775 16.2378C16.1569 16.4255 15.3354 16.5202 14.4343 16.5202H13.8537C13.4386 16.5202 13.0353 16.6697 12.7188 16.9377C12.4015 17.2113 12.1915 17.5851 12.1271 17.9939L12.0833 18.2317L11.3485 22.8882L11.3151 23.0592C11.3063 23.1133 11.2912 23.1403 11.269 23.1586C11.2491 23.1753 11.2204 23.1864 11.1926 23.1864H7.60815Z"
                fill="#253B80"
            />
            <path
                d="M20.1598 6.09761C20.1375 6.23997 20.1121 6.38551 20.0834 6.53503C19.0996 11.586 15.7339 13.3309 11.4352 13.3309H9.24656C8.72086 13.3309 8.27787 13.7127 8.19595 14.2312L7.07537 21.3381L6.75804 23.3526C6.70475 23.693 6.9672 24 7.31078 24H11.1927C11.6524 24 12.0429 23.666 12.1152 23.2126L12.1534 23.0154L12.8843 18.3772L12.9312 18.1227C13.0028 17.6677 13.3941 17.3337 13.8538 17.3337H14.4343C18.1954 17.3337 21.1396 15.8067 22.0001 11.388C22.3596 9.54209 22.1735 8.00078 21.2223 6.91678C20.9344 6.58991 20.5773 6.31871 20.1598 6.09761Z"
                fill="#179BD7"
            />
            <path
                d="M19.1304 5.68728C18.9801 5.64354 18.825 5.60378 18.666 5.56799C18.5061 5.53299 18.3423 5.50198 18.1737 5.47494C17.5836 5.3795 16.937 5.33417 16.2443 5.33417H10.3972C10.2532 5.33417 10.1164 5.36678 9.99395 5.42563C9.72434 5.55526 9.52392 5.81056 9.47541 6.12311L8.23154 14.0014L8.19576 14.2313C8.27767 13.7127 8.72066 13.331 9.24636 13.331H11.435C15.7337 13.331 19.0994 11.5853 20.0832 6.53508C20.1126 6.38556 20.1373 6.24002 20.1596 6.09766C19.9106 5.96564 19.641 5.85271 19.3507 5.75648C19.2792 5.73262 19.2052 5.70955 19.1304 5.68728Z"
                fill="#222D65"
            />
            <path
                d="M9.47549 6.12307C9.524 5.81052 9.72442 5.55522 9.99403 5.42638C10.1173 5.36753 10.2533 5.33492 10.3972 5.33492H16.2444C16.9371 5.33492 17.5837 5.38026 18.1738 5.47569C18.3424 5.50273 18.5062 5.53375 18.6661 5.56874C18.8251 5.60453 18.9802 5.6443 19.1305 5.68804C19.2053 5.71031 19.2792 5.73337 19.3516 5.75644C19.6419 5.85267 19.9115 5.9664 20.1604 6.09762C20.4531 4.23104 20.1581 2.96014 19.1488 1.80932C18.0362 0.5424 16.028 0 13.4584 0H5.9984C5.4735 0 5.02574 0.381748 4.94462 0.901084L1.83735 20.5969C1.77611 20.9866 2.07674 21.3381 2.46962 21.3381H7.07525L8.23163 14.0014L9.47549 6.12307Z"
                fill="#253B80"
            />
        </SvgIcon>
    );
};

export const ApplePayLogo = (props: SvgIconProps) => {
    const { sx, ...rest } = props;
    return (
        <SvgIcon
            {...rest}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 40 40"
            sx={{ ...sx, fontSize: '1.5rem' }}
        >
            <g clipPath="url(#a)">
                <path
                    fill="#000"
                    d="M36.4179 7.19618H3.58218c-.13675 0-.27375 0-.41026.0008-.1154.00082-.23052.0021-.34565.00524-.25109.00679-.50432.02161-.75227.06617-.25191.04534-.48632.11928-.715.23572-.22482.11433-.430641.26387-.609008.44231-.178445.17844-.327985.38393-.442291.60898-.116456.22869-.190453.46317-.2354743.71527-.0448015.24802-.0597145.50121-.06648097.75203-.00309326.1151-.00441998.2303-.00517154.3454-.00079749.1368-.00053166.2735-.00053166.4105v18.4431c0 .137-.00026582.2735.00053166.4105.00075156.1151.00207828.2303.00517154.3454.00676647.2506.02167947.5038.06648097.7517.0450213.2522.1190183.4866.2354743.7153.114306.2251.263846.4308.442291.609.178367.1787.384188.3283.609008.4423.22868.1167.46309.1907.715.236.24795.0443.50118.0594.75227.0662.11513.0026.23025.0041.34565.0047.13651.001.27351.001.41026.001H36.4179c.1365 0 .2735 0 .41-.001.1151-.0006.2302-.0021.3459-.0047.2505-.0068.5037-.0219.7523-.0662.2515-.0453.486-.1193.7147-.236.2251-.114.4303-.2636.609-.4423.1782-.1782.3277-.3839.4423-.609.1167-.2287.1906-.4631.2354-.7153.0449-.2479.0594-.5011.0662-.7517.0032-.1151.0047-.2303.0052-.3454.0011-.137.0011-.2735.0011-.4105V10.7786c0-.137 0-.2737-.0011-.4105-.0005-.1151-.002-.2303-.0052-.3454-.0068-.25082-.0213-.50401-.0662-.75203-.0448-.2521-.1187-.48658-.2354-.71527-.1146-.22505-.2641-.43054-.4423-.60898-.1787-.17844-.3839-.32798-.609-.44231-.2287-.11644-.4632-.19038-.7147-.23572-.2486-.04456-.5018-.05938-.7523-.06617-.1157-.00314-.2308-.00442-.3459-.00524-.1365-.0008-.2735-.0008-.41-.0008Z"
                />
                <path
                    fill="#fff"
                    d="m36.4179 8.04973.4039.00077c.1094.00078.2189.00196.3289.00496.1914.00517.4153.01553.624.05294.1814.03268.3335.08236.4796.1567.1441.07324.2762.16923.3915.28438.1158.11593.2119.24821.2861.39396.0739.14488.1233.2963.1557.47904.0374.20643.0477.43093.0529.62352.003.1087.0044.2174.005.3287.001.1346.001.2691.001.4039v18.4431c0 .1348 0 .2691-.0011.4065-.0005.1087-.0019.2174-.0049.3263-.0052.1923-.0155.4167-.0533.6255-.032.1803-.0814.3317-.1557.4773-.074.1454-.17.2775-.2852.3927-.116.116-.2476.2117-.3935.2857-.1449.0739-.2968.1236-.4764.1559-.213.0379-.4462.0484-.6219.0531-.1106.0025-.2206.004-.3332.0046-.1343.001-.2691.001-.4034.001H3.57686c-.13277 0-.26581 0-.40099-.0011-.11022-.0005-.2202-.0019-.32654-.0044-.17969-.0048-.41311-.0152-.6243-.053-.18117-.0325-.33306-.0822-.47989-.1571-.14449-.0733-.27603-.1689-.39205-.2851-.11513-.1149-.2108-.2467-.28477-.3923-.074048-.1454-.123589-.2973-.156162-.4797-.037653-.2085-.047997-.432-.053166-.6229-.00295-.1094-.004183-.2187-.004884-.3274l-.000531-.3209.000024-.0847V10.7786l-.000024-.0847.000507-.3202c.000725-.1094.001958-.2187.004908-.328.005169-.19115.015513-.41473.053477-.62493.032286-.18074.081803-.33265.156231-.47883.0734-.14449.16924-.27639.28497-.39209.11527-.11532.24724-.21119.39265-.28514.14526-.07397.29744-.12363.47861-.15623.20875-.03751.43279-.04785.62467-.05305.10938-.00297.21875-.00415.32731-.0049l.40529-.0008h32.8357Z"
                />
                <path
                    fill="#000"
                    d="M10.9197 15.8091c.3425-.4284.5749-1.0036.5136-1.5913-.5013.0249-1.1131.3307-1.46735.7594-.31804.3672-.59954.9664-.52615 1.5296.5628.0488 1.1251-.2813 1.4799-.6977Zm.5072.8076c-.8173-.0486-1.51221.4639-1.90252.4639-.39053 0-.98823-.4393-1.63469-.4275-.84141.0124-1.62214.4881-2.04911 1.2448-.87823 1.5136-.23177 3.7589.62226 4.9917.41473.6099.91456 1.2815 1.57315 1.2574.62227-.0245.86617-.403 1.62256-.403.75585 0 .97555.403 1.63425.3908.6831-.0123 1.1101-.6103 1.5249-1.2208.4757-.6952.6705-1.3665.6828-1.4034-.0123-.0122-1.3172-.5128-1.3293-2.0139-.0124-1.2569 1.0245-1.8547 1.0733-1.8918-.5855-.866-1.5004-.9636-1.8176-.9882Zm7.1167-1.7009c1.7764 0 3.0134 1.2245 3.0134 3.0073 0 1.7891-1.2624 3.02-3.058 3.02h-1.9668v3.1279h-1.4211v-9.1552h3.4325Zm-2.0114 4.8345h1.6305c1.2373 0 1.9414-.6661 1.9414-1.8209 0-1.1546-.7041-1.8145-1.935-1.8145h-1.6369v3.6354Zm5.3961 2.4237c0-1.1675.8946-1.8844 2.4809-1.9732l1.8271-.1078v-.5139c0-.7423-.5013-1.1865-1.3386-1.1865-.7933 0-1.2882.3806-1.4086.9771h-1.2942c.0761-1.2055 1.1038-2.0938 2.7535-2.0938 1.6178 0 2.6519.8566 2.6519 2.1953v4.5998h-1.3134v-1.0976h-.0315c-.387.7423-1.2309 1.2118-2.1064 1.2118-1.307 0-2.2207-.8121-2.2207-2.0112Zm4.308-.6027v-.5266l-1.6433.1015c-.8185.0571-1.2816.4187-1.2816.9897 0 .5837.4822.9644 1.2182.9644.958 0 1.7067-.6599 1.7067-1.529Zm2.6039 4.9551V25.416c.1014.0254.3297.0254.444.0254.6344 0 .9771-.2664 1.1863-.9516 0-.0128.1207-.4061.1207-.4124l-2.4108-6.6809h1.4844l1.6878 5.431h.0253l1.6878-5.431h1.4465l-2.4999 7.0234c-.5708 1.618-1.2307 2.1382-2.6138 2.1382-.1143 0-.4569-.0127-.5583-.0317Z"
                />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 7.19618h40v25.6076H0z" />
                </clipPath>
            </defs>
        </SvgIcon>
    );
};

export const GooglePayLogo = (props: SvgIconProps) => {
    return (
        <SvgIcon
            {...props}
            width="45"
            height="25"
            viewBox="13 8 13 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10.6383 9.8617H29.3617C34.9366 9.8617 39.5 14.4251 39.5 20C39.5 25.5749 34.9366 30.1383 29.3617 30.1383H10.6383C5.06338 30.1383 0.5 25.5749 0.5 20C0.5 14.4251 5.06338 9.8617 10.6383 9.8617Z"
                fill="white"
                stroke="#888"
            />
            <path
                d="M19.0744 20.7553V23.9734H18.0532V16.0266H20.7606C21.4468 16.0266 22.0319 16.2553 22.5106 16.7128C23 17.1702 23.2447 17.7287 23.2447 18.3883C23.2447 19.0638 23 19.6223 22.5106 20.0745C22.0372 20.5266 21.4521 20.75 20.7606 20.75H19.0744V20.7553ZM19.0744 17.0053V19.7766H20.7819C21.1861 19.7766 21.5266 19.6383 21.7925 19.367C22.0638 19.0957 22.2021 18.766 22.2021 18.3936C22.2021 18.0266 22.0638 17.7021 21.7925 17.4309C21.5266 17.1489 21.1915 17.0106 20.7819 17.0106H19.0744V17.0053Z"
                fill="#3C4043"
            />
            <path
                d="M25.9149 18.3564C26.6702 18.3564 27.2659 18.5585 27.7021 18.9628C28.1383 19.367 28.3564 19.9202 28.3564 20.6223V23.9734H27.383V23.2181H27.3404C26.9202 23.8404 26.3564 24.1489 25.6542 24.1489C25.0532 24.1489 24.5532 23.9734 24.1489 23.617C23.7447 23.2606 23.5425 22.8191 23.5425 22.2872C23.5425 21.7234 23.7553 21.2766 24.1808 20.9468C24.6064 20.6117 25.1755 20.4468 25.883 20.4468C26.4893 20.4468 26.9894 20.5585 27.3776 20.7819V20.5479C27.3776 20.1915 27.2394 19.8936 26.9574 19.6436C26.6755 19.3936 26.3457 19.2713 25.9681 19.2713C25.3989 19.2713 24.9468 19.5106 24.617 19.9947L23.7181 19.4309C24.2128 18.7128 24.9468 18.3564 25.9149 18.3564ZM24.5957 22.3032C24.5957 22.5691 24.7074 22.7926 24.9362 22.9681C25.1596 23.1436 25.4255 23.234 25.7287 23.234C26.1596 23.234 26.5425 23.0745 26.8776 22.7553C27.2128 22.4362 27.383 22.0638 27.383 21.633C27.0638 21.383 26.6223 21.2553 26.0532 21.2553C25.6383 21.2553 25.2925 21.3564 25.0159 21.5532C24.734 21.7606 24.5957 22.0106 24.5957 22.3032Z"
                fill="#3C4043"
            />
            <path
                d="M33.9096 18.5319L30.5053 26.3617H29.4521L30.7181 23.6223L28.4734 18.5319H29.5851L31.2021 22.4362H31.2234L32.7979 18.5319H33.9096Z"
                fill="#3C4043"
            />
            <path
                d="M15.0122 20.1064C15.0122 19.7734 14.9824 19.4548 14.9271 19.1484H10.6462V20.9037L13.1117 20.9043C13.0117 21.4883 12.6899 21.9862 12.1968 22.3181V23.4569H13.6643C14.5212 22.6638 15.0122 21.4915 15.0122 20.1064Z"
                fill="#4285F4"
            />
            <path
                d="M12.1973 22.3181C11.7888 22.5936 11.2627 22.7548 10.6473 22.7548C9.45846 22.7548 8.44995 21.9537 8.08878 20.8739H6.57495V22.0484C7.32495 23.5367 8.86644 24.558 10.6473 24.558C11.8781 24.558 12.9122 24.1532 13.6648 23.4564L12.1973 22.3181Z"
                fill="#34A853"
            />
            <path
                d="M7.94624 20.0027C7.94624 19.6995 7.99677 19.4064 8.0888 19.1309V17.9564H6.57497C6.26486 18.5718 6.09039 19.2665 6.09039 20.0027C6.09039 20.7388 6.26539 21.4335 6.57497 22.0489L8.0888 20.8745C7.99677 20.5989 7.94624 20.3059 7.94624 20.0027Z"
                fill="#FABB05"
            />
            <path
                d="M10.6473 17.25C11.3191 17.25 11.9207 17.4814 12.3957 17.9335L13.6962 16.634C12.9063 15.8984 11.8765 15.4468 10.6473 15.4468C8.86697 15.4468 7.32495 16.4681 6.57495 17.9564L8.08878 19.1308C8.44995 18.0511 9.45846 17.25 10.6473 17.25Z"
                fill="#E94235"
            />
        </SvgIcon>
    );
};

export const AffirmLogo = (props: SvgIconProps) => {
    const { sx = {}, ...rest } = props;
    return (
        <SvgIcon
            {...rest}
            sx={{
                ...sx,
                width: '3rem',
                height: 'auto',
                svg: { verticalAlign: 'inherit' },
            }}
            width="429"
            height="171"
            viewBox="0 0 429 171"
            fill="none"
        >
            <defs>
                <clipPath id="clip0_2525_303">
                    <rect width="428.55" height="171" fill="white" />
                </clipPath>
            </defs>
            <g clipPath="url(#clip0_2525_303)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M28.1 156.27C22.8 156.27 20.1 153.66 20.1 149.37C20.1 141.37 29.02 138.68 45.29 136.95C45.29 147.61 38.08 156.27 28.05 156.27H28.1ZM35.1 96.3198C23.47 96.3198 10.1 101.79 2.83998 107.58L9.46998 121.58C15.29 116.25 24.7 111.69 33.19 111.69C41.26 111.69 45.72 114.39 45.72 119.83C45.72 123.48 42.77 125.33 37.19 126.06C16.33 128.76 -0.0200195 134.52 -0.0200195 150.59C-0.0200195 163.33 9.04998 171.04 23.22 171.04C33.34 171.04 42.34 165.42 46.62 158.04V169.04H65.48V122.95C65.48 103.95 52.29 96.2798 35.11 96.2798L35.1 96.3198Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M224.39 98.3902V168.95H244.57V134.95C244.57 118.8 254.35 114.05 261.16 114.05C264.226 114.026 267.229 114.914 269.79 116.6L273.48 97.9502C270.62 96.8056 267.559 96.2511 264.48 96.3202C254.11 96.3202 247.59 100.91 243.29 110.25V98.3902H224.39Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M367 96.3198C356.33 96.3198 348.35 102.62 344.2 108.7C340.35 100.85 332.2 96.3198 322.4 96.3198C311.74 96.3198 304.35 102.24 300.94 109.06V98.3898H281.48V168.95H301.68V132.62C301.68 119.62 308.51 113.33 314.88 113.33C320.65 113.33 325.95 117.06 325.95 126.69V168.95H346.11V132.62C346.11 119.43 352.77 113.33 359.44 113.33C364.78 113.33 370.44 117.21 370.44 126.55V168.95H390.6V120.17C390.6 104.32 379.93 96.3198 367.04 96.3198"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M175.28 98.3901H157V91.2201C157 81.8801 162.33 79.2201 166.92 79.2201C170.054 79.261 173.135 80.0313 175.92 81.4701L182.14 67.2401C182.14 67.2401 175.83 63.1201 164.36 63.1201C151.47 63.1201 136.8 70.3901 136.8 93.2001V98.3901H106.25V91.2201C106.25 81.8801 111.57 79.2201 116.17 79.2201C119.309 79.2199 122.4 79.9926 125.17 81.4701L131.39 67.2401C127.68 65.0701 121.71 63.1201 113.62 63.1201C100.73 63.1201 86.0599 70.3901 86.0599 93.2001V98.3901H74.3799V113.95H86.0899V168.95H106.25V113.95H136.84V168.95H157V113.95H175.28V98.3901Z"
                    fill="black"
                />
                <path
                    d="M207.46 98.3901H187.32V168.92H207.46V98.3901Z"
                    fill="black"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M188.06 86.4H207.79C219.3 50.21 258.35 18.4 304.79 18.4C361.27 18.4 410.08 61.4 410.08 128.34C410.252 142.08 408.364 155.769 404.48 168.95H423.63L423.82 168.29C427.047 155.241 428.639 141.842 428.56 128.4C428.56 53.75 374.16 0.0200195 304.83 0.0200195C250.37 0.0200195 201.83 37.82 188.07 86.42L188.06 86.4Z"
                    fill="#4A4AF4"
                />
            </g>
        </SvgIcon>
    );
};

export const CheckoutShieldLock = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 24 24">
            <path
                fill="currentColor"
                d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M12,7C13.4,7 14.8,8.1 14.8,9.5V11C15.4,11 16,11.6 16,12.3V15.8C16,16.4 15.4,17 14.7,17H9.2C8.6,17 8,16.4 8,15.7V12.2C8,11.6 8.6,11 9.2,11V9.5C9.2,8.1 10.6,7 12,7M12,8.2C11.2,8.2 10.5,8.7 10.5,9.5V11H13.5V9.5C13.5,8.7 12.8,8.2 12,8.2Z"
            ></path>
        </SvgIcon>
    );
};
