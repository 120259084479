import { Box, Grid } from '@mui/material';
import ScContainer from '../../../../../Components/Layout/ScContainer/ScContainer';
import {
    DesktopAndTabletDevice,
    isMobile,
} from '../../../../../Contexts/DeviceTypeProvider';
import { Item } from '../../../../../types';
import QuestionsAndAnswersWriteQuestionButton from '../Components/Buttons/QuestionsAndAnswersWriteQuestionButton';
import QuestionsAndAnswersSearchBar from '../Components/QuestionsAndAnswersSearchBar';
import QuestionsAndAnswersSortDropdown from '../Components/QuestionsAndAnswersSortDropdown';

const QuestionsAndAnswersHeader = (props: { item: Item }) => {
    const { item } = props;
    let buttonSX = {
        height: '42px',
        fontSize: 16,
    };
    if (isMobile) {
        buttonSX = {
            height: '100%',
            fontSize: 14,
        };
    }
    return (
        <>
            <ScContainer variant="alt" sx={{ p: 1 }}>
                <Grid container columnSpacing={4}>
                    <DesktopAndTabletDevice>
                        <Grid item xs={0} md={4}>
                            <QuestionsAndAnswersSearchBar />
                        </Grid>
                    </DesktopAndTabletDevice>
                    <Grid item xs={6} md={4}>
                        <QuestionsAndAnswersSortDropdown />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Box justifyContent="right" display="flex">
                            <QuestionsAndAnswersWriteQuestionButton
                                item={item}
                                sx={buttonSX}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </ScContainer>
        </>
    );
};

export default QuestionsAndAnswersHeader;
