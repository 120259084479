import { useQuery } from '@apollo/client';
import { Divider, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import PageBanners from '../../Components/Banners/Banners';
import PageBreadcrumbs from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { PageHeader, Section } from '../../Components/_base/BBQGuysComponents';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { QL_DEPT } from '../../Graphql/queries';
import { RenderContentWithId } from '../PageContent/PageContent';
import DepartmentBrands from './Components/DepartmentBrands';
import DepartmentCollections from './Components/DepartmentCollections';
import DepartmentLearningCenter from './Components/DepartmentLearningCenter';
import HubPageDepartments from './Components/HubPageDepartments';

export default function PageDepartmentHub(props: { deptId?: string }) {
    let { deptId = '' } = useParams();
    if (props.deptId !== undefined) {
        deptId = props.deptId;
    }
    if (parseInt(deptId) == 0) {
        throw new Error('Invalid Department Id');
    }

    const { data, loading } = useQuery(QL_DEPT, {
        variables: {
            departmentId: deptId,
        },
    });

    if (loading) {
        return <></>;
    }

    return (
        <>
            <ScContainer className="hub">
                <PageBreadcrumbs type="department" typeId={deptId} />
                <PageHeader title={data.department.h1Title} />
                <TwoColumnLayout
                    leftSideComponent={
                        <DesktopAndTabletDevice>
                            <HubPageDepartments deptId={deptId} />
                        </DesktopAndTabletDevice>
                    }
                    rightSideComponent={
                        <>
                            <PageBanners />
                            <MobileDevice>
                                <HubPageDepartments deptId={deptId} />
                            </MobileDevice>
                            <DepartmentCollections deptId={deptId} />
                            <DepartmentBrands deptId={deptId} />
                            <DepartmentLearningCenter deptId={deptId} />
                            <Divider />
                            <RenderContentWithId contentId="42114" />
                            {data.department.customerDescription.length > 0 ? (
                                <Section
                                    title={data.department.title}
                                    alignTitle="center"
                                >
                                    <Typography variant="body1" component="div">
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: data.department
                                                    .customerDescription,
                                            }}
                                        />
                                    </Typography>
                                </Section>
                            ) : null}
                        </>
                    }
                />
            </ScContainer>
        </>
    );
}
