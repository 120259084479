import CancelIcon from '@mui/icons-material/Cancel';
import {
    Box,
    IconButton,
    Link,
    Stack,
    SxProps,
    Typography,
} from '@mui/material';
import theme from '../../../../Theme';
import { Item } from '../../../../types';
import { Image } from '../../../Image';
import { CurrentPrice, ProPriceLabel } from '../../../Item/Pricing';
import { LineEllipsis } from '../../../_base/BBQGuysComponents';

export const ProductCardSmall = ({
    item,
    onDelete,
    sx = {},
}: {
    item: Item;
    onDelete?: () => void;
    sx?: SxProps;
}) => {
    return (
        <Box
            sx={{
                border: theme.palette.border,
                backgroundColor: theme.palette.common.white,
                display: 'grid',
                gridTemplateColumns: '85px 1fr',
                gap: 1,
                p: 1,
                width: 270,
                flex: '0 0 270px',
                '&:hover': {
                    textDecoration: 'none',
                },
                ...sx,
            }}
        >
            <Image rounded src={item.imageUrl} width={85} height={85} />
            <Stack direction="row">
                <Stack direction="column">
                    <Link
                        href={item.url}
                        sx={{
                            textDecoration: 'none',
                        }}
                    >
                        <Typography
                            variant="bold"
                            component="div"
                            fontSize={14}
                            marginBottom={1}
                        >
                            <LineEllipsis maxLines={2} text={item.name} />
                        </Typography>
                    </Link>
                    {item.showProLogo && (
                        <ProPriceLabel
                            item={item}
                            sx={{ fontSize: '.8rem', display: 'block' }}
                        />
                    )}
                    <Typography
                        variant="currentPrice"
                        component="div"
                        fontSize={21}
                        fontWeight="bold"
                    >
                        <CurrentPrice item={item} />
                    </Typography>
                </Stack>
                {onDelete && (
                    <Box sx={{ justifyContent: 'flex-start', width: '34px' }}>
                        <IconButton
                            onClick={onDelete}
                            sx={{ width: '15px', height: '15px' }}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
                )}
            </Stack>
        </Box>
    );
};
