import { HelpOutlined } from '@mui/icons-material';
import {
    Accordion as MuiAccordion,
    AccordionProps,
    Box,
    Button,
    FormControlLabel,
    Link,
    Step as MuiStep,
    StepContent as MuiStepContent,
    StepContentProps,
    StepLabel as MuiStepLabel,
    StepLabelProps,
    Stepper as MuiStepper,
    StepperProps,
    StepProps,
    Table as MuiTable,
    TableBody as MuiTableBody,
    TableCell as MuiTableCell,
    TableFooter as MuiTableFooter,
    TableHead as MuiTableHead,
    TableRow as MuiTableRow,
} from '@mui/material';
import { styled } from '@mui/system';
import { isMobile } from '../../Contexts/DeviceTypeProvider';

import theme, { PRIMARY_FONT_FAMILY, SECONDARY_FONT_FAMILY } from '../../Theme';
const typography = theme.typography;

export const Label = styled(FormControlLabel)(({ theme }) => ({
    margin: 0,
    '.MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'calc(100% - 64px)',
        lineHeight: 1,
        [theme.breakpoints.only('md')]: {
            fontSize: '.8rem',
        },
    },
}));

export const CheckboxIcon = styled('span')(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    width: 18,
    height: 18,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : `inset 0 0.125em 0.25em rgba(0,0,0,0.2), inset 0 0 0 2px ${theme.palette.secondary.light}`,
    backgroundColor:
        theme.palette.mode === 'dark' ? '#394b59' : theme.palette.common.white,
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor:
            theme.palette.mode === 'dark'
                ? '#30404d'
                : theme.palette.common.white,
    },
    'input:disabled ~ &': {
        background:
            theme.palette.mode === 'dark'
                ? 'rgba(57,75,89,.5)'
                : theme.palette.grey[50],
    },
}));

export const CheckboxCheckedIcon = styled(CheckboxIcon)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? theme.palette.primary.dark
            : theme.palette.primary.main,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : `inset 0 0.125em 0.25em rgba(0,0,0,0.2), inset 0 0 0 2px ${theme.palette.primary.main}`,
    backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        color: '#fff',
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.primary.main,
    },
}));

export const ShowMoreContainer = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: '-1px',
    background: 'transparent',
    backgroundImage: `linear-gradient(180deg, transparent 0%, rgba(0,0,0, .04) 80%)`,
    marginTop: `-${theme.spacing(3)}`,
    zIndex: 100,
    position: 'relative',
}));

export const ShowMoreButton = styled(Button)({
    borderWidth: '0.0625rem',
    fontSize: typography.body2.fontSize,
    fontWeight: 'bold',
    lineHeight: '1.25',
    maxWidth: '180px',
    padding: '0.125rem 1.5rem',
    // position: 'absolute',
    // bottom: '0',
    // left: '50%',
    transform: 'translateY(50%)',
    textTransform: 'none',
    whiteSpace: 'nowrap',
    zIndex: '10',
});

export const HelpTipIconStyled = styled(HelpOutlined)({
    color: 'rgb(132, 152, 173)',
});

export const ChildDepartmentLinkLevel1 = styled(Link)(({ theme }) => ({
    display: 'block',
    fontWeight: 'bold',
    fontFamily: PRIMARY_FONT_FAMILY,
    lineHeight: '1.2',
    fontSize: typography.body1.fontSize,
    paddingTop: theme.spacing(0.7),
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1),
    textDecoration: 'none',
}));

export const ChildDepartmentLinkLevel2 = styled(Link)(({ theme }) => ({
    display: 'block',
    lineHeight: '1.4',
    color: theme.palette.secondary,
    fontFamily: SECONDARY_FONT_FAMILY,
    textDecoration: 'none',
}));

export const SidebarList = styled('ul')(({ theme }) => ({
    paddingTop: theme.spacing(0.7),
    marginBottom: theme.spacing(3),
    padding: 0,
    listStyle: 'none',
    '> li': {
        listStyle: 'none',
        margin: '3px 0 !important',
    },
}));
export const SidebarListItem = styled('li')(({ theme }) => ({
    margin: 0,
    listStyle: 'none',
}));

/** TABLE */

export const Table = styled(MuiTable)(({ theme }) => ({
    borderCollapse: 'collapse',
    borderSpacing: 0,
    [theme.breakpoints.down('md')]: {
        borderRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
        borderRadius: theme.shape.borderRadius,
        boxShadow: '0 0.0625em 0.1875em rgb(40 43 45 / 20%)',
    },
    overflow: 'hidden',
    width: '100%',
    marginBottom: theme.spacing(3),
}));

export const TableHead = styled(MuiTableHead)(({ theme }) => ({
    backgroundColor: theme.palette.grey[800],
    [theme.breakpoints.up('md')]: {
        borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
    },
    '&> tr > th': {
        color: 'white',
        fontSize: typography.body2.fontSize,
        fontWeight: typography.fontWeightBold,
        padding: theme.spacing(1, 2),
        '&:last-of-type': {
            textAlign: 'right',
            paddingRight: theme.spacing(2),
        },
        '&:first-of-type': {
            textAlign: 'left',
            paddingLeft: theme.spacing(2),
        },
    },
}));
export const TableBody = styled(MuiTableBody)(({ theme }) => ({
    '&> tr > td': {
        padding: theme.spacing(2),
    },
}));
export const TableRow = styled(MuiTableRow)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        borderLeft: theme.palette.border,
        borderRight: theme.palette.border,
    },
    '& > td': {
        borderBottom: theme.palette.border,
    },
    verticalAlign: 'top',
    borderTopWidth: 0,
}));
export const TableRowOrderItem = styled(TableRow)(({ theme }) => ({
    verticalAlign: 'top',
    [theme.breakpoints.up('md')]: {
        borderLeft: theme.palette.border,
        borderRight: theme.palette.border,
    },
    '& > td': {
        borderTop: theme.palette.border,
    },
}));
export const TableRowParentOrderItem = styled(TableRowOrderItem)(
    ({ theme }) => ({
        '& > td': {
            borderBottom: 0,
        },
    }),
);
export const TableRowChildOrderItem = styled(TableRowOrderItem)(
    ({ theme }) => ({
        '& > td': {
            borderTop: 0,
            '&:first-of-type': {
                paddingLeft: '85px',
                [theme.breakpoints.down('sm')]: {
                    paddingLeft: '1rem',
                },
            },
            '&> div': {
                gridTemplateColumns: '75px 1fr',
                [theme.breakpoints.down('md')]: {
                    gridTemplateColumns: '.75fr 1fr 1fr',
                },
                [theme.breakpoints.down('sm')]: {
                    gridTemplateColumns: '1fr 1fr 1fr',
                },
                img: {
                    width: 75,
                    height: 75,
                },
            },
        },
    }),
);
export const TableCell = styled(MuiTableCell)(({ theme }) => ({
    padding: theme.spacing(0.5, 2),
}));

export const TableFooter = styled(MuiTableFooter)(({ theme }) => ({
    backgroundColor: theme.palette.grey[100],
    '& > tr > td': {
        '&:first-of-type': {
            borderTop: theme.palette.border,
        },
        '&:last-of-type': {
            borderBottom: theme.palette.border,
        },
    },
}));

export const BreadcrumbLink = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
}));

export const QuickViewButton = styled(Button)(({ theme }) => ({
    whiteSpace: 'nowrap',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    bottom: 110,
    width: '80%',
}));

export const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `${theme.palette.border}`,
    '&:not(.last-elem)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const Stepper = styled((props: StepperProps) => (
    <MuiStepper orientation="vertical" connector={<></>} {...props} />
))(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(-2.5),
        marginRight: theme.spacing(-2.5),
        boxShadow: 'none',
        border: 0,
    },
}));

export const Step = styled((props: StepProps) => <MuiStep {...props} />)(
    ({ theme }) => ({
        '&.Mui-completed, .Mui-disabled': {
            overflow: 'hidden',
        },
    }),
);

export const StepLabel = styled((props: StepLabelProps) => (
    <MuiStepLabel {...props} />
))(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    background: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
    fontWeight: 'bold',
    padding: theme.spacing(1),
}));

export const StepContent = styled((props: StepContentProps) => (
    <MuiStepContent {...props} />
))(({ theme }) => ({
    margin: 0,
    padding: 0,
    border: 0,
    [theme.breakpoints.up('sm')]: {
        boxShadow: '0 0.0625em 0.1875em rgb(0 0 0 / 15%)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
    },
    backgroundColor: '#fff',
    '.MuiCollapse-wrapper': {
        border: 0,
        margin: '0 0 1rem 0',
        padding: theme.spacing(2, 3),
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            border: theme.palette.border,
            borderBottomColor: theme.palette.divider,
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        },
        width: '100%',
    },
}));

/**
 * EXPERT REVIEW BAR
 */
export const ExpertReviewRatingBar = styled(Box)(({ theme }) => ({
    borderLeft: '2px solid #4c5256',
    borderRight: '2px solid #4c5256',
    height: 32,
    margin: `${theme.spacing(2)} auto`,
    position: 'relative',
    width: '99.66667%',
}));

export const ExpertReviewBar = styled(Box)(({ theme }) => ({
    height: 2,
    backgroundColor: '#4c5256',
    position: 'absolute',
    top: '50%',
    right: 0,
    left: 0,
}));

export const ExpertReviewBulb = styled(Box)(({ theme }) => ({
    backgroundColor: '#307140',
    borderRadius: '100%',
    boxShadow: '0 0 0 4px rgb(48 113 64 / 25%)',
    height: 16,
    position: 'absolute',
    top: -8,
    transform: 'translateX(-50%)',
    width: 16,
}));

export const ColorSwatches = styled(Box)(({ theme }) => ({
    border: `1px solid ${theme.palette.common.white}`,
    display: 'flex',
    height: 24,
    width: 24,
    outline: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    '&> span': {
        display: 'block',
        height: '100%',
        width: '100%',
    },
}));
export const ColorSwatchContainer = styled(Box)({
    display: 'flex',
    padding: '1px',
    position: 'relative',
    gridAutoFlow: 'column',
    alignItems: 'center',
    height: 30,
    marginTop: '.5rem',
});
export const ColorSwatchContainerInner = styled(Box)({
    display: 'flex',
    padding: '1px',
    gap: theme.spacing(1),
    position: 'relative',
    marginRight: theme.spacing(1),
});
export const ColorSwatchSpan = styled(Box)({
    '&:hover': {
        cursor: 'pointer',
    },
    '&> span.active': {
        outlineColor: theme.palette.primary.main,
    },
});
export const ColorSwatchLink = styled(Link)({
    '&:hover': {
        cursor: 'pointer',
    },
});
export const ColorSwatchLinkRemaining = styled('span')({
    width: 80,
    textAlign: 'left',
    fontSize: 12,
});

export const ImageGalleryButton = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        whiteSpace: 'nowrap',
    },
    [theme.breakpoints.up('md')]: {
        svg: {
            color: 'white',
            fill: 'white',
        },
        alignItems: 'center',
        aspectRatio: '1/1',
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        minWidth: 'auto',
        padding: 0,
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
        width: '100%',
        '&:hover': {
            backgroundColor: theme.palette.secondary.dark,
            borderColor: theme.palette.secondary.dark,
            textDecoration: 'none',
        },
    },
}));

export const FooterLink = styled(Link)(({ theme }) => ({
    margin: isMobile ? '0 !important' : 'inherit',
    padding: theme.spacing(1, 0),
    display: 'block',
    transition: 'all 0.218s cubic-bezier(0.42, 0, 0.58, 1)',
    textDecoration: 'none',
}));

export const ChevronLink = styled(Link)(({ theme }) => ({
    display: 'block',
    lineHeight: '1.4',
    color: theme.palette.text.primary,
    fontFamily: SECONDARY_FONT_FAMILY,
    textDecoration: 'underline',
    '::after': {
        content: `""`,
        marginLeft: theme.spacing(0.5),
        borderColor: 'currentColor',
        borderStyle: 'solid',
        borderWidth: '0.125em 0.125em 0 0',
        display: 'inline-block',
        height: theme.spacing(1.2),
        width: theme.spacing(1.2),
        position: 'relative',
        textAlign: 'center',
        transform: 'rotate(45deg)',
        verticalAlign: 'middle',
        transition: 'all 0.109s cubic-bezier(0.42, 0, 0.58, 1)',
    },
    ':hover': {
        color: theme.palette.text.secondary,
        '::after': {
            marginLeft: theme.spacing(1),
            color: theme.palette.text.secondary,
        },
    },
}));
