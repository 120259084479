import { gql, useQuery } from '@apollo/client';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import {
    ProductsGridDisplay,
    ProductsGridLoading,
} from '../../Components/ProductGridItems/ProductGridItems';
import { ProductGridRefinement } from '../../Contexts/ProductGridRefinementProvider';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { PAGINATED_ITEMS } from '../../Graphql/fragments';
import { PaginatedItemsResponse, SortOption } from '../../types';

const QL_CAT = gql`
    ${PAGINATED_ITEMS}
    query getDepartmentItems(
        $departmentId: ID
        $page: Int
        $refinements: String
        $sort: String
        $pricingTierId: Int
    ) {
        departmentItems(
            deptId: $departmentId
            page: $page
            sort: $sort
            refinements: $refinements
            pricingTierId: $pricingTierId
        ) {
            ...PaginatedItems
        }
        searchSortOptions(type: "department") {
            label
            value
        }
    }
`;

const DepartmentProductsGridItems = (props: {
    departmentId: string;
    items?: PaginatedItemsResponse;
    sortOptions?: SortOption[];
}) => {
    const { page } = React.useContext(ProductGridRefinement);
    const { getPricingTierId } = React.useContext(UserAuth);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchPageParam = parseInt(searchParams.get('page') as string) || 0;
    const searchSortParam = (searchParams.get('sort') as string) || '';
    const page2 = searchPageParam > 0 ? searchPageParam : page;
    searchParams.delete('page');
    searchParams.delete('sort');
    const refinements = searchParams.toString();

    const { data, loading } = useQuery(QL_CAT, {
        fetchPolicy: 'cache-and-network',
        variables: {
            departmentId: props.departmentId,
            page: page2,
            sort: searchSortParam,
            refinements: refinements,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ProductsGridLoading />;
    }

    const items = props.items ?? data.departmentItems;

    return (
        <ProductsGridDisplay
            data={items}
            sortOptions={props.sortOptions ?? data.searchSortOptions}
            hideAlertIfNoResults={true}
            hideTitleIfNoResults={true}
        />
    );
};
export default function DepartmentProductsGrid(props: {
    departmentId?: string;
    loading?: boolean;
    items?: PaginatedItemsResponse;
    sortOptions?: SortOption[];
}) {
    if (props.departmentId === undefined) {
        return <ProductsGridLoading />;
    }

    if (props.items !== undefined) {
        return (
            <ProductsGridDisplay
                data={props.items}
                sortOptions={props.sortOptions}
                hideAlertIfNoResults={true}
                hideTitleIfNoResults={true}
            />
        );
    }

    return (
        <DepartmentProductsGridItems
            departmentId={props.departmentId}
            items={props.items}
            sortOptions={props.sortOptions}
        />
    );
}
