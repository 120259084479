import { Button } from '@mui/material';
import { useContext } from 'react';
import { CompareDrawerContext } from '../../Contexts/CompareDrawerProvider';
import { Item } from '../../types';
import { DesktopAndTabletDevice } from '../../Contexts/DeviceTypeProvider';
import { Box } from '@mui/system';

export const CompareDrawerButton = ({ item }: { item: Item }) => {
    const {
        compareButtonEnabled,
        addItemToCompareList,
        itemInCompareList,
        hasMaxItemsInCompareList,
    } = useContext(CompareDrawerContext);
    const disableButton = hasMaxItemsInCompareList() || itemInCompareList(item);

    return (
        <>
            {compareButtonEnabled && (
                <DesktopAndTabletDevice>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                            onClick={() => addItemToCompareList(item)}
                            sx={{
                                fontSize: '0.8rem',
                                opacity: disableButton ? 0.25 : 1,
                            }}
                            disabled={disableButton}
                        >
                            Compare
                        </Button>
                    </Box>
                </DesktopAndTabletDevice>
            )}
        </>
    );
};
