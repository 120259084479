import { FieldWithLabelProps } from '../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';
import { States } from '../../../Cart/PageCheckout/Forms/states';
import { SelectWithLabel } from './SelectWithLabel';
interface StatesWithLabelProps extends FieldWithLabelProps {
    data?: any;
}
export const StatesWithLabel = (props: StatesWithLabelProps) => {
    const {
        name = 'state',
        id,
        label = 'State',
        formik,
        sx,
        autoComplete = 'address-level1',
        ...rest
    } = props;
    const newStates = States.map(s => {
        return { label: s.name, value: s.code };
    });
    return (
        <SelectWithLabel
            id={id ?? name}
            name={name}
            formik={formik}
            label={label}
            autoComplete={autoComplete}
            sx={sx}
            data={newStates}
            {...rest}
        />
    );
};
