import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { SearchBarProvider} from '../../Contexts/SearchBarProvider';
import React from 'react';
import { ResultNotFoundDescription } from '../../Components/Component404/Component404';

export const ResultsNotFoundMessaging = ({
    title = "We're sorry, this page is no longer available.",
}: {
    title?: string | React.ReactNode;
}) => {
    return (
        <Box sx={{ py: 4 }}>
            <Typography variant="subtitle" sx={{ mb: 4 }} component="h1">
                {title}
            </Typography>
            <ResultNotFoundDescription />
        </Box>
    );
};

function Page404() {
    return (
        <ScContainer className="page404">
            <ResultsNotFoundMessaging />
        </ScContainer>
    );
}
export default memo(Page404);
