import * as Yup from 'yup';
import { isDevelopment } from '../config';

export const MIN_PASSWORD_CHARS = 6;
const MIN_TEXTFIELD_CHARS = 2;
const MAX_TEXTFIELD_CHARS = 50;
const MIN_TEXTAREA_CHARS = 2;
const MAX_TEXTAREA_CHARS = 10000;
const MIN_WISHLIST_CHARS = 2;
const MAX_WISHLIST_CHARS = 50;
const ZIPCODE_CHARS = 5;
const MIN_PHONE_NUMBER = 14;

const SHAPE_BASIC_FIELD = Yup.string()
    .min(MIN_TEXTFIELD_CHARS, 'Too Short!')
    .max(MAX_TEXTFIELD_CHARS, 'Too Long!')
    .trim();
const SHAPE_BASIC_TEXTAREA = Yup.string()
    .min(MIN_TEXTAREA_CHARS, 'Too Short!')
    .max(MAX_TEXTAREA_CHARS, 'Too Long!')
    .trim();
const SHAPE_EMAIL = Yup.string()
    .email('Enter a valid email address')
    .required('Enter your email address')
    .trim();

const SHAPE_PASSWORD = Yup.string()
    .min(
        MIN_PASSWORD_CHARS,
        `Password is too short - must be at least ${MIN_PASSWORD_CHARS} characters in length.`,
    )
    .max(MAX_TEXTFIELD_CHARS, 'Too Long!');

const SHAPE_OPT_IN = Yup.bool().oneOf(
    [true],
    'Accepting Terms and Conditions is required',
);

const SHAPE_FIRST_NAME = Yup.string().required('Enter your first name').trim();
const SHAPE_LAST_NAME = Yup.string().required('Enter your last name').trim();
const SHAPE_PHONE = Yup.string()
    .min(MIN_PHONE_NUMBER, 'Enter a valid 10-digit phone number')
    .test('phone', 'Enter a valid phone number', value => {
        if (!value) return false;
        const digitsOnly = value.replace(/\D/g, '');
        return digitsOnly.length >= 10;
    })
    .transform(value => {
        if (!value) return value;
        const digitsOnly = value.replace(/\D/g, '');
        let formatted = digitsOnly
            .substring(0, 10)
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        if (digitsOnly.length > 10) {
            formatted += ` ext. ${digitsOnly.slice(10)}`;
        }
        return formatted;
    })

    .required('Enter your phone number');
const SHAPE_ADDRESS_1 = Yup.string().required('Enter your address').trim();
const SHAPE_ADDRESS_2 = Yup.string().trim();
const SHAPE_CITY = SHAPE_BASIC_FIELD.required('Enter your city');
const SHAPE_STATE = Yup.string().required('Select your state');
const SHAPE_ZIP = Yup.string()
    .matches(/^[0-9]+$/, 'Enter a valid zip code')
    .min(ZIPCODE_CHARS, 'Enter a valid zip code')
    .max(ZIPCODE_CHARS, 'Enter a valid zip code')
    .required('Enter your zip code')
    .trim();

const SHAPE_GIFT_CARD_CODE = SHAPE_BASIC_FIELD.required(
    'Enter a gift card code',
);

const SHAPE_ORDER_NUMBER = Yup.string()
    .min(8, 'Too Short!')
    .max(15, 'Too Long!')
    .matches(/^N2[0-9]+(C|S|B)?$/, 'Invalid order number')
    .required('Enter an Order Number');

const SHAPE_ADDRESS = {
    firstName: SHAPE_FIRST_NAME,
    lastName: SHAPE_LAST_NAME,
    email: SHAPE_EMAIL,
    phone: SHAPE_PHONE,
    company: SHAPE_BASIC_FIELD,
    address1: SHAPE_ADDRESS_1,
    address2: SHAPE_ADDRESS_2,
    city: SHAPE_CITY,
    state: SHAPE_STATE,
    zip: SHAPE_ZIP,
};

export const PasswordSchema = Yup.object().shape({
    currentPassword: SHAPE_PASSWORD.required(
        'Please enter your current password',
    ),

    createNewPassword: isDevelopment()
        ? SHAPE_PASSWORD.required(
              'Must Contain ' + MIN_PASSWORD_CHARS + ' characters',
          )
        : SHAPE_PASSWORD.required('Please enter your new password'),
    confirmNewPassword: SHAPE_PASSWORD.oneOf(
        [Yup.ref('createNewPassword'), null],
        'Passwords must match',
    ).required('Please confirm your new password'),
});

export const EditAccountFormSchema = Yup.object().shape({
    ...SHAPE_ADDRESS,
    country: SHAPE_BASIC_FIELD.required('Please enter your country'),
});

export const CreateWishlistFormSchema = Yup.object().shape({
    title: Yup.string()
        .min(MIN_WISHLIST_CHARS, 'Too Short!')
        .max(MAX_WISHLIST_CHARS, 'Too Long!')
        .required('Enter a title')
        .trim(),
});

export const EditWishlistFormSchema = Yup.object().shape({
    title: Yup.string()
        .min(MIN_WISHLIST_CHARS, 'Too Short!')
        .max(MAX_WISHLIST_CHARS, 'Too Long!')
        .required('Enter a title')
        .trim(),
    visibility: Yup.string()
        .oneOf(['public', 'private'], 'Invalid list visibility')
        .required('Please select an option'),
});

export const GiftCardSchema = Yup.object().shape({
    code: SHAPE_GIFT_CARD_CODE,
});

export const GiftCardApplySchema = Yup.object().shape({
    code: SHAPE_GIFT_CARD_CODE,
    balance: Yup.string().required('Balance is required'),
    payType: Yup.string()
        .oneOf(['payall', 'paydiff'], 'Invalid value paytype')
        .required('Select a pay type')
        .trim(),
    paydiffAmount: Yup.number()
        .test(
            'maxDigitsAfterDecimal',
            'number field must have 2 digits after decimal or less',
            number => /^\d+(\.\d{1,2})?$/.test(String(number)),
        )
        .typeError('Value must be a number')
        .required('Enter an amount'),
});

export const ForgotPasswordSchema = Yup.object().shape({
    email: SHAPE_EMAIL,
});

export const LoginSchema = Yup.object().shape({
    loginEmail: SHAPE_EMAIL,
    loginPassword: SHAPE_PASSWORD.required('Password is required'),
});

export const RegistrationSchema = Yup.object().shape({
    emailRegister: SHAPE_EMAIL,
    passwordRegister: SHAPE_PASSWORD.required('Password is required'),
    passwordConfirm: SHAPE_PASSWORD.oneOf(
        [Yup.ref('passwordRegister'), null],
        'Passwords must match',
    ).required('Password is required'),
    optInDescription: SHAPE_OPT_IN.required(
        'Accepting Terms and Conditions is required',
    ),
});

export const UpdatePasswordSchema = Yup.object().shape({
    email: SHAPE_EMAIL,
    newPassword: SHAPE_PASSWORD.notOneOf(
        [Yup.ref('currentPassword')],
        'Can not be the same as current password',
    ).required('New Password is required'),
    newPasswordConfirm: SHAPE_PASSWORD.oneOf(
        [Yup.ref('newPassword'), null],
        'Passwords must match',
    ),
    token: Yup.string().required('Token is required'),
});

export const CouponCodeSchema = Yup.object().shape({
    code: SHAPE_BASIC_FIELD.required('Enter a coupon code'),
});

export const AddressFormSchema = Yup.object().shape(SHAPE_ADDRESS);
export const BillingFormSchema = Yup.object().shape(SHAPE_ADDRESS);
export const ShippingFormSchema = Yup.object().shape(SHAPE_ADDRESS);

export const CreditCardFormSchema = Yup.object().shape({
    number: Yup.string().required('Enter a credit card number').trim(),
    expirationDate: Yup.string().required('Enter an expiration date').trim(),
    cvv: Yup.string()
        .min(3, 'Too short')
        .max(4, 'Too long')
        .required('Enter a CVV Code')
        .trim(),
});

export const NewsletterSignupSchema = Yup.object().shape({
    email: SHAPE_EMAIL,
});
export const NewsletterSignupCheckboxSchema = Yup.object().shape({
    email: SHAPE_EMAIL,
    newsletterSignup: Yup.boolean().oneOf(
        [true, false],
        'Invalid signup option',
    ),
});
export const NotifyMeWhenInStockSchema = Yup.object().shape({
    email: SHAPE_EMAIL,
    newsletterSignup: Yup.boolean().oneOf(
        [true, false],
        'Invalid signup option',
    ),
});

export const OrderStatusSchema = Yup.object().shape({
    orderNumber: SHAPE_ORDER_NUMBER,
    zipCode: SHAPE_ZIP,
});

export const ContactUsSchema = Yup.object().shape({
    fullName: SHAPE_FIRST_NAME.required('First Name is required'),
    orderNumber: Yup.string(),
    emailAddress: SHAPE_EMAIL.required('Email Address is required'),
    phoneNumber: SHAPE_PHONE.required('Phone number is required'),
    subject: Yup.string().required('Subject is required'),
    comment: Yup.string()
        .required('Comment is required'),
});

export const ContactUsProSchema = Yup.object().shape({
    fullName: SHAPE_FIRST_NAME.required('First Name is required'),
    orderNumber: SHAPE_ORDER_NUMBER,
    emailAddress: SHAPE_EMAIL.required('Email Address is required'),
    phoneNumber: SHAPE_PHONE.required('Phone number is required'),
    businessType: Yup.string(),
    subject: Yup.string().required('Subject is required'),
    comment: Yup.string()
        .required('Comment is required')
        .min(15, 'Comment should be at least 15 characters.'),
});

export const SearchTermSchema = Yup.object().shape({
    term: Yup.string().required('Search term is required').trim(),
});

export const VendorFormSchema = Yup.object().shape({
    name: SHAPE_FIRST_NAME.required('Enter your name'),
    title: SHAPE_BASIC_FIELD,
    email: SHAPE_EMAIL.required('Enter your email address'),
    phone: SHAPE_PHONE.required('Enter your phone number'),
    companyName: SHAPE_FIRST_NAME.required('Enter your company name'),
    companyAddress: SHAPE_ADDRESS_1,
    companyCity: SHAPE_CITY,
    companyState: SHAPE_STATE,
    companyZip: SHAPE_ZIP,
    companyPhone: SHAPE_PHONE.required('Enter your company phone number'),
    companyHistory: SHAPE_BASIC_TEXTAREA.required('Enter your company history'),
    productCategory: SHAPE_BASIC_FIELD,
    productManufacturer: SHAPE_BASIC_FIELD,
    productBrief: SHAPE_BASIC_TEXTAREA.required(
        'Explanation of product fit is required',
    ),
    productStores: SHAPE_BASIC_FIELD.required('This field is required'),
    productStoreList: SHAPE_BASIC_FIELD,
    productForm: SHAPE_BASIC_FIELD.required('This field is required'),
    productMargins: SHAPE_BASIC_FIELD,
    productDropShop: SHAPE_BASIC_FIELD.required('This field is required'),
    productReturns: SHAPE_BASIC_FIELD.required('This field is required'),
});
