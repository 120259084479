import React from 'react';
import AddToCart from '../../../../../../Components/Buttons/AddToCart/AddToCart';
import { OpenBoxItem } from '../../../../../../types';
import OpenBoxConfirmationModal from '../OpenBoxConfirmationModal/OpenBoxConfirmationModal';

const OpenBoxAddToCart = ({
    item,
    onAdded,
}: {
    item: OpenBoxItem;
    onAdded: () => void;
}) => {
    const [showConfirmationModal, setShowConfirmationModal] =
        React.useState(false);
    const CONFIRMATION_TITLE = 'Open-Box Notice';
    const CONFIRMATION_CONTENT_ID = '42231';
    return (
        <>
            <AddToCart
                item={item}
                fullWidth={true}
                onItemsAdded={() => {
                    onAdded();
                }}
                onClick={
                    item.condition === 'New'
                        ? undefined
                        : () => {
                              setShowConfirmationModal(true);
                          }
                }
            />
            {showConfirmationModal && (
                <OpenBoxConfirmationModal
                    item={item}
                    title={CONFIRMATION_TITLE}
                    contentId={CONFIRMATION_CONTENT_ID}
                    onClose={() => setShowConfirmationModal(false)}
                />
            )}
        </>
    );
};

export default OpenBoxAddToCart;
