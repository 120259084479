import { Box, Grid, Typography } from '@mui/material';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';
import { date } from '../../../../../functions';
import { CustomerReview, Item } from '../../../../../types';
import CustomerReviewsHelpfulButton from '../Components/Buttons/CustomerReviewsHelpfulButton';
import CustomerReviewsUnhelpfulButton from '../Components/Buttons/CustomerReviewsUnhelpfulButton';
import CustomerReviewsSyndicationLink from '../Components/CustomerReviewsSyndicationLink';
import CustomerReviewsTotalFeedback from '../Components/CustomerReviewsTotalFeedback';
import CustomerReviewsVerifiedPurchaser from '../Components/CustomerReviewsVerifiedPurchaser';
import CustomerReviewsMediaInlineImages from '../Components/CustomReviewsMediaInlineImages';

const CustomerReviewsContentMobile = (props: {
    review: CustomerReview;
    item: Item;
}) => {
    const { review, item } = props;
    return (
        <Box sx={{ mb: 1 }}>
            <Grid container sx={{ alignItems: 'center', mb: 1 }}>
                <Grid item xs={6}>
                    <StarRatings
                        rating={review.rating || 0}
                        item={item}
                        readOnly={true}
                        showCount={false}
                        size="large"
                    />
                </Grid>
                <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <CustomerReviewsVerifiedPurchaser review={review} />
                </Grid>
            </Grid>
            <Typography variant="bold">{review.title}</Typography>
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    gap: 1,
                    mb: 1,
                }}
            >
                <Typography variant="muted" sx={{ mr: 1 }}>
                    {review.name}
                </Typography>
                {/* <Typography variant="muted" sx={{ mr: 1 }}>{review.location}</Typography> */}
                <Typography variant="muted" sx={{ mr: 1, textAlign: 'right' }}>
                    {review.location !== 'Undisclosed' && review.location}
                </Typography>
            </Box>
            <Typography variant="body1">{review.text}</Typography>
            {review && review.media && review.media.length > 0 && (
                <CustomerReviewsMediaInlineImages media={review.media} />
            )}
            <CustomerReviewsTotalFeedback
                review={review}
                fallbackComponent={
                    <Typography
                        variant="muted"
                        component="div"
                        sx={{ display: 'flex', gap: 1, my: 1 }}
                    >
                        Was This Helpful?
                        <CustomerReviewsHelpfulButton review={review} />
                        <CustomerReviewsUnhelpfulButton review={review} />
                    </Typography>
                }
            />
            <Typography component="div" variant="muted">
                Review published on {date.format(new Date(review.datetime))}
            </Typography>

            <CustomerReviewsSyndicationLink review={review} />
        </Box>
    );
};

export default CustomerReviewsContentMobile;
