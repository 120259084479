import { Button, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddToCart from '../../../../../../Components/Buttons/AddToCart/AddToCart';
import { loadContent } from '../../../../../../Components/_base/BBQGuysComponents';
import Modal from '../../../../../../Components/_base/BBQGuysModal';
import { Content, OpenBoxItem } from '../../../../../../types';
import { RenderContentData } from '../../../../../PageContent/PageContent';

const OpenBoxConfirmationModal = ({
    item,
    title,
    contentId,
    onClose,
}: {
    item: OpenBoxItem;
    title: string;
    contentId: string;
    onClose: () => void;
}) => {
    const [content, setContent] = React.useState<Content | null>(null);
    const navigate = useNavigate();
    loadContent(contentId).then((content: Content) => {
        setContent(content);
    });

    return content ? (
        <Modal
            open={true}
            handleClose={() => {
                onClose();
            }}
            title={title}
        >
            <DialogContent>
                <RenderContentData content={content} />
            </DialogContent>
            <DialogActions>
                <Button variant="link" onClick={() => onClose()}>
                    Cancel
                </Button>
                <AddToCart
                    item={item}
                    fullWidth={false}
                    size="medium"
                    label="I Agree, Continue to Cart"
                    onItemsAdded={() => {
                        onClose();
                        navigate('/cart');
                    }}
                    showTrapModal={false}
                />
            </DialogActions>
        </Modal>
    ) : (
        <></>
    );
};

export default OpenBoxConfirmationModal;
