import { Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useRef, useState } from 'react';
import {
    DesktopAndTabletDevice,
    MobileDevice,
    isDesktop,
} from '../../../../Contexts/DeviceTypeProvider';
import { client } from '../../../../Graphql/ApolloClient';
import { QL_ITEMS_BY_IDS } from '../../../../Graphql/queries';
import theme from '../../../../Theme';
import { ColorSwatch, Item, Maybe, Promotion } from '../../../../types';
import AddToCart from '../../../Buttons/AddToCart/AddToCart';
import { CompareDrawerButton } from '../../../CompareDrawer/CompareDrawerButton';
import { Image } from '../../../Image';
import { LeavesWarehouseIn } from '../../../Item/ItemInfo';
import { PriceDisplay } from '../../../Item/Pricing';
import {
    PromoFlag,
    PromotionDisplay,
} from '../../../PromoCountdown/PromoCountdown';
import StarRatings from '../../../StarRatings/StarRatings';
import {
    BulletedList,
    LineEllipsis,
    MediaRow,
} from '../../../_base/BBQGuysComponents';
import { QuickView } from '../../../_base/BBQGuysQuickView';
import {
    DeleteProductCardButton,
    ItemColorSwatch,
    ProductCardColorSwatches,
    ProductCardOptions,
    ViewItemButton,
} from '../../ProductCards';
import './../../ProductCards.scss';
import { getEnvironmentVariable } from '../../../../config';

const ITEM_DATA = new Map();

const ProductCardDefaultList = (props: ProductCardOptions) => {
    const {
        item,
        withAddToCartButton = false,
        withViewItemButton = false,
        withLeadTime = true,
        lazyLoad = true,
        withQuickView = true,
        withMoreOptionsAvailable = false,
        withBorderSeparator = true,
        trackingType,
        trackingGroup,
        onDelete,
    } = props;
    const [quickViewVisible, setQuickViewVisible] = useState(false);
    const IMAGE_SIZE = isDesktop ? 300 : 85;

    const [currentItem, setCurrentItem] = useState(item);
    const [loading, setLoading] = useState(false);
    const imgContainerRef = useRef<HTMLAnchorElement>(null);

    const handleProductCardSwap = (
        itemId: string,
        colorSwatches: ItemColorSwatch,
    ): Promise<Item> => {
        return new Promise((resolve, reject) => {
            (async function () {
                if (!ITEM_DATA.has(itemId)) {
                    const response = await client.query({
                        query: QL_ITEMS_BY_IDS,
                        variables: {
                            itemIds: Object.values(colorSwatches)
                                .map((c: Maybe<ColorSwatch>, i) => {
                                    if (c) {
                                        return c.itemId;
                                    }
                                })
                                .join(','),
                        },
                    });

                    if (response.error) {
                        reject();
                    }
                    if (
                        response.data &&
                        response.data.itemsByIds?.totalResults > 1
                    ) {
                        response.data.itemsByIds.results.forEach(
                            (itm: Item) => {
                                ITEM_DATA.set(itm.id, itm);
                            },
                        );
                        resolve(ITEM_DATA.get(itemId));
                    }
                } else {
                    resolve(ITEM_DATA.get(itemId));
                }
            })();
        });
    };
    const productHasMoreOptions = currentItem?.moreOptionsAvailable ?? 0 > 1;

    return (
        <MediaRow
            sx={{
                pb: 2,
                pt: 2,
                backgroundColor: theme.palette.background.default,
                borderWidth: 0,
                borderTopWidth: withBorderSeparator ? 1 : 0,
                borderStyle: 'solid',
                borderColor: '#ccc',
            }}
            image={
                <Box sx={{ mt: 2 }}>
                    <DesktopAndTabletDevice>
                        {currentItem.promotions && currentItem.promotions[0] ? (
                            <PromoFlag
                                promotion={
                                    currentItem.promotions[0] as Promotion
                                }
                            />
                        ) : null}
                    </DesktopAndTabletDevice>
                    <Box
                        sx={{ position: 'relative', width: IMAGE_SIZE }}
                        onMouseEnter={() => setQuickViewVisible(true)}
                        onMouseLeave={() => setQuickViewVisible(false)}
                    >
                        <Link
                            href={currentItem.url || '#'}
                            className="img-container"
                            ref={imgContainerRef}
                            sx={{ pr: isDesktop ? 3 : 1, display: 'block' }}
                        >
                            <Image
                                src={
                                    loading
                                        ? getEnvironmentVariable(
                                              'REACT_APP_CLEAR_PIXEL',
                                          )
                                        : (currentItem.imageUrl as string)
                                }
                                alt={currentItem.name + ' image'}
                                width={IMAGE_SIZE}
                                lazyLoadImage={lazyLoad}
                                data-parent-product-group={trackingGroup}
                                data-parent-product-group-type={trackingType}
                                data-product-id={item.id}
                            />
                        </Link>
                        {withQuickView && (
                            <QuickView
                                item={currentItem}
                                visible={quickViewVisible}
                            />
                        )}
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <ProductCardColorSwatches
                            item={item}
                            onlyShowIfSwatchesExist
                            swatchesToShow={6}
                            onHover={(
                                swatch: ColorSwatch,
                                swatches: ItemColorSwatch,
                            ) => {
                                setLoading(true);
                                if (imgContainerRef.current) {
                                    imgContainerRef.current.style.width =
                                        imgContainerRef.current?.offsetWidth +
                                        'px';
                                    imgContainerRef.current.style.height =
                                        imgContainerRef.current?.offsetHeight +
                                        'px';
                                    imgContainerRef.current.style.overflow =
                                        'hidden';
                                }
                                handleProductCardSwap(swatch.itemId, swatches)
                                    .then((itemData: Item) => {
                                        setCurrentItem(itemData);
                                    })
                                    .finally(() => {
                                        setLoading(false);
                                    });
                            }}
                        />
                    </Box>
                    <CompareDrawerButton item={item} />
                </Box>
            }
        >
            <Stack
                direction={{ xs: 'column', md: 'row' }}
                display="grid"
                gridTemplateColumns={{ xs: '1fr', md: '9fr 3fr' }}
                gap={{ xs: 1, md: 3 }}
            >
                <div>
                    <Link
                        variant="noUnderline"
                        href={currentItem.url || '#'}
                        className="name"
                    >
                        <LineEllipsis maxLines={2} text={currentItem.name} />
                        {withMoreOptionsAvailable && productHasMoreOptions && (
                            <Typography
                                component="span"
                                variant="small"
                                sx={{
                                    fontSize: theme =>
                                        theme.typography.caption.fontSize,
                                    visibility: productHasMoreOptions
                                        ? 'visible'
                                        : 'hidden',
                                }}
                            >
                                <>+ More Options</>
                            </Typography>
                        )}
                    </Link>
                    {currentItem.userReviewsRating > 0 &&
                    currentItem.userReviewsCount > 0 ? (
                        <Typography
                            variant="body2"
                            component="div"
                            className="user-rating"
                        >
                            <StarRatings
                                rating={currentItem.userReviewsRating}
                                item={item}
                                count={currentItem.userReviewsCount || 0}
                                showCountText={false}
                                size="small"
                                sx={{ my: 1 }}
                            />
                        </Typography>
                    ) : null}
                    {currentItem.promotions ? (
                        <MobileDevice>
                            {currentItem.promotions.map(
                                (p: Maybe<Promotion>, i: number) => {
                                    return p ? (
                                        <PromotionDisplay
                                            key={i}
                                            item={currentItem}
                                            promotion={p as Promotion}
                                            variant="small"
                                        />
                                    ) : null;
                                },
                            )}
                        </MobileDevice>
                    ) : null}
                    <DesktopAndTabletDevice>
                        {currentItem.bulletPoints ? (
                            <BulletedList bullets={currentItem.bulletPoints} />
                        ) : null}
                    </DesktopAndTabletDevice>
                </div>
                <Box textAlign={{ xs: 'left', md: 'right' }}>
                    <DesktopAndTabletDevice>
                        {currentItem.pricing && (
                            <PriceDisplay item={currentItem} variant="small" />
                        )}
                        {withLeadTime && (
                            <LeavesWarehouseIn
                                shipsIn={currentItem.shipsIn}
                                size="medium"
                            />
                        )}
                    </DesktopAndTabletDevice>
                    <MobileDevice>
                        {currentItem.pricing && (
                            <PriceDisplay item={currentItem} variant="small" />
                        )}
                        {withLeadTime && (
                            <LeavesWarehouseIn
                                shipsIn={currentItem.shipsIn}
                                size="small"
                            />
                        )}
                    </MobileDevice>
                    {withAddToCartButton ? (
                        <>
                            <AddToCart
                                item={currentItem}
                                withIcon={false}
                                fullWidth
                                sx={{ mb: 2 }}
                                trackingType={trackingType}
                                trackingGroup={trackingGroup}
                            />
                        </>
                    ) : null}
                    {withViewItemButton && (
                        <ViewItemButton item={currentItem} />
                    )}
                </Box>
            </Stack>
            <DeleteProductCardButton item={item} onDelete={onDelete} />
        </MediaRow>
    );
};
export default ProductCardDefaultList;
