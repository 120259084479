import { Box, Stack, Typography } from '@mui/material';
import { HelpTipIcon } from '../../../../../../Components/_base/BBQGuysComponents';
import { OpenBoxItem } from '../../../../../../types';
import OpenBoxItemRow from './OpenBoxItemRow';
import { OpenBoxItemGroupData } from '../../OpenBoxItems';

const OpenBoxItemGroupRow = ({
    openBoxItemGroupData,
    onAdded,
}: {
    openBoxItemGroupData: OpenBoxItemGroupData;
    onAdded: () => void;
}) => {
    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                sx={{ borderBottom: theme => theme.palette.border }}
            >
                <Typography alignItems="center" component="h3" variant="bold">
                    {(openBoxItemGroupData.condition != 'New'
                        ? 'Open-Box '
                        : '') + openBoxItemGroupData.condition}
                </Typography>
                <HelpTipIcon contentId="19724" title="Open-Box Conditions" />
            </Stack>
            <Box
                display="grid"
                rowGap={2}
                sx={{
                    '&> div:not(:first-of-type)': {
                        borderTop: theme => theme.palette.border,
                    },
                }}
            >
                {openBoxItemGroupData.items.map(
                    (openBoxItem: OpenBoxItem, i: number) => {
                        return (
                            <OpenBoxItemRow
                                key={i}
                                item={openBoxItem}
                                showTitle={false}
                                onAdded={onAdded}
                            />
                        );
                    },
                )}
            </Box>
        </>
    );
};

export default OpenBoxItemGroupRow;
