import { Button, SxProps } from '@mui/material';
import { useState } from 'react';
import { Item } from '../../../../../../types';
import PowerReviewsWriteModal from '../../../CustomerReviews/PowerReviewsWriteModal';

const QuestionsAndAnswersWriteQuestionButton = (props: {
    item: Item;
    sx?: SxProps;
}) => {
    const { item, sx = {} } = props;
    const [writeQuestionOpen, setWriteQuestionOpen] = useState(false);

    const handleWriteQuestion = () => {
        return setWriteQuestionOpen(true);
    };

    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => handleWriteQuestion()}
                sx={sx}
            >
                Ask Question
            </Button>
            <PowerReviewsWriteModal
                type="qa"
                item={item}
                open={writeQuestionOpen}
                handleClose={() => setWriteQuestionOpen(false)}
            />
        </>
    );
};

export default QuestionsAndAnswersWriteQuestionButton;
