import {
    FieldWithLabel,
    FieldWithLabelProps,
} from '../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';

export const EmailWithLabel = (props: FieldWithLabelProps) => {
    const {
        id = 'email',
        name = 'email',
        label = 'Email Address',
        type = 'email',
        autoComplete = 'email',
        autoCorrect = 'off',
        spellCheck = 'false',
        ...rest
    } = props;
    return (
        <FieldWithLabel
            id={id}
            name={name}
            label={label}
            type={type}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
            spellCheck={spellCheck}
            {...rest}
        />
    );
};
