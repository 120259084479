import { useQuery } from '@apollo/client';
import { Skeleton, Typography } from '@mui/material';
import React from 'react';
import ImageCardCarousel, {
    ImageCardData,
} from '../../../Components/Carousels/ImageCardCarousel/ImageCardCarousel';
import { ProductCarouselLoading } from '../../../Components/ProductCarousels/ProductCarousel/ProductCarousel';
import {
    ChildDepartments,
    ChildDepartmentsLoading,
    Section,
} from '../../../Components/_base/BBQGuysComponents';
import { QL_DEPT_COLLECTIONS } from '../../../Graphql/queries';
import { Department, DepartmentCollection } from '../../../types';

const DepartmentCollections = ({ deptId }: { deptId: string }) => {
    const { data, loading } = useQuery(QL_DEPT_COLLECTIONS, {
        variables: {
            departmentId: deptId,
        },
    });

    if (loading) {
        return (
            <>
                <ChildDepartmentsLoading />
                <br />
                <Skeleton variant="rectangular" height={1} />
                <br />
                <ProductCarouselLoading title={''} />
                <br />
                <Skeleton variant="rectangular" height={1} />
                <br />
                <ProductCarouselLoading title={''} />
                <br />
                <Skeleton variant="rectangular" height={1} />
                <br />
                <ProductCarouselLoading title={''} count={5} />
            </>
        );
    }

    if (data === undefined || data.departmentCollections === undefined) {
        return <></>;
    }

    const deptCollections = [...data.departmentCollections.results];
    const firstCollection = deptCollections.shift();

    return (
        <>
            {firstCollection?.departments?.length > 0 && (
                <Section withTopBorder={false}>
                    <Typography component="h2" className="visually-hidden">
                        Popular Categories
                    </Typography>
                    <ChildDepartments
                        departments={
                            firstCollection.departments as Department[]
                        }
                    />
                </Section>
            )}
            {deptCollections.map(
                (collection: DepartmentCollection, i: number) => {
                    return (
                        collection?.departments?.length > 0 && (
                            <React.Fragment key={i}>
                                <ImageCardCarousel
                                    withCardBorder={false}
                                    withCardTitle={true}
                                    title={collection.name}
                                    countToShow={4}
                                    alignTitle="center"
                                    imageCardData={(
                                        collection.departments as Department[]
                                    ).map((d: Department) => {
                                        return {
                                            url: d.url,
                                            src: d.imageUrl,
                                            title: d.title,
                                            alt: d.title,
                                        } as ImageCardData;
                                    })}
                                />
                            </React.Fragment>
                        )
                    );
                },
            )}
        </>
    );
};

export default DepartmentCollections;
