import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { QL_ITEM_LEARNING_CENTER } from '../../../../Graphql/queries';
import ItemLearningCenterLoading from './ItemLearningCenterLoading';
import LearningCenterDocuments from './LearningCenterDocuments';
import LearningCenterLinks from './LearningCenterLinks';
import LearningCenterVideos from './LearningCenterVideos';

export default function ItemLearningCenter(props: { itemId: string }) {
    const { itemId } = props;

    const { data, loading } = useQuery(QL_ITEM_LEARNING_CENTER, {
        variables: {
            itemId: itemId,
        },
    });

    if (loading) {
        return <ItemLearningCenterLoading />;
    }

    const learningCenter = data.itemLearningCenterLinks;

    const hasLearningCenterLinks =
        learningCenter &&
        learningCenter.links &&
        learningCenter.links.length > 0;
    const hasLearningCenterVideos =
        learningCenter &&
        learningCenter.videos &&
        learningCenter.videos.length > 0;
    const hasLearningCenterDocuments =
        learningCenter &&
        learningCenter.documents &&
        learningCenter.documents.length > 0;
    return hasLearningCenterLinks || hasLearningCenterVideos ? (
        <Section
            title="Learning Center"
            expandable={false}
            id="learning-center-articles"
        >
            <Box
                gap={4}
                display="grid"
                gridTemplateColumns={{
                    xs: '1fr',
                    md: '1fr 1fr',
                    lg: '1fr 1fr 1fr',
                }}
            >
                {hasLearningCenterLinks ? (
                    <Box>
                        <Typography variant="bold">Related Articles</Typography>
                        <LearningCenterLinks links={learningCenter.links} />
                    </Box>
                ) : (
                    <></>
                )}
                {hasLearningCenterDocuments ? (
                    <Box>
                        <Typography variant="bold">
                            Manufacturer Downloads
                        </Typography>
                        <LearningCenterDocuments
                            documents={learningCenter.documents}
                        />
                    </Box>
                ) : (
                    <></>
                )}
                {hasLearningCenterVideos ? (
                    <Box>
                        <Typography variant="bold">Product Videos</Typography>
                        <LearningCenterVideos videos={learningCenter.videos} />
                    </Box>
                ) : (
                    <></>
                )}
            </Box>
        </Section>
    ) : (
        <></>
    );
}
