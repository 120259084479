import * as React from 'react';
import { Locations, MenuItem, Site, SiteHeaderPromotions } from '../types';

const siteContextObj = {
    site: {} as Site,
    email: 'customerservice@bbqguys.com',
    setEmail: (val: string) => {
        return;
    },
    phone: '',
    setPhone: (val: string) => {
        return;
    },
    phoneHours: '',
    headerPromo: {
        url: '/promotions',
        textColor: '#fff',
        backgroundColor: '#333',
        text: "Today's Deals | Save up to 60% Now >",
    } as SiteHeaderPromotions,
    locations: {} as Locations,
    freeShippingAmount: 49,
    freeShippingPrice: '$49',
    flatRateShippingAmount: 6.95,
    flatRateShippingPrice: '$6.95',
    setHeaderPromo: (promos: SiteHeaderPromotions) => {
        return;
    },
    setSite: (val: Site) => {
        return;
    },
    topMenu: [] as MenuItem[],
    setTopMenu: (val: MenuItem[]) => {
        return;
    },
    mainMenu: [] as MenuItem[],
    setMainMenu: (val: MenuItem[]) => {
        return;
    },
    footerMenu: [] as MenuItem[],
    setFooterMenu: (val: MenuItem[]) => {
        return;
    },
    hasError: false,
    setHasError: (val: boolean) => {
        return;
    },
};

export const SiteContext = React.createContext(siteContextObj);

export function SiteProvider(props: {
    site: Site;
    headerPromo: SiteHeaderPromotions;
    topMenu: MenuItem[];
    mainMenu: MenuItem[];
    footerMenu: MenuItem[];
    children: any;
}) {
    const [site, setSiteData] = React.useState(props.site);
    const [email, setEmail] = React.useState('customerservice@bbqguys.com');
    const [phone, setPhone] = React.useState(
        props.site.locations?.website?.phone || '',
    );
    const [phoneHours, setPhoneHours] = React.useState(
        props.site.locations?.website?.hours?.primary || '',
    );
    const freeShippingAmount =
        props.site.config?.freeShipping || siteContextObj.freeShippingAmount;
    const freeShippingPrice = siteContextObj.freeShippingPrice;
    const flatRateShippingAmount = siteContextObj.flatRateShippingAmount;
    const flatRateShippingPrice = siteContextObj.flatRateShippingPrice;
    const [locations, setLocations] = React.useState(
        props.site.locations || {},
    );

    const [topMenu, setTopMenu] = React.useState(props.topMenu);
    const [mainMenu, setMainMenu] = React.useState(props.mainMenu);
    const [footerMenu, setFooterMenu] = React.useState(props.footerMenu);
    const [headerPromo, setHeaderPromo] = React.useState(
        props.headerPromo ?? siteContextObj.headerPromo,
    );
    const [hasError, setHasError] = React.useState(siteContextObj.hasError);

    const setSite = (val: Site) => {
        setPhone(val.locations?.website?.phone || '');
        setPhoneHours(val.locations?.website?.hours?.primary || '');
        setSiteData(val);
        setLocations(val.locations || {});
    };
    return (
        <SiteContext.Provider
            value={{
                site,
                email,
                setEmail,
                phone,
                setPhone,
                phoneHours,
                headerPromo,
                freeShippingAmount,
                freeShippingPrice,
                flatRateShippingAmount,
                flatRateShippingPrice,
                setHeaderPromo,
                setSite,
                topMenu,
                setTopMenu,
                mainMenu,
                setMainMenu,
                footerMenu,
                setFooterMenu,
                locations,
                setHasError,
                hasError,
            }}
        >
            {props.children}
        </SiteContext.Provider>
    );
}
