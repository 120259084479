import document from 'global/document';

export default class AdobeTargetEvent {
    static adobeTargetEventLabel: 'AdobeTargetEvent';
    static resetAdobeTargetEventListeners() {
        document.body.adobeTargetEventListeners = {
            listeners: {},
            push: function (eventFilter: string, eventMethod: any) {
                if (
                    !Object.prototype.hasOwnProperty.call(
                        this.listeners,
                        eventFilter,
                    )
                ) {
                    this.listeners[eventFilter] =
                        document.body.addEventListener(
                            AdobeTargetEvent.adobeTargetEventLabel,
                            function () {
                                if (!document.body.adobeTargetEvents) {
                                    return;
                                }
                                const eventContent =
                                    document.body.adobeTargetEvents.popEventContent(
                                        eventFilter,
                                    );

                                if (eventContent) {
                                    eventMethod(eventContent);
                                }
                            },
                        );
                }
                // Dispatch the event to pick up anything in the queue
                document.body.dispatchEvent(
                    new CustomEvent(AdobeTargetEvent.adobeTargetEventLabel),
                );
            },
            clear: function () {
                for (const eventFilter in this.listeners) {
                    document.body.removeEventListener(
                        AdobeTargetEvent.adobeTargetEventLabel,
                        this.listeners[eventFilter],
                        false,
                    );
                }
                this.listeners = {};
            },
        };
    }
    static initializeAdobeTargetEventListeners() {
        if (!document.body.adobeTargetEventListeners) {
            this.resetAdobeTargetEventListeners();
        }
    }
    static resetAdobeTargetEvents() {
        document.body.adobeTargetEvents = {
            queue: {},
            popEventContent: function (eventName: string) {
                if (
                    !Object.prototype.hasOwnProperty.call(this.queue, eventName)
                ) {
                    return false;
                }
                const eventContent = this.queue[eventName];
                delete eventContent[eventName];
                return eventContent;
            },
            push: function (eventName: string, eventContent: any) {
                this.queue[eventName] = eventContent;
                // Now fire the Adobe Target custom event, so the tests can check the queue
                document.body.dispatchEvent(
                    new CustomEvent(AdobeTargetEvent.adobeTargetEventLabel),
                );
            },
        };
    }
    static initializeAdobeTargetEvents() {
        if (!document.body.adobeTargetEvents) {
            this.resetAdobeTargetEvents();
        }
    }
    static reset() {
        this.resetAdobeTargetEvents();
        this.resetAdobeTargetEventListeners();
    }
    static dispatch(eventName: string, eventContent: any) {
        try {
            this.initializeAdobeTargetEvents();
            this.initializeAdobeTargetEventListeners();
            document.body.adobeTargetEvents.push(eventName, eventContent);
        } catch (e) {
            console.error(e);
        }
    }
}
