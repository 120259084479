import { useQuery } from '@apollo/client';
import { Section } from '../../../Components/_base/BBQGuysComponents';
import { QL_BRAND_PROMOTIONS } from '../../../Graphql/queries';
import { Brand } from '../../../types';
import { PromotionCardLoading } from '../../PagePromotion/PromotionCardLoading';
import { PromotionsLayout } from '../../PagePromotion/PromotionsLayout';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import { useContext } from 'react';

const BrandPromotions = ({ brand }: { brand: Brand }) => {
    const { getPricingTierId } = useContext(UserAuth);
    const { data, loading } = useQuery(QL_BRAND_PROMOTIONS, {
        variables: {
            brandId: brand.id,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return (
            <Section title={brand.name + ' Promotions'}>
                <PromotionCardLoading />
            </Section>
        );
    }
    return (
        <>
            {data.brandPromotions?.results.length > 0 ? (
                <Section title={brand.name + ' Promotions'}>
                    <PromotionsLayout
                        promotions={data.brandPromotions.results}
                    />
                </Section>
            ) : null}
        </>
    );
};

export default BrandPromotions;
