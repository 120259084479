import { useQuery } from '@apollo/client';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Link,
    Skeleton,
    Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Image } from '../../../Components/Image';
import {
    ChildDepartmentLinks,
    ChildDepartmentLinksLoading,
    ScreenReaderOnly,
} from '../../../Components/_base/BBQGuysComponents';
import { QL_BRAND_DEPARTMENTS } from '../../../Graphql/queries';
import { Brand } from '../../../types';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../Contexts/DeviceTypeProvider';
import PageBanners from '../../../Components/Banners/Banners';
import BrandTitle from './BrandTitle';
import { useState } from 'react';

export const BrandSidebarLoading = () => {
    return (
        <>
            <Skeleton variant="rectangular" width="100%" height={130} />
            <br />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
            <br />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
            <br />
            <Skeleton variant="text" width="50%" />
            <br />
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="60%" />
            <br />
            <Skeleton variant="text" width="80%" />
            <br />
            <Skeleton variant="text" width="80%" />
        </>
    );
};

const BrandSidebar = ({ brand }: { brand: Brand }) => {
    const { data, loading } = useQuery(QL_BRAND_DEPARTMENTS, {
        variables: {
            brandId: brand.id,
            depth: 2,
        },
    });

    const shopAllLink = (
        <Link
            href={brand.shopAllUrl ?? ''}
            sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                fontWeight: '900',
                '&:hover svg': {
                    fill: 'var(--brand-primary-color-dark)',
                },
                svg: {
                    alignSelf: 'center',
                    width: '27px !important',
                    height: '27px !important',
                },
            }}
        >
            Shop All Products <KeyboardArrowRightIcon />
        </Link>
    );

    const IMG_HEIGHT = 100;

    const [expanded, setExpanded] = useState(false);

    return (
        <>
            <MobileDevice>
                <BrandTitle brand={brand} />
                <PageBanners />
            </MobileDevice>
            <DesktopAndTabletDevice>
                <Image
                    src={brand.logoUrl ?? ''}
                    alt={brand.name + ' logo'}
                    height={IMG_HEIGHT}
                    width="auto"
                    sx={{
                        maxWidth: '100%',
                        objectFit: 'contain',
                    }}
                />
            </DesktopAndTabletDevice>
            {loading ? (
                <ChildDepartmentLinksLoading />
            ) : data.brandDepartments?.results.length > 0 ? (
                <>
                    <ScreenReaderOnly>
                        <Typography
                            variant="subtitle"
                            component="h2"
                            sx={{
                                mb: {
                                    xs: 2,
                                    md: 0,
                                },
                            }}
                        >
                            Departments
                        </Typography>
                    </ScreenReaderOnly>
                    <MobileDevice>
                        <Accordion
                            expanded={expanded}
                            onChange={() => setExpanded(!expanded)}
                            sx={{
                                '&:last-of-type': {
                                    borderRadius: 0,
                                },
                                '&.Mui-expanded:last-of-type {': {
                                    borderBottom: 0,
                                },
                            }}
                        >
                            <AccordionSummary
                                id="select-department"
                                expandIcon={<ExpandMoreIcon />}
                            >
                                Select a Department
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    padding: 0,
                                    '.MuiPaper-root': {
                                        borderLeft: 0,
                                        borderRight: 0,
                                        margin: '0 !important',
                                    },
                                }}
                            >
                                <ChildDepartmentLinks
                                    departments={data.brandDepartments.results}
                                    optionalListItem={shopAllLink}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </MobileDevice>
                    <DesktopAndTabletDevice>
                        <ChildDepartmentLinks
                            departments={data.brandDepartments.results}
                            optionalListItem={shopAllLink}
                        />
                    </DesktopAndTabletDevice>
                </>
            ) : null}
        </>
    );
};

export default BrandSidebar;
