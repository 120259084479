import { Typography } from '@mui/material';
import React from 'react';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import { CustomerReview } from '../../../../../types';

const CustomerReviewsTotalFeedback = ({
    review,
    fallbackComponent,
}: {
    review: CustomerReview;
    fallbackComponent?: React.ReactElement;
}): React.ReactElement => {
    const { hasInteractedWithHelpfulUnhelpful } = React.useContext(
        CustomerReviewsContext,
    );
    const totalFeedbackCount = review.countHelpful + review.countUnhelpful;

    const hasInteractedHelpful = hasInteractedWithHelpfulUnhelpful(
        review.id,
        'helpful',
    );
    const hasInteractedUnhelpful = hasInteractedWithHelpfulUnhelpful(
        review.id,
        'unhelpful',
    );

    if (hasInteractedHelpful) {
        return (
            <Typography variant="muted" component="p" sx={{ my: 1 }}>
                You found this review helpful
            </Typography>
        );
    } else if (hasInteractedUnhelpful) {
        return (
            <Typography variant="muted" component="p" sx={{ my: 1 }}>
                You found this review unhelpful
            </Typography>
        );
    } else if (review.countHelpful > 0 && review.countUnhelpful == 0) {
        return (
            <Typography
                variant="muted"
                component="p"
                sx={{ my: 1 }}
            >{`${review.countHelpful} user${review.countHelpful === 1 ? '' : 's'} found this review helpful`}</Typography>
        );
    } else if (review.countHelpful == 0 && review.countUnhelpful > 0) {
        return (
            <Typography
                variant="muted"
                component="p"
                sx={{ my: 1 }}
            >{`${review.countUnhelpful} user${review.countUnhelpful === 1 ? '' : 's'} found this review unhelpful`}</Typography>
        );
    } else if (totalFeedbackCount > 0) {
        return (
            <Typography variant="muted" component="p" sx={{ my: 1 }}>
                {review.countHelpful +
                    ' of ' +
                    totalFeedbackCount +
                    ' found this review helpful'}
            </Typography>
        );
    } else if (fallbackComponent) {
        return fallbackComponent;
    }
    return <></>;
};

export default CustomerReviewsTotalFeedback;
