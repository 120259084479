import { Box, Skeleton } from '@mui/material';

const CustomerReviewsMediaCarouselLoading = () => {
    return (
        <Box
            display="flex"
            gap={2}
            sx={{ overflowX: 'scroll', scrollbarWidth: 'none' }}
        >
            <Skeleton variant="rectangular" width="100%" height={120} />
            <Skeleton variant="rectangular" width="100%" height={120} />
            <Skeleton variant="rectangular" width="100%" height={120} />
            <Skeleton variant="rectangular" width="100%" height={120} />
        </Box>
    );
};

export default CustomerReviewsMediaCarouselLoading;
