import {
    DesktopDevice,
    MobileDevice,
    TabletDevice,
} from '../../../Contexts/DeviceTypeProvider';
import './MainMenu.scss';
import DesktopMegaMenu from './versions/Desktop/DesktopMegaMenu/DesktopMegaMenu';
import MobileDefaultMenu from './versions/Mobile/MobileDefaultMenu/MobileDefaultMenu';

export default function MainMenu(props: any) {
    return (
        <>
            <DesktopDevice>
                <DesktopMegaMenu {...props} />
            </DesktopDevice>
            <TabletDevice>
                <MobileDefaultMenu buttonWidth={props.buttonWidth} />
            </TabletDevice>
            <MobileDevice>
                <MobileDefaultMenu buttonWidth={props.buttonWidth} />
            </MobileDevice>
        </>
    );
}
