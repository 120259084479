import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
    Card,
    CardContent,
    CardMedia,
    Grid,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { Box } from '@mui/system';
import React, { Fragment, useContext, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { SiteContext } from '../../../Contexts/SiteProvider';
import {
    DesktopAndTabletDevice,
    DesktopDevice,
    MobileDevice,
    TabletDevice,
    isMobile,
} from '../../../Contexts/DeviceTypeProvider';
import theme from '../../../Theme';
import { Location, Locations, Maybe, MenuItem } from '../../../types';
import StarRatings from '../../StarRatings/StarRatings';
import {
    LiveChatLink,
    PhoneLink,
    TrustBadges,
    ScrollTop,
} from '../../_base/BBQGuysComponents';
import { FooterLink } from '../../_base/BBQGuysStyledComponents';
import { IconPrivacyChoices } from '../../_base/icons/icons';
import ScContainer from '../ScContainer/ScContainer';
import FooterNewsletterSignup from './FooterNewsletterSignup';

const FooterLinksSection = (props: { children: Maybe<MenuItem>[] }) => {
    return (
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {props.children !== null
                ? props.children.map((l, i) => {
                      return l === null ? null : l &&
                        l.href !== null &&
                        l.href !== undefined ? (
                          <li key={'f-children-' + i}>
                              <FooterLink href={l.href}>{l.text}</FooterLink>
                          </li>
                      ) : (
                          <li key={'f-children-' + i}>
                              <FooterLink href={'#'}>{l.text}</FooterLink>
                          </li>
                      );
                  })
                : null}
        </ul>
    );
};

const MobileFooter = (props: { footerLinks: Maybe<MenuItem>[] }) => {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel: any) => (event: any, isExpanded: any) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Box sx={{ px: 2 }}>
            {props.footerLinks.map((section, i) => {
                return section === null ? null : (
                    <Accordion
                        key={'m-footer-' + i}
                        expanded={expanded === 'panel' + i}
                        onChange={handleChange('panel' + i)}
                        sx={{
                            borderWidth: 0,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon htmlColor="#333" />}
                            aria-controls="footer-content"
                            sx={[
                                {
                                    backgroundColor: 'rgba(250, 246, 242, 0.6)',
                                    color: '#333',
                                },
                                theme => ({
                                    '&:hover': {
                                        color: theme.palette.primary.main,
                                    },
                                }),
                            ]}
                        >
                            <Typography variant="body1">
                                {section.text}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <FooterLinksSection>
                                {section.children}
                            </FooterLinksSection>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </Box>
    );
};

const AboutUs = () => {
    const { site } = useContext(SiteContext);
    return (
        <Box textAlign="center" paddingY={6}>
            <Typography variant="h3" component="h2">
                Why Choose {site.name}?
            </Typography>
            <Typography variant="body1" component="p" marginTop={0.5}>
                <Link href="/about-us">
                    Learn more about us{' '}
                    <KeyboardArrowRightIcon fontSize="small" />
                </Link>
            </Typography>
        </Box>
    );
};

const FlipCard = (props: { front: any; back: any }) => {
    const { front, back } = props;
    const [flipped, setFlipped] = useState(false);

    return (
        <Box
            sx={{
                flex: 1,
                flexShrink: 0,
                border: '1px solid white',
                transformStyle: 'preserve-3d',
                perspective: '100em',
                aspectRatio: 'auto 1/1',
            }}
            onMouseEnter={() => setFlipped(true)}
            onMouseLeave={() => setFlipped(false)}
        >
            <ReactCardFlip
                isFlipped={flipped}
                flipDirection="horizontal"
                cardStyles={{
                    front: {},
                    back: {
                        backgroundColor: theme.palette.secondary.dark,
                        bottom: 0,
                        color: theme.palette.secondary.contrastText,
                        letterSpacing: '0.0375em',
                        verticalAlign: 'middle',
                    },
                }}
            >
                {front}
                {back}
            </ReactCardFlip>
        </Box>
    );
};

type FlipCardProps = {
    image: string;
    text: string;
    altText: string;
};

const FlipCards = (props: { cards: FlipCardProps[] }) => {
    const { cards } = props;
    const WIDTH = '100%';
    const HEIGHT = 250;

    const renderDesktopCard = (card: FlipCardProps) => {
        return (
            <FlipCard
                front={
                    <Box sx={{ width: WIDTH, minHeight: HEIGHT + 'px' }}>
                        <Box
                            role="img"
                            aria-label={card.altText}
                            sx={{
                                backgroundImage: 'url(' + card.image + ')',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center center',
                                backgroundSize: 'cover',
                                width: '100%',
                                minHeight: HEIGHT + 'px',
                            }}
                        />
                    </Box>
                }
                back={
                    <Typography
                        variant="body1"
                        tabIndex={0}
                        sx={{
                            position: 'relative',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignContent: 'center',
                            height: HEIGHT + 'px',
                            padding: 4,
                        }}
                    >
                        {card.text}
                    </Typography>
                }
            />
        );
    };

    const renderTabletCard = (card: FlipCardProps) => {
        return (
            <Card sx={{ width: WIDTH }}>
                <CardMedia component="img" image={card.image} height={HEIGHT} />
                <CardContent tabIndex={0}>{card.text}</CardContent>
            </Card>
        );
    };

    return (
        <>
            <DesktopDevice>
                <Stack direction="row" overflow="hidden">
                    {cards.map((card: FlipCardProps, i: number) => {
                        return (
                            <Fragment key={i}>
                                {renderDesktopCard(card)}
                            </Fragment>
                        );
                    })}
                </Stack>
            </DesktopDevice>
            <TabletDevice>
                <Stack direction="row" spacing={1} sx={{ overflowX: 'scroll' }}>
                    {cards
                        .filter((v: any, i: number) => i < 4)
                        .map((card: FlipCardProps, i: number) => {
                            return (
                                <Fragment key={i}>
                                    {renderTabletCard(card)}
                                </Fragment>
                            );
                        })}
                </Stack>
            </TabletDevice>
        </>
    );
};

const CustomerTestimonials = () => {
    return (
        <Box textAlign="center" paddingY={4} margin="0 auto">
            <StarRatings
                readOnly={true}
                showCount={false}
                showCountText={false}
                rating={5}
                size="x-large"
                block={true}
            />

            <Typography variant="h5" component="h2">
                What are our customers saying?
            </Typography>
            <Box>
                <Link href="/reviews" variant="linkColor">
                    Read customer reviews{' '}
                    <KeyboardArrowRightIcon fontSize="small" />
                </Link>
            </Box>
        </Box>
    );
};

const FooterTitle = ({ children }: { children: any }) => {
    return (
        <h2>
            <Typography component="strong" variant="footerTitle">
                {children}
            </Typography>
        </h2>
    );
};

const FooterWebsiteHours = (props: { locationWebsite: Maybe<Location> }) => {
    const { locationWebsite } = props;
    return (
        <>
            {locationWebsite && locationWebsite.hours && (
                <Box aria-label="Talk to an expert now">
                    <Typography variant="footerTitle" component="div">
                        Talk to an expert now
                    </Typography>
                    <Box sx={{ my: 1 }}>
                        <PhoneLink
                            linkProps={{
                                sx: { color: 'inherit' },
                                'aria-label':
                                    'Talk to an expert now by calling ' +
                                    locationWebsite.phone,
                            }}
                        />
                    </Box>
                    <Box sx={{ my: 1 }}>
                        <LiveChatLink
                            title="Chat with an Expert"
                            linkProps={{
                                sx: { color: 'inherit' },
                                'aria-label': 'Chat with an expert now',
                            }}
                        />
                    </Box>
                    <Typography variant="small" component="div">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: locationWebsite.hours.secondary ?? '',
                            }}
                        ></span>
                    </Typography>
                </Box>
            )}
        </>
    );
};

const FooterPrivacyLinks = () => {
    return (
        <>
            <FooterLink href="/policies#privacy">Privacy Policy</FooterLink>
            <FooterLink href="/accessibility-statement">
                Accessibility Statement
            </FooterLink>
            <FooterLink href="https://privacyportal.onetrust.com/webform/11b6f4a4-aceb-48e6-b8c1-0678da7f03b9/bc6c2053-f2a5-4d27-8354-d50ea2cdd6e0">
                Your Privacy Choices{' '}
                <IconPrivacyChoices sx={{ height: 14, width: 30 }} />
            </FooterLink>
            <FooterLink className="ot-sdk-show-settings">
                Manage My Cookies
            </FooterLink>
            <FooterLink href="/contact-us">Contact Us</FooterLink>
        </>
    );
};

const FooterInfo = (props: {
    direction: 'column' | 'row';
    locations: Locations;
}) => {
    const { direction, locations } = props;
    const locationWebsite = locations.website ?? ({} as Location);
    return (
        <>
            <Stack
                direction={direction}
                justifyContent="center"
                spacing={3}
                sx={{
                    my: theme.spacing(6),
                    textAlign: 'center',
                    gap: theme.spacing(2, 8),
                }}
            >
                <FooterWebsiteHours locationWebsite={locationWebsite} />
            </Stack>
            <Stack
                direction={direction}
                spacing={1.5}
                sx={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    my: isMobile ? theme.spacing(3) : 0,
                    textAlign: 'center',
                    gap: theme.spacing(0, 2),
                }}
            >
                <FooterPrivacyLinks />
            </Stack>
            <Typography
                variant="small"
                component="p"
                sx={{
                    my: isMobile ? theme.spacing(6) : theme.spacing(2),
                    px: theme.spacing(6),
                    textAlign: 'center',
                }}
            >
                Copyright © {new Date().getFullYear()}. Owned and operated by
                ShoppersChoice.com, LLC ® d/b/a BBQGuys.com
                <br />
                All Rights Reserved.
            </Typography>
        </>
    );
};

export default function Footer(props: any) {
    const { footerMenu: footerLinks, site } = React.useContext(SiteContext);
    return (
        <>
            <DesktopAndTabletDevice>
                <ScContainer
                    fullWidth
                    sx={{
                        borderTopWidth: 1,
                        borderTopStyle: 'solid',
                        borderTopColor: theme.palette.divider,
                    }}
                >
                    <AboutUs />
                    <FlipCards
                        cards={[
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-experts.jpg',
                                altText: 'customer service agent smiling',
                                text: 'Our customer service reps are real people offering great service, backed by 8 weeks of hands-on training with all of our products.',
                            },
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-louisiana.jpg',
                                altText: 'BBQGuys employees eating crawfish',
                                text: "Founded in Baton Rouge in 1998, BBQGuys is proud to call South Louisiana home (yes, the food's as good as they say it is).",
                            },
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-odk.jpg',
                                altText:
                                    'happy customers eating watermelon by an outdoor grill',
                                text: 'If you can dream it, we can design it. Our outdoor kitchen design experts have helped thousands of customers transform their backyards at no extra cost.',
                            },
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-researchanddev.jpg',
                                altText:
                                    'BBQGuys employee manufacturing BBQ grills',
                                text: 'Great innovations build great products. Our R&D department develops high-quality, fairly priced grilling equipment.',
                            },
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-recipes.jpg',
                                altText: 'searing a steak on an open flame',
                                text: 'BBQGuys does more than test and sell grills — we show you how to use them, too. Our experts provide the recipes and skills you need to be a grill master.',
                            },
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-community.jpg',
                                altText: 'BBQGuys employee smiling with child',
                                text: "From food drives to historical preservation, we're passionate about lifting up and giving back to our community.",
                            },
                            {
                                image: 'https://cdn.shocho.co/sc-site/redesign/about-us/whychoose-paradise.jpg',
                                altText:
                                    'BBQGuys employees from the creative team at a photo shoot',
                                text: 'We strive for the perfect balance of workplace and community to create a fun, enjoyable environment for all of our employees.',
                            },
                        ]}
                    />
                    <CustomerTestimonials />
                </ScContainer>
            </DesktopAndTabletDevice>
            <FooterNewsletterSignup />
            <Box
                component="footer"
                id="footer"
                sx={{
                    background: theme.palette.brand.lighttan,
                    color: theme.palette.text.primary,
                    borderTop: '1px solid rgba(218, 196, 167, 0.3)',
                    borderBottom: '1px solid rgba(218, 196, 167, 0.3)',
                    paddingY: 2,
                    paddingX: 1,
                }}
            >
                <ScContainer
                    sx={{
                        width: '96%',
                        margin: '0 auto',
                    }}
                >
                    <DesktopDevice>
                        <nav
                            className="center-horizontally"
                            aria-label={'Footer menu links'}
                        >
                            {footerLinks.map((item: MenuItem, i: number) => {
                                return (
                                    <Box key={i} aria-label={item.text}>
                                        <FooterTitle>{item.text}</FooterTitle>
                                        <FooterLinksSection>
                                            {item.children}
                                        </FooterLinksSection>
                                    </Box>
                                );
                            })}
                        </nav>
                    </DesktopDevice>
                    <TabletDevice>
                        <Grid container>
                            <Grid item sm={4}>
                                <FooterTitle>
                                    {footerLinks[0]?.text}
                                </FooterTitle>
                                <FooterLinksSection>
                                    {footerLinks[0]?.children}
                                </FooterLinksSection>
                            </Grid>
                            <Grid item sm={4}>
                                <FooterTitle>
                                    {footerLinks[1]?.text}
                                </FooterTitle>
                                <FooterLinksSection>
                                    {footerLinks[1]?.children}
                                </FooterLinksSection>
                            </Grid>
                            <Grid item sm={4}>
                                <FooterTitle>
                                    {footerLinks[2]?.text}
                                </FooterTitle>
                                <FooterLinksSection>
                                    {footerLinks[2]?.children}
                                </FooterLinksSection>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item sm={4}>
                                <FooterTitle>
                                    {footerLinks[3]?.text}
                                </FooterTitle>
                                <FooterLinksSection>
                                    {footerLinks[3]?.children}
                                </FooterLinksSection>
                            </Grid>
                            <Grid item sm={4}>
                                <FooterTitle>
                                    {footerLinks[4]?.text}
                                </FooterTitle>
                                <FooterLinksSection>
                                    {footerLinks[4]?.children}
                                </FooterLinksSection>
                            </Grid>
                            <Grid item sm={4}></Grid>
                        </Grid>
                    </TabletDevice>
                    <MobileDevice>
                        <MobileFooter footerLinks={footerLinks} />
                    </MobileDevice>
                    <DesktopAndTabletDevice>
                        <FooterInfo
                            direction="row"
                            locations={site?.locations}
                        />
                    </DesktopAndTabletDevice>
                    <MobileDevice>
                        <FooterInfo
                            direction="column"
                            locations={site?.locations}
                        />
                    </MobileDevice>
                </ScContainer>
            </Box>
            <DesktopAndTabletDevice>
                <Box>
                    <TrustBadges />
                    <Box sx={{ position: 'relative' }}>
                        <ScrollTop />
                    </Box>
                </Box>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Box sx={{ position: 'relative' }}>
                    <ScrollTop />
                </Box>
            </MobileDevice>
        </>
    );
}
