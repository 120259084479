import { InMemoryCache } from '@apollo/client';
import { Request } from 'express';
import { window } from 'global/window';
import * as PaymentDeferredUtils from '../Contexts/PaymentDeferredUtils';
import { OrderPricing, OrderPricingFormatted } from '../types';

export default function getApolloMemoryCache(
    isSSR = false,
    req: Request | undefined = undefined,
) {
    const cache = new InMemoryCache({
        typePolicies: {
            MenuItem: {
                keyFields: false,
            },
            Item: {
                keyFields: ['id', 'pricing', ['type']],
                fields: {
                    promotions: {
                        // prevent merging of order items after delete
                        merge(
                            existing: any[],
                            incoming: any[],
                            { readField, mergeObjects },
                        ) {
                            const merged: any[] = [];
                            const promoIdToIndex: Record<string, number> =
                                Object.create(null);

                            incoming.forEach(promo => {
                                const id = readField<string>('id', promo);
                                if (id) {
                                    const index = promoIdToIndex[id];
                                    if (typeof index === 'number') {
                                        // Merge the new author data with the existing author data.
                                        merged[index] = mergeObjects(
                                            merged[index],
                                            promo,
                                        );
                                    } else {
                                        // First time we've seen this author in this array.
                                        promoIdToIndex[id] = merged.length;
                                        merged.push(promo);
                                    }
                                }
                            });
                            return merged;
                        },
                    },
                },
            },
            Order: {
                fields: {
                    orderItems: {
                        // prevent merging of order items after delete
                        merge(
                            existing: any[],
                            incoming: any[],
                            { readField, mergeObjects },
                        ) {
                            const merged: any[] = [];
                            const orderItemIdToIndex: Record<string, number> =
                                Object.create(null);

                            incoming.forEach(orderItem => {
                                const id = readField<string>('id', orderItem);
                                if (id) {
                                    const index = orderItemIdToIndex[id];
                                    if (typeof index === 'number') {
                                        // Merge the new author data with the existing author data.
                                        merged[index] = mergeObjects(
                                            merged[index],
                                            orderItem,
                                        );
                                    } else {
                                        // First time we've seen this author in this array.
                                        orderItemIdToIndex[id] = merged.length;
                                        merged.push(orderItem);
                                    }
                                }
                            });
                            return merged;
                        },
                    },
                    paymentDeferreds: {
                        read(_, { variables }) {
                            return PaymentDeferredUtils.getPaymentDeferreds();
                        },
                    },
                    pricing: {
                        merge(
                            existing: OrderPricing,
                            incoming: OrderPricing,
                            { readField, mergeObjects },
                        ) {
                            const merged = Object.assign(
                                { selected: false },
                                incoming,
                            );
                            merged.totalOwed =
                                PaymentDeferredUtils.getTotalOwed();
                            return merged;
                        },
                    },
                    pricingFormatted: {
                        merge(
                            existing: OrderPricingFormatted,
                            incoming: OrderPricingFormatted,
                            { readField, mergeObjects },
                        ) {
                            const merged = Object.assign(
                                { selected: false },
                                incoming,
                            );
                            merged.totalOwed =
                                PaymentDeferredUtils.currencyFormat(
                                    PaymentDeferredUtils.getTotalOwed(),
                                );
                            return merged;
                        },
                    },
                },
            },
            OrderPricing: {
                fields: {
                    totalOwed: {
                        // prevent merging of order items after delete
                        merge(
                            existing: number,
                            incoming: number,
                            { readField, mergeObjects },
                        ) {
                            return incoming;
                        },
                        read(_, { variables }) {
                            return PaymentDeferredUtils.getTotalOwed();
                        },
                    },
                },
            },
            OrderPricingFormatted: {
                fields: {
                    totalOwed: {
                        merge(
                            existing: string,
                            incoming: string,
                            { readField, mergeObjects },
                        ) {
                            return incoming;
                        },
                        read(_, { variables }) {
                            return PaymentDeferredUtils.currencyFormat(
                                PaymentDeferredUtils.getTotalOwed(),
                            );
                        },
                    },
                },
            },
            ColorSwatch: {
                keyFields: ['itemId', 'title', 'hexCode'],
            },
        },
    });
    if (isSSR) {
        return cache;
    }
    if (window && window.__APOLLO_STATE__) {
        return cache.restore(window.__APOLLO_STATE__ || {});
    }
    return cache;
}
