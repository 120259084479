import { gql, useQuery } from '@apollo/client';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Checkbox, Link, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import AddToCart from '../../../../Components/Buttons/AddToCart/AddToCart';
import {
    GridItemImageSet,
    MultiItemImageSet,
} from '../../../../Components/Image';
import { Price, ProPriceLabel } from '../../../../Components/Item/Pricing';
import {
    LineEllipsis,
    Section,
} from '../../../../Components/_base/BBQGuysComponents';
import { AddToCartItem } from '../../../../Contexts/CartProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { PAGINATED_ITEMS } from '../../../../Graphql/fragments';
import { Item } from '../../../../types';
import FrequentlyBoughtTogetherItemsLoading from './FrequentlyBoughtTogetherItemsLoading';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';

const QL_ITEM_FBTs = gql`
    ${PAGINATED_ITEMS}
    query getItemFBTs($itemId: ID, $pricingTierId: Int) {
        itemFBTs(itemId: $itemId, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

interface CheckedItem {
    checked: boolean;
    item: Item;
}

export default function FrequentlyBoughtTogetherItems(props: { item: Item }) {
    const { item } = props;
    const { getPricingTierId } = React.useContext(UserAuth);
    const [items, setItems] = React.useState(new Map<string, CheckedItem>());
    const [itemValues, setItemValues] = React.useState([] as CheckedItem[]);

    const setItemMap = (mapItems: Map<string, CheckedItem>) => {
        const values = [...mapItems.values()];
        setItems(mapItems);
        setItemValues(values);
    };

    const setSelectedItems = (allItems: Item[], uncheckId?: string) => {
        const uncheckedId = (uncheckId !== undefined && uncheckId) || '0';
        const tempItems = new Map<string, CheckedItem>();
        tempItems.set(item.id, {
            checked: item.id != uncheckedId && item.inStock,
            item: item,
        });
        for (let i = 0; i < allItems.length; i++) {
            tempItems.set(allItems[i].id, {
                checked: allItems[i].id != uncheckedId && allItems[i].inStock,
                item: allItems[i],
            });
        }
        setItemMap(tempItems);
    };

    const { data, loading } = useQuery(QL_ITEM_FBTs, {
        variables: {
            itemId: item.id,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <FrequentlyBoughtTogetherItemsLoading />;
    } else if (
        items.size == 0 &&
        data &&
        data.itemFBTs &&
        data.itemFBTs.results.length > 0
    ) {
        setSelectedItems(data.itemFBTs.results);
    }

    const getTotalPrice = () => {
        let price = 0.0;
        let listPrice = 0.0;
        items.forEach((ckItem: CheckedItem) => {
            price += (ckItem.checked && ckItem.item.pricing.current) || 0.0;
            listPrice += (ckItem.checked && ckItem.item.pricing.retail) || 0.0;
        });
        return { list: listPrice, current: price };
    };

    const handleChecked = (ckItem: CheckedItem) => {
        const tempItems = items;
        tempItems.set(ckItem.item.id, {
            checked: !ckItem.checked,
            item: ckItem.item,
        });
        setItemMap(tempItems);
    };

    const pricing = getTotalPrice();
    const checkedItems = itemValues
        .filter((ckItem: CheckedItem) => ckItem.checked)
        .map((ckItem: CheckedItem) => ckItem.item);

    const atcItems = [] as AddToCartItem[];
    itemValues
        .filter((ckItem: CheckedItem) => ckItem.checked)
        .map((ckItem: CheckedItem) => {
            atcItems.push({ item: ckItem.item, qty: 1 } as AddToCartItem);
        });

    let label = '';
    switch (checkedItems.length) {
        case 3:
            label = 'Add all three to cart';
            break;
        case 2:
            label = 'Add both to cart';
            break;
        case 1:
            label = 'Add to cart';
            break;
    }

    return itemValues.length > 0 ? (
        <Section title="Frequently Bought Together">
            <DesktopAndTabletDevice>
                <Stack direction="row" spacing={3} sx={{ mb: 2 }}>
                    <MultiItemImageSet items={checkedItems} resolution={160} />
                    {atcItems.length > 0 ? (
                        <Box sx={{ ml: 5 }}>
                            <>Total price:</>
                            <br />
                            <ProPriceLabel item={item} label="Total" />
                            <Price
                                price={pricing.current}
                                sx={{
                                    fontSize: '1.75rem',
                                    lineHeight: 1.5,
                                }}
                            />
                            <br />
                            <AddToCart
                                items={atcItems}
                                isItemPackage={true}
                                className="ItemPackage"
                                data-button-parent-type="ItemPackage"
                                label={label}
                                sx={{
                                    marginTop: '2.75rem',
                                }}
                            />
                        </Box>
                    ) : (
                        <Typography variant="body1" height={120}>
                            <i>Please select an item below</i>
                        </Typography>
                    )}
                </Stack>
                {itemValues.map((ckItem: CheckedItem, i: number) => {
                    return (
                        ckItem && (
                            <Stack
                                key={i}
                                direction="row"
                                spacing={1}
                                sx={{ mb: 1, alignItems: 'center' }}
                            >
                                <Checkbox
                                    checked={ckItem.checked}
                                    onChange={() => handleChecked(ckItem)}
                                    inputProps={{
                                        role: 'checkbox',
                                        'aria-label':
                                            'checkbox for ' + ckItem.item.name,
                                    }}
                                    sx={{ py: 0.5 }}
                                />
                                <>
                                    {ckItem.item.id === item.id ? (
                                        <>
                                            <strong>This Item:</strong>&nbsp;
                                            <LineEllipsis
                                                text={ckItem.item.name}
                                                maxLines={1}
                                                component="span"
                                                marginLeft="0 !important"
                                            />
                                        </>
                                    ) : (
                                        <Link href={ckItem.item.url}>
                                            <LineEllipsis
                                                text={ckItem.item.name}
                                                maxLines={1}
                                                component="span"
                                            />
                                        </Link>
                                    )}
                                    &nbsp;
                                    {!ckItem.item.inStock ? (
                                        <strong>
                                            [<em>Out of Stock</em>]
                                        </strong>
                                    ) : null}
                                    <strong>
                                        {ckItem.item.pricingFormatted.current}
                                    </strong>
                                </>
                            </Stack>
                        )
                    );
                })}
            </DesktopAndTabletDevice>

            <MobileDevice>
                {checkedItems.length > 0 ? (
                    <GridItemImageSet
                        items={checkedItems}
                        resolution={90}
                        numRows={3}
                    />
                ) : (
                    <Typography variant="body1" height={90}>
                        <i>Please select an item below</i>
                    </Typography>
                )}
                <Box sx={{ border: '1px solid #ccc' }}>
                    {itemValues.map((ckItem: CheckedItem, i: number) => {
                        return (
                            ckItem && (
                                <Stack
                                    key={i}
                                    direction="row"
                                    spacing={1}
                                    sx={{ alignItems: 'center' }}
                                >
                                    <Box
                                        position="relative"
                                        sx={{
                                            borderBottom: '1px solid #ccc',
                                            py: 1,
                                            alignItems: 'center',
                                            display: 'flex',
                                            width: '100%',
                                        }}
                                    >
                                        <Checkbox
                                            checked={ckItem.checked}
                                            onChange={() =>
                                                handleChecked(ckItem)
                                            }
                                            sx={{
                                                py: 0.5,
                                                position: 'absolute',
                                                left: 0,
                                                width: '40px',
                                            }}
                                        />
                                        <Box
                                            marginRight={1}
                                            marginLeft={6}
                                            sx={{
                                                width: '100%',
                                            }}
                                        >
                                            {ckItem.item.id === item.id ? (
                                                <>
                                                    <strong>This Item:</strong>
                                                    &nbsp;
                                                </>
                                            ) : null}
                                            <LineEllipsis
                                                text={ckItem.item.name}
                                                maxLines={2}
                                                component="span"
                                                sx={{
                                                    width: '100%',
                                                }}
                                            />
                                            <strong>
                                                {
                                                    ckItem.item.pricingFormatted
                                                        .current
                                                }
                                            </strong>
                                            {!ckItem.item.inStock ? (
                                                <Box
                                                    sx={{
                                                        mt: 1,
                                                        fontWeight: 800,
                                                        fontStyle: 'italic',
                                                    }}
                                                >
                                                    Out of Stock
                                                </Box>
                                            ) : null}
                                        </Box>
                                        {ckItem.item.id != item.id && (
                                            <Link
                                                href={ckItem.item.url}
                                                sx={{
                                                    alignItems: 'center',
                                                    justifyContent: 'right',
                                                    display: 'flex',
                                                    height: '2rem',
                                                    width: '2rem',
                                                    fontSize: '2rem',
                                                    marginRight: '1rem',
                                                }}
                                            >
                                                <KeyboardArrowRightIcon
                                                    sx={{
                                                        height: '2rem !important',
                                                        width: '2rem !important',
                                                        fontSize:
                                                            '2rem !important',
                                                    }}
                                                />
                                            </Link>
                                        )}
                                    </Box>
                                </Stack>
                            )
                        );
                    })}

                    <Box sx={{ p: 2 }}>
                        <Typography variant="body1">Total Price:</Typography>
                        <Typography variant="currentPrice">
                            <Price price={pricing.current} />
                        </Typography>
                        <br />
                        <AddToCart
                            items={atcItems}
                            isItemPackage={true}
                            className="ItemPackage"
                            label={label}
                            sx={{
                                marginTop: '.5em',
                            }}
                        />
                    </Box>
                </Box>
            </MobileDevice>
        </Section>
    ) : null;
}
