import { gql, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { decodeHTMLEntities } from '../../functions';
import PageBanners from '../../Components/Banners/Banners';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { CONTENT_FIELDS } from '../../Graphql/fragments';
import { ContentDisplay, PageContentLoading } from '../PageContent/PageContent';
import { AddWebsiteSchema } from '../../Components/Meta/CreateSchemaOrg';

const QL_HOME_PAGE_CONTENT = gql`
    ${CONTENT_FIELDS}
    query getHomePage($url: ID) {
        contentByUrl(url: $url) {
            ...ContentFields
            author {
                name
            }
        }
    }
`;
function PageHome() {
    const qry = QL_HOME_PAGE_CONTENT;
    const variables = {
        url: '/',
    };
    const { data, loading } = useQuery(qry, {
        variables: variables,
    });

    if (loading) {
        return <PageContentLoading withBreadcrumbs={false} />;
    }
    const title = decodeHTMLEntities(data?.contentByUrl?.title ?? '');

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <AddWebsiteSchema />
            <Typography component="h1" variant="screenReaderOnly">
                BBQGuys for Your Grill, Outdoor Kitchen, and Outdoor Living
                Needs
            </Typography>
            <PageBanners sx={{ mt: 2 }} />
            <ScContainer>
                <ContentDisplay
                    content={data.contentByUrl}
                    withPageHead={false}
                />
            </ScContainer>
        </>
    );
}
export default memo(PageHome);
