import { useQuery } from '@apollo/client';
import { QL_BANNERS } from '../../../Graphql/queries';
import BannerCampaigns from './BannerCampaigns';
import { SxProps } from '@mui/system';

const BannersByUrl = ({
    url,
    query = '',
    sx,
}: {
    url: string;
    query?: string;
    sx?: SxProps;
}) => {
    const { data, loading } = useQuery(QL_BANNERS, {
        variables: {
            url: url,
            query: query,
        },
    });

    if (loading || data === undefined) {
        return <BannerCampaigns campaigns={[]} sx={sx} />;
    }
    return <BannerCampaigns campaigns={data.bannerCampaigns} sx={sx} />;
};

export default BannersByUrl;
