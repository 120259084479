import { Grid, Skeleton, Stack } from '@mui/material';
import {
    IMAGE_CAROUSEL_THUMBNAIL_SIZE,
    ImageCarouselLoading,
} from '../../../../Components/ImageCarousel/ImageCarousel';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { ItemPriceAndQtyLoading } from './ItemPriceAndQty';
import { BBQGuysGrid } from '../../../../Components/_base/BBQGuysGrid';
import { ItemOptionsLoading } from './ItemOptionsDropdown';

export const ItemAboveTheFoldLoading = () => {
    return (
        <>
            <DesktopAndTabletDevice>
                <Grid container columnSpacing={2} sx={{ mb: 4 }}>
                    <Grid item md={7}>
                        <BBQGuysGrid
                            sx={{
                                gridTemplateColumns:
                                    IMAGE_CAROUSEL_THUMBNAIL_SIZE + 'px 1fr',
                            }}
                        >
                            <ImageCarouselLoading />
                            <Skeleton
                                variant="rectangular"
                                width={'100%'}
                                height={'100%'}
                                sx={{ minHeight: 650 }}
                            />
                        </BBQGuysGrid>
                    </Grid>
                    <Grid item md={5}>
                        <Skeleton
                            variant="text"
                            height={'1.5rem'}
                            width={'10%'}
                        />
                        <Skeleton
                            variant="text"
                            height={'2rem'}
                            width={'100%'}
                        />
                        <Skeleton
                            variant="text"
                            height={'2rem'}
                            width={'70%'}
                        />
                        <Stack
                            direction="column"
                            sx={{ mb: 0, gap: 1, whiteSpace: 'nowrap' }}
                        >
                            <Skeleton
                                variant="text"
                                height={'2rem'}
                                width={'15%'}
                            />
                            <Skeleton
                                variant="text"
                                height={'2rem'}
                                width={'20%'}
                            />
                        </Stack>
                        <br />
                        <Skeleton
                            variant="text"
                            height={'1.5rem'}
                            width={'80%'}
                        />
                        <ItemPriceAndQtyLoading />
                        <br />
                        <br />
                        <ItemOptionsLoading />
                        <Grid container columnSpacing={3}>
                            <Grid item xs={4}>
                                <Skeleton
                                    variant="text"
                                    height={'6.5rem'}
                                    width={'100%'}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Skeleton
                                    variant="text"
                                    height={'6.5rem'}
                                    width={'80%'}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Skeleton variant="rectangular" height={300} width="100%" />
                <br />
                <Grid container columnSpacing={3}>
                    <Grid item xs={3}>
                        <Skeleton
                            variant="text"
                            height={'2rem'}
                            width={'100%'}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Skeleton
                            variant="text"
                            height={'2rem'}
                            width={'100%'}
                        />
                    </Grid>
                </Grid>
                <Skeleton variant="text" height={'2.5rem'} width={'100%'} />
                <Skeleton variant="text" height={'2.5rem'} width={'90%'} />
                <Skeleton variant="text" height={'1.5rem'} width={'80%'} />
                <br />
                <Skeleton variant="text" height={'1.5rem'} width={'10%'} />
                <Skeleton variant="text" height={'3rem'} width={'25%'} />
                <Skeleton variant="text" height={'1.5rem'} width={'70%'} />
                <Skeleton variant="text" height={'1.5rem'} width={'40%'} />
                <Skeleton variant="text" height={'1.5rem'} width={'60%'} />
                <Grid container columnSpacing={3}>
                    <Grid item xs={4}>
                        <Skeleton
                            variant="text"
                            height={'6.5rem'}
                            width={'100%'}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Skeleton
                            variant="text"
                            height={'6.5rem'}
                            width={'80%'}
                        />
                    </Grid>
                </Grid>
            </MobileDevice>
        </>
    );
};
