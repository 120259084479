import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import {
    Box,
    Button,
    Link,
    ListItem,
    Stack,
    styled,
    Theme,
    Typography,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getEnvironmentVariable } from '../../../config';
import { CartContext } from '../../../Contexts/CartProvider';
import {
    SearchBarContext,
    SearchBarProvider,
} from '../../../Contexts/SearchBarProvider';
import { SiteContext } from '../../../Contexts/SiteProvider';
import { LogoutResponse, UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    DesktopAndTabletDevice,
    DesktopDevice,
    MobileDevice,
    TabletDevice,
} from '../../../Contexts/DeviceTypeProvider';
import { alphanumericTextOnly } from '../../../functions';
import theme from '../../../Theme';
import { Maybe, MenuItem } from '../../../types';
import {
    BulletedList,
    ContentModalButton,
    EmailLink,
    LiveChatLink,
    PhoneLink,
    Popover,
    SignInButton,
} from '../../_base/BBQGuysComponents';
import { Image } from '../../Image';
import { ProLabel } from '../../Item/Pricing';
import ScContainer from '../ScContainer/ScContainer';
import MainMenu from './../Menu/MainMenu';
import { SearchInputBox } from './../SearchInputBox/SearchInputBox';
import window from 'global/window';
import './Header.scss';

const CART_ICON_SIZE = 44;

const PopoverLink = styled(Link)(() => ({
    display: 'block',
    padding: '0.25em',
    textDecoration: 'none',
}));

const ShoppingCartIcon = (props: any) => {
    const { count = 0 } = props;
    const cnt = props.count;
    return (
        <div id="cart-icon-wrapper">
            <svg id="cart-outline" viewBox={'-2 0 26 26'}>
                <path
                    fill={props.htmlColor ?? 'currentColor'}
                    d="M7 18c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m10 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m-9.8-3.2v-.1l.9-1.7h7.4c.7 0 1.4-.4 1.7-1l3.9-7-1.7-1-1.1 2-2.8 5h-7l-.1-.3L6.2 6l-1-2-.9-2H1v2h2l3.6 7.6L5.3 14c-.2.3-.3.6-.3 1 0 1.1.9 2 2 2h12v-2H7.4c-.1 0-.2-.1-.2-.2z"
                ></path>
                <text
                    x="12"
                    y="7"
                    id="cartCount"
                    fill="currentColor"
                    className="cart-count"
                    textAnchor="middle"
                    style={{ fontSize: cnt > 90 ? '7px' : '9px' }}
                >
                    {cnt > 99 ? '99+' : count}
                </text>
            </svg>
        </div>
    );
};

const TopMenuDropdown = (props: { item: MenuItem }) => {
    return (
        <>
            <Popover
                title={props.item.text}
                buttonText={props.item.text}
                buttonChevron={true}
                width={220}
                buttonTextProps={{
                    component: 'b',
                    sx: {
                        fontSize: (theme: Theme) =>
                            theme.typography.caption.fontSize,
                    },
                }}
            >
                {props.item.children.map(
                    (mItem: Maybe<MenuItem>, i: number) => {
                        return mItem && mItem.href !== null ? (
                            <PopoverLink key={i} href={mItem.href}>
                                {mItem.text}
                            </PopoverLink>
                        ) : null;
                    },
                )}
                {props.item.text === 'Help Center' && (
                    <Button
                        variant="contained"
                        fullWidth
                        href="/contact-us"
                        target="_blank"
                    >
                        Visit Help Center
                    </Button>
                )}
            </Popover>
        </>
    );
};

const TopMenuLink = (props: { item: MenuItem }) => {
    return props.item.href ? (
        <Link
            href={props.item.href}
            className={props.item.cssClasses ?? ''}
            variant="secondaryMenu"
        >
            {props.item.text}
        </Link>
    ) : props.item.children.length > 0 ? (
        <TopMenuDropdown item={props.item} />
    ) : null;
};

const TopBar = (props: any) => {
    const { isPro } = React.useContext(UserAuth);
    const { topMenu, freeShippingPrice } = React.useContext(SiteContext);
    const menuItems = topMenu.filter(
        (mi: MenuItem) => !(isPro() && mi.text === 'Pro Services'),
    );
    const { phone } = useContext(SiteContext);
    const handlePhoneClick = () => {
        window.location.href = `tel:${phone}`;
    };
    return (
        <ScContainer
            className="top-bar"
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 2,
            }}
        >
            <DesktopAndTabletDevice>
                <ContentModalButton
                    size="lg"
                    contentId={'31566'}
                    title={undefined}
                    button={
                        <Button
                            tabIndex={0}
                            className="free-shipping-text"
                            sx={{ p: 0, minWidth: 200 }}
                        >
                            {'FREE SHIPPING Over ' + freeShippingPrice + '*'}
                        </Button>
                    }
                />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Button
                    variant="link"
                    tabIndex={0}
                    className="free-shipping-text"
                    style={{ color: 'black', textDecoration: 'underline' }}
                    onClick={handlePhoneClick}
                >
                    {phone}
                </Button>
            </MobileDevice>
            <DesktopDevice>
                <Box component="nav" className="top-menu">
                    {menuItems ? (
                        <>
                            {menuItems
                                .slice(0, 2)
                                .map((item: MenuItem, i: number) => {
                                    return (
                                        <TopMenuLink
                                            key={item.text + i}
                                            item={item}
                                        />
                                    );
                                })}
                            {menuItems
                                .slice(2, 3)
                                .map((item: MenuItem, i: number) => {
                                    return (
                                        <TopMenuLink
                                            key={item.text + i}
                                            item={item}
                                        />
                                    );
                                })}

                            {menuItems
                                .slice(3)
                                .map((item: MenuItem, i: number) => {
                                    return (
                                        <TopMenuLink
                                            key={item.text + i}
                                            item={item}
                                        />
                                    );
                                })}
                        </>
                    ) : null}
                </Box>
            </DesktopDevice>
        </ScContainer>
    );
};

const MenuBar = (props: any) => {
    return <MainMenu {...props} />;
};

const PromoBar = () => {
    const { headerPromo } = React.useContext(SiteContext);
    const { user, isPro, isEmployee } = React.useContext(UserAuth);

    const getPromoColor = () => {
        const COLOR_PRO = '#1167b1';
        const COLOR_EMP = '#267038';
        const COLOR_DEFAULT =
            headerPromo?.backgroundColor ?? theme.palette.secondary.main;
        return {
            background: isEmployee()
                ? COLOR_EMP
                : isPro()
                  ? COLOR_PRO
                  : COLOR_DEFAULT,
            text:
                isEmployee() || isPro()
                    ? '#fff'
                    : headerPromo?.textColor ?? theme.palette.text.secondary,
        };
    };
    const [promoBarColor, setPromoBarColor] = React.useState(getPromoColor());
    React.useEffect(() => {
        setPromoBarColor(getPromoColor());
    }, [user]);

    return (
        <div className="promo-bar">
            <PopoverLink
                href={headerPromo.url}
                sx={{
                    backgroundColor: promoBarColor.background,
                    color: promoBarColor.text,
                }}
                aria-label={
                    'Site wide Promotion: ' +
                    alphanumericTextOnly(headerPromo.text)
                }
            >
                {headerPromo.text}
            </PopoverLink>
        </div>
    );
};

const SearchBar = (props: any) => {
    return (
        <SearchInputBox placeholder={props.placeholder} showProducts={true} />
    );
};

export const CustomerServiceMessagingContent = () => {
    const { user, isPro } = React.useContext(UserAuth);
    const { phoneHours } = React.useContext(SiteContext);
    const linkProps = {
        sx: {
            '& svg': {
                width: '24px',
                height: '24px',
                verticalAlign: '-.4375em',
            },
        },
    };
    return (
        <>
            <Stack spacing={2}>
                <PhoneLink
                    number={
                        isPro() && user.proAccountRep?.phone
                            ? user.proAccountRep?.phone
                            : null
                    }
                    linkProps={linkProps}
                />
                {!isPro() && <LiveChatLink linkProps={linkProps} />}
                <EmailLink
                    emailAddress="/contact-us"
                    emailText="Email Us"
                    linkProps={{
                        ...linkProps,
                        'aria-label': 'email us',
                    }}
                />
            </Stack>
            <br />
            <div tabIndex={0}>
                <Typography variant="body2" component="div">
                    <div dangerouslySetInnerHTML={{ __html: phoneHours }} />
                </Typography>
            </div>
        </>
    );
};

const CustomerServiceMessaging = () => {
    const { user, getProAccountRepPhoto, isPro } = React.useContext(UserAuth);
    const PopoverContent = () => {
        return <CustomerServiceMessagingContent />;
    };
    return (
        <DesktopAndTabletDevice>
            <>
                <DesktopDevice>
                    <ListItem
                        alignItems="flex-start"
                        component="div"
                        sx={{ py: 0 }}
                    >
                        <ListItemAvatar
                            sx={{
                                mt: 0.5,
                                display: {
                                    md: 'none',
                                    lg: 'none',
                                    xl: 'block',
                                },
                            }}
                        >
                            <Avatar
                                sx={{
                                    width: 48,
                                    height: 48,
                                    background: (theme: Theme) =>
                                        theme.palette.brand.lightesttan,
                                }}
                                alt={
                                    isPro() && user.proAccountRep?.name
                                        ? user.proAccountRep?.name
                                        : 'Customer Support Rep'
                                }
                                src={getProAccountRepPhoto()}
                            />
                        </ListItemAvatar>
                        <Popover
                            title={
                                isPro()
                                    ? 'Pro Account Manager Hours'
                                    : 'Ask an Expert'
                            }
                            buttonChevron={true}
                            buttonTitle={
                                isPro() && user.proAccountRep?.name
                                    ? user.proAccountRep?.name
                                    : 'Ask an Expert'
                            }
                            buttonText={
                                isPro() && user.proAccountRep?.phone
                                    ? user.proAccountRep?.phone
                                    : '1-877-743-2269'
                            }
                            buttonTextProps={{
                                variant: 'bold',
                            }}
                            buttonTitleProps={{
                                'aria-label':
                                    'Speak with a Customer Service Agent',
                                sx: {
                                    color: 'inherit',
                                    display: 'block',
                                    textAlign: 'left',
                                    lineHeight: '1',
                                    fontSize: (theme: Theme) =>
                                        theme.typography.body2.fontSize,
                                    mt: 0.5,
                                },
                            }}
                        >
                            <PopoverContent />
                        </Popover>
                    </ListItem>
                </DesktopDevice>
                <TabletDevice>
                    <Popover
                        title={'Call Us'}
                        open={false}
                        buttonChevron={true}
                        buttonIconSxProps={{ color: 'primary' }}
                        buttonIcon={
                            <PhoneIcon
                                fontSize="large"
                                sx={{ ml: -2, mb: -2 }}
                            />
                        }
                        buttonText={
                            <Typography variant="caption" component="span">
                                Call Us
                            </Typography>
                        }
                    >
                        <PopoverContent />
                    </Popover>
                </TabletDevice>
            </>
        </DesktopAndTabletDevice>
    );
};

const ProMessaging = () => {
    const { isPro } = React.useContext(UserAuth);
    return isPro() ? null : (
        <Popover
            title={'Are You A Pro?'}
            buttonChevron={false}
            buttonTitle={'Are you a pro?'}
            buttonText={
                <span>
                    Get <ProLabel /> Pricing
                </span>
            }
            buttonTitleProps={{
                sx: {
                    display: 'block',
                    textAlign: 'left',
                    lineHeight: '1',
                    fontSize: (theme: Theme) => theme.typography.body2.fontSize,
                    mt: 0.5,
                },
            }}
            buttonTextProps={{
                variant: 'bold',
            }}
            buttonProps={{
                sx: {
                    ':hover': {
                        color: (theme: Theme) => theme.palette.secondary.main,
                        textDecoration: 'none',
                        span: {
                            color: (theme: Theme) => theme.palette.pro.main,
                        },
                    },
                },
            }}
            onClick={() => (window.location.href = '/pro')}
        />
    );
};

const AccountDropdown = () => {
    const { user, isLoggedIn, logout, setLoginRedirectUrl } =
        React.useContext(UserAuth);
    const { setOrder } = React.useContext(CartContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const location = useLocation();

    React.useEffect(() => {
        handleClose();
    }, [location]);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const PopoverContent = () => {
        return isLoggedIn() ? (
            <>
                <PopoverLink href="/account/change-address">
                    Edit My Account
                </PopoverLink>
                <PopoverLink href="/account">My Orders</PopoverLink>
                <PopoverLink href="/account/manage-my-lists">
                    My Lists
                </PopoverLink>
                <PopoverLink href="/account/payments">
                    Gift Card Balance
                </PopoverLink>
                <PopoverLink href="/account/payments/points">
                    Reward Points History
                </PopoverLink>
                <PopoverLink
                    className="link-signout"
                    onClick={e => {
                        e.preventDefault();
                        logout().then((resp: LogoutResponse) => {
                            setOrder(resp.order);
                            handleClose();
                        });
                    }}
                >
                    Sign Out
                </PopoverLink>
            </>
        ) : (
            <>
                {window.location.href.indexOf('/cart') > -1 ? (
                    <>
                        <SignInButton
                            type="login"
                            button={
                                <Button
                                    component={Link}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                >
                                    Sign In / Create Account
                                </Button>
                            }
                            onComplete={() => {
                                handleClose();
                            }}
                        />
                    </>
                ) : (
                    <>
                        <Button
                            component={Link}
                            color="primary"
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                setLoginRedirectUrl();
                                navigate('/login');
                                handleClose();
                            }}
                        >
                            Sign In / Create Account
                        </Button>
                    </>
                )}
                <br />
                <br />
                <div tabIndex={0}>
                    <Typography variant="bold">Account Benefits</Typography>
                    <BulletedList
                        bullets={[
                            'Earn 1 point per $1 spent',
                            'Track your orders',
                            'Save your favorite items',
                            'Quick and easy checkout',
                        ]}
                    />
                </div>
            </>
        );
    };
    return (
        <>
            <DesktopDevice>
                <Popover
                    title={'My Account'}
                    width={isLoggedIn() ? 220 : 280}
                    open={open}
                    buttonChevron={true}
                    buttonTitle={
                        isLoggedIn()
                            ? user.firstName
                                ? 'Hi ' + user.firstName
                                : 'Hi Griller'
                            : 'Sign In'
                    }
                    buttonText={'My Account'}
                    buttonProps={{
                        'aria-label': 'My Account',
                        id: 'my-account-btn',
                    }}
                    buttonTitleProps={{
                        sx: {
                            position: 'relative',
                            zIndex: 9,
                            display: 'block',
                            textAlign: 'left',
                            lineHeight: '1',
                            fontSize: (theme: Theme) =>
                                theme.typography.body2.fontSize,
                            mt: 0.5,
                        },
                    }}
                    buttonTextProps={{
                        variant: 'bold',
                    }}
                >
                    <PopoverContent />
                </Popover>
            </DesktopDevice>
            <TabletDevice>
                <Popover
                    title={'My Account'}
                    width={260}
                    open={open}
                    buttonChevron={true}
                    buttonIconSxProps={{ color: 'secondary' }}
                    buttonIcon={
                        <AccountCircleIcon
                            fontSize="large"
                            sx={{ ml: -2, mb: -2 }}
                        />
                    }
                    buttonText={
                        <Typography variant="caption" component="div">
                            Account
                        </Typography>
                    }
                    buttonProps={{
                        'aria-label': 'My Account',
                        id: 'my-account-btn',
                    }}
                >
                    <PopoverContent />
                </Popover>
            </TabletDevice>
        </>
    );
};

const AccountSignin = () => {
    return <AccountDropdown />;
};

// const CartFlyoutContents = (props: { order: Order }) => {
//     const order = props.order;
//     return (
//         <Box sx={{ padding: 0 }}>
//             <Grid container sx={{ height: '5vh', padding: '.5vh 1rem' }}>
//                 <Grid item sm={6}><Typography variant="subtitle">Cart</Typography></Grid>
//                 <Grid item sm={6} textAlign="right"><Typography variant="subtitle">{order.orderNumber}</Typography></Grid>
//             </Grid>
//             <Divider />
//             <Box paddingLeft="1rem" paddingRight="1rem" paddingBottom="5rem" sx={{ overflow: 'scroll', height: '85vh' }}>
//                 <List className={"cart-items"}>
//                     {order?.orderItems.map((oi: OrderItem, i: number) => {
//                         return (
//                             <React.Fragment key={i}>
//                                 <ListItem button key={oi.id}>
//                                     <ListItemIcon style={{ marginRight: 20 }}>
//                                         <img src={oi.item.imageUrl} width={75} height={75} alt={oi.name} />
//                                     </ListItemIcon>
//                                     <ListItemText>
//                                         {(oi.tdiId != null ? (
//                                             <Typography variant="bold">[OPEN BOX] </Typography>
//                                         ) : null)}
//                                         <Link href={oi.item.url}>
//                                             <Typography variant="linkColor">{oi.name}</Typography>
//                                         </Link>
//                                         <Grid container>
//                                             <Grid item xs={6}>
//                                                 QTY: {oi.qty}
//                                             </Grid>
//                                             <Grid item xs={6} textAlign="right">
//                                                 {oi.pricingFormatted.price}
//                                             </Grid>
//                                         </Grid>
//                                     </ListItemText>
//                                 </ListItem>
//                                 <Divider />
//                             </React.Fragment>
//                         )
//                     })}
//                 </List>
//             </Box>
//             {order?.orderItems.length > 0 ? (
//                 <Box position="absolute" bottom="0" left="0" right="0" sx={{ height: '20vh', padding: '.5vh 1rem' }}>
//                     <Grid container>
//                         <Grid item sm={6}>
//                             <Typography variant="body1">Subtotal</Typography>
//                         </Grid>
//                         <Grid item sm={6} textAlign="right">
//                             <Typography variant="body1">{order.pricingFormatted.subtotal}</Typography>
//                         </Grid>
//                     </Grid>
//                     <Box sx={{ marginBottom: '1rem' }}>
//                         <Link href="/cart">
//                             <Button variant="outlined" size="large" fullWidth>View Cart</Button>
//                         </Link>
//                     </Box>
//                     <Box>
//                         <Link href="/cart/checkout">
//                             <Button variant="contained" size="large" fullWidth>Checkout</Button>
//                         </Link>
//                     </Box>
//                 </Box>
//             ) : null}
//         </Box>
//     )
// }

const CartIcon = (props: any) => {
    const size = props.size ? props.size : CART_ICON_SIZE;
    const { getTotalQtyInCart } = React.useContext(CartContext);

    const [color, setColor] = React.useState('secondary');
    const cnt = getTotalQtyInCart();
    return (
        <Link
            href="/cart"
            color="primary"
            id="my-cart-btn"
            aria-label={
                cnt +
                (cnt === 1 ? ' item' : ' items') +
                ' in my shopping cart. Click to navigate to cart.'
            }
            onMouseEnter={() => setColor('primary')}
            onMouseLeave={() => setColor('secondary')}
            data-testid="cart-link"
            sx={{
                position: 'relative',
                zIndex: 9,
                padding: 0,
                textDecoration: 'none',
                ...props.style,
                marginTop: { md: 0 },
                borderRadius: 0,
            }}
        >
            <Box
                component="span"
                sx={{
                    textAlign: 'center',
                }}
            >
                <ShoppingCartIcon size={size} count={cnt} />
                <DesktopDevice>
                    <Typography
                        variant="small"
                        component="div"
                        color={color}
                        sx={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        My Cart
                    </Typography>
                </DesktopDevice>
                <TabletDevice>
                    <Typography
                        variant="small"
                        component="div"
                        color={color}
                        sx={{ mt: -1, whiteSpace: 'nowrap' }}
                    >
                        My Cart
                    </Typography>
                </TabletDevice>
            </Box>
        </Link>
    );
};

export const Logo = (props: any) => {
    const { isPro } = React.useContext(UserAuth);
    const width = props.width ? props.width : isPro() ? '300px' : '256px';
    const height = props.height ? props.height : '38px';
    const imgSxProps = props.sx ? props.sx : {};
    let logo = getEnvironmentVariable('IMAGE_LOGO_URL');
    if (isPro()) {
        logo = getEnvironmentVariable('IMAGE_LOGO_URL_PRO');
    }
    return (
        <Link
            href="/"
            aria-label="BBQGuys Logo: return to home page"
            display="inline-flex"
        >
            <Image
                alt="BBQGuys logo"
                className="logo"
                height={height}
                width={width}
                src={logo}
                useExactUrl
                lazyLoadImage={false}
                sx={imgSxProps}
            />
        </Link>
    );
};

const HeaderLayoutDesktop = () => {
    const { isPro } = React.useContext(UserAuth);
    const { expandSearchBar } = React.useContext(SearchBarContext);
    let gridSizing = '6fr 8fr 3fr 3fr 3fr 1fr';
    if (expandSearchBar) {
        gridSizing = '6fr 17fr 3fr 1fr';
    }
    if (isPro()) {
        gridSizing = '6fr 10fr 5fr 3fr 2fr';
        if (expandSearchBar) {
            gridSizing = '6fr 15fr 3fr 2fr';
        }
    }
    return (
        <ScContainer
            containerProps={{
                id: 'header',
                display: 'grid',
                gridTemplateColumns: gridSizing,
                gap: 2,
                alignItems: 'center',
            }}
            sx={{ my: 0.5 }}
        >
            <Box className="logo-container">
                <Logo height={48} />
            </Box>
            <Box className="search-bar-container">
                <SearchBar placeholder="Search BBQGuys" />
            </Box>
            {expandSearchBar === false ? (
                <>
                    <CustomerServiceMessaging />
                    <ProMessaging />
                    <AccountSignin />
                    <CartIcon size={CART_ICON_SIZE} />
                </>
            ) : (
                <>
                    <AccountSignin />
                    <CartIcon size={CART_ICON_SIZE} />
                </>
            )}
        </ScContainer>
    );
};

const HeaderLayoutTablet = () => {
    const { expandSearchBar } = React.useContext(SearchBarContext);
    const { mainMenu } = React.useContext(SiteContext);
    return (
        <ScContainer
            containerProps={{
                id: 'header',
                display: 'grid',
                gridTemplateColumns: expandSearchBar
                    ? '1fr 10fr 1fr'
                    : '6fr 8fr 6fr',
                gap: '1rem',
                padding: '0 1rem',
                width: 'auto',
            }}
            sx={{ my: 2 }}
        >
            <Box
                className="logo-container"
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 7fr',
                    gap: '1rem',
                }}
            >
                <MenuBar menu={mainMenu} buttonWidth={45} />
                {expandSearchBar === false ? <Logo height={48} /> : null}
            </Box>
            <Box className="search-bar-container">
                <SearchBar placeholder="Search BBQGuys" />
            </Box>
            <Box
                display="grid"
                gap="1rem"
                gridTemplateColumns={expandSearchBar ? '1fr' : '3fr 3fr 2fr'}
            >
                {expandSearchBar === false ? (
                    <>
                        <CustomerServiceMessaging />
                        <AccountSignin />
                        <CartIcon size={CART_ICON_SIZE} />
                    </>
                ) : (
                    <CartIcon size={CART_ICON_SIZE} />
                )}
            </Box>
        </ScContainer>
    );
};

const HeaderBar = () => {
    return (
        <>
            <DesktopDevice>
                <HeaderLayoutDesktop />
            </DesktopDevice>
            <TabletDevice>
                <HeaderLayoutTablet />
            </TabletDevice>
        </>
    );
};

const HeaderDesktop = () => {
    return (
        <AppBar position="static" color="transparent" elevation={0}>
            <Box id="header-container">
                <TopBar />
                <HeaderBar />
                <MenuBar />
            </Box>
            <PromoBar />
        </AppBar>
    );
};

const HeaderTablet = () => {
    return (
        <AppBar
            position="static"
            color="transparent"
            elevation={0}
            sx={{ p: 0 }}
        >
            <Box id="header-container">
                <TopBar />
                <HeaderBar />
            </Box>
            <PromoBar />
        </AppBar>
    );
};

const MobileSearchBar = () => {
    return <SearchBar placeholder="Search BBQGuys" />;
};

const HeaderMobile = () => {
    const { searchBarFocused, autocompleteOpen } =
        React.useContext(SearchBarContext);
    const SEARCH_BAR_ALWAYS_EXPANDED = true;
    return (
        <AppBar position="static" color="transparent">
            <Box id="header-container">
                <TopBar />
                <Box
                    id="header"
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '2fr 7fr 2fr',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        gap: 1,
                        paddingTop: 1,
                    }}
                >
                    <MenuBar buttonWidth={44} />
                    <Logo height={32} />
                    <CartIcon
                        size={32}
                        orderItems={[]}
                        style={{ marginTop: 5 }}
                    />
                </Box>
            </Box>
            {autocompleteOpen || SEARCH_BAR_ALWAYS_EXPANDED ? (
                <Box
                    className={
                        'search-bar-container' +
                        (searchBarFocused && autocompleteOpen
                            ? ' focused'
                            : '') +
                        (!SEARCH_BAR_ALWAYS_EXPANDED ? ' with-bg' : '')
                    }
                >
                    <MobileSearchBar />
                </Box>
            ) : null}
            <PromoBar />
        </AppBar>
    );
};

export default function Header() {
    const { getUser } = React.useContext(UserAuth);

    // this call to get the user needs to be here since its nested within the UserAuthProvider. Moving outside UserAuthProvider (like into App.jsx) would cause the user to not update on the provider
    React.useEffect(() => {
        getUser();
    }, []);
    return (
        <SearchBarProvider>
            <DesktopDevice>
                <HeaderDesktop />
            </DesktopDevice>

            <TabletDevice>
                <HeaderTablet />
            </TabletDevice>

            <MobileDevice>
                <HeaderMobile />
            </MobileDevice>
        </SearchBarProvider>
    );
}
