import { gql, useQuery } from '@apollo/client';
import React from 'react';
import { PAGINATED_ITEMS } from '../../../Graphql/fragments';
import { Item } from '../../../types';
import {
    ProductCard,
    ProductCardLoading,
} from '../../ProductCards/ProductCards';
import BBQGuysCarousel, {
    BBQGuysCarouselLoading,
} from '../../_base/BBQGuysCarousel';
import './ProductCarousel.scss';
import {
    AtcTrackingGroup,
    AtcTrackingType,
} from '../../Buttons/AddToCart/AddToCart';
import { DigitalDataContext } from '../../../Contexts/DigitalDataProvider';
import { UserAuth } from '../../../Contexts/UserAuthProvider';

export type ImageType = { id: number; url: string };

type ProductCarouselBaseProps = {
    title?: string;
    count?: number;
    trackingType?: AtcTrackingType;
    trackingGroup?: AtcTrackingGroup;
    dependencyName?: string;
};

type ProductCarouselWithItemsProps = ProductCarouselBaseProps & {
    items: Item[];
};
type ProductCarouselLookupItemsProps = ProductCarouselBaseProps & {
    itemIds: number[];
};

type ProductCarouselProps = ProductCarouselBaseProps & {
    items?: Item[];
    itemIds?: number[];
};

const QL_ITEMS = gql`
    ${PAGINATED_ITEMS}
    query getItemsCarousel($itemIds: ID, $pricingTierId: Int) {
        itemsByIds(itemIds: $itemIds, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

const ProductCarouselWithItems = (props: ProductCarouselWithItemsProps) => {
    const { items, title, count, trackingType, trackingGroup, dependencyName } =
        props;

    let trackingTypeLocal = trackingType;
    let trackingGroupLocal = trackingGroup;

    if (dependencyName) {
        const carouselName: string = dependencyName;
        const itemWidth: number = count ?? 6;
        const { pushCarouselDependency } = React.useContext(DigitalDataContext);
        pushCarouselDependency({
            carouselName: carouselName,
            results: items,
            itemWidth: itemWidth,
        });

        trackingTypeLocal = trackingType ?? 'carousel';
        trackingGroupLocal = trackingGroup ?? dependencyName;
    }
    return (
        <BBQGuysCarousel title={title} count={count}>
            {items.map((itm: Item, i: number) => {
                return (
                    <ProductCard
                        item={itm}
                        trackingType={trackingTypeLocal}
                        trackingGroup={trackingGroupLocal}
                        trackingItemOrder={i}
                        key={'product-card-' + itm.id + '-' + itm.pricing.type}
                    />
                );
            })}
        </BBQGuysCarousel>
    );
};

const ProductCarouselLookupItems = (props: ProductCarouselLookupItemsProps) => {
    const {
        itemIds,
        title,
        count,
        trackingType,
        trackingGroup,
        dependencyName,
    } = props;
    const { getPricingTierId } = React.useContext(UserAuth);
    const { data, loading } = useQuery(QL_ITEMS, {
        variables: {
            itemIds: itemIds.join(','),
            pricingTierId: getPricingTierId(),
        },
    });
    if (loading) {
        return <ProductCarouselLoading title={title} count={count} />;
    } else {
        const items = data.itemsByIds.results;
        return (
            <ProductCarouselWithItems
                items={items}
                count={count}
                title={title}
                trackingType={trackingType}
                trackingGroup={trackingGroup}
                dependencyName={dependencyName}
            />
        );
    }
};

export default function ProductCarousel(props: ProductCarouselProps) {
    const {
        itemIds = [],
        title,
        items = [],
        trackingType,
        trackingGroup = undefined,
        dependencyName,
    } = props;
    const count = props.count !== undefined ? props.count : 6;
    if (items.length === 0 && itemIds.length === 0) {
        return <></>;
    }

    if (items.length > 0) {
        return (
            <ProductCarouselWithItems
                items={items}
                title={title}
                count={count}
                trackingType={trackingType}
                trackingGroup={trackingGroup}
                dependencyName={dependencyName}
            />
        );
    }

    return (
        <ProductCarouselLookupItems
            itemIds={itemIds}
            title={title}
            count={count}
            trackingType={trackingType}
            trackingGroup={trackingGroup}
            dependencyName={dependencyName}
        />
    );
}

export function ProductCarouselLoading(props: {
    count?: number;
    title?: string;
}) {
    const { count = 6, title } = props;
    return (
        <BBQGuysCarouselLoading
            count={count}
            title={title}
            loadingComponent={<ProductCardLoading />}
        />
    );
}
