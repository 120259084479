import './SearchInputBox.scss';
import { HawkSearchProps, HawkSearchBar } from './Search/HawkSearch/SearchBar';
import React from 'react';

export const SearchInputBox: React.FC<HawkSearchProps> = ({
    placeholder,
    showProducts = true,
    isFullWidth = false,
}) => {
    return (
        <div className="search-bar">
            <HawkSearchBar
                placeholder={placeholder}
                showProducts={showProducts}
                isFullWidth={isFullWidth}
            />
        </div>
    );
}
