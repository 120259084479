import { FormikProvider, useFormik } from 'formik';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import * as Yup from 'yup';
import { ProductQtyContext } from '../../Contexts/ProductQtyProvider';
import { NumberWithLabel } from '../../Pages/Account/PageEditMyAccount/Components/NumberWithLabel';

const QtyInput = () => {
    const { qty, setQty, maxQty, loading } = useContext(ProductQtyContext);
    const [lastValidQty, setLastValidQty] = useState<number>(qty);

    useEffect(() => {
        setLastValidQty(qty);
    }, [qty]);

    const debounce = (
        func: (...args: any[]) => void,
        wait: number,
    ): ((...args: any[]) => void) => {
        let timeout: NodeJS.Timeout | null;
        return (...args: any[]) => {
            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(() => {
                func.apply(this, args);
            }, wait);
        };
    };

    const formik = useFormik({
        initialValues: { qty },
        validationSchema: Yup.object({
            qty: Yup.number().min(1).max(maxQty).required(),
        }),
        validateOnBlur: true,
        onSubmit: values => {
            setQty(values.qty);
            setLastValidQty(values.qty);
        },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const numericValue = parseInt(value, 10);
        if (!isNaN(numericValue)) {
            formik.setFieldValue(
                'qty',
                Math.max(1, Math.min(numericValue, maxQty)),
                false,
            );
        } else {
            formik.setFieldValue('qty', '', false);
        }
        debounceValidate(value);
    };

    const handleBlur = () => {
        const qtyValue = Number(formik.values.qty);
        if (isNaN(qtyValue) || qtyValue < 1) {
            formik.setFieldValue('qty', lastValidQty);
            setQty(lastValidQty);
        } else {
            formik.handleSubmit();
        }
    };

    const validateQty = (value: string) => {
        let number = parseInt(value, 10);
        if (!isNaN(number)) {
            number = Math.max(1, Math.min(number, maxQty));
            setLastValidQty(number);
            formik.setFieldValue('qty', number, false);
            setQty(number);
        }
    };

    const debounceValidate = useCallback(debounce(validateQty, 1500), [maxQty]);

    return (
        <FormikProvider value={formik}>
            <NumberWithLabel
                formik={formik}
                id="atc-qty-select"
                name="qty"
                fullWidth
                maxQty={maxQty}
                minQty={1}
                onChange={handleChange}
                onBlur={handleBlur}
                formControlProps={{ sx: { m: 0 } }}
                sx={{
                    m: 0,
                    '#atc-qty-select-label': { visibility: 'hidden' },
                }}
                aria-label="Item Quantity"
                label="Item Quantity"
                labelHidden
                required={false}
                disabled={loading}
                inputProps={{
                    sx: { m: 0, textAlign: 'center', fontSize: '2em' },
                }}
            />
        </FormikProvider>
    );
};

export default QtyInput;
