import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import React from 'react';
import { HelpTipIcon } from '../../Components/_base/BBQGuysComponents';
import {
    ExpertReviewBar,
    ExpertReviewBulb,
    ExpertReviewRatingBar,
} from '../../Components/_base/BBQGuysStyledComponents';
import { DesktopDevice } from '../../Contexts/DeviceTypeProvider';
import { ExpertReviewClass } from '../../types';

export const ExpertReviewRatingsBar = (props: { rating: number }) => {
    return (
        <ExpertReviewRatingBar>
            <ExpertReviewBar>
                <ExpertReviewBulb sx={{ left: props.rating * 20 + '%' }} />
            </ExpertReviewBar>
        </ExpertReviewRatingBar>
    );
};

export default function ExpertReviewsRatingsTable(props: {
    expertReview: ExpertReviewClass;
    fontSize?: number;
}) {
    const { expertReview, fontSize = 16 } = props;

    return expertReview.fields && expertReview.rating != '0' ? (
        <Paper
            elevation={2}
            sx={{
                paddingX: 4,
                paddingY: 2,
            }}
        >
            <Grid spacing={1} container alignItems="baseline">
                <Grid item xs={6}>
                    <Typography variant="bold">Overall Rating</Typography>
                </Grid>
                <Grid item xs={6} textAlign="right">
                    <Typography
                        variant="bold"
                        fontSize={{ lg: fontSize * 1.5 }}
                        title={expertReview.ratingDescription}
                    >
                        <Typography
                            variant="bold"
                            fontSize={{ lg: fontSize * 2.5 }}
                        >
                            {expertReview.rating}
                        </Typography>{' '}
                        out of 5
                    </Typography>
                </Grid>
            </Grid>
            <Stack spacing={1}>
                {expertReview.fields.map((field, i) => {
                    return (
                        <React.Fragment key={i}>
                            {field && (
                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: {
                                            xs: '1fr 45px',
                                            lg: '130px 1fr 45px',
                                        },
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: 2,
                                    }}
                                >
                                    <Box
                                        display="grid"
                                        gap={1}
                                        gridTemplateColumns={'1fr 25px'}
                                        alignContent="center"
                                        justifyContent="space-between"
                                    >
                                        <Typography fontSize={fontSize}>
                                            {field?.name}
                                        </Typography>
                                        <HelpTipIcon
                                            contentId={field.contentId}
                                            title={field?.name || 'Unknown'}
                                        />
                                    </Box>
                                    <DesktopDevice>
                                        <Box>
                                            <ExpertReviewRatingsBar
                                                rating={field?.rating || 1}
                                            />
                                        </Box>
                                    </DesktopDevice>
                                    <Box textAlign="right">
                                        <Typography
                                            fontSize={fontSize * 1.625}
                                            variant="bold"
                                        >
                                            {field?.rating.toFixed(1)}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </React.Fragment>
                    );
                })}
            </Stack>
        </Paper>
    ) : (
        <></>
    );
}
