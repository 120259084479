import document from 'global/document';

export enum AdobeLaunchEvents {
    CART_REMOVE_ITEM = 'CartItemRemove',
    CART_ADD = 'cartAdd',
    CART_REMOVE = 'cartRemove',
}

export default class AdobeLaunchEvent {
    static dispatch(eventName: string, eventContent?: any) {
        if (document.body) {
            document.body.dispatchEvent(new CustomEvent(eventName, eventContent));
        }
    }
}
