import {
    Box,
    FormControl,
    FormControlOwnProps,
    FormLabel,
    FormControlLabel,
    FormHelperText,
    SxProps,
    Radio,
    RadioGroup,
} from '@mui/material';
import { FormikValues } from 'formik';
import { HelpTipIcon, ScreenReaderOnly } from '../../_base/BBQGuysComponents';
import Asterisk from '../../Asterisk';

interface RadioWWithLabelProps {
    id: string;
    name: string;
    formik: FormikValues;
    label?: string | React.ReactNode;
    labelHidden?: boolean;
    required?: boolean;
    sx?: SxProps;
    formControlProps?: FormControlOwnProps;
    helpTipContentId?: string | number;
    helpTipTitle?: string;
    touched?: boolean;
    data?: any;
}

interface RadioOptions {
    label: string;
    value: string;
}

export const RadioWithLabel = (props: RadioWWithLabelProps) => {
    const {
        id,
        name,
        formik,
        label = '',
        labelHidden = false,
        required = true,
        sx = {},
        formControlProps = {},
        helpTipContentId,
        helpTipTitle,
        touched = false,
        data,
        ...rest
    } = props;
    return (
        <FormControl
            variant="standard"
            error={
                (touched || formik.touched[name]) &&
                Boolean(formik.errors[name])
            }
            disabled={formik.isSubmitting}
            sx={{ width: '100%', mb: 3 }}
            {...formControlProps}
        >
            {label && labelHidden ? (
                <ScreenReaderOnly>
                    <Box
                        display="grid"
                        gridTemplateColumns={required ? '.8rem 1fr' : '1fr'}
                        component="span"
                        alignItems="baseline"
                    >
                        <Asterisk required={required} />
                        <FormLabel htmlFor={id} id={`radio-${id}-group-label`}>
                            {label}
                            {helpTipContentId && (
                                <HelpTipIcon
                                    contentId={helpTipContentId}
                                    title={helpTipTitle}
                                />
                            )}
                        </FormLabel>
                    </Box>
                </ScreenReaderOnly>
            ) : (
                label && (
                    <Box
                        display="grid"
                        gridTemplateColumns={required ? '.8rem 1fr' : '1fr'}
                        component="span"
                        alignItems="baseline"
                    >
                        <Asterisk required={required} />
                        <FormLabel htmlFor={id} id={`radio-${id}-group-label`}>
                            {label}
                            {helpTipContentId && (
                                <HelpTipIcon
                                    contentId={helpTipContentId}
                                    title={helpTipTitle}
                                />
                            )}
                        </FormLabel>
                    </Box>
                )
            )}
            <RadioGroup
                aria-labelledby={`radio-${id}-group-label`}
                value={formik.values[name]}
                id={id}
                name={name}
                onChange={formik.handleChange}
                sx={sx}
                row
                {...rest}
            >
                {data?.map((item: RadioOptions, i: number) => {
                    return (
                        <FormControlLabel
                            key={id + i}
                            value={item.value ?? ''}
                            label={item.label ?? ''}
                            control={<Radio />}
                            disabled={formik.isSubmitting}
                        />
                    );
                })}
            </RadioGroup>
            {formik.touched[name] && formik.errors[name] ? (
                <FormHelperText error>{formik.errors[name]}</FormHelperText>
            ) : null}
        </FormControl>
    );
};
