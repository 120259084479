import { useQuery } from '@apollo/client';
import { Avatar, Box, FormLabel, ListItemAvatar } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { SelectChangeEvent } from '@mui/material/Select';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { Image } from '../../../../Components/Image';
import {
    HelpTipIcon,
    Select,
} from '../../../../Components/_base/BBQGuysComponents';
import { ProductQtyContext } from '../../../../Contexts/ProductQtyProvider';
import { QL_ITEM_WARRANTIES } from '../../../../Graphql/queries';
import { Item, PaginatedExtendedWarrantyResponse } from '../../../../types';
import { ItemOptionsLoading } from './ItemOptionsDropdown';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';
import { isMobile } from '../../../../Contexts/DeviceTypeProvider';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ItemWarrantiesDropdown({
    itemId,
    onReset,
    onSelectChange,
}: {
    itemId: string;
    onSelectChange: (warrantyItem: Item) => void;
    onReset: () => void;
}) {
    const { getPricingTierId } = React.useContext(UserAuth);
    const { addItemToBundle, removeItemsFromBundle } =
        React.useContext(ProductQtyContext);
    const { data, loading } = useQuery(QL_ITEM_WARRANTIES, {
        variables: {
            itemId: itemId,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ItemOptionsLoading />;
    }
    const itemWarranties = data.itemWarranties;
    return itemWarranties && itemWarranties.countResults > 0 ? (
        <div aria-description="Extended warranty options">
            <FormLabel sx={{ alignItems: 'center' }}>
                Add product protection offered by{' '}
                <Image
                    alt="Extend"
                    width={47}
                    height={10}
                    useExactUrl
                    src="https://cdn.shocho.co/sc-site/icons/Extend/Extend_color.png?i10c=img.resize(width:47,height:10)"
                    lazyLoadImage={false}
                    sx={{ ml: 0.5 }}
                />
                :
            </FormLabel>
            <ItemWarranties
                extendedWarranties={itemWarranties}
                onReset={() => {
                    removeItemsFromBundle(
                        itemWarranties.results[0]?.items ?? [],
                    );
                    onReset();
                }}
                onSelectChange={itm => {
                    removeItemsFromBundle(
                        itemWarranties.results[0]?.items ?? [],
                    );
                    addItemToBundle(itm, itemId);
                    onSelectChange(itm);
                }}
            />
        </div>
    ) : null;
}

function ItemWarranties(props: {
    extendedWarranties: PaginatedExtendedWarrantyResponse;
    onSelectChange: (warrantyItem: Item) => void;
    onReset: () => void;
}) {
    const { extendedWarranties, onSelectChange, onReset } = props;
    const DEFAULT_VALUE = 'Decline Protection';
    const [selectedValue, setSelectedValue] =
        React.useState<string>(DEFAULT_VALUE);

    const getWarrantyLabel = (warrantyLengthMonths: number): string => {
        const lengthYears = Math.round(warrantyLengthMonths / 12);
        return lengthYears
            ? lengthYears + ' Year Parts & Labor'
            : 'Decline Protection';
    };

    /* gets the active selected option based on the value */
    const getActiveSelectedOption = (
        extendedWarranties: PaginatedExtendedWarrantyResponse,
        value: string,
    ): Maybe<Item> => {
        return (
            extendedWarranties.results[0]?.items.find(
                (itm: Maybe<Item>) =>
                    itm &&
                    getWarrantyLabel(itm?.warrantyLengthMonths ?? 0) === value,
            ) || null
        );
    };

    /* change events on the select component */
    const handleChange = React.useCallback(
        (event: SelectChangeEvent<typeof selectedValue>) => {
            const { value } = event.target;
            setSelectedValue(value as string);
            /* if not a mobile device, navigate to the options URL*/
            if (!isMobile) {
                const selected = getActiveSelectedOption(
                    extendedWarranties,
                    value as string,
                );
                if (selected) {
                    onSelectChange(selected);
                } else if (value === DEFAULT_VALUE || value === '0') {
                    onReset();
                }
            }
        },
        [
            isMobile,
            extendedWarranties,
            onSelectChange,
            onReset,
            getActiveSelectedOption,
        ],
    );

    /* handling the drawer closing to reset or commit the changes */
    const handleClose = React.useCallback(
        (type: 'cancel' | 'confirm') => {
            if (type === 'cancel') {
                /* reset the select value back to default */
                setSelectedValue(DEFAULT_VALUE);
                onReset();
            } else if (type === 'confirm') {
                const selected = getActiveSelectedOption(
                    extendedWarranties,
                    selectedValue as string,
                );
                if (selected) {
                    onSelectChange(selected);
                } else if (
                    selectedValue === DEFAULT_VALUE ||
                    selectedValue === '0'
                ) {
                    onReset();
                }
            }
        },
        [
            selectedValue,
            extendedWarranties,
            onSelectChange,
            onReset,
            getActiveSelectedOption,
        ],
    );

    if (extendedWarranties.results[0] === undefined) {
        return <></>;
    }
    const selectItems = [
        { label: DEFAULT_VALUE, value: DEFAULT_VALUE, img: null },
        ...(extendedWarranties.results[0]?.items ?? []).map(
            (opt: Maybe<Item>) => {
                const warrantyLengthMonths = opt?.warrantyLengthMonths ?? 0;
                let label = opt?.name;
                let priceLabel = '';
                if (warrantyLengthMonths) {
                    label = getWarrantyLabel(warrantyLengthMonths);
                    priceLabel = 'Only ' + opt?.pricingFormatted.sale + ' more';
                }

                return {
                    label: label,
                    value: label,
                    img: opt?.imageUrl,
                    priceLabel: priceLabel,
                };
            },
        ),
    ];
    return (
        <Box
            sx={{
                m: 0,
                mb: 2,
                display: 'grid',
                gridTemplateColumns: 'calc(100% - 24px) 16px',
                gap: 1,
                alignItems: 'center',
            }}
        >
            <Box>
                <Select
                    grouped={true}
                    groupIndex={0}
                    groupSize={1}
                    labelId={'warranty-options-label'}
                    label={extendedWarranties.results[0]?.title}
                    title="Add Protection Plan by Extend"
                    id={'warranty-options'}
                    value={selectedValue}
                    onChange={handleChange}
                    onClose={handleClose}
                    MenuProps={MenuProps}
                    aria-description={extendedWarranties.results[0]?.title}
                >
                    {selectItems.map((opt: any) => {
                        return (
                            <MenuItem
                                key={'warranty-' + opt.value}
                                value={opt.value}
                            >
                                {opt.img && (
                                    <ListItemAvatar>
                                        <Avatar
                                            alt={opt.label}
                                            src={opt.img}
                                            variant="square"
                                            sx={{ mixBlendMode: 'multiply' }}
                                        />
                                    </ListItemAvatar>
                                )}
                                <ListItemText
                                    primary={opt.label}
                                    secondary={opt.priceLabel}
                                    sx={{ whiteSpace: 'normal' }}
                                />
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>
            {extendedWarranties.results[0]?.contentId && (
                <HelpTipIcon
                    contentId={extendedWarranties.results[0]?.contentId}
                    title={'Extend Protection Plan'}
                />
            )}
        </Box>
    );
}
