import { useQuery } from '@apollo/client';
import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { Maybe } from 'graphql/jsutils/Maybe';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    HelpTipIcon,
    Section,
} from '../../../../Components/_base/BBQGuysComponents';
import {
    DesktopAndTabletDevice,
    DesktopDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { QL_ITEM_SPECIFICATIONS } from '../../../../Graphql/queries';
import { Specification } from '../../../../types';
import './Specifications.scss';

export const chunkArray = (arr: any[], n: number) => {
    const chunkLength = Math.max(Math.ceil(arr.length / n), 1);
    const chunks = [];
    for (let i = 0; i < n; i++) {
        if (chunkLength * i <= arr.length)
            chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
};

interface Data {
    label: string;
    value: string;
    contentId: number;
}

function createData(label: string, value: string, contentId: number): Data {
    return { label, value, contentId };
}

const renderSpecsTableRows = (specs: Data[], index: number) => {
    return (
        <>
            <Table className="specifications" style={{ width: '100%' }}>
                <TableBody>
                    {specs.map((row, n) => {
                        return (
                            <TableRow
                                key={n}
                                id={'spec' + index + '-' + n}
                                aria-label={row.label + ': ' + row.value}
                            >
                                <TableCell
                                    component="th"
                                    scope="row"
                                    aria-labelledby={'spec' + index + '-' + n}
                                >
                                    {row.label}
                                    <HelpTipIcon
                                        contentId={row.contentId + ''}
                                        title={row.label}
                                    />
                                </TableCell>
                                <TableCell
                                    scope="col"
                                    aria-labelledby={'spec' + index + '-' + n}
                                >
                                    {row.value}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

const RenderSpecsGrid = React.memo(function RenderSpecsGrid(props: {
    specs: Data[];
    dimensions: Data[];
    expandable?: boolean;
}) {
    const { specs = [], dimensions = [], expandable = false } = props;
    return (
        <Section
            open={false}
            expandable={expandable}
            numberOfElements={specs?.length || 0}
            elementsPerRow={1}
            initialHeight={300}
        >
            <Stack
                direction="row"
                spacing={{ xs: 0, lg: 4 }}
                display="grid"
                gridTemplateColumns={{ xs: '1fr', lg: '3fr 1fr' }}
            >
                {specs.length > 0 ? (
                    <div id="specifications">
                        <Typography variant="sectionTitle">
                            Specifications
                        </Typography>
                        {renderSpecsTableRows(specs, 0)}
                    </div>
                ) : null}

                {dimensions.length > 0 ? (
                    <div id="dimensions">
                        <Typography variant="sectionTitle">
                            Dimensions
                        </Typography>
                        {renderSpecsTableRows(dimensions, 2)}
                    </div>
                ) : null}
            </Stack>
        </Section>
    );
});
const getSpecsDimensionsArray = () => {
    return ['Width', 'Depth', 'Height', 'Weight'];
};
const getSpecsWithoutDimensions = (specifications: Specification[]) => {
    return (
        specifications.filter((spec: Maybe<Specification>) => {
            if (
                spec?.fieldName &&
                getSpecsDimensionsArray().indexOf(spec?.fieldName) === -1
            ) {
                return spec;
            }
        }) || []
    );
};
const getSpecDimensions = (specifications: Specification[]) => {
    return (
        specifications.filter((spec: Maybe<Specification>) => {
            if (
                spec?.fieldName &&
                getSpecsDimensionsArray().indexOf(spec?.fieldName) > -1
            ) {
                return spec;
            }
        }) || []
    );
};

export default function Specifications(props: {
    specifications: Specification[];
}) {
    const { specifications } = props;
    const [data, setData] = React.useState({
        specs: [] as Data[],
        dimensions: [] as Data[],
    });
    React.useEffect(() => {
        const s = getSpecsWithoutDimensions(specifications ?? []);
        const d = getSpecDimensions(specifications ?? []);
        setData({
            specs: s.map(
                (spec: Maybe<Specification>) =>
                    spec &&
                    createData(spec.fieldName, spec.fieldValue, spec.contentId),
            ) as Data[],
            dimensions: d.map(
                (spec: Maybe<Specification>) =>
                    spec &&
                    createData(spec.fieldName, spec.fieldValue, spec.contentId),
            ) as Data[],
        });
    }, []);
    return (
        <>
            <DesktopAndTabletDevice>
                <RenderSpecsGrid
                    expandable={true}
                    specs={data.specs}
                    dimensions={data.dimensions}
                />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <RenderSpecsGrid
                    expandable={true}
                    specs={data.specs}
                    dimensions={data.dimensions}
                />
            </MobileDevice>
        </>
    );
}

export function ItemSpecifications({ itemId }: { itemId: string }) {
    const { data, loading } = useQuery(QL_ITEM_SPECIFICATIONS, {
        variables: {
            itemId: itemId,
        },
    });

    if (loading) {
        return <SpecificationsLoading />;
    }

    const specs: Specification[] = data.itemSpecifications ?? [];

    return (
        <>
            <Helmet>
                <meta
                    property="product:weight:value"
                    content={
                        specs
                            .filter(
                                (s: Specification) => s.fieldName === 'Weight',
                            )
                            .pop()?.fieldValue ?? '0'
                    }
                />
                <meta property="product:weight:units" content="lbs" />
                <meta
                    property="product:upc"
                    content={
                        specs
                            .filter((s: Specification) => s.fieldName === 'UPC')
                            .pop()?.fieldValue ?? ''
                    }
                />
            </Helmet>
            <Specifications specifications={specs} />
        </>
    );
}

export function SpecificationsLoading() {
    return (
        <Section title="Specifications" expandable>
            <Grid container columnSpacing={3}>
                <Grid item xs={12} lg={4}>
                    {[...Array(10)].map((v: number, i: number) => {
                        return (
                            <Stack
                                key={'specs-row-loading-1-' + i}
                                direction="row"
                                spacing={3}
                            >
                                <Skeleton
                                    variant="text"
                                    width="40%"
                                    height="2rem"
                                />
                                <Skeleton
                                    variant="text"
                                    width="100%"
                                    height="2rem"
                                />
                            </Stack>
                        );
                    })}
                </Grid>
                <DesktopDevice>
                    <Grid item xs={0} lg={4}>
                        {[...Array(10)].map((v: number, i: number) => {
                            return (
                                <Stack
                                    key={'specs-row-loading-2-' + i}
                                    direction="row"
                                    spacing={3}
                                >
                                    <Skeleton
                                        variant="text"
                                        width="40%"
                                        height="2rem"
                                    />
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        height="2rem"
                                    />
                                </Stack>
                            );
                        })}
                    </Grid>

                    <Grid item xs={0} lg={4}>
                        {[...Array(4)].map((v: number, i: number) => {
                            return (
                                <Stack
                                    key={'specs-row-loading-3-' + i}
                                    direction="row"
                                    spacing={3}
                                >
                                    <Skeleton
                                        variant="text"
                                        width="40%"
                                        height="2rem"
                                    />
                                    <Skeleton
                                        variant="text"
                                        width="100%"
                                        height="2rem"
                                    />
                                </Stack>
                            );
                        })}
                    </Grid>
                </DesktopDevice>
            </Grid>
        </Section>
    );
}
