import { gql, useQuery } from '@apollo/client';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { QuestionAndAnswersContext } from '../../../../../Contexts/QuestionAndAnswersProvider';
import QuestionsAndAnswersWriteQuestionButton from '../Components/Buttons/QuestionsAndAnswersWriteQuestionButton';
import QuestionsAndAnswersLoading from '../QuestionsAndAnswersLoading';
import QuestionsAndAnswersDisplay from './QuestionsAndAnswersDisplay';

const QL_ITEM_QA = gql`
    query getItemQA(
        $itemId: ID
        $page: Int
        $sort: String
        $searchTerm: String
    ) {
        questionsAndAnswers(
            itemId: $itemId
            page: $page
            sort: $sort
            searchTerm: $searchTerm
        ) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            results {
                id
                datetime
                answerCount
                expertAnswerCount
                name
                location
                text
                answers {
                    id
                    datetime
                    questionId
                    isVerified
                    source
                    notHelpfulVotes
                    helpfulVotes
                    location
                    isExpert
                    text
                    name
                }
            }
        }
    }
`;

const QuestionsAndAnswersGrid = () => {
    const { page, sort, searchTerm, questions, item } = React.useContext(
        QuestionAndAnswersContext,
    );
    const { data, loading } = useQuery(QL_ITEM_QA, {
        variables: {
            itemId: item.id,
            page: page,
            sort: sort,
            searchTerm: searchTerm || '',
        },
    });

    if (loading) {
        return <QuestionsAndAnswersLoading />;
    }
    if (
        data &&
        data.questionsAndAnswers.countResults < 1 &&
        searchTerm === ''
    ) {
        return (
            <>
                <Box>
                    <Typography variant="body1">
                        Be the first to write a question!
                    </Typography>
                    <Box mt={2}>
                        <QuestionsAndAnswersWriteQuestionButton item={item} />
                    </Box>
                </Box>
            </>
        );
    } else if (data && data.questionsAndAnswers && questions) {
        return (
            <QuestionsAndAnswersDisplay
                questions={data.questionsAndAnswers.results}
                totalPages={data.questionsAndAnswers.totalPages}
                currentPage={data.questionsAndAnswers.currentPage}
            />
        );
    } else {
        return <></>;
    }
};

export default QuestionsAndAnswersGrid;
