import { Pagination, PaginationProps } from '@mui/material';
import { Box, SxProps } from '@mui/system';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface BBQProps extends PaginationProps {
    id?: string;
    sx?: SxProps;
}

export function BBQPagination(props: BBQProps) {
    const {
        variant = 'text',
        shape = 'circular',
        color = 'standard',
        size = 'medium',
        count = 0,
        page = 1,
        onChange,
        id,
        sx,
    } = props;

    const [url, setUrl] = React.useState('');
    const location = useLocation();
    const ttl = Number((count / 40).toFixed(0)) - 1; // may need to get this value from results.

    React.useEffect(() => {
        if (location.pathname !== url) {
            setUrl(location.pathname);
        }
    }, [location.pathname]);

    function LinksForPrevNext(): JSX.Element {
        if (page === 1) {
            return (
                <Helmet>
                    <link rel="next" href={url + '?start=' + (page + 1)} />
                </Helmet>
            );
        } else if (page === 2) {
            return (
                <Helmet>
                    <link rel="prev" href={url} />
                    <link rel="next" href={url + '?start=' + (page + 1)} />
                </Helmet>
            );
        } else if (page > 2) {
            return (
                <Helmet>
                    <link rel="prev" href={url + '?start=' + (page - 1)} />
                    <link rel="next" href={url + '?start=' + (page + 1)} />
                </Helmet>
            );
        } else if (page === ttl) {
            return (
                <Helmet>
                    <link rel="prev" href={url + '?start=' + (page - 1)} />
                </Helmet>
            );
        } else return <></>;
    }

    return (
        <>
            <LinksForPrevNext />
            <Box component="span" display="inline-block" alignSelf="center">
                <Pagination
                    variant={variant}
                    shape={shape}
                    color={color}
                    siblingCount={0}
                    size={size}
                    count={count}
                    page={page}
                    onChange={onChange}
                    id={id}
                    sx={sx}
                />
            </Box>
        </>
    );
}
