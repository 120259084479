import * as React from 'react';

export const ProductPageContext = React.createContext({
    itemId: '0',
    loading: false,
    setLoading: (val: boolean) => {
        return;
    },
    setItemId: (itemId: string) => {
        return;
    },
});

export function ProductPageProvider(props: { itemId: string; children: any }) {
    const [itemId, setProductId] = React.useState(props.itemId);
    const [loading, setLoadingVal] = React.useState(false);

    const setItemId = (itemId: string) => {
        setProductId(itemId);
    };
    const setLoading = (val: boolean) => {
        setLoadingVal(val);
    };

    return (
        <ProductPageContext.Provider
            value={{
                itemId,
                setItemId,
                loading,
                setLoading,
            }}
        >
            {props.children}
        </ProductPageContext.Provider>
    );
}
