import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Divider, Grid, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import { PageHeader } from '../_base/BBQGuysComponents';
import ScContainer from '../Layout/ScContainer/ScContainer';
import { RenderContentWithId } from '../../Pages/PageContent/PageContent';
import { StatesWithLabel } from '../../Pages/Account/PageEditMyAccount/Components/StatesWithLabel';
import { FieldWithLabel } from '../../Pages/Cart/PageCheckout/Forms/Components/FieldWithLabel';
import { CategoriesWithLabel } from './Forms/CategoriesWithLabel';
import { RadioWithLabel } from './Forms/RadioWithLabel';
import { VendorFormSchema } from '../../Schemas/Schemas';
import { gql } from '@apollo/client';
import { client } from '../../Graphql/ApolloClient';

interface VendorFormValues {
    name: string;
    title: string;
    email: string;
    phone: string;
    companyName: string;
    companyAddress: string;
    companyCity: string;
    companyState: string;
    companyZip: string;
    companyPhone: string;
    companyHistory: string;
    productCategory: string;
    productManufacturer: string;
    productBrief: string;
    productStores: string;
    productStoreList: string;
    productForm: string;
    productMargins: string;
    productDropShop: string;
    productReturns: string;
}

interface VendorFormProps {
    initialValues: VendorFormValues;
}

export const QL_UPDATE_VENDOR_INFO = gql`
    mutation SendVendorInfo($VendorRegistrationForm: VendorRegistrationProps!) {
        sendVendorInfo(VendorRegistrationForm: $VendorRegistrationForm) {
            success
            message
        }
    }
`;

const sendVendorInfo = (values: VendorFormValues): Promise<string> => {
    return new Promise((resolve, reject) => {
        (async function () {
            const response = await client.mutate({
                mutation: QL_UPDATE_VENDOR_INFO,
                variables: {
                    VendorRegistrationForm: values,
                },
                fetchPolicy: 'no-cache',
            });
            if (response.errors) {
                reject(response.errors[0]);
            }
            if (response.data.sendVendorInfo) {
                if (response.data.sendVendorInfo.success) {
                    resolve(response.data.sendVendorInfo.message);
                } else {
                    reject(response.data.sendVendorInfo.message);
                }
            } else {
                reject('There was an error submitting your information.');
            }
        })();
    });
};

const VendorForm: React.FC<VendorFormProps> = ({ initialValues }) => {
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const formik = useFormik({
        initialValues,
        validationSchema: VendorFormSchema,
        onSubmit: (
            values: VendorFormValues,
            actions: any,
        ): void | Promise<void> => {
            setErrorMessage(null);
            setSuccessMessage(null);
            actions.setSubmitting(true);
            sendVendorInfo(values)
                .then(async (message: string) => {
                    setSuccessMessage(message);
                })
                .catch(err => {
                    setErrorMessage(err);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                    window.scrollTo(0, 0); // scroll to the top to allow the user to read the message
                });
        },
    });

    const YES_NO_OPTIONS = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' },
    ];

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <Grid container columnSpacing={4} columns={{ xs: 4, md: 12 }}>
                    <Grid item xs={12}>
                        {successMessage && (
                            <Alert severity="success">
                                <AlertTitle>Success!</AlertTitle>
                                {successMessage}
                            </Alert>
                        )}
                        {errorMessage && (
                            <Alert severity="error">
                                <AlertTitle>Error</AlertTitle>
                                <strong>{errorMessage}</strong>
                            </Alert>
                        )}
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '1rem' }}>
                        <Typography
                            variant="bold"
                            component="h2"
                            sx={{ pb: 2, fontSize: '1.15em' }}
                        >
                            Contact Information
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldWithLabel
                            label="Full Name"
                            id="name"
                            name="name"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldWithLabel
                            label="Title"
                            id="title"
                            name="title"
                            type="text"
                            required={false}
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldWithLabel
                            label="Email Address"
                            id="email"
                            name="email"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldWithLabel
                            label="Phone Number"
                            id="phone"
                            name="phone"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '1rem' }}>
                        <Typography
                            variant="bold"
                            component="h2"
                            sx={{ pb: 2, fontSize: '1.15em' }}
                        >
                            Company Information
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="Company Name"
                            id="companyName"
                            name="companyName"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="Company Address"
                            id="companyAddress"
                            name="companyAddress"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <FieldWithLabel
                            label="City"
                            id="companyCity"
                            name="companyCity"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <StatesWithLabel
                            name="companyState"
                            id="companyState"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={4}>
                        <FieldWithLabel
                            label="Zip Code"
                            id="companyZip"
                            name="companyZip"
                            type="text"
                            autoComplete="postal-code"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="Company Phone Number"
                            id="companyPhone"
                            name="companyPhone"
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="Company History &amp; Background"
                            id="companyHistory"
                            name="companyHistory"
                            type="text"
                            formik={formik}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ padding: '1rem' }}>
                        <Typography
                            variant="bold"
                            component="h2"
                            sx={{ pb: 2, fontSize: '1.15em' }}
                        >
                            Product Details
                        </Typography>
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <CategoriesWithLabel
                            label="Product Category(s)"
                            name="productCategory"
                            id="productCategory"
                            required={false}
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <FieldWithLabel
                            label="Where is the product manufactured?"
                            id="productManufacturer"
                            name="productManufacturer"
                            required={false}
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="Why is this product a good fit for BBQGuys.com?"
                            id="productBrief"
                            name="productBrief"
                            type="text"
                            formik={formik}
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <RadioWithLabel
                            label="Is the product(s) sold on other online stores?"
                            id="productStores"
                            name="productStores"
                            formik={formik}
                            data={YES_NO_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="If so, which stores?"
                            id="productStoreList"
                            name="productStoreList"
                            required={false}
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <RadioWithLabel
                            label="Are you willing to fill out our standard product load forms to get the product online?"
                            id="productForm"
                            name="productForm"
                            formik={formik}
                            data={YES_NO_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <FieldWithLabel
                            label="What are the margins we can expect to make on the product(s)?"
                            id="productMargins"
                            name="productMargins"
                            required={false}
                            type="text"
                            formik={formik}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <RadioWithLabel
                            label="Do you drop-ship?"
                            id="productDropShop"
                            name="productDropShop"
                            formik={formik}
                            data={YES_NO_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={4} md={6}>
                        <RadioWithLabel
                            label="Do you accept returns?"
                            id="productReturns"
                            name="productReturns"
                            formik={formik}
                            data={YES_NO_OPTIONS}
                        />
                    </Grid>
                    <Grid item xs={4} md={12}>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            loading={formik.isSubmitting}
                        >
                            Send Info
                        </LoadingButton>
                    </Grid>
                </Grid>
            </form>
        </FormikProvider>
    );
};

export default function VendorRegistration() {
    return (
        <ScContainer size="large" sx={{ mt: 3 }}>
            <PageHeader title="Become a Vendor" />
            <Divider />
            <RenderContentWithId contentId="42618" />
            <VendorForm
                initialValues={
                    {
                        name: '',
                        title: '',
                        email: '',
                        phone: '',
                        companyName: '',
                        companyAddress: '',
                        companyCity: '',
                        companyState: '',
                        companyZip: '',
                        companyPhone: '',
                        companyHistory: '',
                        productCategory: '',
                        productManufacturer: '',
                        productBrief: '',
                        productStores: '',
                        productStoreList: '',
                        productForm: '',
                        productMargins: '',
                        productDropShop: '',
                        productReturns: '',
                    } as VendorFormValues
                }
            />
        </ScContainer>
    );
}
