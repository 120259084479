import { Box } from '@mui/material';
import React from 'react';
import { RenderContentWithId } from '../../Pages/PageContent/PageContent';

const EmployeeTestimonials: React.FC = () => {
    return (
        <Box sx={{ mt: 2, pb: 4 }}>
            <RenderContentWithId contentId="42853" />
        </Box>
    );
};

export default EmployeeTestimonials;
