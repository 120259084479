import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { Button, Skeleton, Typography, styled } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import {
    DesktopDevice,
    MobileAndTabletDevice,
    MobileDevice,
    TabletDevice,
} from '../../Contexts/DeviceTypeProvider';
import { Maybe } from '../../types';
import { randomNumber } from '../../functions';
import theme from '../../Theme';

export const CarouselContainer = styled(Box)(({ theme }) => ({
    // paddingBottom: theme.spacing(4),
}));
export const CarouselContainerInner = styled(Box)(({ theme }) => ({
    position: 'relative',
}));
export const CarouselInnerItemsContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    [theme.breakpoints.up('lg')]: {
        overflowX: 'hidden',
    },
}));
export const CarouselInnerItems = styled('div')(({ theme }) => ({
    justifyContent: 'center',
    maxWidth: 200,
    flexShrink: 0,
    [theme.breakpoints.up('lg')]: {
        minWidth: 'calc(100% / 6)',
    },
}));
export const CarouselButton = styled(Button)(({ theme }) => ({
    position: 'absolute',
    top: '40%',
    background: 'transparent',
    border: 0,
    padding: 0,
    minWidth: '2rem',
    userSelect: 'none',
    svg: {
        fill: theme.palette.secondary.main,
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    '&:hover': {
        cursor: 'pointer',
        svg: {
            fill: theme.palette.primary.light,
        },
    },
}));
export const CarouselButtonLeft = styled(CarouselButton)(({ theme }) => ({
    left: theme.spacing(-5),
}));
export const CarouselButtonRight = styled(CarouselButton)(({ theme }) => ({
    right: theme.spacing(-5),
}));

export type CarouselAlignTitle = 'left' | 'right' | 'center';

interface HasMoreObject {
    left: boolean;
    right: boolean;
}

export default function BBQGuysCarousel(props: {
    children: Iterable<React.ReactNode>;
    title?: string;
    count?: number;
    alignTitle?: CarouselAlignTitle;
}) {
    const { title, children, count = 6, alignTitle = 'left' } = props;
    const childNodes = Array.from(children);
    return (
        <>
            <DesktopDevice>
                <CarouselContent
                    total={childNodes.length ?? 1}
                    count={count}
                    title={title}
                    alignTitle={alignTitle}
                >
                    {children}
                </CarouselContent>
            </DesktopDevice>
            <TabletDevice>
                <CarouselContent
                    total={childNodes.length ?? 1}
                    count={3}
                    title={title}
                    alignTitle={alignTitle}
                >
                    {children}
                </CarouselContent>
            </TabletDevice>
            <MobileDevice>
                <CarouselContent
                    total={childNodes.length ?? 1}
                    count={2}
                    title={title}
                    alignTitle={alignTitle}
                >
                    {children}
                </CarouselContent>
            </MobileDevice>
        </>
    );
}
const MIN_TOTAL_ITEMS_IN_CAROUSEL = 2;
const CarouselContent: React.FC<{
    children: Iterable<React.ReactNode>;
    total: number;
    count: number;
    title?: string;
    alignTitle?: CarouselAlignTitle;
}> = ({
    children,
    count,
    title,
    total = MIN_TOTAL_ITEMS_IN_CAROUSEL,
    alignTitle = 'left',
}) => {
    const [selectedImageIndex, setSelectedImageIndex] = React.useState(0);
    const [cnt] = React.useState(count);
    const carouselContainerRef = React.useRef<HTMLDivElement | null>(null);
    const carouselItemsRef = React.useRef<HTMLDivElement[] | null[]>([]);

    const getHasMoreItemsDirections = (idx: number): HasMoreObject => {
        return {
            left: idx > 0,
            right: total > MIN_TOTAL_ITEMS_IN_CAROUSEL && idx + cnt < total,
        };
    };

    const [hasMore, setHasMore] = React.useState<HasMoreObject>(
        getHasMoreItemsDirections(selectedImageIndex),
    );

    const handleSelectedImageChange = (newIdx: number) => {
        if (
            carouselContainerRef?.current &&
            carouselItemsRef?.current[newIdx]
        ) {
            const scrollLeft = carouselItemsRef?.current[newIdx]
                ?.offsetLeft as number;
            carouselContainerRef.current.scrollTo(
                scrollLeft,
                carouselItemsRef?.current[newIdx]?.offsetTop as number,
            );
            setHasMore(getHasMoreItemsDirections(newIdx));
        }
        setSelectedImageIndex(newIdx);
    };

    const handleRightClick = () => {
        let newIdx = selectedImageIndex + cnt;
        if (newIdx > total) {
            newIdx = total - selectedImageIndex;
            handleSelectedImageChange(newIdx);
            setTimeout(() => {
                setSelectedImageIndex(newIdx);
            }, 30);
        } else {
            handleSelectedImageChange(newIdx);
        }
    };

    const handleLeftClick = () => {
        let newIdx = selectedImageIndex - cnt;
        if (newIdx < 0) {
            newIdx = 0;
        }
        handleSelectedImageChange(newIdx);
    };

    const CarouselItems = (props: { children: Iterable<React.ReactNode> }) => {
        const { children } = props;
        const childNodes = Array.from(children);
        return (
            <>
                {childNodes.map((c: Maybe<React.ReactNode>, i: number) => {
                    return c ? (
                        <CarouselInnerItems
                            key={
                                'carousel-item-' +
                                i +
                                '-' +
                                randomNumber(0, 10000)
                            }
                            className={`carousel__item`}
                            ref={el => (carouselItemsRef.current[i] = el)}
                            sx={{
                                [theme.breakpoints.up('lg')]: {
                                    minWidth: `calc(100% / ${count ?? 6})`,
                                },
                            }}
                        >
                            {c}
                        </CarouselInnerItems>
                    ) : (
                        <CarouselInnerItems></CarouselInnerItems>
                    );
                })}
            </>
        );
    };

    return (
        <CarouselContainer className="carousel-container">
            <Box component="div" display="flex" justifyContent={alignTitle}>
                {title ? (
                    <Typography component="h2" variant="sectionTitle">
                        {title}
                    </Typography>
                ) : null}
            </Box>
            <DesktopDevice>
                <CarouselContainerInner className="carousel">
                    {total > 0 ? (
                        <CarouselInnerItemsContainer
                            className="carousel__items"
                            style={{ display: 'flex' }}
                            ref={el => (carouselContainerRef.current = el)}
                        >
                            <CarouselItems>{children}</CarouselItems>
                        </CarouselInnerItemsContainer>
                    ) : (
                        <CarouselInnerItemsContainer></CarouselInnerItemsContainer>
                    )}
                    {total > cnt ? (
                        <>
                            <CarouselButtonLeft
                                className="carousel__button carousel__button-left"
                                onClick={handleLeftClick}
                                aria-label="Carousel previous slide"
                                disabled={!hasMore.left}
                            >
                                <ChevronLeftRounded />
                            </CarouselButtonLeft>
                            <CarouselButtonRight
                                className="carousel__button carousel__button-right"
                                onClick={handleRightClick}
                                aria-label="Carousel next slide"
                                disabled={!hasMore.right}
                            >
                                <ChevronRightRounded />
                            </CarouselButtonRight>
                        </>
                    ) : (
                        <></>
                    )}
                </CarouselContainerInner>
            </DesktopDevice>
            <MobileAndTabletDevice>
                <CarouselContainerInner
                    className="carousel"
                    sx={{ overflowX: 'auto' }}
                >
                    {total > 0 ? (
                        <CarouselInnerItemsContainer
                            className="carousel__items"
                            ref={el => (carouselContainerRef.current = el)}
                        >
                            <CarouselItems>{children}</CarouselItems>
                        </CarouselInnerItemsContainer>
                    ) : (
                        <CarouselInnerItemsContainer></CarouselInnerItemsContainer>
                    )}
                </CarouselContainerInner>
            </MobileAndTabletDevice>
        </CarouselContainer>
    );
};

const CarouselLoadingDisplay = (props: {
    loadingComponent: React.ReactNode;
    count?: number;
    title?: string;
    alignTitle?: CarouselAlignTitle;
}) => {
    const { count = 6, title, alignTitle = 'left', loadingComponent } = props;

    return (
        <CarouselContainer className="carousel-container">
            <Box display="flex" justifyContent={alignTitle}>
                {title ? (
                    <Typography variant="sectionTitle">{title}</Typography>
                ) : (
                    title !== '' && (
                        <Skeleton variant="text" width="40%" height="4rem" />
                    )
                )}
            </Box>
            <CarouselContainerInner className="carousel">
                <CarouselInnerItemsContainer className="carousel__items">
                    {[...Array(count)].map((v: unknown, i: number) => {
                        return (
                            <React.Fragment key={i}>
                                {Array(count)
                                    .fill(0)
                                    .map((t: number, n: number) => (
                                        <React.Fragment key={n}>
                                            {loadingComponent}
                                        </React.Fragment>
                                    ))}
                            </React.Fragment>
                        );
                    })}
                </CarouselInnerItemsContainer>
            </CarouselContainerInner>
        </CarouselContainer>
    );
};

export function BBQGuysCarouselLoading(props: {
    loadingComponent: React.ReactNode;
    count?: number;
    title?: string;
    alignTitle?: CarouselAlignTitle;
}) {
    const { count = 6, title, alignTitle = 'left', loadingComponent } = props;
    return (
        <>
            <DesktopDevice>
                <CarouselLoadingDisplay
                    title={title}
                    count={count}
                    alignTitle={alignTitle}
                    loadingComponent={loadingComponent}
                />
            </DesktopDevice>
            <TabletDevice>
                <CarouselLoadingDisplay
                    title={title}
                    count={4}
                    alignTitle={alignTitle}
                    loadingComponent={loadingComponent}
                />
            </TabletDevice>
            <MobileDevice>
                <CarouselLoadingDisplay
                    title={title}
                    count={2}
                    alignTitle={alignTitle}
                    loadingComponent={loadingComponent}
                />
            </MobileDevice>
        </>
    );
}
