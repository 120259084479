import { onError } from '@apollo/client/link/error';
import { Request } from 'express';
import { reauthenticateUser } from '../Contexts/UserAuthProvider';
import window from 'global/window';
import { isProduction } from '../config';

// handle our error messaging
export default function getErrorLink(isSSR = false, req: Request | undefined) {
    return onError(
        ({ response, graphQLErrors, networkError, operation, forward }) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(
                    ({ message, locations, path, extensions }) => {
                        switch (extensions.code) {
                            case 'UNAUTHENTICATED':
                            case 'FORBIDDEN': {
                                // attempt to reauthenticate the user
                                reauthenticateUser(2)
                                    .then(() => {
                                        // Retry the request, returning the new observable
                                        if (window && window.location) {
                                            window.location.reload();
                                        } else {
                                            console.log(
                                                'window object was not found while re-authenitcating user',
                                            );
                                        }
                                    })
                                    .catch(err => {
                                        console.log(err);
                                    });
                                return forward(operation);
                            }
                            default:
                                if (message) {
                                    const errorDetails = {
                                        '[GraphQL error message]:': message,
                                        ...(!isProduction() && { extensions }),
                                    };
                                    console.error(errorDetails);
                                    // redirect 301 and 302 responses
                                    if (
                                        window?.location &&
                                        extensions?.response
                                    ) {
                                        const response =
                                            extensions.response as any;
                                        const body = response.body as any;
                                        if (
                                            body?.result?.statusCode === 301 ||
                                            body?.result?.statusCode === 302
                                        ) {
                                            /* append tracking information to redirect if present */
                                            const newURL = new URL(
                                                body.result?.url || '/',
                                                window.location.origin,
                                            );
                                            newURL.search =
                                                window.location.search;
                                            window.location.href =
                                                newURL.toString();
                                        }
                                    }
                                } else if (
                                    response &&
                                    response.errors &&
                                    response.errors[0] &&
                                    response.errors[0].message
                                ) {
                                    console.error(
                                        '[GraphQL response error]:',
                                        response.errors[0].message,
                                    );
                                }
                                // Now, pass the modified operation to the next link
                                // in the chain. This effectively intercepts the old
                                // failed request, and retries it with a new token
                                return forward(operation);
                        }
                    },
                );
            }
            if (networkError) {
                console.log(
                    `[Network error url]: ${operation.getContext().url}`,
                );
                console.log(`[Network error]: ${networkError}`);
            }
        },
    );
}
