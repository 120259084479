import { PageBreadcrumbsLoading } from '../../Components/Breadcrumbs/PageBreadcrumbs';
import { ItemCompareGridLoading } from '../../Components/ItemComparison/ItemComparison';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { Section } from '../../Components/_base/BBQGuysComponents';
import { ItemAboveTheFoldLoading } from './Components/AboveTheFold/ItemAboveTheFoldLoading';
import CustomerReviewsLoading from './Components/CustomerReviews/CustomerReviewsLoading';
import FrequentlyBoughtTogetherItemsLoading from './Components/FrequentlyBoughtTogetherItems/FrequentlyBoughtTogetherItemsLoading';
import ItemLearningCenterLoading from './Components/LearningCenter/ItemLearningCenterLoading';
import { SpecificationsLoading } from './Components/Specifications/Specifications';

const PageItemLoading = () => {
    return (
        <ScContainer>
            <PageBreadcrumbsLoading />
            <ItemAboveTheFoldLoading />
            <FrequentlyBoughtTogetherItemsLoading />
            <SpecificationsLoading />
            <ItemLearningCenterLoading />
            <CustomerReviewsLoading />
            <Section title="Compare Similar Items" id="compare-similar-items">
                <ItemCompareGridLoading />
            </Section>
        </ScContainer>
    );
};

export default PageItemLoading;
