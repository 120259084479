import { LoadingButton } from '@mui/lab';
import {
    Alert,
    AlertTitle,
    Breakpoint,
    Button,
    DialogActions,
    DialogContent,
    Divider,
    MenuItem,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { client } from '../../Graphql/ApolloClient';
import { CreateWishlistFormSchema } from '../../Schemas/Schemas';
import { Wishlist, WishlistItemProps, WishlistUser } from '../../types';
import { Select, SignInButton } from '../_base/BBQGuysComponents';
import Modal from '../_base/BBQGuysModal';
import { Box, Stack } from '@mui/system';
import { isMobile } from '../../Contexts/DeviceTypeProvider';
import {
    QL_USER_WISHLIST,
    QL_USER_WISHLIST_CREATE,
    QL_USER_WISHLIST_UPDATE,
    QL_USER_WISHLISTS,
} from '../../Graphql/queries';

export const loadUserWishlists = async (page = 1): Promise<Wishlist[]> => {
    return new Promise((resolve, reject) => {
        (async () => {
            const response = await client.query({
                query: QL_USER_WISHLISTS,
                variables: {
                    page: page,
                },
                fetchPolicy: 'no-cache',
            });

            if (response.error) {
                reject(response.error);
            }

            if (!response.loading) {
                const wish = [] as Wishlist[];
                response.data.userWishlists.results.map(
                    (wlUser: WishlistUser) => {
                        wish.push(wlUser.wishlist);
                    },
                );
                resolve(wish);
            }
        })();
    });
};
export const loadUserWishlist = async (id: string): Promise<Wishlist> => {
    return new Promise((resolve, reject) => {
        (async () => {
            const response = await client.query({
                query: QL_USER_WISHLIST,
                variables: {
                    id: id,
                },
                fetchPolicy: 'no-cache',
            });

            if (response.error) {
                reject(response.error);
            }

            if (!response.loading) {
                resolve(response.data.wishlist);
            }
        })();
    });
};
export const createUserWishlist = async (
    title: string,
    items: WishlistItemProps[],
): Promise<Wishlist> => {
    return new Promise((resolve, reject) => {
        (async () => {
            const response = await client.mutate({
                mutation: QL_USER_WISHLIST_CREATE,
                variables: {
                    title: title,
                    items: items,
                },
                fetchPolicy: 'no-cache',
            });

            if (response.errors) {
                reject(response.errors[0].message);
            }

            if (response.data.createWishlist) {
                if (response.data.createWishlist.id == '') {
                    reject(response.data.createWishlist.title);
                }
                resolve(response.data.createWishlist);
            }
        })();
    });
};

export const WishlistButton = (props: {
    items?: WishlistItemProps[];
    button: any;
    create?: boolean;
    size?: Breakpoint | false;
    onSuccess?: (wishlist: Wishlist) => void;
}) => {
    let { button } = props;
    const {
        size = 'xl',
        create = false,
        items = [],
        onSuccess = () => {
            return;
        },
    } = props;
    const { isLoggedIn } = React.useContext(UserAuth);
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const [buttonSelected, setButtonSelected] = React.useState<
        'create' | 'choose' | null
    >(null);
    const [wishlists, setWishlists] = React.useState<Wishlist[] | null>(null);

    const handleClickOpen = () => {
        if (isLoggedIn()) {
            setOpen(true);
            setButtonSelected(create ? 'create' : null);
            loadUserWishlists().then((wl: Wishlist[]) => {
                setWishlists(wl);
            });
        } else {
            navigate('/login');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleBack = () => {
        setButtonSelected(null);
    };

    React.useEffect(() => {
        if (isLoggedIn()) {
            loadUserWishlists().then((wl: Wishlist[]) => {
                setWishlists(wl);
            });
        }
    }, []);

    button = React.cloneElement(button, { onClick: () => handleClickOpen() });

    return (
        <>
            {wishlists === null || !isLoggedIn() ? (
                <SignInButton
                    type="login"
                    button={button}
                    onComplete={() => handleClickOpen()}
                />
            ) : wishlists !== null ? (
                <>
                    {button}

                    <Modal
                        open={open}
                        handleClose={handleClose}
                        scroll={'paper'}
                        size={size}
                        title={
                            buttonSelected === 'choose'
                                ? 'Choose Existing List'
                                : 'Save to List'
                        }
                        sx={{
                            width: '600px',
                        }}
                        fullScreen={isMobile == true}
                    >
                        <Box gap={2}>
                            {(() => {
                                if (
                                    buttonSelected === null &&
                                    wishlists?.length > 0
                                ) {
                                    return (
                                        <WishlistModalScreenButtons
                                            onButtonCreateWishlist={() =>
                                                setButtonSelected('create')
                                            }
                                            onButtonChooseExistingWishlist={() =>
                                                setButtonSelected('choose')
                                            }
                                        />
                                    );
                                } else if (buttonSelected === 'choose') {
                                    return (
                                        <WishlistModalScreenChooseExistingWishlist
                                            wishlists={wishlists}
                                            wishlistItems={items}
                                            onSuccess={onSuccess}
                                            handleBack={handleBack}
                                        />
                                    );
                                } else {
                                    return (
                                        <WishlistModalScreenCreateNewWishlist
                                            wishlistItems={items}
                                            onSuccess={onSuccess}
                                            handleBack={
                                                wishlists?.length > 0
                                                    ? handleBack
                                                    : undefined
                                            }
                                        />
                                    );
                                }
                            })()}
                        </Box>
                    </Modal>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

const WishlistModalScreenButtons = (props: {
    onButtonCreateWishlist: () => void;
    onButtonChooseExistingWishlist: () => void;
}) => {
    return (
        <DialogContent>
            <Typography sx={{ my: 2, display: 'block' }}>
                Please select a list option:
            </Typography>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                spacing={2}
                sx={{ my: 2, justifyContent: 'space-between' }}
            >
                <Button
                    onClick={props.onButtonCreateWishlist}
                    variant="contained"
                    color="secondary"
                    sx={{ flexGrow: 1 }}
                >
                    Create New List
                </Button>
                <Divider sx={{ my: 1 }} />
                <Button
                    onClick={props.onButtonChooseExistingWishlist}
                    variant="contained"
                    color="secondary"
                    sx={{ flexGrow: 1 }}
                >
                    Choose Existing List
                </Button>
            </Stack>
        </DialogContent>
    );
};

const WishlistModalScreenCreateNewWishlist = (props: {
    wishlistItems: WishlistItemProps[];
    onSuccess: (wishlist: Wishlist) => void;
    handleBack?: () => void;
}) => {
    const {
        wishlistItems = [],
        onSuccess = () => {
            return;
        },
        handleBack = undefined,
    } = props;
    return (
        <>
            <WishlistCreateForm
                wishlistItems={wishlistItems}
                onSuccess={onSuccess}
                handleBack={handleBack}
            />
        </>
    );
};

interface WishlistCreateValues {
    title: string;
}
const WishlistCreateForm = (props: {
    wishlistItems: WishlistItemProps[];
    onSuccess?: (wishlist: Wishlist) => void;
    handleBack?: () => void;
}) => {
    const {
        wishlistItems,
        onSuccess = () => {
            return;
        },
        handleBack = undefined,
    } = props;
    const [success, setSuccess] = React.useState(false);
    const [error, setError] = React.useState('');

    return (
        <Formik
            initialValues={{
                title: '',
            }}
            validationSchema={CreateWishlistFormSchema}
            onSubmit={(values: WishlistCreateValues, actions: any) => {
                actions.setSubmitting(true);
                createUserWishlist(values.title, wishlistItems)
                    .then((wishlist: Wishlist) => {
                        setSuccess(true);
                        onSuccess(wishlist);
                    })
                    .catch(error => {
                        console.error(error);
                        setError(error);
                    })
                    .finally(() => {
                        actions.setSubmitting(false);
                    });
            }}
        >
            {(formik: any) => (
                <Form>
                    {success ? (
                        <DialogContent>
                            <Alert severity="success">
                                <AlertTitle>List Created</AlertTitle>
                                The list was created and the item
                                {props.wishlistItems.length === 1
                                    ? ' was'
                                    : 's were'}{' '}
                                added
                            </Alert>
                        </DialogContent>
                    ) : (
                        <>
                            <DialogContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                {error && (
                                    <Alert severity="error" sx={{ mb: 1 }}>
                                        <AlertTitle>
                                            Oops, An error occurred!
                                        </AlertTitle>
                                        {error}
                                    </Alert>
                                )}
                                <Stack
                                    direction="column"
                                    spacing={2}
                                    sx={{ my: 2 }}
                                >
                                    <Typography
                                        sx={{ my: 2, display: 'block' }}
                                    >
                                        Please give your new list a title:
                                    </Typography>
                                    <Field
                                        name="title"
                                        id="title"
                                        formik={formik}
                                        placeholder="E.g Epic Outdoor Kitchen Build"
                                        style={{ padding: '10px' }}
                                    />
                                </Stack>
                            </DialogContent>
                            <DialogActions>
                                {handleBack && (
                                    <Button
                                        onClick={handleBack}
                                        variant="link"
                                        size="medium"
                                        color="secondary"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    loading={formik.isSubmitting}
                                    aria-label="Submit to create a new list"
                                    sx={{ alignSelf: 'flex-end' }}
                                >
                                    Create List
                                </LoadingButton>
                            </DialogActions>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

const WishlistModalScreenChooseExistingWishlist = (props: {
    wishlists: Wishlist[];
    wishlistItems: WishlistItemProps[];
    onSuccess: (wishlist: Wishlist) => void;
    handleBack: () => void;
}) => {
    const {
        wishlists,
        wishlistItems = [],
        onSuccess = () => {
            return;
        },
        handleBack = () => {
            return;
        },
    } = props;
    const [selectedWishlistId, setSelectedWishlistId] = React.useState<string>(
        wishlists[0].id,
    );
    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);

    const handleSave = async () => {
        setLoading(true);
        const response = await client.mutate({
            mutation: QL_USER_WISHLIST_UPDATE,
            variables: {
                wishlistId: selectedWishlistId,
                items: wishlistItems,
            },
            fetchPolicy: 'no-cache',
        });

        if (response.errors) {
            alert('An error occurred while saving items to wishlist.');
            console.error(response.errors[0].message);
        }

        if (response.data.updateWishlist) {
            setSuccess(true);
            onSuccess(response.data.updateWishlist as Wishlist);
        }
        setLoading(false);
    };
    return (
        <>
            {success ? (
                <DialogContent>
                    <Alert
                        title="Success"
                        variant="standard"
                        severity="success"
                    >
                        Items added to list
                    </Alert>
                </DialogContent>
            ) : (
                <>
                    <DialogContent
                        sx={{ display: 'flex', flexDirection: 'column' }}
                    >
                        <Typography sx={{ my: 2, display: 'block' }}>
                            Please select the existing list to which you would
                            like to add these items:
                        </Typography>
                        <Select
                            name="listAdd"
                            labelId="list-select-add"
                            id="list-select"
                            value={selectedWishlistId}
                            label="List"
                            sx={{ mb: '16px' }}
                            onChange={(e: SelectChangeEvent) => {
                                setSelectedWishlistId(e.target.value as string);
                            }}
                        >
                            {wishlists?.length > 0 &&
                                wishlists.map(
                                    (wishlist: Wishlist, i: number) => {
                                        return (
                                            <MenuItem
                                                key={i}
                                                value={wishlist.id}
                                            >
                                                {wishlist.title}
                                            </MenuItem>
                                        );
                                    },
                                )}
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleBack}
                            variant="link"
                            size="medium"
                            color="secondary"
                        >
                            Back
                        </Button>
                        <LoadingButton
                            onClick={() => handleSave()}
                            variant="contained"
                            color="secondary"
                            loading={loading}
                            sx={{ alignSelf: 'flex-end' }}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>
                </>
            )}
        </>
    );
};
