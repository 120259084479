import {
    Box,
    FormControl,
    FormControlOwnProps,
    FormLabel,
    OutlinedTextFieldProps,
    SxProps,
    TextField,
    TextFieldProps,
} from '@mui/material';
import { FormikValues } from 'formik';
import React, { ElementType } from 'react';
import {
    HelpTipIcon,
    ScreenReaderOnly,
} from '../../../../../Components/_base/BBQGuysComponents';
import Asterisk from '../../../../../Components/Asterisk';
export interface FieldWithLabelProps
    extends Omit<OutlinedTextFieldProps, 'variant'> {
    id: string;
    name: string;
    formik: FormikValues;
    label?: string | React.ReactNode;
    labelHidden?: boolean;
    labelComponent?: ElementType<any>;
    required?: boolean;
    type?: string;
    autoComplete?: string;
    sx?: SxProps;
    inputMaskProps?: any;
    textFieldProps?: TextFieldProps;
    formControlProps?: FormControlOwnProps;
    inputProps?: any;
    helpTipContentId?: string | number;
    helpTipTitle?: string;
    touched?: boolean;
    variant?: 'outlined' | 'standard' | 'filled';
}
export const FieldWithLabel = (props: FieldWithLabelProps) => {
    const {
        label = '',
        labelHidden = false,
        labelComponent = 'label',
        type = 'text',
        formik,
        InputProps,
        inputProps,
        textFieldProps,
        formControlProps = {},
        id,
        name,
        autoComplete,
        required = true,
        helpTipContentId,
        helpTipTitle,
        sx = {},
        touched = false,
        ...rest
    } = props;
    return (
        <FormControl
            variant="standard"
            error={
                (touched || formik.touched[name]) &&
                Boolean(formik.errors[name])
            }
            disabled={formik.isSubmitting}
            sx={{ width: '100%' }}
            {...formControlProps}
        >
            {label && labelHidden ? (
                <ScreenReaderOnly>
                    <Box
                        display="grid"
                        gridTemplateColumns={required ? '.8rem 1fr' : '1fr'}
                        component="span"
                        alignItems="baseline"
                    >
                        <Asterisk required={required} />
                        <FormLabel htmlFor={id} component={labelComponent}>
                            {label}
                            {helpTipContentId && (
                                <HelpTipIcon
                                    contentId={helpTipContentId}
                                    title={helpTipTitle}
                                />
                            )}
                        </FormLabel>
                    </Box>
                </ScreenReaderOnly>
            ) : (
                label && (
                    <Box
                        display="grid"
                        gridTemplateColumns={required ? '.8rem 1fr' : '1fr'}
                        component="span"
                        alignItems="baseline"
                    >
                        <Asterisk required={required} />
                        <FormLabel htmlFor={id} component={labelComponent}>
                            {label}
                            {helpTipContentId && (
                                <HelpTipIcon
                                    contentId={helpTipContentId}
                                    title={helpTipTitle}
                                />
                            )}
                        </FormLabel>
                    </Box>
                )
            )}
            <TextField
                id={id}
                name={name}
                type={type}
                value={formik.values[name]}
                onChange={formik.handleChange}
                error={
                    (touched || formik.touched[name]) &&
                    Boolean(formik.errors[name])
                }
                helperText={
                    (touched || formik.touched[name]) && formik.errors[name]
                }
                autoComplete={autoComplete}
                disabled={formik.isSubmitting}
                sx={sx}
                InputProps={InputProps}
                inputProps={inputProps}
                {...textFieldProps}
                {...rest}
            />
        </FormControl>
    );
};
