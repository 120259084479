import { PaymentDeferred, Order } from '../types';

const LOCAL_STORAGE_KEY_DEFERRED_PAYMENTS = 'paymentDeferreds';
const LOCAL_STORAGE_KEY_TOTAL_OWED = 'totalOwed';
const DEFERRED_TYPE_CARD = 'gift_card';
const DEFERRED_TYPE_POINTS = 'points';

export const getPointType = (): string => {
    return DEFERRED_TYPE_POINTS;
};
export const getGiftCardType = (): string => {
    return DEFERRED_TYPE_CARD;
};
export const getFormatter = (): Intl.NumberFormat => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
};
export const currencyFormat = (amount: number): string => {
    const formatter = getFormatter();
    return formatter.format(amount);
};
export const getTotalOwed = (): number => {
    const totalOwedStr = localStorage.getItem(LOCAL_STORAGE_KEY_TOTAL_OWED);
    if (!totalOwedStr) {
        return -1;
    }
    return Number(totalOwedStr);
};
export const setTotalOwed = (o: Order): void => {
    localStorage.setItem(
        LOCAL_STORAGE_KEY_TOTAL_OWED,
        getTotalMinusDeferred(true, o).toString(),
    );
};
export const removeTotalOwed = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_TOTAL_OWED);
};
export const getAmountFormatted = (
    paymentDeferred: PaymentDeferred,
): string => {
    return currencyFormat(paymentDeferred.amount);
};

export const removePaymentDeferredType = (type: string): void => {
    const currentPaymentDeferreds = getPaymentDeferreds();
    const newPaymentDeferreds: Array<PaymentDeferred> = [];

    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        const currentPaymentDeferred: PaymentDeferred =
            currentPaymentDeferreds[i];
        if (currentPaymentDeferred.deferredType != type) {
            newPaymentDeferreds.push(currentPaymentDeferred);
        }
    }

    setPaymentDeferreds(newPaymentDeferreds);
};

export const getRewardPointsNeededMinusDeferred = (o: Order): number => {
    const amountDeferredInPoints = Math.round(getTotalAmountDeferred() * 100);
    return Math.max(o.pricing.total * 100 - amountDeferredInPoints, 0);
};

export const getRewardPointsNeededMinusDeferredFormatted = (
    o: Order,
): string => {
    return currencyFormat(getRewardPointsNeededMinusDeferred(o) / 100.0);
};

export const getTotalMinusDeferred = (totalOwed: boolean, o: Order): number => {
    const total = totalOwed
        ? o.pricing.totalOwedWithoutDeferred
        : o.pricing.total;
    return (
        Math.round(Math.max(total - getTotalAmountDeferred(), 0) * 100) / 100.0
    );
};

export const getTotalMinusServerSideDeferred = (
    totalOwed: boolean,
    o: Order,
): number => {
    const total = totalOwed
        ? o.pricing.totalOwedWithoutDeferred
        : o.pricing.total;
    return (
        Math.round(
            Math.max(total - getTotalAmountServerSideDeferred(o), 0) * 100,
        ) / 100.0
    );
};

export const getTotalAmountServerSideDeferred = (o: Order): number => {
    let totalAmountDeferred: number = 0.0;
    const currentPaymentDeferreds = o?.serverSidePaymentDeferreds ?? [];
    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        totalAmountDeferred += Number(currentPaymentDeferreds[i].amount);
    }
    return totalAmountDeferred;
};

export const getWouldFullPointsChargeTax = (
    totalOwed: boolean,
    o: Order,
): boolean => {
    const total = totalOwed
        ? o.pricing.totalOwedWithoutDeferred
        : o.pricing.total;
    const currentAmountDeferredNonPoints =
        getTotalAmountDeferredForTypes(getNonPointsTypes());
    const totalTax = getTotalMinusTax(totalOwed, o);
    return total - currentAmountDeferredNonPoints > totalTax;
};

export const getTotalChargedByPoints = (): number => {
    return getTotalAmountDeferredForTypes(Array<string>(DEFERRED_TYPE_POINTS));
};

export const getTotalChargedByPointsFormatted = (): string => {
    return currencyFormat(getTotalChargedByPoints());
};

export const getTotalChargeableByPoints = (
    totalOwed: boolean,
    o: Order,
): number => {
    const total = totalOwed
        ? o.pricing.totalOwedWithoutDeferred
        : o.pricing.total;
    const currentAmountDeferredNonPoints =
        getTotalAmountDeferredForTypes(getNonPointsTypes());
    const totalChargeable = Math.min(
        getTotalMinusTax(totalOwed, o),
        total - currentAmountDeferredNonPoints,
    );
    return (
        Math.round(
            Math.max(
                Math.min(
                    totalChargeable,
                    o.pricing.rewardPointsAvailable.inDollars.available,
                ),
                0,
            ) * 100.0,
        ) / 100.0
    );
};

export const getTotalMinusTax = (totalOwed: boolean, o: Order): number => {
    const total = totalOwed
        ? o.pricing.totalOwedWithoutDeferred
        : o.pricing.total;
    const taxAmount = o.pricing.tax;
    return Math.round((total - taxAmount) * 100.0) / 100.0;
};

export const getNonPointsTypes = (): Array<string> => {
    return Array<string>(DEFERRED_TYPE_CARD);
};

export const getTotalMinusDeferredForTypes = (
    totalOwed: boolean,
    o: Order,
    types: Array<string>,
): number => {
    const total = totalOwed
        ? o.pricing.totalOwedWithoutDeferred
        : o.pricing.total;
    return Math.max(total - getTotalAmountDeferredForTypes(types), 0);
};

export const getTotalAmountDeferredForTypes = (
    types: Array<string>,
): number => {
    let totalAmountDeferred: number = 0;
    const currentPaymentDeferreds = getPaymentDeferreds();
    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        if (types.indexOf(currentPaymentDeferreds[i].deferredType) != -1) {
            totalAmountDeferred =
                totalAmountDeferred + Number(currentPaymentDeferreds[i].amount);
        }
    }
    return totalAmountDeferred;
};

export const getTotalAmountDeferredForTypeAndToken = (
    type: string,
    token: string,
): number => {
    const currentPaymentDeferreds = getPaymentDeferreds();
    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        if (
            currentPaymentDeferreds[i].deferredType == type &&
            currentPaymentDeferreds[i].token == token
        ) {
            return Number(currentPaymentDeferreds[i].amount);
        }
    }
    return 0;
};

export const getTotalAmountDeferred = (): number => {
    let totalAmountDeferred: number = 0.0;
    const currentPaymentDeferreds = getPaymentDeferreds();
    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        totalAmountDeferred += Number(currentPaymentDeferreds[i].amount);
    }
    return totalAmountDeferred;
};

export const getPaymentDeferreds = (): Array<PaymentDeferred> => {
    const localStorageString: string = getPaymentDeferredsString();
    if (!localStorageString) {
        return [] as Array<PaymentDeferred>;
    }
    try {
        return JSON.parse(localStorageString) as Array<PaymentDeferred>;
    } catch (e) {
        return [] as Array<PaymentDeferred>;
    }
};

export const getPaymentDeferredsString = (): string => {
    return localStorage.getItem(LOCAL_STORAGE_KEY_DEFERRED_PAYMENTS) ?? '';
};

export const getTotalMinusDeferredFormatted = (
    totalOwed: boolean,
    o: Order,
): string => {
    const total = getTotalMinusDeferred(totalOwed, o);
    return currencyFormat(total);
};

export const getPaymentDeferredByType = (
    deferredType: string,
): Array<PaymentDeferred> => {
    const paymentDeferreds: Array<PaymentDeferred> = [];
    const currentPaymentDeferreds = getPaymentDeferreds();

    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        const currentPaymentDeferred: PaymentDeferred =
            currentPaymentDeferreds[i];
        if (currentPaymentDeferred.deferredType == deferredType) {
            paymentDeferreds.push(currentPaymentDeferred);
        }
    }

    return paymentDeferreds;
};

export const getPaymentDeferredsByCard = (): Array<PaymentDeferred> => {
    return getPaymentDeferredByType(DEFERRED_TYPE_CARD);
};

export const getPaymentDeferredsByPoints = (): Array<PaymentDeferred> => {
    return getPaymentDeferredByType(DEFERRED_TYPE_POINTS);
};

export const getServerSidePaymentDeferredByType = (
    deferredType: string,
    o: Order,
): Array<PaymentDeferred> => {
    const paymentDeferreds: Array<PaymentDeferred> = [];
    const currentPaymentDeferreds = o?.serverSidePaymentDeferreds ?? [];

    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        const currentPaymentDeferred: PaymentDeferred =
            currentPaymentDeferreds[i];
        if (currentPaymentDeferred.deferredType == deferredType) {
            paymentDeferreds.push(currentPaymentDeferred);
        }
    }

    return paymentDeferreds;
};

export const getServerSidePaymentDeferredsByCard = (
    o: Order,
): Array<PaymentDeferred> => {
    return getServerSidePaymentDeferredByType(DEFERRED_TYPE_CARD, o);
};

export const getServerSidePaymentDeferredsByPoints = (
    o: Order,
): Array<PaymentDeferred> => {
    return getServerSidePaymentDeferredByType(DEFERRED_TYPE_POINTS, o);
};

export const addPaymentDeferred = (paymentDeferred: PaymentDeferred): void => {
    const deferredType = paymentDeferred.deferredType;
    const token = paymentDeferred.token;

    if (!deferredType || !token) {
        return;
    }

    const currentPaymentDeferreds = getPaymentDeferreds();
    const newPaymentDeferreds: Array<PaymentDeferred> = [];

    for (let i = 0; i < currentPaymentDeferreds.length; ++i) {
        const currentPaymentDeferred: PaymentDeferred =
            currentPaymentDeferreds[i];
        if (
            currentPaymentDeferred.deferredType != deferredType ||
            currentPaymentDeferred.token != token
        ) {
            newPaymentDeferreds.push(currentPaymentDeferred);
        }
    }

    if (paymentDeferred.amount > 0) {
        newPaymentDeferreds.push(paymentDeferred);
    }
    setPaymentDeferreds(newPaymentDeferreds);
};

export const setPaymentDeferreds = (
    paymentDeferreds: Array<PaymentDeferred>,
): void => {
    try {
        localStorage.setItem(
            LOCAL_STORAGE_KEY_DEFERRED_PAYMENTS,
            JSON.stringify(paymentDeferreds),
        );
    } catch (e) {
        console.error(e);
    }
};

export const removePaymentDeferreds = (): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEY_DEFERRED_PAYMENTS);
};
