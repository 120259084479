import { useQuery } from '@apollo/client';
import { Box, Link, Typography } from '@mui/material';
import PageBreadcrumbs from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import {
    LoadingSpinner,
    PageHeader,
} from '../../Components/_base/BBQGuysComponents';
import { BBQGuysGrid } from '../../Components/_base/BBQGuysGrid';
import { BBQGuysImageCard } from '../../Components/_base/BBQGuysImageCard';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import { QL_BRAND, QL_BRANDS } from '../../Graphql/queries';
import theme from '../../Theme';
import { getGraphQLErrorStatus } from '../../functions';
import { Brand } from '../../types';
import Page404 from '../Page404/Page404';
import { RenderContentWithId } from '../PageContent/PageContent';
import BrandBestSellingItems from './Components/BrandBestSellingItems';
import BrandDepartments from './Components/BrandDepartments';
import BrandExpertReviews from './Components/BrandExpertReviews';
import BrandHeader from './Components/BrandHeader';
import BrandLearningCenter from './Components/BrandLearningCenter';
import BrandsList from './Components/BrandList';
import BrandPromotions from './Components/BrandPromotions';
import BrandSidebar from './Components/BrandSidebar';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { useParams } from 'react-router-dom';

export const BrandPage = (props: { brandId: string }) => {
    const { brandId } = props;
    const { data, error } = useQuery(QL_BRAND, {
        variables: {
            brandId: brandId,
        },
    });

    if (error) {
        if (getGraphQLErrorStatus(error) == 404) {
            return <Page404 />;
        }
    }

    return data && data.brand ? (
        <ScContainer className="brand">
            <PageBreadcrumbs type="brand" typeId={brandId} />
            <TwoColumnLayout
                leftSideComponent={<BrandSidebar brand={data.brand} />}
                rightSideComponent={
                    <>
                        <DesktopAndTabletDevice>
                            <BrandHeader brand={data.brand} />
                        </DesktopAndTabletDevice>
                        <BrandDepartments brand={data.brand} />
                        <BrandBestSellingItems brand={data.brand} />
                        <BrandPromotions brand={data.brand} />
                        <BrandExpertReviews brand={data.brand} />
                        <BrandLearningCenter brand={data.brand} />
                        <MobileDevice>
                            <BrandHeader brand={data.brand} />
                        </MobileDevice>
                    </>
                }
            />
        </ScContainer>
    ) : (
        <></>
    );
};

export function BrandPageHub() {
    const { data, loading, error } = useQuery(QL_BRANDS);

    if (loading) {
        return <LoadingSpinner center />;
    }

    if (error) {
        if (getGraphQLErrorStatus(error) == 404) {
            return <Page404 />;
        }
    }

    return (
        <>
            <ScContainer>
                <PageHeader title="Shop by Brand" />
                <RenderContentWithId contentId="35915" />
            </ScContainer>
            <ScContainer
                className="brand"
                variant="alt"
                containerProps={{
                    sx: { borderBottom: theme.palette.border, pb: 4 },
                }}
            >
                <Typography
                    component="h2"
                    variant="sectionTitle"
                    sx={{
                        paddingLeft: {
                            xs: '5%',
                            xl: '0',
                        },
                    }}
                >
                    Top Brands
                </Typography>
                <BBQGuysGrid>
                    {data && data.brandBestSellers && (
                        <>
                            {data.brandBestSellers.results.map(
                                (brand: Brand, i: number) => {
                                    return (
                                        <BBQGuysImageCard
                                            key={'brand-' + brand.id}
                                            image={brand.logoUrl}
                                            url={brand.url || ''}
                                            title={brand.name}
                                            withTitle={false}
                                            imageProps={{
                                                sx: {
                                                    width: 250,
                                                    height: 150,
                                                    minWidth: 100,
                                                    minHeight: 50,
                                                },
                                                width: 250,
                                                height: 150,
                                                alt: `${brand.name} Logo`,
                                            }}
                                            cardContentProps={{
                                                pb: 4,
                                                px: 0,
                                                height: '100%',
                                            }}
                                        >
                                            <Box sx={{ pt: 1, pb: 3 }}>
                                                <p
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            brand.about ?? '',
                                                    }}
                                                />
                                            </Box>
                                            <Link
                                                href={brand.url || ''}
                                                sx={{
                                                    position: 'absolute',
                                                    bottom: 16,
                                                    left: 0,
                                                    right: 0,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {brand.name}
                                            </Link>
                                        </BBQGuysImageCard>
                                    );
                                },
                            )}
                        </>
                    )}
                </BBQGuysGrid>
            </ScContainer>
            <ScContainer sx={{ my: 3 }}>
                <Typography variant="h2" sx={{ pb: 3 }}>
                    Shop All Brands{' '}
                    <span style={{ fontSize: 'medium', color: '#636d76' }}>
                        (A-Z)
                    </span>
                </Typography>
                <BrandsList />
            </ScContainer>
        </>
    );
}

export default function PageBrand() {
    let { brandId } = useParams();
    if (brandId === undefined) {
        brandId = '0';
    }
    return <BrandPage brandId={brandId} />;
}
