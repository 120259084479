import { useQuery } from '@apollo/client';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import PageBreadcrumbs from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { QL_ITEM_WITH_IMAGES } from '../../Graphql/queries';
import { getGraphQLErrorStatus, scrollToHash } from '../../functions';
import { ImageType, Item } from '../../types';
import Page404 from '../Page404/Page404';
import ItemAboveTheFold from './Components/AboveTheFold/ItemAboveTheFold';
import CustomerReviews from './Components/CustomerReviews/CustomerReviews';
import ExpertReviewSection from './Components/ExpertReview/ExpertReviewSection';
import FrequentlyBoughtTogetherItems from './Components/FrequentlyBoughtTogetherItems/FrequentlyBoughtTogetherItems';
import ItemComparisonSection from './Components/ItemComparison/ItemComparisonSection';
import ItemPageHead from './Components/ItemPageHead/ItemPageHead';
import ItemDescription from './Components/KeyFeaturesDescription/KeyFeaturesDescription';
import ItemLearningCenter from './Components/LearningCenter/ItemLearningCenter';
import OpenBoxItemsSection from './Components/OpenBoxItems/OpenBoxItemsSection';
import Prop65 from './Components/Prop65/Prop65';
import QuestionsAndAnswers from './Components/QuestionsAndAnswers/QuestionsAndAnswers';
import RelatedDepartments from './Components/RelatedDepartments/ItemRelatedDepartments';
import ItemShippingAndReturnsInfo from './Components/ShippingInfo/ShippingInfo';
import ShopTheCollectionItems from './Components/ShopTheCollection/ShopTheCollectionItems';
import { ItemSpecifications } from './Components/Specifications/Specifications';
import WarrantyInfo from './Components/WarrantyInfo/WarrantyInfo';
import PageItemLoading from './PageItemLoading';
import {
    ProductPageContext,
    ProductPageProvider,
} from '../../Contexts/ProductPageProvider';
import SimilarItems from './Components/SimilarItems/SimilarItems';

const PageItemLayout = (props: { item: Item; itemImages: ImageType[] }) => {
    const { item, itemImages = [] } = props;
    const { addToRecentlyViewedItem } = React.useContext(UserAuth);
    React.useEffect(() => {
        setTimeout(() => {
            addToRecentlyViewedItem(item as Item);
            scrollToHash();
        }, 200);
    }, [item]);

    return (
        <>
            <ItemPageHead item={item} />
            <ScContainer className="item">
                <PageBreadcrumbs type="item" typeId={item.id} />
                {item.isAvailable ? <></> : <SimilarItems item={item} />}
                <ItemAboveTheFold item={item} itemImages={itemImages} />
                <FrequentlyBoughtTogetherItems item={item} />
                <ShopTheCollectionItems item={item} />
                <ItemDescription item={item} />
                <ItemSpecifications itemId={item.id} />
                <ItemLearningCenter itemId={item.id} />
                <WarrantyInfo item={item} itemImages={itemImages} />
                <RelatedDepartments item={item} />
                <OpenBoxItemsSection item={item} />
                <CustomerReviews item={item} />
                <ExpertReviewSection itemId={item.id} />
                <QuestionsAndAnswers item={item} />
                <ItemComparisonSection item={item} />
                <Prop65 item={item} />
                <ItemShippingAndReturnsInfo item={item} />
            </ScContainer>
        </>
    );
};
function PageItemInner() {
    const { itemId } = React.useContext(ProductPageContext);
    const { getPricingTierId } = React.useContext(UserAuth);

    const { data, loading, error } = useQuery(QL_ITEM_WITH_IMAGES, {
        variables: {
            itemId: itemId,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <PageItemLoading />;
    }

    if (error) {
        if (getGraphQLErrorStatus(error) == 404 || parseInt(itemId) == 0) {
            return <Page404 />;
        }
    }

    return <PageItemLayout item={data.item} itemImages={data.itemImages} />;
}
export default function PageItem(props: { itemId?: string }) {
    let { itemId = '' } = useParams();

    if (props.itemId !== undefined) {
        itemId = props.itemId;
    }

    return (
        <ProductPageProvider itemId={itemId}>
            <PageItemInner />
        </ProductPageProvider>
    );
}
