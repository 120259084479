import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';

export default function QuestionsAndAnswersLoading() {
    return (
        <React.Fragment>
            {[...Array(10)].map((v: number, i: number) => {
                return (
                    <Box key={i} sx={{ borderTop: '1px solid #ccc' }}>
                        <Grid container spacing={3} sx={{ pt: 4, pb: 1 }}>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="80%" />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton variant="text" width="100%" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ pt: 1, pb: 4 }}>
                            <Grid item xs={2}>
                                <Skeleton variant="text" width="80%" />
                            </Grid>
                            <Grid item xs={10}>
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="100%" />
                                <Skeleton variant="text" width="60%" />
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
        </React.Fragment>
    );
}
