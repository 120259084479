import { Image } from '../../../../../Components/Image';
import VideoPlayer from '../../../../../Components/_base/VideoPlayer';
import {
    CustomerReviewMedia,
    CustomerReviewMediaTypes,
    ImageType,
} from '../../../../../types';
import { openPhotoSwipe } from '../../../../../Components/ImageCarousel/ImageCarousel';

const CustomerReviewsMediaInlineImages = (props: {
    media: CustomerReviewMedia[];
}) => {
    const { media } = props;

    if (media.length > 0) {
        const mediaImages: ImageType[] = media
            .filter(
                (m: CustomerReviewMedia) =>
                    m.type === CustomerReviewMediaTypes.Image,
            )
            .map((m: CustomerReviewMedia, i: number) => {
                return {
                    id: m.id,
                    url: m.url,
                    description: m.caption ?? '',
                };
            });
        return (
            <>
                {media.map((m: CustomerReviewMedia, i: number) => {
                    if (m.type === CustomerReviewMediaTypes.Image) {
                        return (
                            <Image
                                rounded
                                key={'cust-review-' + m.sourceId}
                                src={m.url}
                                alt={
                                    m.caption ??
                                    'customer review image for review id ' +
                                        m.id
                                }
                                onClick={() => {
                                    openPhotoSwipe(i, mediaImages);
                                }}
                            />
                        );
                    } else {
                        return (
                            <VideoPlayer
                                key={'cust-review-' + m.sourceId}
                                title={m.caption ?? 'Customer Review'}
                                src={m.url}
                                image={m.thumbnailUrl}
                            />
                        );
                    }
                })}
            </>
        );
    }

    return <></>;
};

export default CustomerReviewsMediaInlineImages;
