import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import Countdown from 'react-countdown';
import { Image } from '../../../../../../Components/Image';
import ImageGalleryViewPhotosLink from '../../../../../../Components/ImageCarousel/ImageGalleryViewPhotosLink';
import { LineEllipsis } from '../../../../../../Components/_base/BBQGuysComponents';
import { CartContext } from '../../../../../../Contexts/CartProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../../../Contexts/DeviceTypeProvider';
import { getEnvironmentVariable } from '../../../../../../config';
import { OpenBoxItem } from '../../../../../../types';
import OpenBoxConditionDetails from './Components/OpenBoxConditionDetails';
import OpenBoxPriceDetails from './Components/OpenBoxPriceDetails';
import OpenBoxShippingDetails from './Components/OpenBoxShippingDetails';
import OpenBoxAddToCart from '../OpenBoxAddToCart/OpenBoxAddToCart';

const addMinutesAndReturnString = (date: string, minutes: number) => {
    const d = new Date(date);
    d.setMinutes(d.getMinutes() + minutes);
    return (
        d.toISOString().split('T')[0] +
        ' ' +
        d.toISOString().split('T')[1].split('.')[0]
    );
};

const OpenBoxItemRow = (props: {
    item: OpenBoxItem;
    onAdded: () => void;
    showTitle?: boolean;
}) => {
    const { item, showTitle = false } = props;
    const EXPIRES_IN_MINUTES = 15;
    const { removeItemFromOrder, getOrderItem } = React.useContext(CartContext);
    const oi = getOrderItem(item.id);
    const AlertBox: React.FC = () => {
        if (oi && oi.dateAdded !== undefined) {
            return (
                <Alert
                    variant="standard"
                    color="warning"
                    sx={{ maxWidth: '70%' }}
                >
                    This open-box item is reserved in your cart. You will have{' '}
                    {EXPIRES_IN_MINUTES} mins to complete your purchase.
                    <br />
                    <br />
                    <strong>
                        <Countdown
                            date={addMinutesAndReturnString(oi.dateAdded, 20)}
                            renderer={({ completed, minutes, seconds }) => {
                                if (completed) {
                                    removeItemFromOrder(oi);
                                    return 'Reservation Expired';
                                }
                                return (
                                    minutes +
                                    ' minutes ' +
                                    seconds +
                                    ' remaining'
                                );
                            }}
                        />
                    </strong>
                </Alert>
            );
        }
        return <></>;
    };

    return (
        <Box
            display="grid"
            gap={1}
            gridTemplateColumns={{ xs: '120px 3fr', md: '200px 3fr' }}
            padding={1}
        >
            <DesktopAndTabletDevice>
                <Box>
                    <Image
                        src={
                            item.image ?? getEnvironmentVariable('NO_IMAGE_URL')
                        }
                        hoverSrc={
                            item.images[1] ? item.images[1].url : undefined
                        }
                        alt="Open Box Item"
                        sx={{
                            minHeight: { xs: 120, md: 160 },
                            width: { xs: 120, md: 'auto' },
                            maxWidth: { xs: 120, md: 160 },
                        }}
                    />
                    <ImageGalleryViewPhotosLink images={item.images} />
                </Box>
                <Box>
                    {showTitle && (
                        <Typography variant="bold" component="div">
                            <LineEllipsis text={item.name} maxLines={2} />
                        </Typography>
                    )}

                    <Box
                        display="grid"
                        gap={2}
                        gridTemplateColumns={'1fr 1fr 1fr'}
                    >
                        <Box>
                            <OpenBoxConditionDetails
                                size="medium"
                                item={item}
                            />
                        </Box>
                        <Box>
                            <OpenBoxShippingDetails item={item} />
                        </Box>
                        <Box textAlign={'right'}>
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                }}
                            >
                                <OpenBoxPriceDetails item={item} />
                                <OpenBoxAddToCart
                                    item={item}
                                    onAdded={props.onAdded}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <AlertBox />
                </Box>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        width: '100%',
                        gridColumn: 'span 2',
                        marginBottom: '10px',
                    }}
                >
                    <Box>
                        <Image
                            src={
                                item.image ??
                                getEnvironmentVariable('NO_IMAGE_URL')
                            }
                            hoverSrc={
                                item.images[1] ? item.images[1].url : undefined
                            }
                            alt="Open Box Item"
                            sx={{
                                height: 'auto',
                                width: { xs: 120, md: 'auto' },
                                maxWidth: { xs: 120, md: 160 },
                            }}
                        />
                        <ImageGalleryViewPhotosLink images={item.images} />
                    </Box>
                    <Box
                        textAlign={'right'}
                        sx={{
                            display: 'inline-flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <OpenBoxPriceDetails item={item} />
                    </Box>
                </Box>
                <Box sx={{ gridColumn: 'span 2' }}>
                    <OpenBoxConditionDetails size="small" item={item} />
                </Box>
                <Box sx={{ gridColumn: 'span 2' }}>
                    <OpenBoxShippingDetails item={item} />
                </Box>
                <Box sx={{ gridColumn: 'span 2' }}>
                    <AlertBox />
                </Box>
                <Box sx={{ gridColumn: 'span 2', marginBottom: '10px' }}>
                    <OpenBoxAddToCart item={item} onAdded={props.onAdded} />
                </Box>
            </MobileDevice>
        </Box>
    );
};

export default OpenBoxItemRow;
