import PageBanners from '../../Components/Banners/Banners';
import ProductFacets from '../../Components/ProductGridItems/ProductFacets';
import { Department, DepartmentContent } from '../../types';
import { ContentDisplay } from '../PageContent/PageContent';
import SearchProductsGrid from '../PageSearch/SearchProductsGrid';
import DepartmentChildPanel from './DepartmentChildPanel';
import DepartmentProductsGrid from './DepartmentProductsGrid';

const DepartmentLayoutMobile = ({
    department,
    contentTop = [],
    contentSide = [],
    contentBottom = [],
}: {
    department: Department;
    contentTop?: DepartmentContent[];
    contentSide?: DepartmentContent[];
    contentBottom?: DepartmentContent[];
}) => {
    return (
        <>
            <PageBanners />
            {/* Adding support for weird hybrid style pages where we use search results instead of department results */}
            {department.useSearchResults ? (
                <SearchProductsGrid term={''} departmentId={department.id} />
            ) : (
                <DepartmentProductsGrid departmentId={department.id} />
            )}
            {contentTop &&
                contentTop.map((dp: DepartmentContent) => (
                    <ContentDisplay
                        key={'content-top-' + dp.content.id}
                        withIntro
                        content={dp.content}
                        sx={{ mb: 2 }}
                    />
                ))}
            {contentBottom &&
                contentBottom.map((dp: DepartmentContent) => (
                    <ContentDisplay
                        key={'content-bottom-' + dp.content.id}
                        withIntro
                        content={dp.content}
                        sx={{ mt: 2 }}
                    />
                ))}

            {department.useSearchResults ? (
                <>
                    <DepartmentChildPanel department={department} />
                    <ProductFacets
                        type={'search'}
                        searchTerm={''}
                        typeId={department.id}
                    />
                </>
            ) : (
                <>
                    <ProductFacets
                        type={
                            department.useSearchResults
                                ? 'search'
                                : 'department'
                        }
                        typeId={department.id}
                    />
                </>
            )}
        </>
    );
};

export default DepartmentLayoutMobile;
