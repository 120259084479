import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Checkbox,
    FormControlLabel,
    Link,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { BulletedList } from '../../../Components/_base/BBQGuysComponents';
import { UserAuth } from '../../../Contexts/UserAuthProvider';
import {
    MIN_PASSWORD_CHARS,
    RegistrationSchema,
} from '../../../Schemas/Schemas';
import { EmailWithLabel } from '../../Account/PageEditMyAccount/Components/EmailWithLabel';
import { PasswordWithLabel } from '../../Account/PageEditMyAccount/Components/PasswordWithLabel';
import Asterisk from '../../../Components/Asterisk';

interface RegistrationValues {
    emailRegister: string;
    passwordRegister: string;
    passwordConfirm: string;
    optInDescription: boolean;
}

export const RegistrationForm = (props: {
    inline?: boolean;
    onComplete?: () => void;
    formProps?: Record<string, unknown>;
}) => {
    const {
        inline = false,
        onComplete = () => {
            return;
        },
        formProps = {},
    } = props;
    const { registerErrorMessage, register, getLoginRedirectUrl } =
        React.useContext(UserAuth);

    const checkboxName = 'optInDescription';
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            emailRegister: '',
            passwordRegister: '',
            passwordConfirm: '',
            optInDescription: false,
        },
        validationSchema: RegistrationSchema,
        onSubmit: (values: RegistrationValues, actions: any) => {
            actions.setSubmitting(true);
            register(values.emailRegister, values.passwordRegister)
                .then(() => {
                    if (!inline) {
                        navigate(getLoginRedirectUrl());
                    }
                    onComplete();
                })
                .catch(() => {
                    actions.setSubmitting(false);
                });
        },
    });

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} {...formProps}>
                {registerErrorMessage.length > 0 ? (
                    <Alert severity="error">{registerErrorMessage}</Alert>
                ) : null}
                <Typography variant="bold">Benefits</Typography>
                <BulletedList
                    bullets={[
                        'Earn 1 point per $1 spent',
                        'Track your orders',
                        'Save your favorite items',
                        'Quick and easy checkout',
                    ]}
                />
                <Typography variant="body1">
                    Are you a pro? Join our{' '}
                    <Link href="/pro" target="_blank">
                        BBQGuys Pro Program
                    </Link>{' '}
                    for benefits and rewards.
                </Typography>
                <EmailWithLabel
                    formik={formik}
                    name="emailRegister"
                    id="emailRegister"
                />
                <PasswordWithLabel
                    label={
                        <>
                            Password{' '}
                            <em>
                                (minimum of {MIN_PASSWORD_CHARS} characters)
                            </em>
                        </>
                    }
                    formik={formik}
                    name="passwordRegister"
                    id="passwordRegister"
                    create
                />
                <PasswordWithLabel
                    label={
                        <>
                            Confirm Password <em>(must match previous)</em>
                        </>
                    }
                    formik={formik}
                    name="passwordConfirm"
                    id="passwordConfirm"
                    confirm
                />

                <FormControlLabel
                    sx={{ alignItems: 'flex-start', pb: 3, mb: 1 }}
                    control={
                        <Checkbox
                            checked={formik.values.optInDescription}
                            onChange={formik.handleChange}
                            name={checkboxName}
                            id={checkboxName}
                            aria-describedby={checkboxName}
                            inputProps={{ autoComplete: 'off' }}
                            sx={{ py: 0 }}
                        />
                    }
                    label={
                        <Typography variant="body1" id={checkboxName}>
                            <Box
                                sx={{
                                    color: '#d32f2f',
                                    fontWeight: 'bold !important',
                                    fontSize: '0.8125rem !important',
                                    lineHeight: '1.66 !important',
                                }}
                            >
                                {formik.touched.optInDescription &&
                                formik.errors.optInDescription
                                    ? formik.touched.optInDescription &&
                                      formik.errors.optInDescription
                                    : ''}
                            </Box>
                            <Asterisk required={true} />
                            By checking this box, I agree to BBQGuys’{' '}
                            <Link href="/terms-conditions">
                                Terms and Conditions
                            </Link>
                            ,{' '}
                            <Link href="/policies#privacy">Privacy Policy</Link>
                            , and to the terms of{' '}
                            <Link href="/policies#myrewards">
                                BBQGuys’ Rewards Program
                            </Link>
                            .
                            <br />
                            <br />
                            Note: CA residents, please refer to the{' '}
                            <Link href="/terms-conditions/notice-california">
                                Notice of Financial Incentive
                            </Link>{' '}
                            and Colorado residents please review our{' '}
                            <Link href="/policies#colorado">
                                CO loyalty disclosures
                            </Link>{' '}
                            prior to checking this box to opt-in to our Rewards
                            Program.
                            <br />
                            From time to time, we may contact you by email with
                            exclusive deals and offers.
                            <br />
                            You may opt out of receiving emails by clicking the
                            “unsubscribe” link within the email.
                        </Typography>
                    }
                />
                <Box
                    display="grid"
                    gridTemplateColumns={{ xs: '1fr', md: '1fr 1fr' }}
                    gap={2}
                    pt={2}
                >
                    <LoadingButton
                        className="btn-create-account"
                        type="submit"
                        variant="contained"
                        color="secondary"
                        loading={formik.isSubmitting}
                    >
                        Create Account
                    </LoadingButton>
                </Box>
            </form>
        </FormikProvider>
    );
};
