import { Typography } from '@mui/material';
import { ListPrice, Price } from '../../../../../../../Components/Item/Pricing';
import { OpenBoxItem } from '../../../../../../../types';

const OpenBoxPriceDetails = (props: { item: OpenBoxItem }) => {
    const { item } = props;
    return (
        <>
            {!item.isCallForPricing && (
                <>
                    <ListPrice amount={item.listPrice} withText={true} />
                    <Typography variant="currentPrice2" component="div">
                        <Price price={item.price} />
                    </Typography>
                    <br />
                </>
            )}
        </>
    );
};

export default OpenBoxPriceDetails;
