import { Skeleton } from '@mui/material';
import React from 'react';
import { PageBreadcrumbsLoading } from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import { PromotionCardLoading } from './PromotionCardLoading';
import { PromotionLeftSidebar } from './PromotionLeftSidebar';

export default function PromotionsLoading() {
    return (
        <>
            <ScContainer>
                <PageBreadcrumbsLoading />
                <Skeleton variant="text" width="70%" height="4rem" />
            </ScContainer>
            <ScContainer variant="alt">
                <TwoColumnLayout
                    mobileReverseLayout={true}
                    leftSideComponent={
                        <PromotionLeftSidebar>
                            {[...Array(3)].map((v: unknown, i: number) => {
                                return (
                                    <React.Fragment key={'promo-loading-' + i}>
                                        <Skeleton
                                            variant="text"
                                            width="90%"
                                            height="3rem"
                                        />
                                        <Skeleton variant="text" width="80%" />
                                        <Skeleton variant="text" width="60%" />
                                        <Skeleton variant="text" width="70%" />
                                        <Skeleton variant="text" width="80%" />
                                        <Skeleton variant="text" width="40%" />
                                    </React.Fragment>
                                );
                            })}
                        </PromotionLeftSidebar>
                    }
                    rightSideComponent={<PromotionCardLoading />}
                />
            </ScContainer>
        </>
    );
}
