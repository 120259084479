import { useLocation } from 'react-router-dom';
import { BannerCampaign } from '../../types';
import BannerCampaigns from './Components/BannerCampaigns';
import BannersByUrl from './Components/BannersByUrl';
import { SxProps } from '@mui/system';

export default function PageBanners({
    campaigns,
    url,
    query,
    sx,
}: {
    campaigns?: BannerCampaign[];
    url?: string;
    query?: string;
    sx?: SxProps;
}) {
    if (campaigns === undefined && url === undefined) {
        const location = useLocation();
        return <BannersByUrl url={location.pathname} sx={sx} />;
    } else if (url) {
        return <BannersByUrl url={url} query={query ?? ''} sx={sx} />;
    }
    if (campaigns) {
        return <BannerCampaigns campaigns={campaigns} sx={sx} />;
    }
    return <BannerCampaigns campaigns={[]} sx={sx} />;
}
