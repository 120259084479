import { useQuery } from '@apollo/client';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import PageBanners from '../../Components/Banners/Banners';
import PageBreadcrumbs from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import ProductFacets from '../../Components/ProductGridItems/ProductFacets';
import {
    ProductsGridDisplay,
    ProductsGridLoading,
} from '../../Components/ProductGridItems/ProductGridItems';
import { PageHeader } from '../../Components/_base/BBQGuysComponents';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import {
    ProductGridRefinement,
    ProductGridRefinementProvider,
} from '../../Contexts/ProductGridRefinementProvider';
import { QL_WAREHOUSE_DEALS } from '../../Graphql/queries';
import { ProductCardType } from '../../Components/ProductCards/ProductCards';

const WarehouseDealsProductsGridItems = () => {
    const { page, setPageType } = React.useContext(ProductGridRefinement);
    const [mounted, setMounted] = React.useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchPageParam = parseInt(searchParams.get('page') as string) || 0;
    const searchSortParam = (searchParams.get('sort') as string) || '';
    const page2 = searchPageParam > 0 ? searchPageParam : page;
    searchParams.delete('page');
    searchParams.delete('sort');
    const refinements = searchParams.toString();

    React.useEffect(() => {
        setMounted(true);
        setPageType('deals');
        return () => {
            setMounted(false);
        };
    }, []);

    const { data, loading } = useQuery(QL_WAREHOUSE_DEALS, {
        fetchPolicy: 'cache-and-network',
        variables: {
            page: page2,
            sort: searchSortParam,
            refinements: refinements,
        },
    });

    if (mounted) {
        return (
            <>
                <TwoColumnLayout
                    leftSideComponent={<ProductFacets type="deal" />}
                    rightSideComponent={
                        <>
                            <PageBanners />
                            {loading ? (
                                <ProductsGridLoading />
                            ) : (
                                <ProductsGridDisplay
                                    data={data.warehouseDealItems}
                                    sortOptions={data.searchSortOptions}
                                    hideAlertIfNoResults={true}
                                    hideTitleIfNoResults={true}
                                    cardType={'deal' as ProductCardType}
                                />
                            )}
                        </>
                    }
                />
            </>
        );
    }

    return <></>;
};
export default function PageWarehouseDeals() {
    return (
        <ScContainer>
            <Helmet>
                <title>Warehouse Deals</title>
            </Helmet>
            <PageBreadcrumbs
                breadcrumbs={[
                    {
                        title: 'Home',
                        url: '/',
                    },
                    {
                        title: 'Warehouse Deals',
                        url: '/deals/used-and-refurbished',
                    },
                ]}
            />
            <PageHeader title="Warehouse Deals" />
            <ProductGridRefinementProvider
                defaultSortType="relevance"
                defaultShowGridViewOptions={false}
            >
                <WarehouseDealsProductsGridItems />
            </ProductGridRefinementProvider>
        </ScContainer>
    );
}
