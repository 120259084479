import { Box } from '@mui/material';
import {
    DesktopDevice,
    MobileDevice,
    TabletDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import { LearningCenterItem, Maybe } from '../../../../types';
import LearningCenterVideoGrid from './LearningCenterVideoGrid';

const LearningCenterVideos = (props: {
    videos: Maybe<LearningCenterItem>[];
}) => {
    if (props.videos && props.videos.length > 0) {
        const sx = {
            display: 'grid',
            gridTemplateColumns: `repeat(3, 175px)`,
            gridTemplateRows: 'repeat(2, auto)',
            gap: '1rem',
        };

        return (
            <>
                <DesktopDevice>
                    <Box sx={sx}>
                        <LearningCenterVideoGrid
                            videos={props.videos}
                            imageSize="hq"
                        />
                    </Box>
                </DesktopDevice>
                <TabletDevice>
                    <Box sx={{ ...sx, gridTemplateColumns: 'repeat(3, 1fr)' }}>
                        <LearningCenterVideoGrid
                            videos={props.videos}
                            imageSize="hq"
                        />
                    </Box>
                </TabletDevice>
                <MobileDevice>
                    <Box sx={{ ...sx, gridTemplateColumns: 'repeat(2, 1fr)' }}>
                        <LearningCenterVideoGrid
                            videos={props.videos}
                            imageSize="hq"
                        />
                    </Box>
                </MobileDevice>
            </>
        );
    } else {
        return <div></div>;
    }
};

export default LearningCenterVideos;
