import { Typography } from '@mui/material';
import { BBQGuysGrid } from '../../Components/_base/BBQGuysGrid';
import { Promotion } from '../../types';
import { PromotionCard } from './PromotionCard';

export const PromotionsLayout = (props: {
    promotions: Promotion[];
    title?: string;
}) => {
    const { promotions, title } = props;

    return (
        <>
            {title ? (
                <Typography variant="sectionTitle">{title}</Typography>
            ) : null}
            <BBQGuysGrid sx={{ m: { xs: 0 }, overflow: 'visible' }}>
                {promotions.map((promo: Promotion, i: number) => {
                    return (
                        <PromotionCard
                            onClick={() => {
                                window.location.href = promo.url;
                            }}
                            promotion={promo}
                            key={'promo-page-' + promo.id}
                            aria-label={`Promotion: ${promo.title}`}
                        />
                    );
                })}
            </BBQGuysGrid>
        </>
    );
};
