import { Link, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { Item } from '../../../../types';
import { Image } from '../../../Image';
import { Price, screenReaderPrice } from '../../../Item/Pricing';
import StarRatings from '../../../StarRatings/StarRatings';
import { LineEllipsis } from '../../../_base/BBQGuysComponents';
import { QuickView } from '../../../_base/BBQGuysQuickView';
import './../../ProductCards.scss';

const ProductCardDeal = (props: {
    item: Item;
    lazyLoad?: boolean;
    withQuickView?: boolean;
}) => {
    const { item, lazyLoad = true, withQuickView = false } = props;

    const [quickViewVisible, setQuickViewVisible] = useState(false);

    const currentItem = item;

    const price =
        currentItem.pricing.openBox === 0
            ? 'Call for Pricing'
            : currentItem.pricingFormatted.openBox;

    const href = (currentItem.url || '#') + '#open-box';

    return (
        <Box
            className="product-card"
            onMouseEnter={() => setQuickViewVisible(true)}
            onMouseLeave={() => setQuickViewVisible(false)}
        >
            <Box
                position="relative"
                sx={{
                    pt: {
                        xs: 1.5,
                        sm: 0,
                    },
                }}
            >
                <Link
                    href={href}
                    aria-labelledby={'product' + currentItem.id}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    <span className="img-container">
                        <Image
                            src={currentItem.imageUrl as string}
                            alt={currentItem.name + ' image'}
                            width={250}
                            height={250}
                            fullWidth
                            lazyLoadImage={lazyLoad}
                        />
                    </span>
                </Link>
                <Link
                    href={href}
                    aria-labelledby={'product' + currentItem.id}
                    sx={{
                        textDecoration: 'none',
                    }}
                >
                    <Typography
                        className="name"
                        id={'product' + currentItem.id}
                    >
                        <LineEllipsis maxLines={3} text={currentItem.name} />
                    </Typography>
                </Link>
                {withQuickView && (
                    <QuickView item={currentItem} visible={quickViewVisible} />
                )}
            </Box>
            <Typography
                variant="body2"
                component="div"
                className="user-rating"
                sx={{ height: '1.5rem', pt: 1 }}
            >
                {currentItem.userReviewsRating > 0 &&
                currentItem.userReviewsCount > 0 ? (
                    <StarRatings
                        size="small"
                        rating={currentItem.userReviewsRating}
                        count={currentItem.userReviewsCount || 0}
                        showCountText={false}
                        item={currentItem}
                    />
                ) : null}
            </Typography>
            <Typography variant="body1" component="div">
                {price === 'Call for Pricing' ? (
                    <Typography variant="currentPrice2">{price}</Typography>
                ) : (
                    <>
                        Starting at:{' '}
                        <Typography variant="currentPrice2">
                            <Price
                                price={currentItem.pricingFormatted.openBox}
                                aria-label={
                                    'Open Box Price starting at ' +
                                    screenReaderPrice(price)
                                }
                            />
                        </Typography>
                    </>
                )}
            </Typography>
            <Typography variant="body1" component="div">
                Available: {currentItem.openBoxCount}
            </Typography>
        </Box>
    );
};
export default ProductCardDeal;
