import { useQuery } from '@apollo/client';
import ProductCarousel, {
    ProductCarouselLoading,
} from '../../../Components/ProductCarousels/ProductCarousel/ProductCarousel';
import { Section } from '../../../Components/_base/BBQGuysComponents';
import { QL_BRAND_BEST_SELLING_ITEMS } from '../../../Graphql/queries';
import { Brand } from '../../../types';
import React from 'react';
import { DigitalDataContext } from '../../../Contexts/DigitalDataProvider';
import { UserAuth } from '../../../Contexts/UserAuthProvider';

const BrandBestSellingItems = ({ brand }: { brand: Brand }) => {
    const { getPricingTierId } = React.useContext(UserAuth);
    const { data, loading } = useQuery(QL_BRAND_BEST_SELLING_ITEMS, {
        variables: {
            brandId: brand.id,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return (
            <Section title={brand.name + ' Best Sellers'}>
                <ProductCarouselLoading />
            </Section>
        );
    }

    const count = 5;
    const dependencyName = 'brandpage-best-sellers-carousel';

    if (data.brandBestSellingItems?.results.length) {
        return (
            <>
                {data.brandBestSellingItems?.results.length > 0 ? (
                    <Section title={brand.name + ' Best Sellers'}>
                        <ProductCarousel
                            items={data.brandBestSellingItems.results}
                            count={count}
                            dependencyName={dependencyName}
                        />
                    </Section>
                ) : null}
            </>
        );
    } else {
        const { pushCarouselDependency } = React.useContext(DigitalDataContext);
        pushCarouselDependency({
            carouselName: dependencyName,
            results: [],
            itemWidth: count,
        });
        return null;
    }
};

export default BrandBestSellingItems;
