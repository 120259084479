import { useQuery } from '@apollo/client';
import { Box } from '@mui/material';
import ImageCardCarousel, {
    ImageCardData,
} from '../../../Components/Carousels/ImageCardCarousel/ImageCardCarousel';
import { ChildDepartmentsLoading } from '../../../Components/_base/BBQGuysComponents';
import { ChevronLink } from '../../../Components/_base/BBQGuysStyledComponents';
import { QL_DEPT_BRANDS } from '../../../Graphql/queries';
import { Brand } from '../../../types';

const DepartmentBrands = ({ deptId }: { deptId: string }) => {
    const { data, loading } = useQuery(QL_DEPT_BRANDS, {
        variables: {
            departmentId: deptId,
        },
    });

    if (loading) {
        return <ChildDepartmentsLoading />;
    }
    return (
        <>
            {data.departmentBrands?.countResults > 0 ? (
                <Box
                    id="hubpage-brands"
                    sx={{
                        '& img': {
                            width: '150px',
                            height: '75px',
                            minHeight: 'auto',
                        },
                    }}
                >
                    <ImageCardCarousel
                        withCardBorder={false}
                        withCardTitle={false}
                        title={'Popular Brands'}
                        countToShow={5}
                        alignTitle="center"
                        imageCardData={data.departmentBrands.results
                            .slice(0, 5)
                            .map((b: Brand) => {
                                return {
                                    url: b.url,
                                    src: b.logoUrl,
                                    title: b.name,
                                    alt: `${b.name}`,
                                } as ImageCardData;
                            })}
                    />
                    <Box
                        component="div"
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            my: 2,
                        }}
                    >
                        <ChevronLink href={'/brands'}>
                            See All Brands
                        </ChevronLink>
                    </Box>
                </Box>
            ) : null}
        </>
    );
};

export default DepartmentBrands;
