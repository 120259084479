import { Helmet } from 'react-helmet-async';
import { CustomerReview, Item } from '../../../../../types';
import { CreateProductSchema } from '../../../../../Components/Meta/CreateSchemaOrg';
import { SiteContext } from '../../../../../Contexts/SiteProvider';
import { useContext } from 'react';

const getSchemaOrgInfo = (item: Item, reviews: CustomerReview[]) => {
    const { site } = useContext(SiteContext);
    const reviewSchemas = [];
    try {
        for (let i = 0; i < reviews.length; i++) {
            const review = reviews[i];
            reviewSchemas.push({
                '@context': 'https://schema.org/',
                '@type': 'Review',
                itemReviewed: JSON.parse(`${CreateProductSchema(site, item)}`),
                reviewRating: {
                    '@type': 'Rating',
                    ratingValue: `${review.rating}`,
                    bestRating: '5',
                    worstRating: '1',
                },

                name: `${review.title}`,
                author: {
                    '@type': 'Person',
                    name: `${review.name}`,
                },
                reviewBody: `${review.text}`,
                publisher: {
                    '@type': 'Organization',
                    name: `${review.isSyndicated ? review.syndicationSource : 'BBQGuys'}`,
                },
            });
        }
    } catch (e) {
        return [];
    }
    return reviewSchemas;
};

const CustomerReviewsSchemaOrg = (props: {
    item: Item;
    reviews: CustomerReview[];
}) => {
    return (
        <Helmet>
            <script type="application/ld+json">
                {JSON.stringify(getSchemaOrgInfo(props.item, props.reviews))}
            </script>
        </Helmet>
    );
};

export default CustomerReviewsSchemaOrg;
