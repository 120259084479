import React from 'react';
import { Maybe, PromotionCategories, PromotionCategoryLink } from '../../types';
import { Button, List, ListItem, Typography } from '@mui/material';
import { BBQGuysBottomDrawer } from '../../Components/_base/BBQGuysModal';
import { ChildDepartmentLinkLevel2 } from '../../Components/_base/BBQGuysStyledComponents';

export default function PromotionSidebarMobile({
    categories,
    selectedCategoryLink,
    setSelectedCategoryLink,
}: {
    categories: PromotionCategories[];
    selectedCategoryLink?: PromotionCategoryLink;
    setSelectedCategoryLink: (link: PromotionCategoryLink) => void;
}) {
    const [open, setOpen] = React.useState(false);
    const handleButtonClick = () => {
        setOpen(true);
    };

    const handleLinkClick = (link: PromotionCategoryLink) => {
        setOpen(false);
        setSelectedCategoryLink(link);
    };

    const handleFilterReset = () => {
        setOpen(false);
        setSelectedCategoryLink({ title: 'Filter', link: '', tags: [] });
    };

    return (
        <>
            <Button variant="standard" fullWidth onClick={handleButtonClick}>
                {selectedCategoryLink ? selectedCategoryLink.title : 'Filter'}
            </Button>
            <BBQGuysBottomDrawer
                anchor="left"
                open={open}
                onClose={() => setOpen(false)}
            >
                <List>
                    {categories.map((cat: PromotionCategories, i: number) => {
                        return (
                            <React.Fragment key={i}>
                                <Typography variant="subtitle" display="block">
                                    {cat.title}
                                </Typography>
                                <ListItem key={i}>
                                    {cat.links ? (
                                        <List>
                                            {cat.links.map(
                                                (
                                                    link: Maybe<PromotionCategoryLink>,
                                                    n: number,
                                                ) => {
                                                    return link ? (
                                                        <ListItem key={n}>
                                                            <ChildDepartmentLinkLevel2
                                                                key={n}
                                                                href={link.link}
                                                                display="block"
                                                                onClick={() =>
                                                                    handleLinkClick(
                                                                        link,
                                                                    )
                                                                }
                                                                sx={{
                                                                    py: 1,
                                                                }}
                                                            >
                                                                {link.title}
                                                            </ChildDepartmentLinkLevel2>
                                                        </ListItem>
                                                    ) : null;
                                                },
                                            )}
                                            <ListItem>
                                                <ChildDepartmentLinkLevel2
                                                    href="/promotions"
                                                    display="block"
                                                    onClick={handleFilterReset}
                                                    sx={{
                                                        pb: 1,
                                                        pl: 1,
                                                    }}
                                                >
                                                    View All
                                                </ChildDepartmentLinkLevel2>
                                            </ListItem>
                                        </List>
                                    ) : null}
                                </ListItem>
                            </React.Fragment>
                        );
                    })}
                </List>
            </BBQGuysBottomDrawer>
        </>
    );
}
