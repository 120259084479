import { Box, Skeleton } from '@mui/material';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';

const CustomerReviewsContentDesktopLoading = () => {
    return (
        <Box
            display="grid"
            gap={5}
            gridTemplateColumns={'1fr 5fr 1fr'}
            sx={{ pt: 4, pb: 4 }}
        >
            <Box>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="20%" />
                <Skeleton variant="text" width="30%" />
                <Skeleton variant="text" width="50%" height="2rem" />
            </Box>
            <Box>
                <StarRatings
                    rating={5}
                    readOnly={true}
                    showCount={false}
                    size="large"
                    loading={true}
                />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="60%" />
            </Box>
            <Box textAlign="center">
                <Skeleton variant="text" width="80%" />
                <br />
                <Box display="grid" gap={3} gridTemplateColumns={'1fr 1fr'}>
                    <Skeleton variant="circular" width={60} height={60} />
                    <Skeleton variant="circular" width={60} height={60} />
                </Box>
            </Box>
        </Box>
    );
};

export default CustomerReviewsContentDesktopLoading;
