import { gql, useQuery } from '@apollo/client';
import { Box, Grid, Stack, Theme, Typography } from '@mui/material';
import React from 'react';
import ScContainer from '../../../../../Components/Layout/ScContainer/ScContainer';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../../Contexts/DeviceTypeProvider';
import { Item } from '../../../../../types';
import CustomerReviewsWriteReviewButton from '../Components/Buttons/CustomerReviewsWriteReviewButton';
import CustomerReviewsMediaCarousel from '../Components/CustomerReviewsMediaCarousel';
import CustomerReviewsRatingBar from '../Components/CustomerReviewsRatingBar';
import CustomerReviewsSearchBar from '../Components/CustomerReviewsSearchBar';
import CustomerReviewsSortDropdown from '../Components/CustomerReviewsSortDropdown';
import CustomerReviewsHeaderLoading from '../CustomerReviewsHeaderLoading';

const QL_CUST_REVIEWS_SUMMARY = gql`
    query CustomerReviewsSummary($itemId: ID) {
        customerReviewsSummary(itemId: $itemId) {
            overallRating
            counts {
                total
                star1
                star2
                star3
                star4
                star5
            }
            percents {
                recommended
                star1
                star2
                star3
                star4
                star5
            }
        }
    }
`;

const CustomerReviewsHeader = (props: { item: Item }) => {
    const { item } = props;
    const { page, sort, rating, searchTerm } = React.useContext(
        CustomerReviewsContext,
    );
    const { data, loading } = useQuery(QL_CUST_REVIEWS_SUMMARY, {
        variables: {
            itemId: item.id,
            page: page,
            sort: sort,
            rating: rating || 0,
            searchTerm: searchTerm,
        },
        fetchPolicy: 'cache-first',
    });
    if (loading || !data || !data.customerReviewsSummary) {
        return <CustomerReviewsHeaderLoading />;
    }
    const reviewSummary = data.customerReviewsSummary;
    return (
        <>
            {reviewSummary.counts === undefined ||
            reviewSummary.counts.total === 0 ? (
                <></>
            ) : (
                <>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: '<a name="ratings"></a>',
                        }}
                    />
                    {reviewSummary ? (
                        <>
                            <DesktopAndTabletDevice>
                                <Grid
                                    container
                                    alignItems="center"
                                    columnSpacing={3}
                                    sx={{ mb: 3 }}
                                >
                                    <Grid item md={4}>
                                        <Stack>
                                            <CustomerReviewsRatingBar
                                                ratingValue={5}
                                                percent={
                                                    reviewSummary.percents
                                                        ?.star5 || '0%'
                                                }
                                                count={
                                                    reviewSummary.counts
                                                        ?.star5 || 0
                                                }
                                                id="ReviewRatingBar5stars"
                                            />
                                            <CustomerReviewsRatingBar
                                                ratingValue={4}
                                                percent={
                                                    reviewSummary.percents
                                                        ?.star4 || '0%'
                                                }
                                                count={
                                                    reviewSummary.counts
                                                        ?.star4 || 0
                                                }
                                                id="ReviewRatingBar4stars"
                                            />
                                            <CustomerReviewsRatingBar
                                                ratingValue={3}
                                                percent={
                                                    reviewSummary.percents
                                                        ?.star3 || '0%'
                                                }
                                                count={
                                                    reviewSummary.counts
                                                        ?.star3 || 0
                                                }
                                                id="ReviewRatingBar3stars"
                                            />
                                            <CustomerReviewsRatingBar
                                                ratingValue={2}
                                                percent={
                                                    reviewSummary.percents
                                                        ?.star2 || '0%'
                                                }
                                                count={
                                                    reviewSummary.counts
                                                        ?.star2 || 0
                                                }
                                                id="ReviewRatingBar2stars"
                                            />
                                            <CustomerReviewsRatingBar
                                                ratingValue={1}
                                                percent={
                                                    reviewSummary.percents
                                                        ?.star1 || '0%'
                                                }
                                                count={
                                                    reviewSummary.counts
                                                        ?.star1 || 0
                                                }
                                                id="ReviewRatingBar1stars"
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item md={3} textAlign="center">
                                        <Typography
                                            fontSize={48}
                                            variant="bold"
                                            color="secondary"
                                        >
                                            {reviewSummary.overallRating}
                                        </Typography>
                                        <div>
                                            <StarRatings
                                                rating={
                                                    reviewSummary.overallRating ||
                                                    0
                                                }
                                                item={item}
                                                readOnly={true}
                                                showCount={false}
                                                size="large"
                                                sx={{
                                                    justifyContent: 'center',
                                                }}
                                            />
                                        </div>
                                        <Typography>
                                            {reviewSummary.counts?.total}{' '}
                                            reviews
                                        </Typography>
                                    </Grid>
                                    <Grid item md={5}>
                                        <CustomerReviewsMediaCarousel />
                                    </Grid>
                                </Grid>
                            </DesktopAndTabletDevice>
                            <MobileDevice>
                                <CustomerReviewsWriteReviewButton
                                    item={item}
                                    sx={{ display: 'block', width: '100%' }}
                                />
                                <Box
                                    sx={{
                                        py: 2,
                                        borderBottom: (theme: Theme) =>
                                            `1px solid ${theme.palette.divider}`,
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={1}
                                        sx={{
                                            py: 2,
                                            mb: 2,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Grid item xs={6}>
                                            <Box
                                                sx={{
                                                    border: (theme: Theme) =>
                                                        `1px solid ${theme.palette.primary.light}`,
                                                    borderRadius: '50%',
                                                    textAlign: 'center',
                                                    height: '7rem',
                                                    width: '7rem',
                                                    padding: '1rem',
                                                }}
                                            >
                                                <Typography
                                                    fontSize={35}
                                                    variant="bold"
                                                    color="primary"
                                                    component="div"
                                                    sx={{ marginTop: '0.4rem' }}
                                                >
                                                    {
                                                        reviewSummary.overallRating
                                                    }
                                                </Typography>
                                                <Typography
                                                    fontSize={17}
                                                    variant="bold"
                                                    color="primary"
                                                    component="div"
                                                    sx={{
                                                        marginTop: '-0.7rem',
                                                    }}
                                                >
                                                    out of 5
                                                </Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} textAlign="center">
                                            <Typography variant="bold">
                                                {
                                                    reviewSummary.percents
                                                        ?.recommended
                                                }{' '}
                                                recommend this product
                                            </Typography>
                                            <StarRatings
                                                rating={
                                                    reviewSummary.overallRating ||
                                                    0
                                                }
                                                item={item}
                                                readOnly={true}
                                                showCount={false}
                                                size="large"
                                                sx={{
                                                    justifyContent: 'center',
                                                }}
                                            />
                                            <Typography>
                                                {reviewSummary.counts?.total}{' '}
                                                reviews
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <CustomerReviewsMediaCarousel />
                                </Box>
                            </MobileDevice>
                        </>
                    ) : null}
                    <DesktopAndTabletDevice>
                        <ScContainer variant="alt" sx={{ p: 1 }}>
                            <Grid container columnSpacing={4}>
                                <Grid item md={4}>
                                    <CustomerReviewsSearchBar />
                                </Grid>
                                <Grid item md={4}>
                                    <CustomerReviewsSortDropdown />
                                </Grid>
                                <Grid item md={4}>
                                    <Box justifyContent="right" display="flex">
                                        <CustomerReviewsWriteReviewButton
                                            item={item}
                                            sx={{ height: 42 }}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </ScContainer>
                    </DesktopAndTabletDevice>
                </>
            )}
        </>
    );
};

export default CustomerReviewsHeader;
