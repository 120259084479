import { useQuery } from '@apollo/client';
import {
    LearningCenterLinks,
    LearningCenterLinksLoading,
    Section,
} from '../../../Components/_base/BBQGuysComponents';
import { QL_BRAND_LEARNING_CENTER } from '../../../Graphql/queries';
import { Brand } from '../../../types';

const BrandLearningCenter = ({ brand }: { brand: Brand }) => {
    const { data, loading } = useQuery(QL_BRAND_LEARNING_CENTER, {
        variables: {
            brandId: brand.id,
        },
    });

    if (loading) {
        return (
            <Section title={brand.name + ' Learning Center'}>
                <LearningCenterLinksLoading />
            </Section>
        );
    }
    return (
        <>
            {data.brandLearningCenterLinks?.results.slice(0, 6).length > 0 && (
                <Section title={brand.name + ' Learning Center'}>
                    <LearningCenterLinks
                        content={data.brandLearningCenterLinks.results.slice(
                            0,
                            6,
                        )}
                    />
                </Section>
            )}
        </>
    );
};

export default BrandLearningCenter;
