import { gql } from '@apollo/client';
import {
    BANNER_CAMPAIGN_FIELDS,
    BRAND_FIELDS,
    CONTACT_INFO_ORDER_FIELDS,
    CONTENT_FIELDS,
    EXPERT_REVIEW_CLASS_FIELDS,
    FACET_FIELDS,
    ITEM_FIELDS,
    MENU_ITEM_FIELDS,
    PAGE_INFO,
    PAGINATED_BRANDS,
    PAGINATED_BRANDSCOUNT,
    PAGINATED_DEPARTMENTS_COLLECTIONS,
    PAGINATED_DEPARTMENTS_WITH_CHILDREN,
    PAGINATED_EXPERT_REVIEW_ITEMS,
    PAGINATED_EXTENDED_WARRANTIES,
    PAGINATED_ITEMS,
    PAGINATED_LEARNING_CENTER,
    PAGINATED_PROMOTIONS,
    WISHLIST_FIELDS,
} from './fragments';

export const QL_PAGE = gql`
    ${PAGE_INFO}
    query getPage($pageURL: ID, $isError: Boolean) {
        page(url: $pageURL, isError: $isError) {
            ...PageInfo
        }
    }
`;

export const QL_ITEM = gql`
    ${ITEM_FIELDS}
    query getItem($itemId: ID, $pricingTierId: Int) {
        item(itemId: $itemId, pricingTierId: $pricingTierId) {
            ...ItemFields
        }
    }
`;

export const QL_ITEM_WITH_IMAGES = gql`
    ${ITEM_FIELDS}
    query getItem($itemId: ID, $pricingTierId: Int) {
        item(itemId: $itemId, pricingTierId: $pricingTierId) {
            ...ItemFields
        }
        itemImages(itemId: $itemId) {
            url
            description
        }
    }
`;

export const QL_BREADCRUMBS = gql`
    query getBreadcrumbs($type: String!, $typeId: ID!) {
        breadcrumbs(type: $type, typeId: $typeId) {
            title
            url
        }
    }
`;

export const QL_ITEM_SPECIFICATIONS = gql`
    query getItemSpecifications($itemId: ID) {
        itemSpecifications(itemId: $itemId) {
            fieldName
            fieldValue
            contentId
        }
    }
`;
export const QL_ITEM_IMAGES = gql`
    query getItemImages($itemId: ID!) {
        itemImages(itemId: $itemId) {
            url
            description
        }
    }
`;

export const QL_ITEM_360_IMAGES = gql`
    query itemImages360($itemId: ID) {
        itemImages360(itemId: $itemId) {
            setName
            images {
                url
            }
        }
    }
`;

export const QL_ITEM_OPTIONS = gql`
    query getItemOptions($itemId: ID, $pricingTierId: Int) {
        itemOptions(itemId: $itemId, pricingTierId: $pricingTierId) {
            selectBoxName
            selectBoxValueSelected
            label
            helpTipContentId
            options {
                id
                title
                url
                priceDifference
                pricing {
                    type
                    current
                    sale
                }
                showImage
                imageUrl
                pricingText
                pricingType
                itemId
            }
        }
    }
`;

export const QL_ITEM_WARRANTIES = gql`
    ${PAGINATED_EXTENDED_WARRANTIES}
    query getItemWarranties($itemId: ID, $pricingTierId: Int) {
        itemWarranties(itemId: $itemId, pricingTierId: $pricingTierId) {
            ...PaginatedExtendedWarranties
        }
    }
`;
export const QL_ITEM_EXPERT_REVIEW = gql`
    ${EXPERT_REVIEW_CLASS_FIELDS}
    query getItemExpertReview($itemId: ID) {
        expertReviewClassByItem(itemId: $itemId) {
            ...ExpertReviewClassFields
        }
    }
`;
export const QL_ITEM_LEARNING_CENTER = gql`
    query getItemLearningCenterLinks($itemId: ID) {
        itemLearningCenterLinks(itemId: $itemId) {
            links {
                title
                url
            }
            videos {
                title
                url
                image
            }
            documents {
                title
                url
                image
            }
        }
    }
`;

export const QL_ITEM_QTY_UPDATE = gql`
    ${ITEM_FIELDS}
    query getItemQty($itemId: ID, $qty: Int, $pricingTierId: Int) {
        item(itemId: $itemId, qty: $qty, pricingTierId: $pricingTierId) {
            ...ItemFields
        }
    }
`;
//     item(itemId: $itemId) {
//         ...ItemFields
//         pricingCustom {
//             label
//             value
//         }
//         description
//         videoUrl
//         warrantyInfo
//         prop65
//         documents {
//             name
//             url
//             fileType
//         }
//         weight
//     }
//     # itemAccessories(itemId: $itemId) {
//     #     prefixLabel
//     #     helpTipContentId
//     #     options {
//     #         id
//     #         label
//     #         priceDifference
//     #         pricing {
//     #             type
//     #             current
//     #             sale
//     #         }
//     #         pricingText
//     #         pricingType
//     #         showImage
//     #         imageUrl
//     #         includeInCart
//     #         qty
//     #     }
//     # }
// }`;

export const QL_SITE = gql`
    ${MENU_ITEM_FIELDS}
    query getSite {
        site {
            id
            name
            legalName
            domain
            config {
                freeShipping
            }
            locations {
                website {
                    phone
                    hours {
                        primary
                        secondary
                    }
                    address {
                        line1
                        line2
                        city
                        state
                        zip
                        country
                    }
                }
                showrooms {
                    name
                    phone
                    hours {
                        primary
                        secondary
                    }
                    address {
                        line1
                        line2
                        city
                        state
                        zip
                        country
                    }
                }
            }
        }
        siteHeaderPromotions {
            totalResults
            results {
                id
                text
                url
                textColor
                backgroundColor
            }
        }
        menus {
            id
            name
            menuItems {
                ...MenuItemFields
                children {
                    ...MenuItemFields
                    children {
                        ...MenuItemFields
                        children {
                            ...MenuItemFields
                        }
                    }
                }
            }
        }
    }
`;

export const QL_CONTENT_BY_ID = gql`
    ${CONTENT_FIELDS}
    query getContentById($id: ID) {
        content(id: $id) {
            ...ContentFields
        }
    }
`;

export const QL_CONTENT_WITH_BREADCRUMBS = gql`
    ${CONTENT_FIELDS}
    query getContentWithBreadcrumbs($contentId: ID) {
        breadcrumbs(type: "content", typeId: $contentId) {
            title
            url
        }
        content(id: $contentId) {
            ...ContentFields
        }
    }
`;

export const QL_ITEMS_BY_IDS = gql`
    ${PAGINATED_ITEMS}
    query getItemsByIds($itemIds: ID, $pricingTierId: Int) {
        itemsByIds(itemIds: $itemIds, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

export const QL_DEPT = gql`
    query getDepartmentInfo($departmentId: ID) {
        department(id: $departmentId) {
            id
            title
            h1Title
            description
            customerDescription
            useSearchResults
        }
    }
`;

export const QL_DEPT_CONTENT = gql`
    ${CONTENT_FIELDS}
    query getDepartmentContent($departmentId: ID) {
        departmentContent(id: $departmentId) {
            position
            content {
                ...ContentFields
            }
        }
    }
`;

export const QL_DEPT_CHILDREN = gql`
    ${PAGINATED_DEPARTMENTS_WITH_CHILDREN}
    query getDepartmentChildren($departmentId: ID, $depth: Int) {
        departmentChildren(id: $departmentId, depth: $depth) {
            ...PaginatedDepartmentsWithChildren
        }
    }
`;

export const QL_DEPT_BEST_SELLERS = gql`
    ${PAGINATED_ITEMS}
    query getDepartmentBestSellers($departmentId: ID, $pricingTierId: Int) {
        departmentBestSellingItems(
            deptId: $departmentId
            pricingTierId: $pricingTierId
        ) {
            ...PaginatedItems
        }
    }
`;

export const QL_SEARCH = gql`
    ${PAGINATED_ITEMS}
    query getSearchItems(
        $term: ID
        $page: Int
        $refinements: String
        $sort: String
        $pricingTierId: Int
        $deptId: String
    ) {
        searchItems(
            term: $term
            page: $page
            sort: $sort
            refinements: $refinements
            pricingTierId: $pricingTierId
            deptId: $deptId
        ) {
            ...PaginatedItems
        }
        searchFacets(term: $term, refinements: $refinements, deptId: $deptId) {
            name
            helpContentId
            attributes {
                label
                labelId
                checked
                selectable
                evar
                url
                parameter
            }
        }
        searchSortOptions(type: "search") {
            label
            value
        }
    }
`;

export const QL_BANNERS = gql`
    ${BANNER_CAMPAIGN_FIELDS}
    query getBannerCampaigns($url: ID, $query: String) {
        bannerCampaigns(url: $url, query: $query) {
            ...BannerCampaignFields
        }
    }
`;

/** DEPARTMENT HUBS */

export const QL_DEPT_COLLECTIONS = gql`
    ${PAGINATED_DEPARTMENTS_COLLECTIONS}
    query getDepartmentCollections($departmentId: ID) {
        departmentCollections(id: $departmentId) {
            ...PaginatedDepartmentCollections
        }
    }
`;
export const QL_DEPT_BRANDS = gql`
    ${PAGINATED_BRANDS}
    query getDepartmentBrands($departmentId: ID) {
        departmentBrands(id: $departmentId) {
            ...PaginatedBrands
        }
    }
`;
export const QL_DEPT_LEARNING_CENTER = gql`
    ${PAGINATED_LEARNING_CENTER}
    query getDepartmentLearningCenter($departmentId: ID) {
        departmentLearningCenterLinks(id: $departmentId) {
            ...PaginatedLearningCenter
        }
    }
`;

/** FACETS */

export const QL_FACETS_SEARCH = gql`
    ${FACET_FIELDS}
    query getSearchFacets($term: ID, $refinements: String, $deptId: String) {
        searchFacets(term: $term, refinements: $refinements, deptId: $deptId) {
            ...FacetFields
        }
    }
`;
export const QL_FACETS_DEPT = gql`
    ${FACET_FIELDS}
    query getDepartmentFacets($departmentId: ID, $refinements: String) {
        departmentFacets(deptId: $departmentId, refinements: $refinements) {
            ...FacetFields
        }
    }
`;
export const QL_FACETS_DEALS = gql`
    ${FACET_FIELDS}
    query getWarehouseDealsFacets($refinements: String) {
        warehouseDealFacets(refinements: $refinements) {
            ...FacetFields
        }
    }
`;
/** WAREHOUSE DEALS */
export const QL_WAREHOUSE_DEALS = gql`
    ${PAGINATED_ITEMS}
    query getWarehouseDealItems(
        $page: Int
        $refinements: String
        $sort: String
    ) {
        warehouseDealItems(
            page: $page
            sort: $sort
            refinements: $refinements
        ) {
            ...PaginatedItems
        }
        searchSortOptions(type: "deal") {
            label
            value
        }
    }
`;

/** BRAND PAGE */

export const QL_BRANDS = gql`
    ${BRAND_FIELDS}
    query getBrands {
        brandBestSellers {
            results {
                ...BrandFields
            }
        }
    }
`;

export const QL_BRAND = gql`
    ${BRAND_FIELDS}
    query getBrand($brandId: ID) {
        brand(id: $brandId) {
            ...BrandFields
        }
    }
`;

export const QL_BRANDS_ALL = gql`
    ${PAGINATED_BRANDS}
    query getBrandsAll {
        brands {
            ...PaginatedBrands
        }
    }
`;

export const QL_BRANDS_COUNT = gql`
    ${PAGINATED_BRANDSCOUNT}
    query getBrandsCount {
        brandsCount {
            ...PaginatedBrandsCount
        }
    }
`;

export const QL_BRAND_DEPARTMENTS = gql`
    ${PAGINATED_DEPARTMENTS_WITH_CHILDREN}
    query getBrandDepartments($brandId: ID, $depth: Int) {
        brandDepartments(brandId: $brandId, depth: $depth) {
            ...PaginatedDepartmentsWithChildren
        }
    }
`;

export const QL_BRAND_BEST_SELLING_ITEMS = gql`
    ${PAGINATED_ITEMS}
    query getBrandBestSellingItems($brandId: ID, $pricingTierId: Int) {
        brandBestSellingItems(
            brandId: $brandId
            pricingTierId: $pricingTierId
        ) {
            ...PaginatedItems
        }
    }
`;

export const QL_BRAND_PROMOTIONS = gql`
    ${PAGINATED_PROMOTIONS}
    query getBrandPromotions($brandId: ID, $pricingTierId: Int) {
        brandPromotions(brandId: $brandId, pricingTierId: $pricingTierId) {
            ...PaginatedPromotions
        }
    }
`;

export const QL_BRAND_LEARNING_CENTER = gql`
    ${PAGINATED_LEARNING_CENTER}
    query getBrandLearningCenter($brandId: ID) {
        brandLearningCenterLinks(brandId: $brandId) {
            ...PaginatedLearningCenter
        }
    }
`;
export const QL_BRAND_EXPERT_REVIEWS = gql`
    ${PAGINATED_EXPERT_REVIEW_ITEMS}
    query getBrandExpertReviews($brandId: ID) {
        brandExpertReviews(brandId: $brandId) {
            ...PaginatedExpertReviewItems
        }
    }
`;

/** OPEN BOX */

export const QL_ITEM_OPEN_BOX = gql`
    query getItemOpenBox($itemId: ID) {
        openBoxItems(itemId: $itemId) {
            countResults
            totalResults
            results {
                id
                name
                condition
                description
                image
                listPrice
                price
                available
                isInCart
                isCallForPricing
                inStock
                qtyMax
                shipsIn
                images {
                    url
                }
            }
        }
    }
`;

export const QL_USER_SECURITY_INFO = gql`
    query UserSecurityQuestionAnswer {
        userSecurityQuestion {
            questionId
            answer
        }
    }
`;

export const QL_USER_WISHLISTS = gql`
    ${WISHLIST_FIELDS}
    query getWishlists($page: Int) {
        userWishlists(page: $page) {
            totalResults
            results {
                id
                wishlist {
                    ...WishlistFields
                }
            }
        }
    }
`;

export const QL_USER_WISHLIST = gql`
    ${WISHLIST_FIELDS}
    query getWishlist($id: ID!) {
        wishlist(id: $id) {
            ...WishlistFields
        }
    }
`;

export const QL_USER_WISHLIST_CREATE = gql`
    ${WISHLIST_FIELDS}
    mutation createUserWishlist($title: String!, $items: [WishlistItemProps]!) {
        createWishlist(title: $title, items: $items) {
            ...WishlistFields
        }
    }
`;

export const QL_USER_WISHLIST_UPDATE = gql`
    ${WISHLIST_FIELDS}
    mutation updateUserWishlist(
        $wishlistId: ID!
        $items: [WishlistItemProps]!
    ) {
        updateWishlist(id: $wishlistId, items: $items) {
            ...WishlistFields
        }
    }
`;

export const QL_USER_WISHLIST_EDIT = gql`
    ${WISHLIST_FIELDS}
    mutation editUserWishlist(
        $wishlistId: ID!
        $title: String
        $isPublic: Boolean
    ) {
        editWishlist(id: $wishlistId, title: $title, isPublic: $isPublic) {
            ...WishlistFields
        }
    }
`;

export const QL_USER_WISHLIST_DELETE = gql`
    mutation deleteUserWishlist($id: ID!) {
        deleteWishlist(id: $id) {
            success
            message
        }
    }
`;

export const QL_USER_WISHLIST_ITEM_DELETE = gql`
    ${WISHLIST_FIELDS}
    mutation deleteUserWishlistItem($itemId: ID!, $wishlistId: ID!) {
        removeWishlistItem(itemId: $itemId, wishlistId: $wishlistId) {
            ...WishlistFields
        }
    }
`;

export const QL_ORDERS = gql`
    ${CONTACT_INFO_ORDER_FIELDS}
    query getUserOrders($page: Int) {
        userOrders(page: $page) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            results {
                ...ContactInfoOrderFields
            }
        }
    }
`;

export const QL_PROMOTIONS = gql`
    query SitePromotions($siteId: ID, $page: Int, $pricingTierId: Int) {
        sitePromotions(
            siteId: $siteId
            page: $page
            pricingTierId: $pricingTierId
        ) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            offset
            results {
                id
                title
                description
                type
                dateStart
                dateEnd
                displayText
                displayTextColor
                showCountdown
                imageUrl
                url
                tags
                categories
            }
        }
        sitePromotionCategories(siteId: $siteId) {
            title
            categories {
                title
                links {
                    title
                    link
                    tags
                }
            }
        }
    }
`;

export const QL_REWARD_POINTS_HISTORY = gql`
    query getRewardPointsHistory($page: Int) {
        userRewardPoints(page: $page) {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            results {
                id
                title
                timestamp
                pointsChanged
                orderId
            }
        }
    }
`;
