import { useLocation } from 'react-router-dom';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { CustomerReviewsProvider } from '../../../../Contexts/CustomerReviewsProvider';
import { Item } from '../../../../types';
import CustomerReviewsGrid from './Layouts/CustomerReviewsGrid';
import CustomerReviewsHeader from './Layouts/CustomerReviewsHeader';

export default function CustomerReviews(props: { item: Item }) {
    const { item } = props;
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchPageParam = parseInt(searchParams.get('page') as string) || 0;
    const searchSortParam = searchParams.get('sort') || 'age_desc';
    const page2 = searchPageParam > 0 ? searchPageParam : 1;

    return (
        <>
            <CustomerReviewsProvider
                page={page2}
                sort={searchSortParam}
                item={item}
            >
                <Section title="Customer Reviews" anchor="reviews">
                    <CustomerReviewsHeader item={item} />
                    <CustomerReviewsGrid />
                </Section>
            </CustomerReviewsProvider>
        </>
    );
}
