import { UpdatePasswordForm } from './UpdatePasswordForm';
import ScContainer from '../../../Components/Layout/ScContainer/ScContainer';
import { Helmet } from 'react-helmet-async';

export default function PageUpdatePassword() {
    return (
        <>
            <Helmet>
                <title>Update Password</title>
            </Helmet>
            <ScContainer size="small">
                <UpdatePasswordForm />
            </ScContainer>
        </>
    );
}
