import { Link } from '@mui/material';
import { BulletedList } from '../../../../Components/_base/BBQGuysComponents';
import { LearningCenterItem, Maybe } from '../../../../types';

const LearningCenterLinks = (props: { links: Maybe<LearningCenterItem>[] }) => {
    if (props.links && props.links.length > 0) {
        const links: any[] = [];

        props.links.forEach((link: Maybe<LearningCenterItem>, i: number) => {
            if (link && link.url) {
                links.push(
                    <Link key={'link-' + link.url} href={link.url}>
                        {link.title}
                    </Link>,
                );
            }
        });
        return <BulletedList bullets={links} />;
    } else {
        return <div></div>;
    }
};

export default LearningCenterLinks;
