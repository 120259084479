import { SxProps, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FormikValues } from 'formik';
import React, { MouseEvent, useState } from 'react';
import { FieldWithLabel } from '../../../Cart/PageCheckout/Forms/Components/FieldWithLabel';

export const PasswordWithLabel = (props: {
    id?: string;
    name?: string;
    formik: FormikValues;
    label?: string | React.ReactNode;
    type?: string;
    autoComplete?: string;
    sx?: SxProps;
    inputmaskprops?: any;
    textFieldProps?: any;
    current?: boolean;
    create?: boolean;
    confirm?: boolean;
    ariaLabel?: string;
    helperText?: string;
    isSubmitting?: boolean;
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const { formik } = props;
    const handleClickShowPassword = () => {
        setShowPassword(show => !show);
    };
    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    React.useEffect(() => {
        if (formik.isSubmitting || formik.submitCount > 0) {
            setShowPassword(false);
        }
    }, [formik.isSubmitting, formik.submitCount]);

    const { current, create, confirm, sx, helperText } = props;
    let {
        name = '',
        label = '',
        id = '',
        ariaLabel = '',
        autoComplete = 'new-password',
    } = props;

    if (current) {
        name = name === '' ? 'currentPassword' : name;
        id = id === '' ? 'currentPassword' : id;
        label = label === '' ? 'Current Password' : label;
        ariaLabel =
            ariaLabel === ''
                ? 'show or hide button for current password'
                : ariaLabel;
        autoComplete = 'current-password';
    }
    if (create) {
        name = name === '' ? 'createNewPassword' : name;
        id = id === '' ? 'createNewPassword' : id;
        label = label === '' ? 'Create New Password' : label;
        ariaLabel =
            ariaLabel === ''
                ? 'show or hide button for create new password'
                : ariaLabel;
        autoComplete = 'new-password';
    }
    if (confirm) {
        name = name === '' ? 'confirmNewPassword' : name;
        id = id === '' ? 'confirmNewPassword' : id;
        label = label === '' ? 'Confirm New Password' : label;
        ariaLabel =
            ariaLabel === ''
                ? 'show or hide button for confirm new password'
                : ariaLabel;
        autoComplete = 'new-password';
    }

    return (
        <FieldWithLabel
            id={id}
            name={name}
            formik={formik}
            label={
                <>
                    {label}
                    {helperText && (
                        <Typography
                            variant="caption"
                            component="span"
                            sx={{
                                color: 'text.secondary',
                                ml: 1,
                                fontStyle: 'italic !important',
                            }}
                        >
                            {helperText}
                        </Typography>
                    )}
                </>
            }
            sx={sx}
            autoComplete={autoComplete}
            aria-label={ariaLabel}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label={ariaLabel}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            <Typography
                                variant="bold"
                                fontSize={12}
                                component="span"
                            >
                                {showPassword ? 'HIDE' : 'SHOW'}
                            </Typography>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};
