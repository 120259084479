import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import StarRatings from '../../../../../Components/StarRatings/StarRatings';
import { CustomerReviewsContext } from '../../../../../Contexts/CustomerReviewsProvider';
import { date, highlightString } from '../../../../../functions';
import { CustomerReview, Item } from '../../../../../types';
import CustomerReviewsHelpfulButton from '../Components/Buttons/CustomerReviewsHelpfulButton';
import CustomerReviewsUnhelpfulButton from '../Components/Buttons/CustomerReviewsUnhelpfulButton';
import CustomerReviewsSyndicationLink from '../Components/CustomerReviewsSyndicationLink';
import CustomerReviewsTotalFeedback from '../Components/CustomerReviewsTotalFeedback';
import CustomerReviewsVerifiedPurchaser from '../Components/CustomerReviewsVerifiedPurchaser';
import CustomerReviewsMediaInlineImages from '../Components/CustomReviewsMediaInlineImages';

const CustomerReviewsContentDesktop = (props: {
    review: CustomerReview;
    item: Item;
}) => {
    const { review, item } = props;
    const { searchTerm } = React.useContext(CustomerReviewsContext);

    return (
        <Grid container spacing={3} sx={{ py: 4 }}>
            <Grid item xs={2}>
                <Typography variant="bold">{review.name}</Typography>
                <Typography variant="muted" component="p">
                    {review.location !== 'Undisclosed' && review.location}
                </Typography>
                <Typography variant="muted" component="p">
                    {date.format(new Date(review.datetime))}
                </Typography>
                <CustomerReviewsVerifiedPurchaser review={review} />
                <CustomerReviewsSyndicationLink review={review} />
            </Grid>
            <Grid item xs={8}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'left',
                        flexDirection: 'column',
                        gap: 1,
                    }}
                >
                    <StarRatings
                        rating={review.rating || 0}
                        item={item}
                        readOnly={true}
                        showCount={false}
                        size="medium"
                    />
                    <Typography
                        variant="bold"
                        dangerouslySetInnerHTML={{
                            __html: highlightString(review.title, searchTerm),
                        }}
                    />
                </Box>
                <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                        __html: highlightString(review.text, searchTerm),
                    }}
                />
                {review && review.media && review.media.length > 0 && (
                    <CustomerReviewsMediaInlineImages media={review.media} />
                )}
                <CustomerReviewsTotalFeedback review={review} />
            </Grid>
            <Grid item xs={2} textAlign="center">
                <Typography variant="bold">Was This Helpful?</Typography>
                <Grid container spacing={2}>
                    <Grid item sm={6} textAlign="right">
                        <CustomerReviewsHelpfulButton review={review} />
                    </Grid>
                    <Grid item sm={6} textAlign="left">
                        <CustomerReviewsUnhelpfulButton review={review} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CustomerReviewsContentDesktop;
