import { useQuery } from '@apollo/client';
import { ChevronRightSharp } from '@mui/icons-material';
import {
    Box,
    Breadcrumbs as MaterialBreadcrumbs,
    Skeleton,
} from '@mui/material';
import { SiteContext } from '../../Contexts/SiteProvider';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { isMobile } from '../../Contexts/DeviceTypeProvider';
import { QL_BREADCRUMBS } from '../../Graphql/queries';
import { Breadcrumb } from '../../types';
import { BreadcrumbLink } from '../_base/BBQGuysStyledComponents';

const getSchemaOrgInfo = (breadcrumbs: Breadcrumb[]) => {
    const { site } = React.useContext(SiteContext);
    const listItems: any[] = [];
    if (breadcrumbs.length > 0) {
        for (let i = 0; i < breadcrumbs.length; i++) {
            const b = breadcrumbs[i];
            // ignore item page last breadcrumb (Item #XXXXX)
            if (b.title && b.title.indexOf(' #') === -1) {
                listItems.push({
                    '@type': 'ListItem',
                    position: i + 1,
                    name: b.title,
                    item: {
                        '@type': 'Thing',
                        '@id': 'https://' + site.domain + b.url,
                    },
                });
            }
        }
    }

    return JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: listItems,
    });
};

function NoBreadcrumbs() {
    return <Box sx={{ minHeight: '1.5rem', my: 1 }}></Box>;
}
function Breadcrumbs({
    type,
    schemaBreadcrumbs = [],
}: {
    type?: string;
    schemaBreadcrumbs?: Breadcrumb[];
}) {
    const targetRef = React.useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 });
    let breadcrumbs: Breadcrumb[];

    if (schemaBreadcrumbs.length > 0 && type !== 'search') {
        if (schemaBreadcrumbs[0].title !== 'Home') {
            schemaBreadcrumbs = [
                {
                    title: 'Home',
                    url: '/',
                },
            ].concat(schemaBreadcrumbs);
        }
    }
    /* last breadcrumb in Category Hub/department is included in schema ONLY */
    if (schemaBreadcrumbs.length > 0 && type === 'department') {
        breadcrumbs = schemaBreadcrumbs.slice(0, -1);
    } else {
        breadcrumbs = [...schemaBreadcrumbs];
    }

    React.useEffect(() => {
        if (isMobile && targetRef.current && dimensions.width === 0) {
            setDimensions({
                width: targetRef.current.offsetWidth || 0,
                height: targetRef.current.offsetHeight || 0,
            });
        }
    }, []);
    React.useEffect(() => {
        if (
            dimensions.width > 0 &&
            isMobile &&
            targetRef.current?.querySelector('ol')
        ) {
            const ol = targetRef.current.querySelector('ol');
            if (ol) {
                ol.scrollLeft = ol.scrollWidth || 0;
            }
        }
    }, [dimensions]);

    if (breadcrumbs === undefined) {
        return <NoBreadcrumbs />;
    }
    return breadcrumbs.length < 1 ? (
        <NoBreadcrumbs />
    ) : (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {getSchemaOrgInfo(schemaBreadcrumbs)}
                </script>
            </Helmet>
            <MaterialBreadcrumbs
                ref={targetRef}
                component="nav"
                sx={{ '& ol': { minHeight: '1.5rem' } }}
                separator={
                    <ChevronRightSharp color="secondary" sx={{ height: 20 }} />
                }
                aria-label="breadcrumb"
            >
                {breadcrumbs.map((crumb: Breadcrumb, i: number) => {
                    return (
                        <BreadcrumbLink
                            key={i}
                            href={crumb.url}
                            variant="body2"
                        >
                            {crumb.title}
                        </BreadcrumbLink>
                    );
                })}
            </MaterialBreadcrumbs>
        </>
    );
}

export default function PageBreadcrumbs({
    type,
    typeId,
    breadcrumbs = [],
}: {
    type?: string;
    typeId?: string;
    breadcrumbs?: Breadcrumb[];
}) {
    if (
        breadcrumbs.length === 0 &&
        type !== undefined &&
        typeId !== undefined
    ) {
        const { data, loading } = useQuery(QL_BREADCRUMBS, {
            variables: {
                type: type,
                typeId: typeId,
            },
        });
        if (loading) {
            return <PageBreadcrumbsLoading />;
        }
        breadcrumbs = data.breadcrumbs;
    }
    return <Breadcrumbs type={type} schemaBreadcrumbs={breadcrumbs} />;
}

export function PageBreadcrumbsLoading({ count = 4 }: { count?: number }) {
    return (
        <MaterialBreadcrumbs
            separator={<ChevronRightSharp />}
            aria-label="breadcrumb"
            style={{ marginTop: 6, marginBottom: 6 }}
        >
            {[...Array(count)].map((z: unknown, i: number) => {
                return <Skeleton key={i} variant="text" width="120px" />;
            })}
        </MaterialBreadcrumbs>
    );
}
