import { useQuery } from '@apollo/client';
import {
    LearningCenterLinks,
    Section,
} from '../../../Components/_base/BBQGuysComponents';
import { QL_DEPT_LEARNING_CENTER } from '../../../Graphql/queries';

const DepartmentLearningCenter = ({ deptId }: { deptId: string }) => {
    const { data, loading } = useQuery(QL_DEPT_LEARNING_CENTER, {
        variables: {
            departmentId: deptId,
        },
    });

    if (loading) {
        return <></>;
    }

    return (
        <>
            {data && data.departmentLearningCenterLinks?.countResults > 0 ? (
                <Section
                    title={'Learning Center'}
                    alignTitle="center"
                    id="hubpage-content"
                >
                    <LearningCenterLinks
                        content={data.departmentLearningCenterLinks.results.slice(
                            0,
                            6,
                        )}
                    />
                </Section>
            ) : null}
        </>
    );
};

export default DepartmentLearningCenter;
