import * as React from 'react';

export const SSRContext = React.createContext({
    userAgent: '',
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    setUserAgent: (val: string) => {
        return;
    },
    isMobileDevice: (): boolean => {
        return false;
    },
    isTabletDevice: (): boolean => {
        return false;
    },
    isDesktopDevice: (): boolean => {
        return false;
    },
    isClientSide: (): boolean => {
        return false;
    },
    isServerSide: (): boolean => {
        return false;
    },
});

export const SSRProvider = React.memo(function SSRProvider(props: {
    userAgent?: string;
    isMobile: boolean;
    isDesktop: boolean;
    isTablet: boolean;
    isServerSideRendered: boolean;
    children: any;
}) {
    const { isServerSideRendered = false } = props;
    const [userAgent, setUserAgentValue] = React.useState(
        props.userAgent ?? 'bbq-react',
    );
    const [isMobile] = React.useState(props.isMobile);
    const [isTablet] = React.useState(props.isTablet);
    const [isDesktop] = React.useState(props.isDesktop);

    const setUserAgent = (val: string) => {
        setUserAgentValue(val);
    };
    const isMobileDevice = () => {
        return isMobile;
    };
    const isTabletDevice = () => {
        return isTablet;
    };
    const isDesktopDevice = () => {
        return isDesktop;
    };

    const isClientSide = () => !isServerSideRendered;
    const isServerSide = () => isServerSideRendered;

    return (
        <SSRContext.Provider
            value={{
                userAgent,
                isMobile,
                isTablet,
                isDesktop,
                setUserAgent,
                isMobileDevice,
                isTabletDevice,
                isDesktopDevice,
                isClientSide,
                isServerSide,
            }}
        >
            {props.children}
        </SSRContext.Provider>
    );
});

export const ClientSideRender = ({ children }: any) => {
    const { isClientSide } = React.useContext(SSRContext);
    return isClientSide() ? <>{children}</> : <></>;
};
