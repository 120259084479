import * as React from 'react';
import {
    Item,
    Maybe,
    PaginatedExtendedWarrantyResponse,
    PaginatedItemsResponse,
} from '../types';

const emptyPaginatedItemsResponse = {
    countResults: 0,
    currentPage: 1,
    nextPage: null,
    offset: 0,
    prevPage: null,
    results: [],
    totalPages: 1,
    totalResults: 0,
} as PaginatedItemsResponse;

export const TrapModalContext = React.createContext({
    items: emptyPaginatedItemsResponse as PaginatedItemsResponse,
    relatedItems: emptyPaginatedItemsResponse as PaginatedItemsResponse,
    warranties:
        emptyPaginatedItemsResponse as PaginatedExtendedWarrantyResponse,
    open: false,
    trapNetworkError: '',
    dataLoaded: false,
    qty: 1,
    setQty: (val: number) => {
        return;
    },
    setItems: (val: Maybe<Item>[]) => {
        return;
    },
    setRelatedItems: (val: Maybe<Item>[]) => {
        return;
    },
    setWarranties: (val: PaginatedExtendedWarrantyResponse) => {
        return;
    },
    setOpen: (val: boolean) => {
        return;
    },
    setTrapNetworkError: (val: string) => {
        return;
    },
    hasNetworkError: (): boolean => {
        return false;
    },
    resetTrapModal: () => {
        return;
    },
    setDataLoaded: (val: boolean) => {
        return;
    },
});

export const TrapModalProvider = React.memo(function TrapModalProvider(props: {
    children: any;
}) {
    const [items, setItemsValue] = React.useState(emptyPaginatedItemsResponse);
    const [relatedItems, setRelatedItemsValue] = React.useState(
        emptyPaginatedItemsResponse,
    );
    const [warranties, setWarantiesValue] = React.useState(
        emptyPaginatedItemsResponse as PaginatedExtendedWarrantyResponse,
    );
    const [open, setOpenValue] = React.useState(false);
    const [trapNetworkError, setTrapNetworkError] = React.useState('');
    const [dataLoaded, setDataLoadedValue] = React.useState(false);
    const [qty, setQty] = React.useState(1);

    const setItems = (val: Maybe<Item>[]) => {
        const paginatedResponse = { ...emptyPaginatedItemsResponse };
        paginatedResponse.results = val;
        paginatedResponse.countResults = val.length;
        paginatedResponse.totalResults = val.length;
        setItemsValue(paginatedResponse);
    };
    const setRelatedItems = (val: Maybe<Item>[]) => {
        const paginatedResponse = { ...emptyPaginatedItemsResponse };
        paginatedResponse.results = val;
        paginatedResponse.countResults = val.length;
        paginatedResponse.totalResults = val.length;
        setRelatedItemsValue(paginatedResponse);
    };
    const setWarranties = (val: PaginatedExtendedWarrantyResponse) => {
        setWarantiesValue(val);
    };
    const setOpen = (val: boolean) => {
        setOpenValue(val);
    };
    const setDataLoaded = (val: boolean) => {
        setDataLoadedValue(val);
    };
    const hasNetworkError = (): boolean => {
        return trapNetworkError.length > 0;
    };
    const resetTrapModal = () => {
        setItems([]);
        setRelatedItems([]);
        setWarranties(
            emptyPaginatedItemsResponse as PaginatedExtendedWarrantyResponse,
        );
        setDataLoadedValue(false);
    };
    return (
        <TrapModalContext.Provider
            value={{
                items,
                relatedItems,
                warranties,
                open,
                trapNetworkError,
                dataLoaded,
                qty,
                setQty,
                setItems,
                setRelatedItems,
                setWarranties,
                setOpen,
                setTrapNetworkError,
                hasNetworkError,
                resetTrapModal,
                setDataLoaded,
            }}
        >
            {props.children}
        </TrapModalContext.Provider>
    );
});
