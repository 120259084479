import { Box, Grid, Typography } from '@mui/material';
import {
    AskAnExpert,
    BulletedList,
    Section,
} from '../../../../Components/_base/BBQGuysComponents';
import { Item } from '../../../../types';

const KeyFeatures = (props: { item: Item }) => {
    const { item } = props;
    const { bulletPoints } = item;
    let firstBullet = '';
    const remainingBullets = [];
    if (bulletPoints && bulletPoints[0]) {
        firstBullet = bulletPoints[0];
        for (let i = 1; i < bulletPoints.length; i++) {
            if (bulletPoints[i] && typeof bulletPoints[i] === 'string') {
                remainingBullets.push(bulletPoints[i]);
            }
        }
    }
    return (
        <>
            {(item.description || firstBullet) && (
                <>
                    <Typography variant="sectionTitle" component="h2">
                        Key Features
                    </Typography>
                    {firstBullet && (
                        <Box>
                            <Typography variant="keyFeatureBullet">
                                {firstBullet}
                            </Typography>
                            <BulletedList bullets={remainingBullets} />
                        </Box>
                    )}
                    {item.description && (
                        <Typography variant="body1" component="div">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: item.description,
                                }}
                            />
                        </Typography>
                    )}
                </>
            )}
        </>
    );
};
const ItemDescription = (props: { item: Item }) => {
    const { item } = props;
    return (
        <Section expandable>
            <Grid container columnSpacing={12}>
                <Grid item xs={12} md={8}>
                    <KeyFeatures item={item} />
                </Grid>
                <Grid item xs={12} md={4} mt={8}>
                    <AskAnExpert />
                </Grid>
            </Grid>
        </Section>
    );
};

export default ItemDescription;
