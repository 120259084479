import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Breakpoint,
    DialogProps,
    Drawer,
    DrawerProps,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled, SxProps } from '@mui/material/styles';
import * as React from 'react';
import {
    DesktopAndTabletDevice,
    isMobile,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { LoadingSpinner } from './BBQGuysComponents';

const BBQGuysModal = styled(Dialog)(({ theme }) => ({}));

export const CloseIconButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(0.25),
    top: theme.spacing(0.125),
    zIndex: theme.zIndex.modal,
    width: 36,
    height: 36,
    padding: theme.spacing(2),
    fontSize: 'medium',
    color: '#63676d',
    '&:hover': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(204, 77, 2, 0.04)',
    },
    '&:focus': {
        color: theme.palette.primary.main,
        backgroundColor: 'rgba(204, 77, 2, 0.3)',
    },
}));

const ModalCloseIcon = (props: { onClose: () => void }) => {
    return (
        <CloseIconButton
            size="medium"
            aria-label="close"
            onClick={() => props.onClose()}
        >
            <CloseIcon />
        </CloseIconButton>
    );
};

export interface DialogTitleProps {
    children?: React.ReactNode;
}

export const BBQGuysModalTitle = (props: DialogTitleProps) => {
    const { children, ...other } = props;

    return children ? (
        <DialogTitle {...other}>
            <Box>{children}</Box>
        </DialogTitle>
    ) : (
        <></>
    );
};

export interface ModalProps extends DialogProps {
    children?: React.ReactNode;
    title?: string | undefined;
    size?: Breakpoint | false;
    loading?: boolean;
    handleClose?: (() => void) | false;
    showCloseButton?: boolean;
    expandFullscreen?: boolean;
    showScrollY?: boolean;
    customHeight?: string;
}

export default function Modal(props: ModalProps) {
    const {
        children,
        open = false,
        size = 'lg',
        handleClose = () => {},
        loading = false,
        title = null,
        showCloseButton = true,
        expandFullscreen = false,
        showScrollY = false,
        customHeight = '30vh',
        sx = {},
        ...rest
    } = props;
    const hasTitle = typeof title == 'string' && title.length > 0;
    const renderModalContent = () => {
        return (
            <>
                {hasTitle && <BBQGuysModalTitle>{title}</BBQGuysModalTitle>}
                {children}
                {loading && <LoadingSpinner center />}
                {showCloseButton && typeof handleClose === 'function' && (
                    <ModalCloseIcon onClose={handleClose} />
                )}
            </>
        );
    };

    const sxMobileOptions: any = {
        p: hasTitle ? 0 : 'inherit',
        minHeight: expandFullscreen ? '100vh' : customHeight ?? '30vh',
    };

    const sxAddedToCart: any = {
        sm: '100%',
        md: hasTitle && title === 'Added to Cart' ? '100%' : 'inherit',
    };

    if (expandFullscreen) {
        sxMobileOptions.top = 0;
    }

    return (
        <>
            <MobileDevice>
                <BBQGuysBottomDrawer
                    aria-labelledby="modal-title"
                    open={open}
                    onClose={
                        typeof handleClose === 'function'
                            ? handleClose
                            : () => {}
                    }
                    sx={sxMobileOptions}
                    variant="temporary"
                >
                    {renderModalContent()}
                </BBQGuysBottomDrawer>
            </MobileDevice>
            <DesktopAndTabletDevice>
                <BBQGuysModal
                    onClose={
                        typeof handleClose === 'function'
                            ? handleClose
                            : () => {}
                    }
                    aria-labelledby="modal-title"
                    open={open}
                    maxWidth={isMobile ? 'xl' : size}
                    fullWidth={isMobile}
                    PaperProps={{
                        sx: {
                            margin: { xs: 0, md: 'inherit' },
                            width: sxAddedToCart,
                            px: { md: 'inherit' },
                            maxHeight: { md: '95vh' },
                            overflowY: showScrollY ? 'auto' : 'hidden',
                            ...sx,
                        },
                    }}
                    {...rest}
                >
                    {renderModalContent()}
                </BBQGuysModal>
            </DesktopAndTabletDevice>
        </>
    );
}

interface BBQGuysDrawerProps extends DrawerProps {
    open: boolean;
    onClose: () => void;
    sx?: SxProps;
    children: any;
}

export const BBQGuysBottomDrawer = (props: BBQGuysDrawerProps) => {
    const {
        sx = {},
        open,
        children,
        onClose,
        anchor = 'bottom',
        variant = 'temporary',
        ...rest
    } = props;
    return (
        <Drawer
            open={open}
            anchor={anchor}
            onClose={onClose}
            variant={variant}
            PaperProps={{
                sx: {
                    p: 3,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                    maxHeight: { xs: '90vh', md: '62vh' },
                    ...sx,
                },
            }}
            {...rest}
        >
            {children}
        </Drawer>
    );
};
