import { useQuery } from '@apollo/client';
import { Skeleton } from '@mui/material';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router-dom';
import PageBreadcrumbs, {
    PageBreadcrumbsLoading,
} from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { ProductFacetsLoading } from '../../Components/ProductGridItems/ProductFacets';
import { ProductGridPageTitle } from '../../Components/_base/BBQGuysComponents';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import { CompareDrawerProvider } from '../../Contexts/CompareDrawerProvider';
import {
    ProductGridRefinement,
    ProductGridRefinementProvider,
} from '../../Contexts/ProductGridRefinementProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { QL_DEPT, QL_DEPT_CONTENT } from '../../Graphql/queries';
import { Department, DepartmentContent } from '../../types';
import { decodeHTMLEntities } from '../../functions';
import DepartmentProductsGrid from './DepartmentProductsGrid';
import DepartmentLayoutDesktop from './DepartmentLayoutDesktop';
import DepartmentLayoutMobile from './DepartmentLayoutMobile';
import { DepartmentBestSellers } from './DepartmentBestSellers';
import Page404 from '../Page404/Page404';

const DepartmentLayout = (props: { department: Department }) => {
    const { department } = props;
    const { data, loading } = useQuery(QL_DEPT_CONTENT, {
        variables: {
            departmentId: department.id,
        },
    });
    const CONTENT_TOP =
        !loading &&
        data.departmentContent.filter(
            (deptCont: DepartmentContent) => deptCont.position == 'Middle',
        );
    const CONTENT_SIDE =
        !loading &&
        data.departmentContent.filter(
            (deptCont: DepartmentContent) => deptCont.position == 'Side',
        );
    const CONTENT_BOTTOM =
        !loading &&
        data.departmentContent.filter(
            (deptCont: DepartmentContent) => deptCont.position == 'Bottom',
        );

    return (
        <>
            <DesktopAndTabletDevice>
                <DepartmentLayoutDesktop
                    department={department}
                    contentTop={CONTENT_TOP}
                    contentSide={CONTENT_SIDE}
                    contentBottom={CONTENT_BOTTOM}
                />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <DepartmentLayoutMobile
                    department={department}
                    contentTop={CONTENT_TOP}
                    contentSide={CONTENT_SIDE}
                    contentBottom={CONTENT_BOTTOM}
                />
            </MobileDevice>
        </>
    );
};

const DepartmentPageLoading = () => {
    return (
        <ScContainer>
            <PageBreadcrumbsLoading />
            <Skeleton width={200} height={50} variant="text" />
            <DesktopAndTabletDevice>
                <TwoColumnLayout
                    leftSideComponent={<ProductFacetsLoading />}
                    rightSideComponent={
                        <DepartmentProductsGrid loading={true} />
                    }
                />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <DepartmentProductsGrid loading={true} />
            </MobileDevice>
        </ScContainer>
    );
};

const PageDepartmentInner = React.memo(function PageDepartmentInner(props: {
    deptId: string;
}) {
    const { deptId } = props;
    const { totalItemsCount } = React.useContext(ProductGridRefinement);

    const { data, error, loading } = useQuery(QL_DEPT, {
        variables: {
            departmentId: deptId,
        },
    });

    if (loading) {
        return <DepartmentPageLoading />;
    }
    if (
        error ||
        (data && data.department && parseInt(data.department.id) === 0)
    ) {
        return <Page404 />;
    }

    const MetaRobotsTag = () => {
        const location = useLocation();
        if (location && location.search.indexOf('?') > -1) {
            return (
                <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                </Helmet>
            );
        }
        return <></>;
    };
    const title = decodeHTMLEntities(data?.department?.title ?? '');
    return (
        <>
            <ScContainer>
                <MetaRobotsTag />
                <Helmet>
                    <title>{title}</title>
                </Helmet>
                <PageBreadcrumbs
                    type="department"
                    typeId={data.department.id}
                />
                <ProductGridPageTitle
                    title={data.department.h1Title}
                    subtitle={
                        totalItemsCount
                            ? `(${totalItemsCount} product${totalItemsCount === 1 ? '' : 's'})`
                            : ''
                    }
                />
                <CompareDrawerProvider>
                    <DepartmentLayout department={data.department} />
                </CompareDrawerProvider>
                <DepartmentBestSellers
                    deptId={data.department.id}
                    title={'Best Selling ' + data.department.title}
                />
            </ScContainer>
        </>
    );
});

export default function PageDepartment(props: { deptId?: string }) {
    let { deptId = '' } = useParams();
    if (props.deptId !== undefined) {
        deptId = props.deptId;
    }
    if (parseInt(deptId) == 0) {
        throw new Error('Invalid Department Id');
    }

    return deptId ? (
        <ProductGridRefinementProvider>
            <PageDepartmentInner deptId={deptId} />
        </ProductGridRefinementProvider>
    ) : (
        <></>
    );
}
