import { Typography } from '@mui/material';
import { Brand } from '../../../types';
import DepartmentCollections from '../../PageDepartmentHub/Components/DepartmentCollections';

const BrandDepartments = ({ brand }: { brand: Brand }) => {
    return (
        <>
            {brand.deptId ? (
                <>
                    <Typography component="h2" className="visually-hidden">
                        Popular Categories
                    </Typography>
                    <DepartmentCollections deptId={`${brand.deptId}`} />
                </>
            ) : null}
        </>
    );
};

export default BrandDepartments;
