import { useQuery } from '@apollo/client';
import { Section } from '../../../Components/_base/BBQGuysComponents';
import { QL_BRAND_EXPERT_REVIEWS } from '../../../Graphql/queries';
import { Brand } from '../../../types';
import {
    ExpertReviewItemLoading,
    ExpertReviewItems,
} from '../../ExpertReviews/ExpertReviewItem';

const BrandExpertReviews = ({ brand }: { brand: Brand }) => {
    const { data, loading } = useQuery(QL_BRAND_EXPERT_REVIEWS, {
        variables: {
            brandId: brand.id,
        },
    });

    if (loading) {
        return (
            <Section title={brand.name + ' Expert Reviews'}>
                <ExpertReviewItemLoading />
            </Section>
        );
    }
    return (
        <>
            {data !== undefined && data.brandExpertReviews.totalResults > 0 ? (
                <Section title={brand.name + ' Expert Reviews'}>
                    <ExpertReviewItems
                        expertReviews={data.brandExpertReviews.results}
                    />
                </Section>
            ) : null}
        </>
    );
};

export default BrandExpertReviews;
