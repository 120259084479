import {
    Box,
    Card,
    CardContent,
    Link,
    Skeleton,
    SxProps,
    Typography,
} from '@mui/material';
import React from 'react';
import { getEnvironmentVariable } from '../../config';
import { Maybe } from '../../types';
import { Image, ImageProps } from '../Image';
import { LineEllipsis } from './BBQGuysComponents';
interface BBQGuysImageCardProps {
    url: string;
    image?: string | Maybe<string>;
    hoverImage?: string;
    title: string;
    width?: number;
    height?: number;
    maxLines?: number;
    cardProps?: SxProps;
    cardContentProps?: SxProps;
    imageProps?: ImageProps;
    withBorder?: boolean;
    withTitle?: boolean;
    textAlign?: 'left' | 'right' | 'center';
    titleAlign?: 'left' | 'right' | 'center';
    children?: any;
    LinkComponent?: React.ElementType;
}
export function BBQGuysImageCard({
    url,
    image = getEnvironmentVariable('NO_IMAGE_URL'),
    title,
    hoverImage,
    withBorder = true,
    withTitle = true,
    textAlign = 'left',
    titleAlign = 'center',
    width = 200,
    height = 200,
    LinkComponent = Link,
    cardProps,
    cardContentProps = {},
    imageProps = {},
    maxLines,
    children,
}: BBQGuysImageCardProps) {
    return (
        <Card
            sx={{
                p: withBorder ? 2 : 0,
                borderWidth: withBorder ? 1 : 0,
                ...cardProps,
            }}
        >
            <CardContent
                sx={{
                    p: 0,
                    position: 'relative',
                    ...cardContentProps,
                }}
            >
                <LinkComponent
                    href={url}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        m: 0,
                        gap: 2,
                        img: {
                            transition: '.15s scale ease-in',
                        },
                        '&:hover': {
                            img: {
                                scale: '1.025',
                            },
                        },
                    }}
                >
                    <Image
                        width={width}
                        height={height}
                        sx={{
                            minHeight: 125,
                            width: '100%',
                            height: height,
                            objectFit: 'contain',
                        }}
                        src={image ?? getEnvironmentVariable('NO_IMAGE_URL')}
                        hoverSrc={hoverImage}
                        rounded
                        {...imageProps}
                    />
                    {withTitle &&
                        title &&
                        (maxLines && maxLines > 0 ? (
                            <Typography
                                variant="bold"
                                title={title}
                                textAlign={titleAlign}
                            >
                                <LineEllipsis
                                    text={title}
                                    maxLines={maxLines}
                                />
                            </Typography>
                        ) : (
                            <Typography
                                variant="bold"
                                textAlign={titleAlign}
                                width="100%"
                            >
                                {title}
                            </Typography>
                        ))}
                </LinkComponent>
                {children &&
                    (maxLines &&
                    maxLines > 0 &&
                    typeof children.replaceAll === 'function' ? (
                        <LineEllipsis
                            text={children.replaceAll('<br>', '')}
                            maxLines={maxLines}
                        />
                    ) : (
                        children
                    ))}
            </CardContent>
        </Card>
    );
}

export function BBQGuysImageCardLoading() {
    return (
        <Box textAlign="left">
            <Skeleton
                variant="rectangular"
                width={'100%'}
                sx={{ height: 240, display: 'inline-block' }}
            />
            <Skeleton
                variant="text"
                width="90%"
                sx={{ display: 'inline-block' }}
            />
        </Box>
    );
}
