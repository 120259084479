import { Box } from '@mui/system';
import * as React from 'react';
import { DesktopDevice } from '../../Contexts/DeviceTypeProvider';
import { Item } from '../../types';
import Modal from './BBQGuysModal';
import { QuickViewButton } from './BBQGuysStyledComponents';
import { BBQGuysGrid } from './BBQGuysGrid';
import { ViewItemButton } from '../ProductCards/ProductCards';
import AddToCart from '../Buttons/AddToCart/AddToCart';
import { ItemMeta } from '../../Pages/PageItem/Components/AboveTheFold/ItemMeta';
import ImageCarousel from '../ImageCarousel/ImageCarousel';
import { BulletedList } from './BBQGuysComponents';
import { PriceDisplay } from '../Item/Pricing';
import { Promo } from '../PromoCountdown/PromoCountdown';
import { LeavesWarehouseIn } from '../Item/ItemInfo';

export const QuickView = (props: {
    item: Item;
    visible?: boolean;
    trackingType?: string;
    trackingGroup?: string;
    trackingItemOrder?: number;
}) => {
    const {
        item,
        visible = true,
        trackingType,
        trackingGroup,
        trackingItemOrder,
    } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const productId = item.id;

    return (
        <DesktopDevice>
            {visible && (
                <QuickViewButton
                    className={'ga-carousel-item-link'}
                    variant="quickView"
                    aria-hidden="true"
                    onClick={handleClickOpen}
                    data-product-id={productId}
                    data-parent-product-group-type={trackingType}
                    data-parent-product-group={trackingGroup}
                    data-item-order={trackingItemOrder}
                >
                    Quickview
                </QuickViewButton>
            )}
            {open && (
                <Modal
                    open={open}
                    handleClose={handleClose}
                    scroll="paper"
                    maxWidth="xl"
                >
                    <BBQGuysGrid columns="1fr 1.5fr" gap={4} sx={{ p: 5 }}>
                        <ImageCarousel
                            item={item}
                            numberOfVisibleThumbnails={5}
                            showVideoAnd360Thumbnails={false}
                            zoomEnabled={false}
                        />
                        <Box>
                            <ItemMeta
                                item={item}
                                withBrand={false}
                                sx={{ mb: 0 }}
                            />

                            <Promo item={item} />
                            <PriceDisplay item={item} variant="medium" />
                            <LeavesWarehouseIn
                                shipsIn={item.shipsIn}
                                size="large"
                            />

                            {item.bulletPoints ? (
                                <BulletedList bullets={item.bulletPoints} />
                            ) : null}
                            <BBQGuysGrid columns={'1fr 1fr'} gap={2}>
                                <ViewItemButton item={item} />
                                <AddToCart
                                    item={item}
                                    qty={1}
                                    size="large"
                                    isPrimaryButton={true}
                                />
                            </BBQGuysGrid>
                        </Box>
                    </BBQGuysGrid>
                </Modal>
            )}
        </DesktopDevice>
    );
};
