import { Maybe } from 'graphql/jsutils/Maybe';
import { Banner, BannerLayouts } from '../../../types';
import {
    BannerHero,
    BannerThin,
    BannerThreeUpPrimeTwins,
    BannerTwoUpHeroThin,
    BannerTwoUpPrimeSide,
} from './BannerStyledContainer';

const BannersByLayout = ({
    layout = BannerLayouts.HomeHero,
    banners,
}: {
    layout: BannerLayouts;
    banners: Maybe<Banner>[];
}) => {
    if (banners.length === 0) {
        return <></>;
    }
    switch (layout) {
        case BannerLayouts.ThreeUpPrimeTwins:
            return (
                <BannerThreeUpPrimeTwins banners={banners} layout={layout} />
            );
        case BannerLayouts.TwoUpPrimeSide:
            return <BannerTwoUpPrimeSide banners={banners} layout={layout} />;
        case BannerLayouts.TwoUpHeroThin:
            return <BannerTwoUpHeroThin banners={banners} layout={layout} />;
        case BannerLayouts.BrandThin:
            return <BannerThin banners={banners} layout={layout} />;
        default:
            return <BannerHero banners={banners} layout={layout} />;
    }
};

export default BannersByLayout;
