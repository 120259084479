import { useQuery } from '@apollo/client';
import document from 'global/document';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { SSRContext } from '../../Contexts/SSRProvider';
import { UserAuth, UserAuthProvider } from '../../Contexts/UserAuthProvider';
import { useDeviceType } from '../../Contexts/DeviceTypeProvider';
import { QL_PAGE } from '../../Graphql/queries';
import { DigitalDataContext } from '../../Contexts/DigitalDataProvider';
import { Order, OrderItem, Page, User } from '../../types';
import { decodeHTMLEntities } from '../../functions';
import { CartContext, CartProvider } from '../../Contexts/CartProvider';
import React from 'react';

const PageMetaDataQuery = React.memo(function PageMetaDataQuery(props: {
    url: string;
    query: string;
}) {
    const { data, loading, error } = useQuery(QL_PAGE, {
        variables: {
            pageURL: props.url,
        },
    });
    if (loading) {
        return <></>;
    }
    let page = {} as Page;
    if (
        error &&
        error.graphQLErrors &&
        error.graphQLErrors[0] &&
        error.graphQLErrors[0].extensions &&
        error.graphQLErrors[0].extensions.response
    ) {
        const response = error.graphQLErrors[0].extensions.response as any;
        if (response && response.body && response.body.result) {
            page = response.body.result as Page;
        }
    } else if (data && data.page) {
        page = data.page as Page;
    }
    return <PageMetaDataRender page={page} query={props.query} />;
});

const GetTrafficMapping = (search: string): any => {
    // Copied over
    const trafficMappings: any = {
        //term, like keyword, product id (for PLA), etc
        utm_term: 'term',
        term: 'term',
        keyword: 'term',
        utm_keyword: 'term',
        kpid: 'term',
        productid: 'term',

        //source, like google, bing, email, yahoo, etc all good
        utm_source: 'source',
        source: 'source',

        //medium, like cpc, pla, abandoned cart email, etc  all good
        utm_medium: 'medium',
        medium: 'medium',

        //matchtype   all good
        matchtype: 'matchtype',

        //creative all good
        creative: 'creative',

        //campaign, like best of summer email, bing generci bbq guys terms, etc  - this is good for now, we may have problems when we create true campaign ids.  We are going to need to make sure ours match the sem team.  I’m hoping to know more about this this week
        utm_campaign: 'campaign',
        campaign: 'campaign',
        campaignid: 'campaign',

        //target – we don’t have any plans for this yet.  Can we wait and add later?  I feel like this will passed in the campaign variable
        target: 'target',

        //placement
        placement: 'placement',
        utm_content: 'placement',

        //product channel
        productchannel: 'productchannel',

        //which manufacturer we attribute the spend to
        adbrand: 'adbrand',

        //ad type  - all good here but not in use,
        adtype: 'adtype',

        //device – do not need this as the visit will record this if not, I assume the dfa data connector will.
        device: 'device',

        //We need to add the following
        adgroup: 'adgroup',
    };

    const results: any = {};
    if (search.length > 1) {
        const queryPairs = search.substring(1).split('&');
        for (let i = 0; i < queryPairs.length; ++i) {
            const currentPair = queryPairs[i].split('=');
            if (currentPair.length > 1) {
                const key = currentPair[0];
                const value = currentPair[1];
                if (
                    Object.prototype.hasOwnProperty.call(trafficMappings, key)
                ) {
                    const location = trafficMappings[key];
                    results[location] = value;
                }
            }
        }
    }

    return results;
};

const getOrderItemMapping = (orderItem: OrderItem): any => {
    const item = orderItem.item;
    const pricing = orderItem.pricing;

    return {
        attributes: {
            upc: item.upc,
            warehouse_deal: orderItem.tdiId ? 'true' : 'false',
        },
        price: {
            basePrice: pricing.unitPrice,
            currency: 'USD',
            priceWithTax: pricing.unitPriceWithTax,
            shipping: pricing.shipping,
            taxRate: pricing.taxRate,
        },
        productInfo: {
            productID: item.id,
            productName: item.name,
            sku: item.modelNumber,
            manufacturer: item.manufacturerName,
            productImage: item.imageUrl,
            productURL: item.url,
            category: {
                defaultPath: item.shopperschoicePath,
            },
        },
        quantity: orderItem.qty,
    };
};

const getUserData = (
    order: Order | null,
    user: User,
    overrideUserInfo: boolean,
): any => {
    const userData: any = {};
    const { hasLocalStorageUserInfo } = React.useContext(UserAuth);

    if (hasLocalStorageUserInfo() && user && user.id) {
        const userIsPro = !!(user.pricingTierId || user.isPro);
        userData.user = [
            {
                profile: {
                    profileInfo: {
                        userProTierId: user.pricingTierId || 0,
                        profileID: user.id || '',
                        email: user.email || '',
                        userName: user.email || '',
                        firstName: user.firstName || '',
                        lastName: user.lastName || '',
                        isProAccount: (userIsPro ? 'Yes' : 'No') || 'No',
                    },
                },
            },
        ];
        userData.userInfo = {
            accountType: userIsPro ? 'proAccount' : 'nonProAccount',
            isPro: userIsPro ? 'Yes' : 'No',
            userName: user.email || '',
            email: user.email || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            hashedEmail: user.hashedEmail || '',
            proTierId: user.pricingTierId || 0,
            pastCustomer: user.pastCustomer || false,
            actValue: user.actValue || 0,
            profileID: user.id,
        };
    } else {
        userData.userInfo = {
            accountType: 'guest',
            isPro: 'No',
        };
    }

    if (
        order &&
        order.id &&
        order.billingAddress &&
        order.billingAddress.email
    ) {
        const billingAddress = order.billingAddress;
        if (overrideUserInfo) {
            userData.userInfo.userName = billingAddress.email;
            userData.userInfo.email = billingAddress.email;
            userData.userInfo.hashedEmail = billingAddress.hashedEmail;
        } else {
            if (!(userData.userInfo?.userName ?? false)) {
                userData.userInfo.userName = billingAddress.email;
            }
            if (!(userData.userInfo?.email ?? false)) {
                userData.userInfo.email = billingAddress.email;
            }
            if (!(userData.userInfo?.hashedEmail ?? false)) {
                userData.userInfo.hashedEmail = billingAddress.hashedEmail;
            }
        }
        if (
            billingAddress.firstName != '' ||
            (userData.userInfo?.firstName ?? '') == ''
        ) {
            userData.userInfo.firstName = billingAddress.firstName;
        }
        if (
            billingAddress.lastName != '' ||
            (userData.userInfo?.lastName ?? '') == ''
        ) {
            userData.userInfo.lastName = billingAddress.lastName;
        }
        userData.userInfo.pastCustomer =
            userData.userInfo?.pastCustomer ?? false;
        userData.userInfo.actValue = userData.userInfo?.actValue ?? 0;
        userData.userInfo.proTierId = userData.userInfo?.proTierId ?? 0;

        userData.userInfo.attributes = {
            normalizedOrderData: order?.normalizedOrderData ?? {},
        };
    }

    return userData;
};

const getCartMapping = (order: Order, user: User): any => {
    const pricing = order.pricing;
    const processedOrderItems = Array<any>();

    if( order.orderItems && order.orderItems.constructor === Array ){
        for (let i = 0; i < order.orderItems.length; ++i) {
            processedOrderItems.push(getOrderItemMapping(order.orderItems[i] as OrderItem));
        }
    }

    const userData = getUserData(order, user, true);
    const userInfo = Object.prototype.hasOwnProperty.call(userData, 'userInfo')
        ? userData.userInfo
        : {};

    userInfo.attributes = {
        normalizedOrderData: order?.normalizedOrderData ?? {},
    };

    return {
        cartID: Number(order.id),
        orderNumber: order.orderNumber,
        estimatedShippingDate: order.estimatedOrderShipDate,
        price: {
            basePrice: pricing.subtotal,
            shipping: pricing.shipping,
            voucherCode: pricing.discounts.coupons[0]?.code ?? '',
            voucherDiscount: pricing.discounts.coupons[0]?.amount ?? 0,
            taxRate: pricing.taxRate,
            tax: pricing.tax,
            priceWithTax: pricing.priceWithTax,
            cartTotal: pricing.total,
            currency: 'USD',
        },
        item: processedOrderItems,
        profile: {
            profileInfo: userInfo,
        },
    };
};

const getTransactionMapping = (order: Order, user: User): any => {
    const cart = getCartMapping(order, user);

    return {
        transactionID: cart.cartID,
        orderNumber: cart.orderNumber,
        estimatedShippingDate: cart.estimatedShippingDate,
        paymentMethod: order?.paymentMethod ?? 'n/a',
        total: cart.price,
        item: cart.item,
        profile: cart.profile,
    };
};

function setAdobeLaunchDigitalData(dd: any) {
    const { getLocalStorageUserInfo, setLoginRedirectUrl } =
        React.useContext(UserAuth);
    const { getOrder, hasOrder, getLastOrder } = React.useContext(CartContext);
    const {
        setDigitalData,
        urlChanged,
        getClientIPAddress,
        pushUserDataDependency,
    } = React.useContext(DigitalDataContext);
    const location = useLocation();

    if (!urlChanged()) {
        return;
    }

    const { deviceType } = useDeviceType();
    dd.page.isReact = true;
    dd.page.attributes.deviceType = deviceType.toLocaleLowerCase();
    dd.page.pageInfo.referringURL = document.referrer;

    let order = getOrder();
    const action = dd.page.attributes.action.toLowerCase();
    if (action == 'cartordercomplete' && (!hasOrder() || !order || !order.id)) {
        order = getLastOrder();
    }

    const user = getLocalStorageUserInfo();
    const userData = getUserData(order, user, false);

    if (Object.prototype.hasOwnProperty.call(userData, 'user')) {
        dd.user = userData.user;
    }

    if (Object.prototype.hasOwnProperty.call(userData, 'userInfo')) {
        dd.userInfo = userData.userInfo;
    }

    if (order && order.id) {
        switch (action) {
            case 'cartitemadd':
            case 'cartbillinginfo':
                dd.cart = getCartMapping(order, user);
                dd.page = dd.page ?? {};
                dd.page.attributes = dd.page.attributes ?? {};
                dd.page.attributes.customerCanEditOrder = true;
                break;
            case 'cartordercomplete':
                dd.transaction = getTransactionMapping(order, user);
                dd.page = dd.page ?? {};
                dd.page.attributes = dd.page.attributes ?? {};
                dd.page.attributes.customerCanEditOrder = false;
                dd.page.attributes.multipleOrders =
                    (order?.totalOrders ?? 0) > 1;
                break;
        }
    }

    const trafficSegments = GetTrafficMapping(location.search);
    if (Object.values(trafficSegments).length > 0) {
        if (Object.prototype.hasOwnProperty.call(dd, 'user')) {
            dd.user[0].segment = {
                traffic: trafficSegments,
            };
        } else {
            dd.user = [
                {
                    segment: {
                        traffic: trafficSegments,
                    },
                },
            ];
        }
    }

    getClientIPAddress().then((ipAddress: string) => {
        pushUserDataDependency({ ipAddress });
    });
    setDigitalData(dd);
    setLoginRedirectUrl();
}

function PageDigitalDataRender(page: Page, query: string) {
    const { isClientSide } = React.useContext(SSRContext);

    let dd: any = {};
    const digitalData: string = page.digitalData ?? '';

    if (digitalData && digitalData.length > 0) {
        dd = JSON.parse(digitalData);
        if (isClientSide()) {
            const classList = document.body.classList;
            const deviceTypeClass =
                'is-' +
                (dd.page.attributes.deviceType == 'm'
                    ? 'mobile'
                    : dd.page.attributes.deviceType == 't'
                      ? 'tablet'
                      : 'desktop');

            const pageActionClass = 'page-' + page.action;

            if (
                !classList.contains(deviceTypeClass) ||
                !classList.contains(pageActionClass)
            ) {
                document.body.classList.remove(...document.body.classList);
                document.body.classList.add(deviceTypeClass);
                document.body.classList.add(pageActionClass);
            }
        }

        setAdobeLaunchDigitalData(dd);
    }
}

const PageMetaDataRender = React.memo(function PageMetaDataRender({
    page,
    query,
}: {
    page: Page;
    query: string;
}) {
    const meta = page.meta ?? [];

    PageDigitalDataRender(page, query);

    const dateYear = new Date().getFullYear();
    const dateMonth = new Date().getMonth();
    const dateDay = new Date().getDate();
    const title = decodeHTMLEntities(page.title ?? '');
    return (
        <>
            <Helmet>
                {meta.map(({ name, content, property }: any, i: number) => {
                    if (name === 'title' || property === 'og:title') {
                        content += ' : BBQGuys';
                    }
                    return content === null ? null : name != null ? (
                        <meta
                            key={'meta-' + name + i}
                            name={name}
                            content={content}
                        />
                    ) : (
                        <meta
                            key={'meta-' + property + i}
                            property={property}
                            content={content}
                        />
                    );
                })}
                <meta
                    httpEquiv="last_modified"
                    content={
                        dateYear +
                        '-' +
                        (dateMonth + 1) +
                        '-' +
                        dateDay +
                        ' 04:00:' +
                        dateDay
                    }
                />
                {page.canonical && (
                    <link rel="canonical" href={page.canonical ?? '/'} />
                )}
                {page.title && <title>{title}</title>}
            </Helmet>
        </>
    );
});

const PageMetaData = function PageMetaData() {
    const location = useLocation();

    const pathname = location.pathname ?? '/';
    const search = location.search;

    return (
        <CartProvider>
            <UserAuthProvider>
                <PageMetaDataQuery
                    url={pathname === '/search' ? pathname + search : pathname}
                    query={search}
                />
            </UserAuthProvider>
        </CartProvider>
    );
};

export default PageMetaData;
