import { Stack, Skeleton, Box } from '@mui/material';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';

const FrequentlyBoughtTogetherItemsLoading = (props: { count?: number }) => {
    const { count = 3 } = props;

    return (
        <>
            <DesktopAndTabletDevice>
                <Section title="Frequently Bought Together">
                    <Stack direction="row" spacing={3} sx={{ mb: 2 }}>
                        {Array(count)
                            .fill(0)
                            .map((v: number, i: number) => {
                                return (
                                    <Skeleton
                                        key={i}
                                        variant="rectangular"
                                        width={160}
                                        height={160}
                                    />
                                );
                            })}
                        <Box sx={{ ml: 5 }}>
                            <Skeleton variant="text" width={90} height={21} />
                            <Skeleton
                                variant="rectangular"
                                width={110}
                                height={36}
                            />
                            <br />
                            <Skeleton
                                variant="rectangular"
                                width={256}
                                height={44}
                                sx={{
                                    marginTop: '2.25rem',
                                }}
                            />
                        </Box>
                    </Stack>
                    {Array(count)
                        .fill(0)
                        .map((v: number, i: number) => {
                            return (
                                <Stack
                                    key={i}
                                    direction="row"
                                    spacing={3}
                                    sx={{ mb: 1 }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={20}
                                        height={20}
                                        sx={{ mt: '3px' }}
                                    />
                                    <Skeleton
                                        variant="text"
                                        width={300}
                                        height={26}
                                    />
                                </Stack>
                            );
                        })}
                </Section>
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Section title="Frequently Bought Together">
                    <Box
                        sx={{
                            mb: 2,
                            display: 'flex',
                            flexWrap: 'wrap',
                            marginLeft: '-0.25rem',
                            marginRight: '-0.25rem',
                        }}
                    >
                        {Array(count)
                            .fill(0)
                            .map((v: number, i: number) => {
                                return (
                                    <Box
                                        key={i}
                                        sx={{
                                            flex: '0 1 33.3%',
                                            paddingLeft: '0.25rem',
                                            paddingRight: '0.25rem',
                                        }}
                                    >
                                        <Skeleton
                                            variant="rectangular"
                                            height={90}
                                            sx={{
                                                width: '100%',
                                                height: '100%',
                                                border: '1px solid #ccc',
                                            }}
                                        />
                                    </Box>
                                );
                            })}
                    </Box>
                    {Array(count)
                        .fill(0)
                        .map((v: number, i: number) => {
                            return (
                                <Stack
                                    key={i}
                                    direction="row"
                                    spacing={3}
                                    sx={{
                                        mb: 1,
                                        p: 1,
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: theme =>
                                            theme.palette.divider,
                                    }}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width={20}
                                        height={20}
                                        sx={{ mt: '30px' }}
                                    />
                                    <Box width="100%">
                                        <Skeleton
                                            variant="text"
                                            width={'100%'}
                                            height={26}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width={'80%'}
                                            height={26}
                                        />
                                        <Skeleton
                                            variant="text"
                                            width={'20%'}
                                            height={26}
                                        />
                                    </Box>
                                </Stack>
                            );
                        })}
                    <Box sx={{ ml: 5 }}>
                        <Skeleton variant="text" width={60} height={20} />
                        <Skeleton
                            variant="rectangular"
                            width={120}
                            height={20}
                        />
                        <br />
                        <Skeleton
                            variant="rectangular"
                            width={180}
                            height={40}
                        />
                    </Box>
                </Section>
            </MobileDevice>
        </>
    );
};

export default FrequentlyBoughtTogetherItemsLoading;
