import { Typography } from '@mui/material';
import { EmailLink, LiveChatLink, PhoneLink } from '../_base/BBQGuysComponents';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import { SearchInputBox } from '../../Components/Layout/SearchInputBox/SearchInputBox';
import { DepartmentBestSellers } from '../../Pages/PageDepartment/DepartmentBestSellers';
import { SearchBarProvider} from '../../Contexts/SearchBarProvider';
import { getIsMobile } from '../../Contexts/DeviceTypeProvider';

export const ResultNotFoundDescription = () => {
    const style = getIsMobile()
        ? {}
        : {
              marginLeft: 10,
          };

    const SearchBar = (props: any) => {
        return (
            <SearchInputBox
                isFullWidth={props.isFullWidth}
                placeholder={props.placeholder}
                showProducts={true}
            />
        );
    };

    return (
        <SearchBarProvider>
            <Box>
                <Typography variant="body1" component="div" sx={style}>
                    To help you find what you&apos;re looking for, you can try:
                    <ul>
                        <li>
                            <LiveChatLink showIcon={false} title="Chat" /> with or{' '}
                            <EmailLink
                                showIcon={false}
                                emailAddress="https://help.bbqguys.com/hc/en-us/articles/360018874952-Submit-a-Ticket"
                                emailText="Email"
                            />{' '}
                            one of our experts
                        </li>
                        <li>
                            or Call us at <PhoneLink showIcon={false} />.
                        </li>
                        <li>
                            Try a new search or check below for more categories
                            <Box
                                className="search-bar-container"
                                sx={{
                                    width: {
                                        xs: '100%',
                                        md: '40%',
                                    },
                                    minWidth: {
                                        xs: 'auto',
                                        md: '400px',
                                    },
                                }}
                            >
                                <SearchBar
                                    isFullWidth={true}
                                    placeholder="Search BBQGuys"
                                />
                            </Box>
                        </li>
                    </ul>
                </Typography>
                <Divider
                    sx={{
                        paddingTop: 2,
                    }}
                />
                <DepartmentBestSellers
                    title="Explore More Items..."
                    deptId="7119"
                />
            </Box>
        </SearchBarProvider>
    );
};
