import { useQuery } from '@apollo/client';
import {
    Avatar,
    Box,
    FormLabel,
    ListItemAvatar,
    Skeleton,
} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    HelpTipIcon,
    Select,
} from '../../../../Components/_base/BBQGuysComponents';
import { QL_ITEM_OPTIONS } from '../../../../Graphql/queries';
import { ItemOption, ItemOptions } from '../../../../types';
import { UserAuth } from '../../../../Contexts/UserAuthProvider';
import window from 'global/window';
import { ProductPageContext } from '../../../../Contexts/ProductPageProvider';
import { isMobile } from '../../../../Contexts/DeviceTypeProvider';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export function ItemOptionsLoading() {
    return (
        <>
            <Skeleton variant="text" width={'30%'} />
            <Skeleton
                variant="rectangular"
                height={'2.3rem'}
                width={'90%'}
                sx={{ mb: 0.5 }}
            />
            <Skeleton variant="rectangular" height={'2.3rem'} width={'90%'} />
        </>
    );
}

export default function ItemOptionsDropdown({ itemId }: { itemId: string }) {
    const { getPricingTierId } = React.useContext(UserAuth);
    const { data, loading } = useQuery(QL_ITEM_OPTIONS, {
        variables: {
            itemId: itemId,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <ItemOptionsLoading />;
    }
    return data && data.itemOptions.length > 0 ? (
        <div aria-description="Product configuration options">
            <FormLabel>Select Product Options:</FormLabel>
            {data.itemOptions.map((options: ItemOptions, i: number) => {
                return (
                    <OptionsDropdown
                        key={'options-dropdown-' + options.selectBoxName}
                        className="item-options-dropdown"
                        options={options}
                        groupIndex={i}
                        groupSize={data.itemOptions.length}
                    />
                );
            })}
        </div>
    ) : null;
}

export function OptionsDropdown(props: {
    options: ItemOptions;
    groupIndex: number;
    groupSize: number;
    className: string;
}) {
    const { options, groupIndex, groupSize, className } = props;
    const { setItemId } = React.useContext(ProductPageContext);
    const [selectedValue, setSelectedValue] = React.useState<string>(
        options.selectBoxValueSelected,
    );
    const navigate = useNavigate();

    /* gets the active selected option based on the value */
    const getActiveSelectedOption = React.useCallback(
        (value: string): ItemOption | null => {
            return options.options.find(opt => opt.title === value) || null;
        },
        [options.options],
    );

    /* change events on the select component */
    const handleChange = React.useCallback(
        (event: React.ChangeEvent<{ value: unknown }>) => {
            const { value } = event.target;
            setSelectedValue(value as string);
            /* if not a mobile device, navigate to the options URL*/
            if (!isMobile) {
                const opt = getActiveSelectedOption(value as string);
                if (opt && window) {
                    setItemId(opt.itemId);
                    if (window) {
                        window.location = opt.url;
                    }
                }
            }
        },
        [isMobile, getActiveSelectedOption],
    );

    /* handling the drawer closing to reset or commit the changes */
    const handleClose = React.useCallback(
        (type: 'cancel' | 'confirm') => {
            if (type === 'cancel') {
                /* reset the select value back to default */
                setSelectedValue(options.selectBoxValueSelected);
            } else if (type === 'confirm') {
                const opt = getActiveSelectedOption(selectedValue as string);
                if (opt && window) {
                    setItemId(opt.itemId);
                    if (window) {
                        window.location = opt.url;
                    }
                }
            }
        },
        [
            selectedValue,
            options.selectBoxValueSelected,
            getActiveSelectedOption,
        ],
    );

    useEffect(() => {
        if (
            !options.options.some(
                (opt: ItemOption) => opt.title === selectedValue,
            )
        ) {
            if (options.options.length > 0) {
                const firstOption = options.options[0];
                setSelectedValue(firstOption.title);
            }
        }
    }, [options, selectedValue, navigate]);

    return (
        <Box
            sx={{
                m: 0,
                display: 'grid',
                gridTemplateColumns: '1fr 16px',
                gap: 1,
                alignItems: 'center',
            }}
        >
            <Select
                grouped={true}
                groupIndex={groupIndex}
                groupSize={groupSize}
                labelId={options.selectBoxName}
                label={options.label}
                id={options.selectBoxName}
                value={selectedValue}
                onChange={handleChange}
                onClose={handleClose}
                MenuProps={MenuProps}
                title="Select Product Options"
                aria-description="Product configuration option"
            >
                {options.options.map((opt: ItemOption) => (
                    <MenuItem
                        key={opt.id}
                        value={opt.title}
                        className={className}
                    >
                        {opt.imageUrl ? (
                            <ListItemAvatar>
                                <Avatar
                                    alt={opt.title}
                                    src={opt.imageUrl}
                                    variant="square"
                                    sx={{ mixBlendMode: 'multiply' }}
                                />
                            </ListItemAvatar>
                        ) : null}
                        <ListItemText
                            primary={opt.title}
                            secondary={
                                opt.priceDifference != 0 ? opt.pricingText : ''
                            }
                        />
                    </MenuItem>
                ))}
            </Select>
            <HelpTipIcon
                contentId={options.helpTipContentId}
                title={options.label}
            />
        </Box>
    );
}
