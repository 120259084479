import { gql } from '@apollo/client';
import { Box, Button, DialogContent, Stack, Typography } from '@mui/material';
import React, { createContext, useEffect, useState } from 'react';
import ItemComparison from '../Components/ItemComparison/ItemComparison';
import { ProductCardSmall } from '../Components/ProductCards/CardTypes/Small/ProductCardSmall';
import Modal, { BBQGuysBottomDrawer } from '../Components/_base/BBQGuysModal';
import {
    DesktopAndTabletDevice,
    isMobile,
} from '../Contexts/DeviceTypeProvider';
import { client } from '../Graphql/ApolloClient';
import { PAGINATED_ITEMS } from '../Graphql/fragments';
import theme from '../Theme';
import { Item } from '../types';
import { UserAuth } from './UserAuthProvider';

const MAX_ITEMS = 4;
const LOCAL_STORAGE_KEY = 'compare-items';

const QL_COMPARE_ITEMS = gql`
    ${PAGINATED_ITEMS}
    query compareItems($itemIds: ID!, $pricingTierId: Int) {
        itemsByIds(itemIds: $itemIds, pricingTierId: $pricingTierId) {
            ...PaginatedItems
        }
    }
`;

export const CompareDrawerContext = createContext({
    compareButtonEnabled: false,
    addItemToCompareList: (item: Item) => {
        return;
    },
    hasMaxItemsInCompareList: (): boolean => {
        return false;
    },
    itemInCompareList: (item: Item): boolean => {
        return false;
    },
    deleteItemFromCompareList: (item: Item) => {
        return;
    },
});

export function CompareDrawerProvider(props: { children: any }) {
    const { getPricingTierId } = React.useContext(UserAuth);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [compareArray, setCompareArray] = useState<Item[]>([]);
    const [compareButtonEnabled] = useState(true);

    useEffect(() => {
        setIsDrawerOpen(compareArray.length > 0);
    }, [compareArray]);

    useEffect(() => {
        // not shown on mobile so don't load anything
        if (!isMobile) {
            getItemsFromServer();
        }
    }, []);

    const itemInCompareList = (item: Item) =>
        compareArray.some((itm: Item) => itm.id === item.id);
    const hasMaxItemsInCompareList = (): boolean =>
        compareArray.length >= MAX_ITEMS;

    const saveToLocalStorage = (newCompare: Item[]) => {
        localStorage.setItem(
            LOCAL_STORAGE_KEY,
            JSON.stringify(newCompare.map((item: Item) => item.id)),
        );
    };
    const clearLocalStorage = () => {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    };

    const addItemToCompareList = (item: Item) => {
        if (compareArray.length === MAX_ITEMS) {
            return alert('You can only compare 4 items at a time.');
        }
        if (!itemInCompareList(item)) {
            const newCompare = [...compareArray, item];
            setCompareArray(newCompare);
            saveToLocalStorage(newCompare);
        }
        setIsDrawerOpen(true);
    };

    const deleteItemFromCompareList = (item: Item) => {
        const newCompare = compareArray.filter(
            (itm: Item) => itm.id !== item.id,
        );
        if (newCompare.length === 0) {
            removeAllItemsFromCompareList();
            setIsDrawerOpen(false);
        } else {
            setCompareArray(newCompare);
            saveToLocalStorage(newCompare);
        }
    };

    const removeAllItemsFromCompareList = () => {
        setCompareArray([]);
        clearLocalStorage();
    };

    const getItemsFromServer = (): Promise<Item[]> => {
        return new Promise((resolve, reject) => {
            (async function () {
                const items = localStorage.getItem(LOCAL_STORAGE_KEY) as string;
                if (items && items.length > 0) {
                    const data = JSON.parse(items);
                    if (data.length > 0) {
                        let itemIds = [];
                        // if for some reason the data comes in as an object, get the ids off of it.
                        // this could happen while migrating old site to this site
                        if (data[0].id) {
                            itemIds = data.map((item: Item) => item.id);
                        } else {
                            itemIds = data;
                        }
                        const response = await client.query({
                            query: QL_COMPARE_ITEMS,
                            variables: {
                                itemIds: itemIds.join(','),
                                pricingTierId: getPricingTierId(),
                            },
                        });
                        if (response.errors) {
                            resolve([]);
                        }
                        if (response.data.itemsByIds?.countResults > 0) {
                            setCompareArray(response.data.itemsByIds.results);
                            resolve(response.data.itemsByIds.results);
                        } else {
                            resolve([]);
                        }
                    } else {
                        resolve([]);
                    }
                } else {
                    resolve([]);
                }
            })();
        });
    };

    return (
        <CompareDrawerContext.Provider
            value={{
                compareButtonEnabled,
                addItemToCompareList,
                hasMaxItemsInCompareList,
                itemInCompareList,
                deleteItemFromCompareList,
            }}
        >
            {props.children}
            <>
                {isDrawerOpen && (
                    <DesktopAndTabletDevice>
                        <BBQGuysBottomDrawer
                            open={isDrawerOpen}
                            onClose={() => setIsDrawerOpen(false)}
                            variant="persistent"
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                background: 'rgba(248, 248, 248, 0.3)',
                                backdropFilter: 'blur(0.5em)',
                                WebkitBackdropFilter: 'blur(0.5em)',
                                padding: 0,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'grid',
                                    gridTemplateColumns: '4fr 1fr',
                                    padding: '16px',
                                    gap: 2,
                                }}
                            >
                                <Box>
                                    <Stack direction="row" spacing={2}>
                                        {compareArray.map(
                                            (item: Item, index: number) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <ProductCardSmall
                                                            item={item}
                                                            onDelete={() =>
                                                                deleteItemFromCompareList(
                                                                    item,
                                                                )
                                                            }
                                                            sx={{
                                                                [theme.breakpoints.down(
                                                                    'lg',
                                                                )]: {
                                                                    flex: '0 0 110px',
                                                                    width: '110px',
                                                                    gap: 0,
                                                                    position:
                                                                        'relative',
                                                                    'div:first-child':
                                                                        {
                                                                            display:
                                                                                'none',
                                                                        },
                                                                    'div:nth-child(2)':
                                                                        {
                                                                            position:
                                                                                'absolute',
                                                                            right: -5,
                                                                        },
                                                                },
                                                            }}
                                                        />
                                                    </React.Fragment>
                                                );
                                            },
                                        )}
                                        {[
                                            ...Array(
                                                MAX_ITEMS - compareArray.length,
                                            ),
                                        ].map((v: number, i: number) => {
                                            return (
                                                <Box
                                                    key={i}
                                                    sx={{
                                                        border: '0.25rem dashed',
                                                        color: `${theme.palette.divider}`,
                                                        display: 'grid',
                                                        gridTemplateColumns:
                                                            '85px 1fr',
                                                        gap: 1,
                                                        p: 1,
                                                        width: 270,
                                                        height: 103,
                                                        flex: '0 0 270px',
                                                        [theme.breakpoints.down(
                                                            'lg',
                                                        )]: {
                                                            flex: '0 0 110px',
                                                            width: '110px',
                                                            gap: 0,
                                                            position:
                                                                'relative',
                                                            'div:first-child': {
                                                                display: 'none',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Typography
                                                        sx={{
                                                            fontSize: 20,
                                                            fontWeight: 800,
                                                        }}
                                                    >
                                                        Compare Item
                                                    </Typography>
                                                </Box>
                                            );
                                        })}
                                    </Stack>
                                </Box>
                                <Stack direction="column" gap={2}>
                                    <Button
                                        onClick={() => setIsModalOpen(true)}
                                        variant="contained"
                                        color="secondary"
                                        disabled={compareArray.length < 2}
                                    >
                                        Compare
                                    </Button>
                                    <Button
                                        onClick={() =>
                                            removeAllItemsFromCompareList()
                                        }
                                        variant="link"
                                    >
                                        Remove
                                    </Button>
                                </Stack>
                            </Box>
                        </BBQGuysBottomDrawer>
                        {isModalOpen && (
                            <Modal
                                open={isModalOpen}
                                handleClose={() => setIsModalOpen(false)}
                                title={`Compare ${compareArray[0].category}`}
                            >
                                <DialogContent>
                                    <ItemComparison
                                        itemIds={compareArray.map(
                                            (item: Item) => item.id,
                                        )}
                                    />
                                </DialogContent>
                            </Modal>
                        )}
                    </DesktopAndTabletDevice>
                )}
            </>
        </CompareDrawerContext.Provider>
    );
}
