import { DialogContent } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../../Components/_base/BBQGuysModal';
import { getEnvironmentVariable } from '../../../../config';
import { doesScriptExist } from '../../../../functions';
import { Item } from '../../../../types';

function PowerReviewsReviewContent(props: {
    type: 'review' | 'qa';
    item: Item;
}) {
    const { item, type } = props;
    const container = React.useRef<HTMLDivElement>(null);
    const POWER_REVIEWS_SCRIPT_URL =
        'https://ui.powerreviews.com/stable/4.1/ui.js';
    const navigate = useNavigate();

    React.useEffect(() => {
        const url = new URL(window.location.toString());
        if (container.current === null) {
            console.log('PR REVIEW CONTAINER NULL');
            return;
        }

        if (type === 'qa') {
            url.searchParams.set('appName', 'askQuestion');
            navigate(
                url.toString().substring(url.toString().indexOf(url.pathname)),
            );
        }
        if (doesScriptExist(POWER_REVIEWS_SCRIPT_URL) === false) {
            const script = document.createElement('script');
            script.async = true;
            script.src = POWER_REVIEWS_SCRIPT_URL;
            document.head.append(script);
        }
        const scriptData = document.createElement('script');

        //https://help.powerreviews.com/Content/Platform/JavaScript%20Reference%20Guide.htm#The3
        scriptData.innerHTML = `
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments); 
        };
        pwr("render", {
            api_key: '${getEnvironmentVariable('POWER_REVIEWS_MERCHANT_GROUP_API_KEY')}',
            locale: '${getEnvironmentVariable('POWER_REVIEWS_LOCALE')}',
            merchant_group_id: '${getEnvironmentVariable('POWER_REVIEWS_MERCHANT_GROUP_ID')}',
            merchant_id: '${getEnvironmentVariable('POWER_REVIEWS_MERCHANT_ID')}',
            page_id: '${item.id}',
            components: {
                Write: '${item.id}_pr_write_${type}'
            }
        });`;
        setTimeout(() => {
            container.current?.append(scriptData);
        }, 100);

        return () => {
            url.searchParams.delete('appName');
            navigate(
                url.toString().substring(url.toString().indexOf(url.pathname)),
            );
        };
    }, []);

    return <Box id={item.id + '_pr_write_' + type} ref={container}></Box>;
}

export default function PowerReviewsWriteModal(props: {
    type: 'review' | 'qa';
    item: Item;
    open: boolean;
    handleClose: () => void;
}) {
    const { item, open = false, type } = props;

    return (
        <>
            <Modal
                open={open}
                title={'Write A ' + (type === 'review' ? 'Review' : 'Question')}
                size="lg"
                handleClose={props.handleClose}
            >
                <DialogContent>
                    <PowerReviewsReviewContent type={type} item={item} />
                </DialogContent>
            </Modal>
        </>
    );
}
