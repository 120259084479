import { Button, DialogActions, DialogContent } from '@mui/material';
import React from 'react';
import Modal from '../../../../Components/_base/BBQGuysModal';
import OpenBoxItems from './OpenBoxItems';

export default function OpenBoxModal(props: { itemId: string; link: any }) {
    const { itemId } = props;
    let { link } = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    link = React.cloneElement(link, {
        onClick: (e: Event) => {
            e.preventDefault();
            handleClickOpen();
        },
    });

    return (
        <>
            {link}
            <Modal
                open={open}
                handleClose={handleClose}
                title="Open-Box Items"
                size="lg"
            >
                <DialogContent>
                    <OpenBoxItems itemId={itemId} showTitle={false} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Modal>
        </>
    );
}
