import { gql, useQuery } from '@apollo/client';
import { Skeleton, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import PageBreadcrumbs, {
    PageBreadcrumbsLoading,
} from '../../Components/Breadcrumbs/PageBreadcrumbs';
import { Image } from '../../Components/Image';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import { BBQGuysGrid } from '../../Components/_base/BBQGuysGrid';
import { BBQGuysImageCard } from '../../Components/_base/BBQGuysImageCard';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { Breadcrumb, ExpertReviewClass } from '../../types';

const QL_EXPERT_REVIEW_CLASSES = gql`
    query getExpertReviewClasses {
        expertReviewClasses {
            currentPage
            prevPage
            nextPage
            countResults
            totalResults
            totalPages
            offset
            results {
                id
                name
                imageUrl
                url
            }
        }
    }
`;
const ExpertReviewsHubLoading = () => {
    return (
        <ScContainer sx={{ mb: 1 }}>
            <PageBreadcrumbsLoading count={1} />
            <Skeleton variant="text" height="50px" width="40%" />
            <Skeleton variant="rectangular" height="230px" />
            <br />
            <BBQGuysGrid>
                {Array.from(Array(18).keys()).map((num: number, i: number) => {
                    return (
                        <Skeleton
                            key={'exp-txt-' + i}
                            variant="rectangular"
                            height="310px"
                        />
                    );
                })}
            </BBQGuysGrid>
        </ScContainer>
    );
};

const PageExpertReviewHub = () => {
    const qry = QL_EXPERT_REVIEW_CLASSES;
    const { data, loading } = useQuery(qry);

    if (loading) {
        return <ExpertReviewsHubLoading />;
    }
    return (
        <ScContainer sx={{ mb: 1 }}>
            <Helmet>
                <title>Expert Reviews</title>
            </Helmet>
            <PageBreadcrumbs
                breadcrumbs={[
                    {
                        title: 'Home',
                        url: '/',
                    } as Breadcrumb,
                    {
                        title: 'Expert Reviews',
                        url: '/expert-reviews',
                    } as Breadcrumb,
                ]}
            />
            <Typography variant="h1" component="h1">
                Expert Reviews
            </Typography>
            <DesktopAndTabletDevice>
                <Image
                    src="https://cdn.shocho.co/sc-site/redesign/expert-reviews/ExpertOverview-Main-Banner.jpg"
                    width={'100%'}
                    height={'230px'}
                />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <Image
                    src="https://cdn.shocho.co/sc-site/redesign/expert-reviews/ExpertOverview-Main-Banner-mobile1.jpg"
                    width={'100%'}
                    height={'180px'}
                />
            </MobileDevice>
            {data.expertReviewClasses.totalResults > 0 ? (
                <BBQGuysGrid>
                    {data.expertReviewClasses.results.map(
                        (exp: ExpertReviewClass, i: number) => {
                            return (
                                <BBQGuysImageCard
                                    key={'exp-rev-' + exp.id}
                                    url={exp.url}
                                    image={exp.imageUrl}
                                    title={exp.name}
                                ></BBQGuysImageCard>
                            );
                        },
                    )}
                </BBQGuysGrid>
            ) : null}
        </ScContainer>
    );
};

export default PageExpertReviewHub;
