import { Link } from '@mui/material';
import {
    DesktopDevice,
    MobileDevice,
    TabletDevice,
} from '../../../Contexts/DeviceTypeProvider';
import { Image } from '../../Image';

const BannerImageInner = (props: any) => {
    let imageHeightMobile = props.imageHeightMobile ?? 0;
    if (!imageHeightMobile) {
        imageHeightMobile = 1;
    }
    return (
        <>
            <DesktopDevice>
                <Image
                    src={props.image}
                    alt={props.altText}
                    title={props.setTitle ? props.altText : ''}
                    fullWidth
                    lazyLoadImage={false}
                    width={props.imageWidth}
                    height={props.imageHeight}
                    useExactUrl
                />
            </DesktopDevice>
            <TabletDevice>
                <Image
                    src={props.image}
                    alt={props.altText}
                    title={props.setTitle ? props.altText : ''}
                    fullWidth
                    lazyLoadImage={false}
                    width={props.imageWidth}
                    height={props.imageHeight}
                    useExactUrl
                />
            </TabletDevice>
            <MobileDevice>
                <Image
                    src={props.imageMobile}
                    alt={props.altText}
                    title={props.setTitle ? props.altText : ''}
                    lazyLoadImage={false}
                    width={props.imageWidthMobile ?? '100%'}
                    height={imageHeightMobile}
                    useExactUrl
                    sx={{ width: '100%', height: '100%' }}
                />
            </MobileDevice>
        </>
    );
};

const BannerImage = (props: any) => {
    return props.href ? (
        <Link href={props.href}>
            {props.setTitle ? (
                <h1>
                    <BannerImageInner {...props}></BannerImageInner>
                </h1>
            ) : (
                <BannerImageInner {...props}></BannerImageInner>
            )}
        </Link>
    ) : props.setTitle ? (
        <h1>
            <BannerImageInner {...props}></BannerImageInner>
        </h1>
    ) : (
        <BannerImageInner {...props}></BannerImageInner>
    );
};
export default BannerImage;
