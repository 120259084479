import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import ScContainer from '../../../Components/Layout/ScContainer/ScContainer';
import { ForgotPasswordForm } from './ForgotPasswordForm';

export default function PageForgotPassword() {
    return (
        <ScContainer size="small">
            <Helmet>
                <title>Forgot Password?</title>
            </Helmet>
            <ForgotPasswordForm />
        </ScContainer>
    );
}
