import { Box, BoxProps } from '@mui/material';
import { BBQGuysGrid } from '../BBQGuysGrid';
interface ColumnWidthProps {
    xs?: string;
    md?: string;
    lg?: string;
}
interface TwoColumnLayoutProps extends BoxProps {
    leftSideComponent: any;
    rightSideComponent: any;
    columnWidth?: ColumnWidthProps;
    mobileReverseLayout?: boolean;
    reverseLayout?: boolean;
}

export default function TwoColumnLayout({
    leftSideComponent,
    rightSideComponent,
    mobileReverseLayout = false,
    reverseLayout = false,
    columnWidth = {
        xs: reverseLayout ? '1fr' : '1fr',
        md: reverseLayout ? '9fr 3fr' : '3fr 9fr',
        lg: reverseLayout ? '9.45fr 2.55fr' : '2.55fr 9.45fr',
    },
    sx = {},
    ...rest
}: TwoColumnLayoutProps) {
    return (
        <BBQGuysGrid
            className="two-column-layout"
            sx={{
                gap: 2,
                gridTemplateColumns: columnWidth as any,
                flexDirection: {
                    xs: 'column' + (mobileReverseLayout ? '-reverse' : ''),
                    md: 'row',
                },
                overflow: 'visible',
                ...sx,
            }}
            {...rest}
        >
            <Box
                minWidth={0}
                alignItems="flex-start"
                className="two-column-layout-left"
            >
                {leftSideComponent}
            </Box>
            <Box minWidth={0} className="two-column-layout-right">
                {rightSideComponent}
            </Box>
        </BBQGuysGrid>
    );
}
