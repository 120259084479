import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../../../Contexts/DeviceTypeProvider';
import CustomerReviewsHeaderDesktopLoading from './Layouts/CustomerReviewsHeaderDesktopLoading';
import CustomerReviewsHeaderMobileLoading from './Layouts/CustomerReviewsHeaderMobileLoading';

export default function CustomerReviewsHeaderLoading() {
    return (
        <>
            <DesktopAndTabletDevice>
                <CustomerReviewsHeaderDesktopLoading />
            </DesktopAndTabletDevice>
            <MobileDevice>
                <CustomerReviewsHeaderMobileLoading />
            </MobileDevice>
        </>
    );
}
