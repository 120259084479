import { gql } from '@apollo/client';
import { ChatBubble, ShoppingBasket } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Divider,
    Link,
    Stack,
    Typography,
} from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import * as React from 'react';
import { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Orders from '../../Components/Admin/Orders/Orders';
import AddToCart from '../../Components/Buttons/AddToCart/AddToCart';
import { Image } from '../../Components/Image';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import {
    Address,
    FullPageIconBanner,
    LoadingSpinner,
    MediaRow,
    PageHeader,
    QuestionsAboutYourOrderBox,
    StatusLabel,
} from '../../Components/_base/BBQGuysComponents';
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
} from '../../Components/_base/BBQGuysStyledComponents';
import { AddToCartItem } from '../../Contexts/CartProvider';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import { client } from '../../Graphql/ApolloClient';
import { CONTACT_FIELDS, CUSTOMER_ORDER_FIELDS } from '../../Graphql/fragments';
import { OrderStatusSchema } from '../../Schemas/Schemas';
import { date } from '../../functions';
import { Contact, Order, OrderItem } from '../../types';
import { OrderTotals } from '../Cart/Components/CartComponents';
import { FieldWithLabel } from '../Cart/PageCheckout/Forms/Components/FieldWithLabel';
import CustomerReviewsWriteReviewButton from '../PageItem/Components/CustomerReviews/Components/Buttons/CustomerReviewsWriteReviewButton';

const QL_ORDER_STATUS = gql`
    ${CUSTOMER_ORDER_FIELDS}
    ${CONTACT_FIELDS}
    query getOrderStatus($orderNumber: String!, $zipCode: String) {
        orderStatus(orderNumber: $orderNumber, zipCode: $zipCode) {
            ...CustomerOrderFields
            billingAddress {
                ...ContactFields
            }
            shippingAddress {
                ...ContactFields
            }
        }
    }
`;

interface OrderStatusValues {
    orderNumber: string;
    zipCode: string;
}

const getOrderStatus = (
    orderNumber: string,
    zipCode: string,
): Promise<Order> => {
    return new Promise((resolve, reject) => {
        (async () => {
            try {
                const response = await client.query({
                    query: QL_ORDER_STATUS,
                    variables: {
                        orderNumber: orderNumber,
                        zipCode: zipCode.substring(0, 5),
                    },
                });
                resolve(response.data.orderStatus);
            } catch (e: any) {
                reject(e.message);
            }
        })();
    });
};

const OrderStatusForm = ({
    orderNumber = '',
    zipCode = '',
}: {
    orderNumber?: string;
    zipCode?: string;
}) => {
    const { isLoggedIn } = useContext(UserAuth);
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            orderNumber: orderNumber,
            zipCode: zipCode,
        },
        validationSchema: OrderStatusSchema,
        onSubmit: (values: OrderStatusValues, actions: any) => {
            actions.setSubmitting(true);
            getOrderStatus(values.orderNumber, values.zipCode)
                .then((order: Order) => {
                    if (order.id) {
                        navigate(
                            '/order-status?orderNumber=' +
                                values.orderNumber +
                                '&zipCode=' +
                                values.zipCode,
                        );
                    }
                })
                .catch(e => {
                    console.log(e);
                    setErrorMessage(
                        'Sorry, we could not find any order that matches the order number and zip code provided.',
                    );
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });
        },
    });

    return (
        <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
                <PageHeader
                    title="Check Order Status"
                    subtitle="Enter the fields below to check your order status and get tracking information. New orders may take a few minutes to show up."
                />
                {errorMessage.length > 0 ? (
                    <Alert severity="error">{errorMessage}</Alert>
                ) : null}
                <FieldWithLabel
                    label="Order Number"
                    id="orderNumber"
                    name="orderNumber"
                    type="text"
                    formik={formik}
                    helpTipContentId={'40472'}
                    helpTipTitle="How to find your Order Number"
                />
                <br />
                <FieldWithLabel
                    label="5 Digit Billing Zip Code"
                    id="zipCode"
                    name="zipCode"
                    type="text"
                    autoComplete="postal-code"
                    formik={formik}
                />
                <br />
                <br />
                <LoadingButton
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={formik.isSubmitting}
                >
                    Get Order Status
                </LoadingButton>
                {!isLoggedIn() && (
                    <p>
                        <i>Already have an account?</i>{' '}
                        <Link href="/login">Sign In</Link> to view all orders.
                    </p>
                )}
            </form>
        </FormikProvider>
    );
};
const OrderStatusTableHead = ({ order }: { order: Order }) => {
    return (
        <TableHead>
            <TableRow>
                <DesktopAndTabletDevice>
                    <TableCell>Order #:&nbsp;{order.orderNumber}</TableCell>
                    <TableCell align="right">
                        Order Date:&nbsp;
                        {date.format(new Date(order.orderDate))}
                    </TableCell>
                </DesktopAndTabletDevice>
                <MobileDevice>
                    <TableCell>
                        Order #:
                        <br />
                        {order.orderNumber}
                    </TableCell>
                    <TableCell align="right">
                        Order Date:
                        <br />
                        {date.format(new Date(order.orderDate))}
                    </TableCell>
                </MobileDevice>
            </TableRow>
        </TableHead>
    );
};
const OrderStatusTableBody = ({ orderItems }: { orderItems: OrderItem[] }) => {
    return (
        <TableBody>
            {orderItems.map((oi: OrderItem, i: number) => {
                return (
                    <TableRow key={i}>
                        <DesktopAndTabletDevice>
                            <TableCell>
                                <OrderItemInfo orderItem={oi} />
                            </TableCell>
                            <TableCell sx={{ width: { xs: '100%', md: 350 } }}>
                                <OrderItemButtonOptions orderItem={oi} />
                            </TableCell>
                        </DesktopAndTabletDevice>
                        <MobileDevice>
                            <TableCell colSpan={2}>
                                <OrderItemInfo orderItem={oi} />
                                <Divider sx={{ my: 2 }} />
                                <OrderItemButtonOptions orderItem={oi} />
                            </TableCell>
                        </MobileDevice>
                    </TableRow>
                );
            })}
        </TableBody>
    );
};
const OrderItemInfo = ({ orderItem: oi }: { orderItem: OrderItem }) => {
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    mb: 1,
                    '> p:before': { content: '"|"', px: 1 },
                }}
            >
                <>
                    <StatusLabel status={oi.status} />
                    {oi.deliveryDateRange !== 'undefined' ?? (
                        <Typography variant="body1">
                            Est. Delivery:{' '}
                            <strong>{oi.deliveryDateRange}</strong>
                        </Typography>
                    )}
                </>
            </Box>
            <MediaRow
                image={
                    <Image
                        src={oi.item.imageUrl}
                        alt={oi.item.name}
                        width={100}
                    />
                }
            >
                <Stack spacing={1}>
                    <Typography variant="body1">ID# {oi.item.id}</Typography>
                    <Box>
                        {oi.item.url ? (
                            <Link href={oi.item.url}>{oi.item.name}</Link>
                        ) : (
                            <>{oi.item.name}</>
                        )}
                    </Box>
                    <Box>
                        <CustomerReviewsWriteReviewButton
                            item={oi.item}
                            button={
                                <Button variant="link">
                                    <ChatBubble
                                        sx={{
                                            height: '1.2rem',
                                            verticalAlign: 'middle',
                                        }}
                                    />
                                    &nbsp;Write a Product Review
                                </Button>
                            }
                            sx={{ textAlign: 'left' }}
                        />
                    </Box>
                </Stack>
            </MediaRow>
        </>
    );
};
const OrderItemButtonOptions = ({ orderItem }: { orderItem: OrderItem }) => {
    const isDelivered = orderItem.status === 'Delivered';
    return (
        <Stack spacing={3}>
            {isDelivered ? (
                <>
                    <Button
                        variant="contained"
                        color="primary"
                        target="_blank"
                        href={orderItem.trackingUrl || '#'}
                    >
                        TRACK PACKAGE
                    </Button>
                    <Button
                        variant="standard"
                        href="https://help.bbqguys.com/hc/en-us/articles/360018350251-Request-a-Return"
                    >
                        RETURN OR EXCHANGE ITEM
                    </Button>
                </>
            ) : (
                <Button
                    variant="standard"
                    href="https://help.bbqguys.com/hc/en-us/articles/360018019012-How-To-Modify-Cancel-an-Order"
                >
                    MODIFY OR CANCEL ITEMS
                </Button>
            )}
            <AddToCart
                items={[
                    {
                        item: orderItem.item,
                        qty: orderItem.qty,
                    } as AddToCartItem,
                ]}
                label={'Reorder Items'}
                variant="standard"
            />
        </Stack>
    );
};
const OrderStatusTable = ({ order }: { order: Order }) => {
    const orderItems =
        order.orderItems && order.orderItems.constructor === Array
            ? (order.orderItems as OrderItem[])
            : [];
    return (
        <Table>
            <OrderStatusTableHead order={order} />
            <OrderStatusTableBody orderItems={orderItems} />
            <OrderStatusTableFooter order={order} />
        </Table>
    );
};

const OrderStatusTableFooter = ({ order }: { order: Order }) => {
    return (
        <TableFooter>
            <TableRow>
                <DesktopAndTabletDevice>
                    <TableCell colSpan={2}>
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 290px"
                            gap={2}
                        >
                            <Box
                                display="grid"
                                gridTemplateColumns="200px 200px"
                                gap={2}
                            >
                                <Box>
                                    <AddressDisplay
                                        title="Shipped To"
                                        address={order.shippingAddress}
                                    />
                                </Box>
                                <Box>
                                    <AddressDisplay
                                        title="Billed To"
                                        address={order.billingAddress}
                                    />
                                </Box>
                            </Box>
                            <Box>
                                <OrderTotals order={order} totalOwed={false} />
                            </Box>
                        </Box>
                    </TableCell>
                </DesktopAndTabletDevice>
                <MobileDevice>
                    <TableCell colSpan={2}>
                        <Stack spacing={3}>
                            <Box>
                                <AddressDisplay
                                    title="Shipped To"
                                    address={order.shippingAddress}
                                />
                            </Box>
                            <Box>
                                <AddressDisplay
                                    title="Billed To"
                                    address={order.billingAddress}
                                />
                            </Box>
                            <Divider />
                            <OrderTotals order={order} totalOwed={false} />
                        </Stack>
                    </TableCell>
                </MobileDevice>
            </TableRow>
        </TableFooter>
    );
};

const OrderStatus = (props: { orderNumber: string; zipCode: string }) => {
    const { orderNumber, zipCode } = props;
    const [order, setOrder] = React.useState<Order | null>(null);
    const { isLoggedIn } = React.useContext(UserAuth);
    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        setErrorMessage('');
        getOrderStatus(orderNumber, zipCode).then((o: Order) => {
            if (o.orderNumber.length > 5) {
                setOrder(o);
            } else {
                setErrorMessage('Order not found');
            }
        });
    }, [orderNumber, zipCode]);

    return (
        <ScContainer>
            {errorMessage.length > 0 ? (
                <FullPageIconBanner
                    icon={<ShoppingBasket />}
                    title="Order Not Found"
                    subtitle={`'${orderNumber}' was not found or zip code '${zipCode}' did not match the billing zip code on the order.`}
                />
            ) : (
                <>
                    {order ? (
                        <>
                            <PageHeader>Order Status</PageHeader>
                            <OrderStatusTable order={order} />
                            {isLoggedIn() && (
                                <>
                                    <Typography variant="title">
                                        Other Orders
                                    </Typography>
                                    <Orders currentOrder={order} />
                                </>
                            )}
                            <QuestionsAboutYourOrderBox
                                orderNumber={order.orderNumber}
                                withCustomerSupportPhoto
                            />
                        </>
                    ) : (
                        <LoadingSpinner center sx={{ mt: 3 }} />
                    )}
                </>
            )}
        </ScContainer>
    );
};

const AddressDisplay = ({
    title,
    address,
}: {
    title: string;
    address: Contact;
}) => {
    return (
        <>
            <Typography variant="bold">{title}</Typography>
            {address ? (
                <>
                    {address.firstName !== null &&
                        address.lastName !== null && (
                            <Typography component="div">
                                {address.firstName + ' ' + address.lastName}
                            </Typography>
                        )}
                    <Address contactInfo={address} />
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default function PageOrderStatus() {
    const [searchParams] = useSearchParams();

    const orderNumber =
        searchParams.get('orderNumber') ??
        searchParams.get('order_number') ??
        searchParams.get('orderid') ??
        '';
    const zipCode =
        searchParams.get('zipCode') ??
        searchParams.get('zip_code') ??
        searchParams.get('zipcode') ??
        '';
    if (
        orderNumber &&
        orderNumber?.length > 0 &&
        zipCode &&
        zipCode?.length > 4
    ) {
        return <OrderStatus orderNumber={orderNumber} zipCode={zipCode} />;
    }

    return (
        <ScContainer size="small" sx={{ mt: 3 }}>
            <OrderStatusForm orderNumber={orderNumber} zipCode={zipCode} />
        </ScContainer>
    );
}
