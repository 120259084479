import React from 'react';
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import { FormikProps } from 'formik';
import theme from '../../Theme';

interface CommentFieldWithLabelProps {
    id: string;
    name: string;
    formik: FormikProps<any>;
    label: string;
    required: boolean;
    sx?: object;
}

export const CommentFieldWithLabel: React.FC<CommentFieldWithLabelProps> = ({
    id,
    name,
    formik,
    label,
    required,
    sx,
}) => {
    return (
        <FormControl fullWidth sx={sx}>
            <FormLabel htmlFor={id}>
                {required && (
                    <span style={{ color: theme.palette.error.main }}>*</span>
                )}
                {label}
            </FormLabel>
            <textarea
                id={id}
                name={name}
                value={formik.values[name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                rows={5}
                style={{
                    color: 'inherit',
                    width: '100%',
                    padding: '0.625rem',
                    border: `1px solid ${theme.palette.grey[500]}`,
                    borderRadius: theme.shape.borderRadius,
                    boxShadow: 'inherit',
                    resize: 'vertical',
                    fontFamily: theme.typography.fontFamily,
                    fontSize: '1rem',
                    backgroundColor: 'inherit',
                }}
            />
            {formik.touched[name] && formik.errors[name] && (
                <FormHelperText error>{formik.errors[name]}</FormHelperText>
            )}
        </FormControl>
    );
};
