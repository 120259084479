import { Box } from '@mui/material';
import { PromotionsImageCard } from './PromotionsImageCard';
import theme from '../../Theme';
import { Promotion } from '../../types';
import { PromoFlag } from '../../Components/PromoCountdown/PromoCountdown';

interface PromotionCardProps {
    promotion: Promotion;
    onClick?: () => void;
}

const getFormattedDate = (date: Date) => {
    return new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    }).format(date);
};

const getCountdownText = (promotion: Promotion) => {
    const promoEndDate = new Date(promotion.dateEnd);
    const now = new Date();
    const timeDiff = promoEndDate.getTime() - now.getTime();

    if (timeDiff <= 0) {
        return 'This promo has ended';
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    );
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

    const fiveDaysFromNow = new Date();
    const thirtyDaysFromNow = new Date();
    fiveDaysFromNow.setDate(fiveDaysFromNow.getDate() + 5);
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);

    if (promoEndDate <= fiveDaysFromNow) {
        if (days === 1) {
            return `This promo ends in ${hours}:${minutes}:${seconds}`;
        } else if (days < 5 && days > 0) {
            return `This promo will end in ${days} ${days === 1 ? 'day' : 'days'}`;
        }
    } else if (promoEndDate <= thirtyDaysFromNow) {
        const formattedDate = getFormattedDate(promoEndDate);
        return `Ends ${formattedDate}`;
    } else {
        return '';
    }
};

const stripHtmlTags = (str: string) => {
    return str.replace(/<\/?[^>]+(>|$)/g, '');
};

export const PromotionCard = ({ promotion, onClick }: PromotionCardProps) => {
    const countdownText = getCountdownText(promotion);
    const dateId = `promo-date-${promotion.id}`;

    const ariaLabel = promotion.displayText
        ? `${stripHtmlTags(promotion.displayText)}${countdownText ? ` - ${countdownText}` : ''} - ${stripHtmlTags(promotion.title)} - ${stripHtmlTags(promotion.description)}`
        : `${stripHtmlTags(promotion.title)} - ${stripHtmlTags(promotion.description)}`;

    return (
        <Box
            onClick={onClick}
            tabIndex={0}
            role="button"
            aria-label={ariaLabel}
            onKeyDown={event => {
                if (event.key === 'Enter' || event.key === ' ') {
                    onClick?.();
                }
            }}
            sx={{
                p: 2,
                pb: 3,
                background: theme.palette.background.paper,
                position: 'relative',
                border: `1px solid ${theme.palette.grey[400]}`,
                '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    border: `2px solid ${theme.palette.primary.light}`,
                    transition: 'opacity 0.5s ease-out',
                    opacity: 0,
                    pointerEvents: 'none',
                },
                '&:hover::before': {
                    opacity: 1,
                },
                outline: 'none',
                '&:focus-visible::before': {
                    opacity: 1,
                },
            }}
        >
            <Box id={dateId} sx={{ display: 'none' }}>
                {promotion.dateEnd
                    ? new Intl.DateTimeFormat('en-US', {
                          month: 'long',
                          day: 'numeric',
                          year: 'numeric',
                      }).format(new Date(promotion.dateEnd))
                    : ''}
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 1,
                }}
            >
                {promotion && promotion.displayTextColor ? (
                    <PromoFlag
                        promotion={{
                            ...promotion,
                            shortTitle: promotion.displayText,
                        }}
                    />
                ) : null}
            </Box>
            <PromotionsImageCard
                title={promotion.title}
                image={promotion.imageUrl}
                withBorder={false}
                titleAlign="left"
                maxLines={2}
                cardContentProps={{
                    m: 0,
                    p: 0,
                    position: 'relative',
                    paddingBottom: '0 !important',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        padding: '0',
                    }}
                    dangerouslySetInnerHTML={{
                        __html: promotion.description,
                    }}
                />
            </PromotionsImageCard>
        </Box>
    );
};
