import { useQuery } from '@apollo/client';
import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import PageBanners from '../../Components/Banners/Banners';
import PageBreadcrumbs from '../../Components/Breadcrumbs/PageBreadcrumbs';
import ScContainer from '../../Components/Layout/ScContainer/ScContainer';
import TwoColumnLayout from '../../Components/_base/Layouts/TwoColumn';
import {
    DesktopAndTabletDevice,
    MobileDevice,
} from '../../Contexts/DeviceTypeProvider';
import {
    Maybe,
    Promotion,
    PromotionCategories,
    PromotionCategoryLink,
} from '../../types';
import PromotionSidebarMobile from './PromotionSidebarMobile';
import PromotionSidebarDesktop from './PromotionSidebarDesktop';
import { QL_PROMOTIONS } from '../../Graphql/queries';
import { PromotionLeftSidebar } from './PromotionLeftSidebar';
import PromotionsLoading from './PromotionLoading';
import { PromotionsLayout } from './PromotionsLayout';
import { UserAuth } from '../../Contexts/UserAuthProvider';
import { SSRContext } from '../../Contexts/SSRProvider';
import window from 'global/window';

const filterByTags = (tags: string[], promotions: Promotion[]) => {
    const promos = [];
    for (const k in promotions) {
        const promo = promotions[k];
        for (const t in tags) {
            const tag = tags[t];
            if (
                promo.tags !== undefined &&
                promo.tags?.map(v => v?.toLowerCase()).indexOf(tag) !== -1
            ) {
                promos.push(promotions[k]);
            }
        }
    }
    return promos;
};
const filterByCategories = (categories: string[], promotions: Promotion[]) => {
    const promos = [];
    for (const k in promotions) {
        const promo = promotions[k];
        for (const c in categories) {
            const cat = categories[c];
            if (
                promo.categories !== undefined &&
                promo.categories?.map(v => v?.toLowerCase()).indexOf(cat) !== -1
            ) {
                promos.push(promotions[k]);
            }
        }
    }
    return promos;
};

const getFilteredPromotions = (tags: string[], promotions: Promotion[]) => {
    if (tags.length === 0) {
        return promotions;
    }
    const all = filterByTags(tags, promotions).concat(
        filterByCategories(tags, promotions),
    );
    return all.filter((value, index, self) => self.indexOf(value) === index);
};

export default function PagePromotion() {
    const { getPricingTierId } = React.useContext(UserAuth);
    const { isClientSide } = React.useContext(SSRContext);
    const [promotions, setPromotions] = React.useState<Promotion[]>([]);
    const [allPromotions, setAllPromotions] = React.useState<Promotion[]>([]);
    const [promosSet, setPromosSet] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [tags, setTags] = React.useState<string[]>([]);
    const [categories, setCategories] = React.useState<PromotionCategories[]>(
        [],
    );
    const [selectedCategoryLink, setSelectedCategoryLink] = React.useState<
        PromotionCategoryLink | undefined
    >(undefined);

    React.useEffect(() => {
        if (isClientSide()) {
            let tags = '';
            if (window) {
                const urlSearch = new URLSearchParams(window.location.search);
                tags = urlSearch.get('tags') ?? '';
            }
            const t = tags
                .replaceAll('-', ' ')
                .split(',')
                .filter(v => v !== '');
            setTags(t);
            setIsLoading(true);
            setPromotions([]);
            setTimeout(() => {
                setPromotions(getFilteredPromotions(t, allPromotions));
                setIsLoading(false);
            }, 10);
            if (t.length > 0 && categories.length > 0) {
                categories.forEach((c: PromotionCategories) => {
                    c.links.forEach((cl: Maybe<PromotionCategoryLink>) => {
                        const t2 = tags;
                        if (cl && cl.tags && cl.tags.indexOf(t2) > -1) {
                            setSelectedCategoryLink(cl);
                        }
                    });
                });
            }
        }
    }, []);

    const { data, loading } = useQuery(QL_PROMOTIONS, {
        variables: {
            siteId: 2,
            page: 1,
            pricingTierId: getPricingTierId(),
        },
    });

    if (loading) {
        return <PromotionsLoading />;
    } else if (
        promotions.length === 0 &&
        data.sitePromotions.results &&
        !loading &&
        !promosSet
    ) {
        setAllPromotions(data.sitePromotions.results);
        setPromotions(data.sitePromotions.results);
        setCategories(data.sitePromotionCategories.categories);
        setPromosSet(true);
        setIsLoading(false);
    }

    return (
        <>
            <ScContainer>
                <PageBreadcrumbs
                    breadcrumbs={[
                        { title: 'Home', url: '/' },
                        { title: 'Promotions', url: '/promotions' },
                    ]}
                />
            </ScContainer>
            <ScContainer
                variant="alt"
                title={data.sitePromotionCategories.title}
            >
                <TwoColumnLayout
                    sx={{
                        mx: {
                            xs: 2,
                            md: 0,
                        },
                    }}
                    mobileReverseLayout={true}
                    leftSideComponent={
                        <PromotionLeftSidebar>
                            {data.sitePromotionCategories.categories.length ? (
                                <>
                                    <MobileDevice>
                                        <PromotionSidebarMobile
                                            categories={categories}
                                            selectedCategoryLink={
                                                selectedCategoryLink
                                            }
                                            setSelectedCategoryLink={
                                                setSelectedCategoryLink
                                            }
                                        />
                                    </MobileDevice>
                                    <DesktopAndTabletDevice>
                                        <PromotionSidebarDesktop
                                            categories={categories}
                                            tags={tags}
                                        />
                                    </DesktopAndTabletDevice>
                                </>
                            ) : null}
                        </PromotionLeftSidebar>
                    }
                    rightSideComponent={
                        <>
                            <PageBanners />
                            {promotions.length > 0 || isLoading ? (
                                <PromotionsLayout promotions={promotions} />
                            ) : (
                                <Paper sx={{ p: 2 }}>
                                    <Typography variant="bold">
                                        No Promotions found
                                    </Typography>
                                </Paper>
                            )}
                        </>
                    }
                />
            </ScContainer>
        </>
    );
}
