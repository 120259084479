import * as React from 'react';
import {
    ExpertReviewClass,
    ExpertReviewField,
    ExpertReviewItem,
    Maybe,
} from '../types';

export interface ExpertReviewSortOption {
    name: string;
    value: string;
}

const DEFAULT_SORT_VALUE = '-1';
const DEFAULT_FILTER_VALUE = '-1';

export const ExpertReviewsContext = React.createContext({
    page: 1,
    totalPages: 1,
    currentPage: 1,
    parentClass: {} as ExpertReviewClass,
    defaultSortValue: DEFAULT_SORT_VALUE,
    defaultFilterValue: DEFAULT_FILTER_VALUE,
    setPage: (val: number) => {
        return;
    },
    setTotalPages: (val: number) => {
        return;
    },
    setCurrentPage: (val: number) => {
        return;
    },
    sort: DEFAULT_SORT_VALUE,
    setSort: (val: string) => {
        return;
    },
    filter: DEFAULT_FILTER_VALUE,
    setFilter: (val: string) => {
        return;
    },
    setItems: (val: ExpertReviewItem[]): ExpertReviewItem[] => {
        return [];
    },
    getItems: (): ExpertReviewItem[] => {
        return [];
    },
    sortOptions: [] as ExpertReviewSortOption[],
    filterOptions: [] as ExpertReviewSortOption[],
    setSortOptions: (val: ExpertReviewSortOption[]) => {
        return;
    },
    setFilterOptions: (val: ExpertReviewSortOption[]) => {
        return;
    },
    getSortOptions: (): ExpertReviewSortOption[] => {
        return [];
    },
    getFilterOptions: (): ExpertReviewSortOption[] => {
        return [];
    },
});

export function ExpertReviewsProvider(props: {
    parentClass: ExpertReviewClass;
    items?: ExpertReviewItem[];
    page?: number;
    sort?: string;
    filter?: string;
    children: any;
}) {
    const defaultSortValue = DEFAULT_SORT_VALUE;
    const defaultFilterValue = DEFAULT_FILTER_VALUE;
    const parentClass = props.parentClass;
    const [page, setPageNumber] = React.useState(props.page ?? 1);
    const [items, setPageItems] = React.useState(props.items ?? []);
    const [totalPages, setTotalPages] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [sort, setPageSort] = React.useState(props.sort ?? defaultSortValue);
    const [filter, setPageFilter] = React.useState(
        props.filter ?? defaultFilterValue,
    );

    const [sortOptions, setPageSortOptions] = React.useState<
        ExpertReviewSortOption[]
    >([]);
    const [filterOptions, setPageFilterOptions] = React.useState<
        ExpertReviewSortOption[]
    >([]);

    const setItems = (val: ExpertReviewItem[]) => {
        setPageItems(val);
        return getItems();
    };
    const setPage = (val: number) => {
        setPageNumber(val);
    };
    const setSort = (val: string) => {
        setPageSort(val);
    };
    const setSortOptions = (val: ExpertReviewSortOption[]) => {
        setPageSortOptions(val);
    };
    const setFilter = (val: string) => {
        setPageFilter(val);
    };
    const setFilterOptions = (val: ExpertReviewSortOption[]) => {
        setPageFilterOptions(val);
    };

    const getSortOptions = (): ExpertReviewSortOption[] => {
        const sortOptions: ExpertReviewSortOption[] = [
            {
                name: 'Overall Rating (Default)',
                value: DEFAULT_SORT_VALUE,
            },
        ];
        parentClass.fields.forEach(
            (expRevField: Maybe<ExpertReviewField>, i: number) => {
                if (expRevField) {
                    sortOptions.push({
                        name: expRevField.name + ' Rating',
                        value: `${expRevField.name ?? ''}`,
                    });
                }
            },
        );
        sortOptions.sort(
            (a: ExpertReviewSortOption, b: ExpertReviewSortOption) =>
                a.value < b.value ? -1 : 1,
        );
        return sortOptions;
    };

    const getFilterOptions = (): ExpertReviewSortOption[] => {
        const filterOptions: ExpertReviewSortOption[] = [
            {
                name: 'All Brands',
                value: DEFAULT_FILTER_VALUE,
            },
        ];
        items.forEach((expRev: ExpertReviewItem, i: number) => {
            if (expRev) {
                const exists = filterOptions.some(
                    (opt: ExpertReviewSortOption, i: number) =>
                        opt.name ===
                        (expRev.item?.manufacturerName ?? 'Unknown'),
                );
                if (!exists) {
                    filterOptions.push({
                        name: expRev.item?.manufacturerName ?? 'Unknown',
                        value: `${expRev.item?.manufacturerName}`,
                    });
                }
            }
        });
        filterOptions.sort(
            (a: ExpertReviewSortOption, b: ExpertReviewSortOption) =>
                a.value < b.value ? -1 : 1,
        );
        return filterOptions;
    };

    const _getFieldRating = (expItem: ExpertReviewItem, field: string) => {
        const fields = expItem.fields.filter(
            (f: Maybe<ExpertReviewField>, n: number) =>
                f !== null && f.name === field,
        );
        if (fields[0]) {
            const field = fields[0];
            return field.rating;
        }
        return -1;
    };

    const getItems = (): ExpertReviewItem[] => {
        const filteredItems = items.filter(
            (expRev: ExpertReviewItem, i: number) =>
                filter === DEFAULT_FILTER_VALUE ||
                expRev.item?.manufacturerName === filter,
        );
        filteredItems.sort((a: ExpertReviewItem, b: ExpertReviewItem) => {
            const ratingA = _getFieldRating(a, sort);
            const ratingB = _getFieldRating(b, sort);
            return ratingB < ratingA ? -1 : 1;
        });
        return filteredItems;
    };

    return (
        <ExpertReviewsContext.Provider
            value={{
                parentClass,
                page,
                setPage,
                defaultSortValue,
                defaultFilterValue,
                sort,
                setSort,
                sortOptions,
                setSortOptions,
                filter,
                setFilter,
                filterOptions,
                setFilterOptions,
                totalPages,
                setTotalPages,
                currentPage,
                setCurrentPage,
                getItems,
                setItems,
                getSortOptions,
                getFilterOptions,
            }}
        >
            {props.children}
        </ExpertReviewsContext.Provider>
    );
}
