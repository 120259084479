import { Typography } from '@mui/material';
import React from 'react';
import { LeavesWarehouseIn } from '../../../../Components/Item/ItemInfo';
import { Section } from '../../../../Components/_base/BBQGuysComponents';
import { SiteContext } from '../../../../Contexts/SiteProvider';
import { currency } from '../../../../functions';
import { Item } from '../../../../types';

const ItemShippingAndReturnsInfo = (props: { item: Item }) => {
    const { freeShippingAmount, flatRateShippingAmount } =
        React.useContext(SiteContext);
    return props.item ? (
        <Section title="Shipping &amp; Returns" id="sc-policy-shipping">
            <Typography variant="green" component="h3">
                Free Shipping on orders over{' '}
                {currency.format(freeShippingAmount).replace('.00', '')}
            </Typography>
            <p>
                Ship your entire order to your home for free, especially the big
                stuff!
                <br />
                <Typography variant="muted" component="em">
                    *Continental US only
                </Typography>
            </p>
            <Typography variant="subtitle" component="h3">
                When will my item arrive?
            </Typography>
            <p>
                Once shipped, items usually arrive within 3-5 business days.
                While UPS and FedEx are our carriers for smaller items, be aware
                that{' '}
                <strong>larger items and orders are shipped via freight</strong>
                . The freight process is a bit more complicated than what
                we&apos;ve all come to expect for small parcels, so it&apos;s
                important that you&apos;re prepared and understand what&apos;s
                required of you well before delivery day.
            </p>
            <Typography variant="body1" component="ul" sx={{ mb: 3 }}>
                <li>
                    Most freight products {''}
                    <strong>ship on a pallet</strong>
                </li>
                <li>
                    Some carriers will call to schedule a delivery window, while
                    others will deliver the shipment to a reasonably accessible
                    location closest to your residence (their goal is to deliver
                    as near a garage door as they can).
                </li>
                <li>
                    <strong>
                        We&apos;ll specify which process you can expect in your
                        shipping confirmation email.
                    </strong>
                </li>
            </Typography>
            <Typography variant="subtitle" component="h4">
                <strong>
                    For carriers that schedule delivery appointments:
                </strong>
            </Typography>
            <p>
                Carriers that schedule delivery appointments require you to be
                home for delivery and provide your signature. The delivery is
                made with a semi-truck with lift gate services, and the{' '}
                <strong>shipment is dropped off at the curb</strong>. If you
                authorize the shipping company to leave the delivery without a
                signature, BBQGuys can&apos;t be held responsible for damages
                that occur during delivery. As complicated as this process may
                seem, we&apos;ve only scratched the surface here — please review
                our detailed guide to freight delivery so there are no surprises
                when a freight shipment heads your way.
            </p>
            <Typography variant="subtitle" component="h4">
                <strong>
                    For carriers that deliver without an appointment:
                </strong>
            </Typography>
            <p>
                Carriers that deliver without an appointment don&apos;t require
                you to be home or set up a delivery window, and you aren&apos;t
                required to sign for the pallet(s). Instead of scheduling an
                appointment, arranging your schedule to be home for the
                delivery, and signing to confirm receipt, you can sit back and
                let your items come to you. If you&apos;re home at the time of
                the drop-off, you can request the driver place your delivery in
                the garage; if you&apos;re out of the house, the driver will
                leave it near the garage or doorstep.
            </p>
            <LeavesWarehouseIn shipsIn={props.item.shipsIn} size="large" />

            <Typography variant="subtitle" component="h3">
                {currency.format(flatRateShippingAmount)} Flat-Rate Shipping
            </Typography>

            <p>
                Anything under{' '}
                {currency.format(freeShippingAmount).replace('.00', '')} ships
                at one low price. If your order increases, your shipping stays
                the same!
            </p>
            <Typography variant="subtitle" component="h3">
                30-Day Returns
            </Typography>
            <p>
                Return all new, unused, and unassembled items in their original
                packaging, and we&apos;ll give you a full refund minus the cost
                of the original AND return shipping.
            </p>
            <Typography variant="subtitle" component="h3">
                No Restocking Fee
            </Typography>
            <p>
                Most companies charge a restocking fee. We just don&apos;t do
                business that way.
            </p>
        </Section>
    ) : null;
};

export default ItemShippingAndReturnsInfo;
