import { FormikProvider, useFormik } from 'formik';
import window from 'global/window';
import { useState } from 'react';
import { NewsletterSignupSchema } from '../../../Schemas/Schemas';
import ScContainer from '../ScContainer/ScContainer';
import theme from '../../../Theme';
import { Alert, AlertTitle, Typography, Grid } from '@mui/material';
import { isDevelopment } from '../../../config';
import { Box } from '@mui/system';
import { EmailWithLabel } from '../../../Pages/Account/PageEditMyAccount/Components/EmailWithLabel';
import { LoadingButton } from '@mui/lab';
import SocialMediaBar from './SocialBar';

interface NewsletterValues {
    email: string;
}

/**
 * Add Email Address
 * @param {string} email              Email address, unencoded
 * @param {string} listId             The list ID
 */
export const addEmailAddressToNewsletterList = (
    email: string,
    listId?: string,
) => {
    return new Promise((resolve, reject) => {
        // Check if `emailAdder` was loaded in from DTM yet.
        if (typeof window.emailAdder !== 'undefined') {
            listId = listId === undefined ? '' : listId;
            window.emailAdder.addEmail(email, listId, () => {
                resolve(email);
            });
        } else {
            reject('emailAdder not defined');
        }
    });
};

export default function FooterNewsletterSignup() {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema: NewsletterSignupSchema,
        onSubmit: (values: NewsletterValues, actions: any) => {
            actions.setSubmitting(true);
            addEmailAddressToNewsletterList(values.email)
                .then(() => {
                    setSuccess(true);
                })
                .catch(error => {
                    console.error(error);
                    setError(error);
                })
                .finally(() => {
                    actions.setSubmitting(false);
                });
        },
    });
    const handdleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        formik.handleSubmit(e);
    };

    return (
        <ScContainer
            sx={{
                backgroundColor: theme.palette.brand.lightesttan,
                borderTop: '1px solid ' + theme.palette.brand.lighttan,
            }}
            fullWidth
        >
            <FormikProvider value={formik}>
                <form onSubmit={handdleFormSubmit} action="/">
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            md={8}
                            lg={5}
                            sx={{
                                textAlign: 'center',
                                margin: '0 auto',
                                py: 3,
                                px: 2,
                                maxWidth: '700px !important',
                            }}
                        >
                            <Typography
                                variant="bold"
                                component="h2"
                                sx={{ pb: 2, fontSize: '1.15em' }}
                            >
                                Join our email list for sales, recipes, buying
                                guides, and more.
                            </Typography>

                            {success ? (
                                <Alert severity="success" sx={{ mb: 1 }}>
                                    <AlertTitle>
                                        You have been added to our email list!
                                    </AlertTitle>
                                    Look for us in your inbox and be sure to add{' '}
                                    <mark>@bbqguys.com</mark> to your contact
                                    list.
                                </Alert>
                            ) : (
                                <>
                                    {error && (
                                        <Alert severity="error" sx={{ mb: 1 }}>
                                            <AlertTitle>
                                                Oops, An error occurred!
                                            </AlertTitle>
                                            <div>
                                                There was a problem saving the
                                                email address you provided.
                                                Please try again.
                                            </div>
                                            {isDevelopment() && (
                                                <pre>{error}</pre>
                                            )}
                                        </Alert>
                                    )}
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            mb: 1,
                                        }}
                                    >
                                        <EmailWithLabel
                                            formik={formik}
                                            name="email"
                                            id="email"
                                            label="Email Address"
                                            labelHidden
                                            autoCorrect="off"
                                            spellCheck="false"
                                            placeholder="Enter your email address"
                                            inputProps={{
                                                sx: {
                                                    height: 34,
                                                    p: 1.5,
                                                },
                                            }}
                                        />
                                        <LoadingButton
                                            type="submit"
                                            disableElevation
                                            variant="contained"
                                            color="secondary"
                                            loading={formik.isSubmitting}
                                            sx={{
                                                width: { xs: '38%', md: '20%' },
                                                fontSize:
                                                    theme.typography.h5
                                                        .fontSize,
                                                height: 60,
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                            }}
                                            aria-label="Submit email to join the mailing list"
                                        >
                                            Join
                                        </LoadingButton>
                                    </Box>
                                </>
                            )}
                            <SocialMediaBar />
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </ScContainer>
    );
}
