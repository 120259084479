import { Box, Typography } from '@mui/material';
import React from 'react';
import { BBQPagination } from '../../../../../Components/Pagination/BBQPagination';
import { QuestionAndAnswersContext } from '../../../../../Contexts/QuestionAndAnswersProvider';
import { SSRContext } from '../../../../../Contexts/SSRProvider';
import { Question } from '../../../../../types';
import QuestionsAndAnswersContent from './QuestionsAndAnswersContent';
import QuestionsAndAnswersHeader from './QuestionsAndAnswersHeader';

const QuestionsAndAnswersDisplay = (props: {
    questions: Question[];
    totalPages: number;
    currentPage: number;
}) => {
    const { questions, totalPages, currentPage } = props;
    const { isClientSide } = React.useContext(SSRContext);
    const questionsRef = React.useRef(null);
    const { setPage, item } = React.useContext(QuestionAndAnswersContext);
    const executeScroll = () => {
        if (isClientSide() && questionsRef.current) {
            const cur = questionsRef.current as HTMLDivElement;
            window.scrollTo(0, cur.offsetTop - 110);
        }
    };

    const handlePaginationChange = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        executeScroll();
        setPage(value);
    };

    return (
        <>
            {questions.length === 0 ? (
                <>
                    <QuestionsAndAnswersHeader item={item} />
                    <Typography variant="bold">No questions found</Typography>
                </>
            ) : (
                <div id="qna" ref={questionsRef}>
                    <QuestionsAndAnswersHeader item={item} />
                    {questions.map((question: Question, i: number) => {
                        return (
                            <QuestionsAndAnswersContent
                                question={question}
                                key={'qna-question-' + question.id}
                            />
                        );
                    })}
                    {totalPages > 1 ? (
                        <Box sx={{ marginTop: '1rem' }} textAlign="center">
                            <BBQPagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePaginationChange}
                            />
                        </Box>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default QuestionsAndAnswersDisplay;
